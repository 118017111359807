import {format} from 'date-fns';
import { Button } from 'react-bootstrap';
import { withTranslation, useTranslation} from 'react-i18next';

export const  COLUMNS=() =>{ 
	const {t}= useTranslation()
	return [

	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
	},
	{
		Header : t("common.exam") ,
		Footer : t("common.exam"),
		accessor: 'examen',
	},
	{
		Header : t("common.results"),
		Footer : t("common.results"),
		Cell: ({ value }) => {
			return <Button
					as='a'
					variant='warning light'
					href={value}
					target="_blank"
					className='btn-card mt-3 mr-3'
					>
						<i className="la la-file-download"></i>
					</Button>
		},
		accessor: 'result_file',
	},
	{
		Header :t("common.send-to") ,
		Footer : t("common.esend-toxam") ,
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		accessor: 'send_at',
	},
	{
		Header : t("common.carried-by") ,
		Footer :t("common.carried-by") ,
		accessor: 'send_by',
	},
];
 }
 