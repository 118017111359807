import {
    getAllAccordRequest,
    validateAccord,
    sendAccordMessage,
    getAccordDetails,
    rejectAccord,
    getProformas,
    getProforma,
    validateProforma,
    rejectProforma,
    rejectProformaLine,
    restoreProformaLine,
    restoreProforma,
    getProformasKine,
    getProformaKine,
    validateProformaKine,
    rejectProformaKine,
    restoreProformaKine,
    rejectProformaKineLine,
    restoreProformaKineLine
} from '../../services/AccordPreaService';
import blobFileUpload from '../../services/BlobStorageService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const LIST_CONFIRMED_ACTION = '[listAccords action] confirmed listAccords';
export const LIST_FAILED_ACTION = '[listAccords action] failed listAccords';


export const VALIDATE_ACCORD_CONFIRMED_ACTION = '[validateAccord action] confirmed validateAccord';
export const VALIDATE_ACCORD_FAILED_ACTION = '[validateAccord action] failed validateAccord';


export const REJECT_ACCORD_CONFIRMED_ACTION = '[rejectAccord action] confirmed rejectAccord';
export const REJECT_ACCORD_FAILED_ACTION = '[rejectAccord action] failed rejectAccord';


export const SEND_MESSAGE_CONFIRMED_ACTION = '[sendMessage action] confirmed sendMessage';
export const SEND_MESSAGE_NOTIFS_FAILED_ACTION = '[sendMessage action] failed sendMessage';


export const GET_ACCORD_CONFIRMED_ACTION = '[getAccord action] confirmed getAccord';
export const GET_ACCORD_NOTIFS_FAILED_ACTION = '[getAccord action] failed getAccord';


export const GET_PROFORMAS_KINE_CONFIRMED_ACTION = '[getProformasKine action] confirmed getProformasKine';
export const GET_PROFORMAS_KINE_FAILED_ACTION = '[getProformasKine action] failed getProformasKine';


export const GET_PROFORMA_KINE_CONFIRMED_ACTION = '[getProformaKine action] confirmed getProformaKine';
export const GET_PROFORMA_KINE_FAILED_ACTION = '[getProformaKine action] failed getProformaKine';


export const VALIDATE_PROFORMA_KINE_CONFIRMED_ACTION = '[validateProformaKine action] confirmed validateProformaKine';
export const VALIDATE_PROFORMA_KINE_FAILED_ACTION = '[validateProformaKine action] failed validateProformaKine';


export const REJECT_PROFORMA_KINE_CONFIRMED_ACTION = '[rejectProformaKine action] confirmed rejectProformaKine';
export const REJECT_PROFORMA_KINE_FAILED_ACTION = '[rejectProformaKine action] failed rejectProformaKine';


export const VALIDATE_PROFORMA_KINE_LINE_CONFIRMED_ACTION = '[validateProformaKineLine action] confirmed validateProformaKineLine';
export const VALIDATE_PROFORMA_KINE_LINE_FAILED_ACTION = '[validateProformaKineLine action] failed validateProformaKineLine';


export const REJECT_PROFORMA_KINE_LINE_CONFIRMED_ACTION = '[rejectProformaKineLine action] confirmed rejectProformaKineLine';
export const REJECT_PROFORMA_KINE_LINE_FAILED_ACTION = '[rejectProformaKineLine action] failed rejectProformaKineLine';


export const RESTORE_PROFORMA_KINE_CONFIRMED_ACTION = '[restoreProformaKine action] confirmed restoreProformaKine';
export const RESTORE_PROFORMA_KINE_FAILED_ACTION = '[restoreProformaKine action] failed restoreProformaKine';

export const GET_PROFORMAS_CONFIRMED_ACTION = '[getProformas action] confirmed getProformas';
export const GET_PROFORMAS_FAILED_ACTION = '[getProformas action] failed getProformas';


export const GET_PROFORMA_CONFIRMED_ACTION = '[getProforma action] confirmed getProforma';
export const GET_PROFORMA_FAILED_ACTION = '[getProforma action] failed getProforma';


export const VALIDATE_PROFORMA_CONFIRMED_ACTION = '[validateProforma action] confirmed validateProforma';
export const VALIDATE_PROFORMA_FAILED_ACTION = '[validateProforma action] failed validateProforma';


export const REJECT_PROFORMA_CONFIRMED_ACTION = '[rejectProforma action] confirmed rejectProforma';
export const REJECT_PROFORMA_FAILED_ACTION = '[rejectProforma action] failed rejectProforma';


export const VALIDATE_PROFORMA_LINE_CONFIRMED_ACTION = '[validateProformaLine action] confirmed validateProformaLine';
export const VALIDATE_PROFORMA_LINE_FAILED_ACTION = '[validateProformaLine action] failed validateProformaLine';


export const REJECT_PROFORMA_LINE_CONFIRMED_ACTION = '[rejectProformaLine action] confirmed rejectProformaLine';
export const REJECT_PROFORMA_LINE_FAILED_ACTION = '[rejectProformaLine action] failed rejectProformaLine';


export const RESTORE_PROFORMA_CONFIRMED_ACTION = '[restoreProforma action] confirmed restoreProforma';
export const RESTORE_PROFORMA_FAILED_ACTION = '[restoreProforma action] failed restoreProforma';

export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';

var I18n = require('react-redux-i18n').I18n;

export function listAllAccordRequestAction() {
    return (dispatch) => {
        getAllAccordRequest()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedListAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.list.error')+error.response?.data?.message);
            dispatch(listFailedAction(error.response?.data?.message));
        });
    };
}

export function validateAccordAction(data, history) {
    return (dispatch) => {
        validateAccord(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedAccordValidateAction(data));
            history.push("/accords-list")
            successToastr(I18n.t('accord.validate.success'));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.validate.error')+error.response?.data?.message);
            dispatch(AccordValidationFailedAction(error.response?.data?.message));
        });
    };
}

export function rejectAccordAction(accordId, data, history) {
    return (dispatch) => {
        rejectAccord(accordId, data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedAccordRejectionAction(data));
            history.push("/accords-list");
            successToastr(I18n.t('accord.reject.success'));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.reject.error')+error.response?.data?.message);
            dispatch(AccordRejectionFailedAction(error.response?.data?.message));
        });
    };
}

export function sendAccordMessageAction(data, history) {
    return async (dispatch) => {
        if (data.fileSrc) {
            let resImg = await blobFileUpload([data.fileSrc], 'amp-providers-container');
            data['docUrl'] = resImg[0];
        }

        sendAccordMessage(data)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedSendAccordMessageAction(data));
            successToastr(I18n.t('accord.sendMessage.success'));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.sendMessage.error')+error.response?.data?.message);
            dispatch(SendAccordMessagelFailedAction(error.response?.data?.message));
        });
    };
}

export function getAccordAction(accordId) {
    return (dispatch) => {
        getAccordDetails(accordId)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetAccordAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.sendMessage.error')+error.response?.data?.message);
            dispatch(getAccordFailedAction(error.response?.data?.message));
        });
    };
}

export function getProformasAction() {
    return (dispatch) => {
        getProformas()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetProformasAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.getProformas.error')+error.response?.data?.message);
            dispatch(getProformasFailedAction(error.response?.data?.message));
        });
    };
}

export function getProformaAction(id) {
    return (dispatch) => {
        getProforma(id)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetProformaAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.getProforma.error')+error.response?.data?.message);
            dispatch(getProformaFailedAction(error.response?.data?.message));
        });
    };
}

export function validateProformaAction(data) {
    return (dispatch) => {
        validateProforma(data) 
        .then((response) => {
            dispatch(confirmedValidateProformaAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.validateProforma.error')+error.response?.data?.message);
            dispatch(validateProformaFailedAction(error.response?.data?.message));
        })
    }
}

export function rejectProformaAction(id, data) {
    return (dispatch) => {
        rejectProforma(id, data) 
        .then((response) => {
            dispatch(confirmedRejectProformaAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.rejectProforma.error')+error.response?.data?.message);
            dispatch(rejectProformaFailedAction(error.response?.data?.message));
        })
    }
}

export function validateProformaLineAction(data) {
    return (dispatch) => {
        restoreProformaLine(data) 
        .then((response) => {
            dispatch(confirmedValidateProformaLineAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.validateProforma.error_line')+error.response?.data?.message);
            dispatch(validateProformaLineFailedAction(error.response?.data?.message));
        })
    }
}

export function rejectProformaLineAction(id, data) {
    return (dispatch) => {
        rejectProformaLine(id, data) 
        .then((response) => {
            dispatch(confirmedRejectProformaLineAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.rejectProforma.error_line')+error.response?.data?.message);
            dispatch(rejectProformaLineFailedAction(error.response?.data?.message));
        })
    }
}

export function restoreProformaAction(data) {
    return (dispatch) => {
        restoreProforma(data) 
        .then((response) => {
            dispatch(confirmedRestoreProformaAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.restoreProforma.error')+error.response?.data?.message);
            dispatch(restoreProformaFailedAction(error.response?.data?.message));
        })
    }
}


export function getProformasKineAction() {
    return (dispatch) => {
        getProformasKine()
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetProformasKineAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.getProformas.error')+error.response?.data?.message);
            dispatch(getProformasKineFailedAction(error.response?.data?.message));
        });
    };
}

export function getProformaKineAction(id) {
    return (dispatch) => {
        getProformaKine(id)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetProformaKineAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.getProforma.error')+error.response?.data?.message);
            dispatch(getProformaKineFailedAction(error.response?.data?.message));
        });
    };
}

export function validateProformaKineAction(data) {
    return (dispatch) => {
        validateProformaKine(data) 
        .then((response) => {
            dispatch(confirmedValidateProformaKineAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.validateProforma.error')+error.response?.data?.message);
            dispatch(validateProformaKineFailedAction(error.response?.data?.message));
        })
    }
}

export function rejectProformaKineAction(id, data) {
    return (dispatch) => {
        rejectProformaKine(id, data) 
        .then((response) => {
            dispatch(confirmedRejectProformaKineAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.rejectProforma.error')+error.response?.data?.message);
            dispatch(rejectProformaKineFailedAction(error.response?.data?.message));
        })
    }
}

export function validateProformaKineLineAction(data) {
    return (dispatch) => {
        restoreProformaKineLine(data) 
        .then((response) => {
            dispatch(confirmedValidateProformaKineLineAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.validateProforma.error_line')+error.response?.data?.message);
            dispatch(validateProformaKineLineFailedAction(error.response?.data?.message));
        })
    }
}

export function rejectProformaKineLineAction(id, data) {
    return (dispatch) => {
        rejectProformaKineLine(id, data) 
        .then((response) => {
            dispatch(confirmedRejectProformaKineLineAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.rejectProforma.error_line')+error.response?.data?.message);
            dispatch(rejectProformaKineLineFailedAction(error.response?.data?.message));
        })
    }
}

export function restoreProformaKineAction(data) {
    return (dispatch) => {
        restoreProformaKine(data) 
        .then((response) => {
            dispatch(confirmedRestoreProformaKineAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('accord.restoreProforma.error')+error.response?.data?.message);
            dispatch(restoreProformaKineFailedAction(error.response?.data?.message));
        })
    }
}

export function confirmedRestoreProformaKineAction(data) {
    return {
        type: RESTORE_PROFORMA_KINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function restoreProformaKineFailedAction(data) {
    return {
        type: RESTORE_PROFORMA_KINE_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedRestoreProformaAction(data) {
    return {
        type: RESTORE_PROFORMA_CONFIRMED_ACTION,
        payload: data,
    };
}

export function restoreProformaFailedAction(data) {
    return {
        type: RESTORE_PROFORMA_FAILED_ACTION,
        payload: data,
    };
}



export function confirmedListAction(data) {
    return {
        type: LIST_CONFIRMED_ACTION,
        payload: data,
    };
}

export function listFailedAction(data) {
    return {
        type: LIST_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedAccordValidateAction(data) {
    return {
        type: VALIDATE_ACCORD_CONFIRMED_ACTION,
        payload: data,
    };
}

export function AccordValidationFailedAction(data) {
    return {
        type: VALIDATE_ACCORD_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedAccordRejectionAction(data) {
    return {
        type: REJECT_ACCORD_CONFIRMED_ACTION,
        payload: data,
    };
}

export function AccordRejectionFailedAction(data) {
    return {
        type: REJECT_ACCORD_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedSendAccordMessageAction(data) {
    return {
        type: SEND_MESSAGE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function SendAccordMessagelFailedAction(data) {
    return {
        type: SEND_MESSAGE_NOTIFS_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetAccordAction(data) {
    return {
        type: GET_ACCORD_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getAccordFailedAction(data) {
    return {
        type: GET_ACCORD_NOTIFS_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetProformasAction(data) {
    return {
        type: GET_PROFORMAS_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getProformasFailedAction(data) {
    return {
        type: GET_PROFORMAS_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetProformaAction(data) {
    return {
        type: GET_PROFORMA_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getProformaFailedAction(data) {
    return {
        type: GET_PROFORMA_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedValidateProformaAction(data) {
    return {
        type: VALIDATE_PROFORMA_CONFIRMED_ACTION,
        payload: data,
    };
}

export function validateProformaFailedAction(data) {
    return {
        type: VALIDATE_PROFORMA_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedRejectProformaAction(data) {
    return {
        type: REJECT_PROFORMA_CONFIRMED_ACTION,
        payload: data,
    };
}

export function rejectProformaFailedAction(data) {
    return {
        type: REJECT_PROFORMA_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedValidateProformaLineAction(data) {
    return {
        type: VALIDATE_PROFORMA_LINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function validateProformaLineFailedAction(data) {
    return {
        type: VALIDATE_PROFORMA_LINE_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedRejectProformaLineAction(data) {
    return {
        type: REJECT_PROFORMA_LINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function rejectProformaLineFailedAction(data) {
    return {
        type: REJECT_PROFORMA_LINE_FAILED_ACTION,
        payload: data,
    };
}




export function confirmedGetProformasKineAction(data) {
    return {
        type: GET_PROFORMAS_KINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getProformasKineFailedAction(data) {
    return {
        type: GET_PROFORMAS_KINE_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedGetProformaKineAction(data) {
    return {
        type: GET_PROFORMA_KINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function getProformaKineFailedAction(data) {
    return {
        type: GET_PROFORMA_KINE_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedValidateProformaKineAction(data) {
    return {
        type: VALIDATE_PROFORMA_KINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function validateProformaKineFailedAction(data) {
    return {
        type: VALIDATE_PROFORMA_KINE_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedRejectProformaKineAction(data) {
    return {
        type: REJECT_PROFORMA_KINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function rejectProformaKineFailedAction(data) {
    return {
        type: REJECT_PROFORMA_KINE_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedValidateProformaKineLineAction(data) {
    return {
        type: VALIDATE_PROFORMA_KINE_LINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function validateProformaKineLineFailedAction(data) {
    return {
        type: VALIDATE_PROFORMA_KINE_LINE_FAILED_ACTION,
        payload: data,
    };
}

export function confirmedRejectProformaKineLineAction(data) {
    return {
        type: REJECT_PROFORMA_KINE_LINE_CONFIRMED_ACTION,
        payload: data,
    };
}

export function rejectProformaKineLineFailedAction(data) {
    return {
        type: REJECT_PROFORMA_KINE_LINE_FAILED_ACTION,
        payload: data,
    };
}


export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
