import {format} from 'date-fns';
import { Button } from 'react-bootstrap';
import { withTranslation,useTranslation } from 'react-i18next';

export const COLUMNS= () => {
	const {t}= useTranslation()
	
	return [

	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
	},
	{
		Header : 'Message',
		Footer : 'Message',
		accessor: 'message',
	},
	{
		Header : 'Fichier',
		Footer : 'Fichier',
		Cell: ({ value }) => {
			return <Button
					as='a'
					variant='primary light'
					href={value}
					target="_blank"
					className='btn-card mt-3 mr-3'
					>
						<i className="la la-file-download"></i>
					</Button>
		},
		accessor: 'file',
	},
	{
		Header : t("common.send-to"),
		Footer : t("common.send-to"),
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		accessor: 'send_at',
	},
]
}


