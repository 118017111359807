import React, {Fragment, useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import BasicDatatable from '../DataTable';
import { Link } from "react-router-dom";
import {
    startLoader,
    importTarifFileUploadAction,
    getTarifsMedicamentAction,
    createTarifMedicamentAction,
    updateTarifMedicamentAction,
    deleteTarifMedicamentProviderAction
} from '../../../../store/actions/ProviderActions';

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Spinner, Button, Modal, ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import DateFnsUtils from "@date-io/date-fns";
import Select from 'react-select';
import swal from "sweetalert";
import {
    getMedicamentsAction
} from '../../../../store/actions/ProviderActions';
import { searchDrugsAction, searchDiseasesAction } from '../../../../store/actions/ConsultationActions';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";


const TarifMedicament = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [errors, setErrors] = useState(errorsObj);

    const { families, tarifs, showLoading, history } = props;

    const [tarifId, setTarifId] = useState("");
    const [nameFr, setNameFr] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [newTarifData, setNewTarifData] = useState({});
    const [loadedActs, setLoadedActs] = useState([]);
    const [currentActs, setCurrentActs] = useState([]);
    const [createdTarifData, setCreatedTarifData] = useState([]);
    const [keySearch, setKeySearch] = useState("");
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);
    //Modal box
    const [addCard, setAddCard] = useState(false);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            totalActes: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'id', headerName: "Code", filterPlaceholder: t("provider.code-filter"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'totalActes', dataType: 'numeric', headerName: t("common.total-medic"), filterPlaceholder: t("common.drug-total-act"), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t("patient.created-on"), filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATETARIF') && <Link href="#" to="#" data-testid={'update-button-'+rowData.id} onClick={e => updateTarif(e, rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'DELETETARIF') && <Link href="#" to="#" data-testid={'delete-button-'+rowData.id} title="Close"
                onClick={() =>
                    swal({
                        title: t("provider.delete-rate") +rowData.nameFr+" ?",
                        text:
                            t("patient.delete-rate-mes"),
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            dispatch(startLoader());
                            dispatch(deleteTarifMedicamentProviderAction(rowData.id));
                        }
                    })
                    }
            >
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    function selectFile(event) {
        setFile(event.target.files[0])
    }

    const addDataToNewTarif = (acte) => {
        if(createdTarifData.filter(elt => { return elt.id === acte.id}).length === 0){
            setLoadedActs(loadedActs.filter(elt => { return elt.id !== acte.id }));
            setCreatedTarifData([...createdTarifData, acte]);
        }
    }

    const removeDataToNewTarif = (acte) => {
        if(loadedActs.filter(elt => { return elt.id === acte.id}).length === 0){
            setCreatedTarifData(createdTarifData.filter(elt => {return elt.id !== acte.id}));
            setLoadedActs([...loadedActs, acte]);
        }
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        initFilters();
        dispatch(startLoader());
        dispatch(getTarifsMedicamentAction());
    }, []);

    function onTarifSave(e) {
        e.preventDefault();
        dispatch(startLoader());
        
        if(file){
            dispatch(importTarifFileUploadAction(file));
            return ;
        }

        if(updateMode) {
            let data = {
                nameFr,
                nameEn: nameFr,
                medicament: createdTarifData.map(tarf => { 
                    return {
                        medicamentId: tarf.id, 
                        medicamentCode: tarf.code, 
                        medicamentName: tarf.name, 
                        amount: tarf.value
                    }
                })
            };
            dispatch(updateTarifMedicamentAction(tarifId, data));
        }else{
            let data = {
                nameFr,
                nameEn: nameFr,
                medicament: createdTarifData.map(tarf => { 
                    return {
                        medicamentId: tarf.id, 
                        medicamentCode: tarf.code, 
                        medicamentName: tarf.name, 
                        amount: tarf.value
                    }
                })
            };
            dispatch(createTarifMedicamentAction(data));
        }
    }

    useEffect(() => {
        setUpdateMode(false);
        setTarifId("");
        setNameFr("");
        setNameEn("");
        setCreatedTarifData([]);
        setAddCard(false);
        setLoadedActs([]);
        setCurrentActs([]);
        setCreatedTarifData([]);
        setKeySearch("");
        setAddCard(false);
        //dispatch(startLoader());
        //dispatch(getTarifsAction()); 
    }, [props.tarifs]);

    useEffect(() => {
        setLoadedActs(props.drugs.map(act => {
            if(!createdTarifData.find(elt => { return elt.id === act.id})){
                act["value"] = act.amount;
                return act;
            }else{
                return
            }
        }).filter(el => { return el}));

        setCurrentActs(props.drugs.map(act => {
            if(!createdTarifData.find(elt => { return elt.id === act.id})){
                act["value"] = act.amount;
                return act;
            }else{
                return
            }
        }).filter(el => { return el}));

    }, [props.drugs]);

    const updateTarif = (e, tarif) => {
        e.preventDefault();
        setUpdateMode(true);
        setTarifId(tarif.id);
        setNameFr(tarif.nameFr);
        setNameEn(tarif.nameEn);
        setCreatedTarifData(tarif.medicaments.map(tarf => {
            return {
                id: tarf.medicamentId,
                code: tarf.medicamentCode,
                name: tarf.medicamentName,
                amount: tarf.amount,
                value: tarf.amount
            }
        }));
        setAddCard(true);
    }

    const openCard = () => {
        setUpdateMode(false);
        setTarifId("");
        setNameFr("");
        setNameEn("");
        setCreatedTarifData([]);
        setAddCard(false);
        setLoadedActs([]);
        setCurrentActs([]);
        setCreatedTarifData([]);
        setKeySearch("");
        setAddCard(true);
    }

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATETARIF') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => openCard()}
                    >
                        + {t("provider.new-rate")}
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{updateMode ? t("provider.modify-rate") : t("provider.create-rate")}</h4>
                        </div>
                        <div className='card-body'>
                            <div className='basic-form'>
                                <form onSubmit={onTarifSave}>
                                    <div className="updatePanel">
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('common.name')}</strong>
                                                </label>
                                                <input type='text' 
                                                    className='form-control'
                                                    placeholder={t("provider.tarif")}
                                                    value={nameFr}
                                                    disabled={props.showLoading || file}
                                                    required={props.showLoading || file}
                                                    onChange={(e) => setNameFr(e.target.value)}
                                                />
                                            </div>
                                        </div>


                                        <h5 className='mt-4 text-primary'>{t("provider.choice-act")}</h5>

                                        <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                                            <div className="input-group search-area ml-auto d-inline-flex mr-3 ">
                                                <input
                                                type="text"
                                                className="form-control border border-primary"
                                                value={keySearch}
                                                placeholder={t("provider.filter-medi")}
                                                onChange={e => {
                                                    setKeySearch(e.target.value);
                                                    dispatch(searchDrugsAction(e.target.value));
                                                }}
                                                />
                                                <div className="input-group-append">
                                                <button type="button" className="input-group-text border border-primary">
                                                    <i className="flaticon-381-search-2" />
                                                </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="actesPanel row">
                                            <div className="col-6 border">
                                                {
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height370"
                                                    >
                                                        <ListGroup as="ul" variant="flush">
                                                            {loadedActs.map((acte, i) => (
                                                                <Fragment key={i}>
                                                                    <ListGroup.Item as="li">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-6">
                                                                                <b>{acte?.name}</b>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <input type='number' className='form-control'
                                                                                    value={loadedActs[i]?.value}
                                                                                    onChange={(e) => setLoadedActs(loadedActs.map(elt => {
                                                                                            if(elt.id === acte.id) {
                                                                                                elt.value = e.target.value;
                                                                                            }
                                                                                            return elt;
                                                                                        })
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <div className="col-2">
                                                                                {loadedActs[i]?.value > 0 && <button
                                                                                    type="button"
                                                                                    onClick={() => addDataToNewTarif(acte)}
                                                                                    className="btn btn-outline-primary btn-rounded mb-2 mr-1"
                                                                                >
                                                                                    +
                                                                                </button>}
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Fragment>
                                                            ))}
                                                        </ListGroup>
                                                    </PerfectScrollbar>
                                                }
                                            </div>
                                            
                                            
                                            <div className="col-6 border">
                                                {
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height370"
                                                    >
                                                        <ListGroup as="ul" variant="flush">
                                                            {createdTarifData.map((acte, i) => (
                                                                <Fragment key={i}>
                                                                    <ListGroup.Item as="li">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-6">
                                                                                <b>{acte.name}</b>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <b>{acte.value}</b>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => removeDataToNewTarif(acte)}
                                                                                    className="btn btn-outline-primary btn-rounded mb-2 mr-1"
                                                                                >
                                                                                    -
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Fragment>
                                                            ))}
                                                        </ListGroup>
                                                    </PerfectScrollbar>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                    <hr />
                                    <div className='row text-center'>
                                        <h4 className='text-center'>{t("common.import-csv-rate")}</h4>
                                    </div>
                                    <div className='input-group mb-3'>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text'>CSV</span>
                                        </div>
                                        <div className='custom-file'>
                                            <input type='file' className='custom-file-input' onChange={selectFile} />
                                            <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                        </div>
                                    </div>
                                    <div className='form-group align-items-center justify-content-center'>
                                        <div className=''>
                                            { props.showLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </Button> }
                                            { !props.showLoading &&
                                                <button type='submit' className='btn btn-primary'>
                                                  {t('common.save')}
                                                </button> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            
            <DataTableBase 
                data={props.tarifs.length > 0 ? props.tarifs.map(acte => {
                        acte.createdAt = new Date(acte.createdAt);
                        acte.totalActes = acte.medicaments?.length;
                        return acte;
                }) : []}
                emptyMessage={t("common.no-rate")}
                filters={filters}
                globalFiltersFields={['id', 'nameFr', 'totalActes', 'createdAt']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Code', dataKey: 'id' },
                    { title: t('common.name'), dataKey: 'nameFr' },
                    { title: t("common.total-act"), dataKey: 'totalActes' },
                    { title: t('common.create-date'), dataKey: 'createdAt' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"tarifs"}
                loading={props.showLoading}
                lazy={false}
            />
            {/*<BasicDatatable header={header} body={tableBody}></BasicDatatable>*/}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        tarifs: state.provider.tarifsMedicament,
        drugs: state.consultation.drugs,
        showLoading: state.provider.showLoading,
        successMessage: state.provider.successMessage,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(TarifMedicament);