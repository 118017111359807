import React, { Fragment, useEffect, useState ,  useRef } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap'
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { getSouscripteursAction } from '../../../../store/actions/ContratActions';
import { globalClientStatsAction, detailedClientStatsAction, visitsClientStatsAction, loadingToggleAction } from '../../../../store/actions/RapportAction';
import { getCollegePerContractAction, getFamillePerCollegeAction, getContractPerClientAction, getBeneficiairesAction } from '../../../../store/actions/ContratActions';
import Select from 'react-select';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Spinner, Button } from "react-bootstrap";
import RapportDepense from './RapportDepense';
import EtatsGlobaux from './EtatsGlobaux';
import EtatsConsommations from './EtatsConsommations';
import EtatsVisites from './EtatsVisites';
import EtatsTopConsommations from './EtatsTopConsommations';
import EtatsDepenseRubrique from './EtatsDepenseRubrique';
import EtatsTopPathologies from './EtatTopPathologies';
import EtatsVisitePrestataire from './EtatsVisistePrestataire';
import './EtatsPanel.css';
import { ExportData } from './ExportsData';
//import { Button } from 'primereact/button'; //monskre


const EtatsPanel = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const { showLoading, souscripteurs, etatsGlobaux, etatsConsommations, etatsVisites,
        contratPerClient, famillePerCollege, collegePerContrat, beneficiaires } = props;

    const typeRapports = [
        { value: 'etatConsommation', label: "Etat détaillé des consommations" },
        { value: 'etatGlobal', label: "Etat global des consommations" },
        { value: 'visitesPrestataires', label: "Visites des prestataire" },
        { value: 'topConsommateurs', label: "Top des consommations" },
        { value: 'depenseRubrique', label: "Dépenses par rubrique" },
        { value: 'topPathologies', label: "Top des pathologies diagnostiquées" },
        { value: 'topSpecialistes', label: "Top des spécialistes fréquentés" },
    ];

    const specialists = [
        { value: 'Dentiste', label: 'Dentistes' },
        { value: 'Examen', label: 'Laboratoires' },
        { value: 'Pharmacie', label: 'Pharmacies' },
        { value: 'Optic', label: 'Opticien' }
    ]

    const [typeRapport, setTypeRapport] = useState('etatGlobal');
    const [specialite, setSpecialite] = useState('Pharmacie');
    const [clientIds, setClientIds] = useState([]);
    const [allClient, setAllClient] = useState(false);
    const [policeId, setPoliceId] = useState('');
    const [collegeId, setCollegeId] = useState('');
    const [familleId, setFamilleId] = useState('');
    const [numAdhPrinc, setNumAdhPrinc] = useState('');
    const [beneficiaireId, setBeneficiaireId] = useState('');
    const [dateMin, setDateMin] = useState(new Date());
    const [dateMax, setDateMax] = useState(new Date());
    const [maxResults, setMaxResults] = useState(20);

    const [filteredEtatsGlobaux, setFilteredEtatsGlobaux] = useState([]);
    const [etatsGlobauxData, setEtatsGlobauxData] = useState({});

    const [etatsConsommationData, setEtatsConsommationData] = useState({});

    const [etatsVisitesData, setEtatsVisitesData] = useState([]);

    const [etatsTopConsommateursData, setEtatsTopConsommateursData] = useState([]);

    const [etatsDepenseRubriqueData, setEtatsDepenseRubriqueData] = useState([]);

    const [etatsTopPathologiesData, setEtatsTopPathologiesData] = useState([]);

    const [etatsTopSpecialistesVisitesData, setEtatsTopSpecialistesVisitesData] = useState([]);

    const filterReport = (e) => {
        e.preventDefault();
        let data = {
            dateDebut: dateMin,
            dateFin: dateMax,
            isAll: allClient
        }

        dispatch(loadingToggleAction(true));
        dispatch(globalClientStatsAction(data, allClient ? [] : clientIds));
        //dispatch(detailedClientStatsAction(data));
        //dispatch(visitsClientStatsAction(data));
    }

    const filterEtatsGlobaux = () => {
        if (allClient) {
            setFilteredEtatsGlobaux(etatsGlobaux);
        } else if (clientIds.length === 1) {
            if (policeId) {
                setFilteredEtatsGlobaux(etatsGlobaux.filter(etat => etat.numeroPolice === policeId));
            }
            if (collegeId) {
                setFilteredEtatsGlobaux(etatsGlobaux.filter(etat => etat.numeroCollege === collegeId));
            }
            if (familleId) {
                setFilteredEtatsGlobaux(etatsGlobaux.filter(etat => etat.numeroAdherentP === numAdhPrinc));
            }
            if (beneficiaireId) {
                setFilteredEtatsGlobaux(etatsGlobaux.filter(etat => etat.patientId === beneficiaireId));
            }
        } else {
            setFilteredEtatsGlobaux(Object.groupBy(etatsGlobaux, ({ numeroClient }) => numeroClient));
        }
    }

    useEffect(() => {
        if (clientIds.length > 1) {
            if (Object.keys(filteredEtatsGlobaux).length > 0) {
                setEtatsGlobauxData(Object.keys(filteredEtatsGlobaux).map(key => {
                    return {
                        souscripteur: filteredEtatsGlobaux[key][0].souscripteur,
                        datas: {
                            totalPrestations: filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.prixTotal, 0).toFixed(2),
                            totalRejete: filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.montantRejete, 0).toFixed(2),
                            totalPaye: filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0).toFixed(2),//Soustraire le montant rejeté
                            nbrePrestataire: Object.keys(Object.groupBy(filteredEtatsGlobaux[key], ({ prestataireId }) => prestataireId)).length,
                            nbreVisite: filteredEtatsGlobaux[key].filter(elt => elt.type === 'Consultation').length
                        }
                    }
                }));
                setEtatsConsommationData(Object.keys(filteredEtatsGlobaux).map(key => {
                    return {
                        souscripteur: filteredEtatsGlobaux[key][0].souscripteur,
                        datas: {
                            totalPrestations: {
                                total: {
                                    montant: filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.prixTotal, 0).toFixed(2),
                                    taux: 100
                                },
                                ambulatoire: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0).toFixed(2),
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0) / filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.prixTotal, 0)) * 100).toFixed(2) || 0
                                },
                                hospitalier: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0).toFixed(2),
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0) / filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.prixTotal, 0)) * 100).toFixed(2) || 0
                                },
                            },
                            totalRejete: {
                                total: {
                                    montant: filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.montantRejete, 0).toFixed(2),
                                    taux: 100
                                },
                                ambulatoire: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0).toFixed(2),
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0) / filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.montantRejete, 0)) * 100 || 0).toFixed(2)
                                },
                                hospitalier: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0).toFixed(2),
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0) / filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.montantRejete, 0)) * 100 || 0).toFixed(2)
                                },
                            },
                            totalPaye: {
                                total: {
                                    montant: filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0).toFixed(2),
                                    taux: 100
                                },
                                ambulatoire: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0).toFixed(2),
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100 || 0).toFixed(2)
                                },
                                hospitalier: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0).toFixed(2),
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100 || 0).toFixed(2)
                                },
                            },
                            nbrePrestataire: {
                                total: {
                                    montant: Object.keys(Object.groupBy(filteredEtatsGlobaux[key], ({ prestataireId }) => prestataireId)).length,
                                    taux: 100
                                },
                                ambulatoire: {
                                    montant: Object.keys(Object.groupBy(filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length,
                                    taux: ((Object.keys(Object.groupBy(filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length / Object.keys(Object.groupBy(filteredEtatsGlobaux[key], ({ prestataireId }) => prestataireId)).length) * 100 || 0).toFixed(2)
                                },
                                hospitalier: {
                                    montant: Object.keys(Object.groupBy(filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length,
                                    taux: ((Object.keys(Object.groupBy(filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length / Object.keys(Object.groupBy(filteredEtatsGlobaux[key], ({ prestataireId }) => prestataireId)).length) * 100 || 0).toFixed(2)
                                },
                            },
                            nbreVisite: {
                                total: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => elt.type === 'Consultation').length,
                                    taux: 100
                                },
                                ambulatoire: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length,
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => !elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length / filteredEtatsGlobaux[key].filter(elt => elt.type === 'Consultation').length) * 100 || 0).toFixed(2)
                                },
                                hospitalier: {
                                    montant: filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length,
                                    taux: ((filteredEtatsGlobaux[key].filter(elt => elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length / filteredEtatsGlobaux[key].filter(elt => elt.type === 'Consultation').length) * 100 || 0).toFixed(2)
                                },
                            }
                        }
                    }
                }));

                setEtatsVisitesData(Object.keys(filteredEtatsGlobaux).map(keyp => {
                    let groupedPrestataires = Object.groupBy(filteredEtatsGlobaux[keyp], ({ prestataireId }) => prestataireId);
                    return {
                        souscripteur: filteredEtatsGlobaux[keyp][0].souscripteur,
                        datas: Object.keys(groupedPrestataires).map(key => {
                            return {
                                providerName: groupedPrestataires[key][0].providerName,
                                total: {
                                    nbreVisites: groupedPrestataires[key].length,
                                    taux: ((groupedPrestataires[key].length / filteredEtatsGlobaux[keyp].length) * 100).toFixed(2)
                                },
                                ambulatoire: {
                                    nbreVisites: groupedPrestataires[key].filter(elt => !elt.hospitalisationCode).length,
                                    taux: ((groupedPrestataires[key].filter(elt => !elt.hospitalisationCode).length / groupedPrestataires[key].length) * 100).toFixed(2)
                                },
                                hospitalier: {
                                    nbreVisites: groupedPrestataires[key].filter(elt => elt.hospitalisationCode).length,
                                    taux: ((groupedPrestataires[key].filter(elt => elt.hospitalisationCode).length / groupedPrestataires[key].length) * 100).toFixed(2)
                                },
                            }
                        })
                    }
                }));

                setEtatsTopConsommateursData(Object.keys(filteredEtatsGlobaux).map(keyp => {
                    let groupedFamille = Object.groupBy(filteredEtatsGlobaux[keyp], ({ numeroAdherentP }) => numeroAdherentP);
                    return {
                        souscripteur: filteredEtatsGlobaux[keyp][0].souscripteur,
                        datas: Object.keys(groupedFamille).map(key => {
                            let groupedAssured = Object.groupBy(groupedFamille[key], ({ patientId }) => patientId);

                            return {
                                name: groupedFamille[key][0].nomAdherentP || `Famille ${key}`,
                                total: {
                                    montant: groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux[keyp].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                ambulatoire: {
                                    montant: groupedFamille[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedFamille[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                hospitalier: {
                                    montant: groupedFamille[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedFamille[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                children: Object.keys(groupedAssured).map(keySub => {
                                    return {
                                        name: groupedAssured[keySub][0].patientName,
                                        total: {
                                            montant: groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                            taux: ((groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                        },
                                        ambulatoire: {
                                            montant: groupedAssured[keySub].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                            taux: ((groupedAssured[keySub].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                        },
                                        hospitalier: {
                                            montant: groupedAssured[keySub].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                            taux: ((groupedAssured[keySub].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                        },
                                    }
                                }).sort((a, b) => b.total.montant - a.total.montant)
                            }
                        }).sort((a, b) => b.total.montant - a.total.montant)
                    }
                }));

                setEtatsDepenseRubriqueData(Object.keys(filteredEtatsGlobaux).map(keyp => {
                    let groupedGaranties = Object.groupBy(filteredEtatsGlobaux[keyp], ({ codeGarantie }) => codeGarantie);
                    return {
                        souscripteur: filteredEtatsGlobaux[keyp][0].souscripteur,
                        datas: Object.keys(groupedGaranties).map(key => {
                            return {
                                providerName: groupedGaranties[key][0].garantie,
                                total: {
                                    montant: groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux[keyp].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                ambulatoire: {
                                    montant: groupedGaranties[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedGaranties[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                hospitalier: {
                                    montant: groupedGaranties[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedGaranties[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                            }
                        })
                    }
                }));
                let affections = Object.keys(Object.groupBy([].concat.apply([], etatsGlobaux.map(elt => elt.patologie)), (elt) => elt));
                setEtatsTopPathologiesData(Object.keys(filteredEtatsGlobaux).map(keyp => {
                    return {
                        souscripteur: filteredEtatsGlobaux[keyp][0].souscripteur,
                        datas: affections.map(affection => {
                            return {
                                name: affection,
                                total: {
                                    montant: filteredEtatsGlobaux[keyp].filter(elt => elt.patologie.includes(affection)).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    nbreOccurence: filteredEtatsGlobaux[keyp].filter(elt => elt.patologie.includes(affection)).length,
                                    taux: 100,
                                },
                                ambulatoire: {
                                    montant: filteredEtatsGlobaux[keyp].filter(elt => !elt.hospitalisationCode && elt.patologie.includes(affection)).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    nbreOccurence: filteredEtatsGlobaux[keyp].filter(elt => !elt.hospitalisationCode && elt.patologie.includes(affection)).length,
                                    taux: ((filteredEtatsGlobaux[keyp].filter(elt => !elt.hospitalisationCode && elt.patologie.includes(affection)).length / filteredEtatsGlobaux[keyp].filter(elt => elt.patologie.includes(affection)).length) * 100).toFixed(2),
                                },
                                hospitalier: {
                                    montant: filteredEtatsGlobaux[keyp].filter(elt => elt.hospitalisationCode && elt.patologie.includes(affection)).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    nbreOccurence: filteredEtatsGlobaux[keyp].filter(elt => elt.hospitalisationCode && elt.patologie.includes(affection)).length,
                                    taux: ((filteredEtatsGlobaux[keyp].filter(elt => elt.hospitalisationCode && elt.patologie.includes(affection)).length / filteredEtatsGlobaux[keyp].filter(elt => elt.patologie.includes(affection)).length) * 100).toFixed(2),
                                }
                            }
                        }).sort((a, b) => b.total.nbreOccurence - a.total.nbreOccurence)
                    }
                }));
                setEtatsTopSpecialistesVisitesData(Object.keys(filteredEtatsGlobaux).map(keyp => {
                    let groupedPrestataires = Object.groupBy(filteredEtatsGlobaux[keyp].filter(elt => elt.type === specialite), ({ prestataireId }) => prestataireId);
                    return {
                        souscripteur: filteredEtatsGlobaux[keyp][0].souscripteur,
                        datas: Object.keys(groupedPrestataires).map(key => {
                            return {
                                providerName: groupedPrestataires[key][0].providerName,
                                total: {
                                    nbreVisites: groupedPrestataires[key].length,
                                    taux: ((groupedPrestataires[key].length / filteredEtatsGlobaux[keyp].length) * 100).toFixed(2)
                                },
                                ambulatoire: {
                                    nbreVisites: groupedPrestataires[key].filter(elt => !elt.hospitalisationCode).length,
                                    taux: ((groupedPrestataires[key].filter(elt => !elt.hospitalisationCode).length / groupedPrestataires[key].length) * 100).toFixed(2)
                                },
                                hospitalier: {
                                    nbreVisites: groupedPrestataires[key].filter(elt => elt.hospitalisationCode).length,
                                    taux: ((groupedPrestataires[key].filter(elt => elt.hospitalisationCode).length / groupedPrestataires[key].length) * 100).toFixed(2)
                                },
                            }
                        })
                    }
                }));
            } else {
                setEtatsGlobauxData({});
                setEtatsConsommationData({});
                setEtatsVisitesData({});
                setEtatsTopConsommateursData({});
                setEtatsDepenseRubriqueData({});
                setEtatsTopPathologiesData({});
                setEtatsTopSpecialistesVisitesData({});
            }
        } else {
            if (filteredEtatsGlobaux.length > 0) {
                let totalPrest = filteredEtatsGlobaux.reduce((sum, elt) => sum + elt.prixTotal, 0);
                let totalRejet = filteredEtatsGlobaux.reduce((sum, elt) => sum + elt.montantRejete, 0);
                let totalPaye = filteredEtatsGlobaux.reduce((sum, elt) => sum + elt.priceToPayByActiva, 0);
                let nbrePrestataire = Object.keys(Object.groupBy(filteredEtatsGlobaux, ({ prestataireId }) => prestataireId)).length;
                let nbreVisite = filteredEtatsGlobaux.filter(elt => elt.type === 'Consultation').length;

                setEtatsGlobauxData({
                    totalPrestations: totalPrest.toFixed(2),
                    totalRejete: totalRejet.toFixed(2),
                    totalPaye: totalPaye.toFixed(2),
                    nbrePrestataire: nbrePrestataire,
                    nbreVisite: nbreVisite
                });
                setEtatsConsommationData({
                    totalPrestations: {
                        total: {
                            montant: totalPrest.toFixed(2),
                            taux: 100
                        },
                        ambulatoire: {
                            montant: filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0).toFixed(2),
                            taux: ((filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0) / totalPrest) * 100).toFixed(2) || 0
                        },
                        hospitalier: {
                            montant: filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0).toFixed(2),
                            taux: ((filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.prixTotal, 0) / totalPrest) * 100).toFixed(2) || 0
                        },
                    },
                    totalRejete: {
                        total: {
                            montant: totalRejet.toFixed(2),
                            taux: 100
                        },
                        ambulatoire: {
                            montant: filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0).toFixed(2),
                            taux: ((filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0) / totalRejet) * 100 || 0).toFixed(2)
                        },
                        hospitalier: {
                            montant: filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0).toFixed(2),
                            taux: ((filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.montantRejete, 0) / totalRejet) * 100 || 0).toFixed(2)
                        },
                    },
                    totalPaye: {
                        total: {
                            montant: totalPaye.toFixed(2),
                            taux: 100
                        },
                        ambulatoire: {
                            montant: filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0).toFixed(2),
                            taux: ((filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / totalPaye) * 100 || 0).toFixed(2)
                        },
                        hospitalier: {
                            montant: filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0).toFixed(2),
                            taux: ((filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / totalPaye) * 100 || 0).toFixed(2)
                        },
                    },
                    nbrePrestataire: {
                        total: {
                            montant: nbrePrestataire,
                            taux: 100
                        },
                        ambulatoire: {
                            montant: Object.keys(Object.groupBy(filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length,
                            taux: ((Object.keys(Object.groupBy(filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length / nbrePrestataire) * 100 || 0).toFixed(2)
                        },
                        hospitalier: {
                            montant: Object.keys(Object.groupBy(filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length,
                            taux: ((Object.keys(Object.groupBy(filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode), ({ prestataireId }) => prestataireId)).length / nbrePrestataire) * 100 || 0).toFixed(2)
                        },
                    },
                    nbreVisite: {
                        total: {
                            montant: nbreVisite,
                            taux: 100
                        },
                        ambulatoire: {
                            montant: filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length,
                            taux: ((filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length / nbreVisite) * 100 || 0).toFixed(2)
                        },
                        hospitalier: {
                            montant: filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length,
                            taux: ((filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode).filter(elt => elt.type === 'Consultation').length / nbreVisite) * 100 || 0).toFixed(2)
                        },
                    }
                });
                let groupedPrestataires = Object.groupBy(filteredEtatsGlobaux, ({ prestataireId }) => prestataireId);
                setEtatsVisitesData(Object.keys(groupedPrestataires).map(key => {
                    return {
                        providerName: groupedPrestataires[key][0].providerName,
                        total: {
                            nbreVisites: groupedPrestataires[key].length,
                            taux: ((groupedPrestataires[key].length / filteredEtatsGlobaux.length) * 100).toFixed(2)
                        },
                        ambulatoire: {
                            nbreVisites: groupedPrestataires[key].filter(elt => !elt.hospitalisationCode).length,
                            taux: ((groupedPrestataires[key].filter(elt => !elt.hospitalisationCode).length / groupedPrestataires[key].length) * 100).toFixed(2)
                        },
                        hospitalier: {
                            nbreVisites: groupedPrestataires[key].filter(elt => elt.hospitalisationCode).length,
                            taux: ((groupedPrestataires[key].filter(elt => elt.hospitalisationCode).length / groupedPrestataires[key].length) * 100).toFixed(2)
                        },
                    }
                }));

                let groupedBeneficiaires = Object.groupBy(filteredEtatsGlobaux, ({ patientId }) => patientId);
                let groupedFamille = Object.groupBy(filteredEtatsGlobaux, ({ numeroAdherentP }) => numeroAdherentP);
                let groupedActes = Object.groupBy(filteredEtatsGlobaux, ({ type }) => type);
                setEtatsTopConsommateursData(beneficiaireId ?
                    Object.keys(groupedActes).map(key => {
                        return {
                            name: groupedActes[key][0].type === 'Hospitalisation' ? 'Chambre' : groupedActes[key][0].type,
                            total: {
                                montant: groupedActes[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                taux: ((groupedActes[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux.reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                            },
                            ambulatoire: {
                                montant: groupedActes[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                taux: ((groupedActes[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedActes[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                            },
                            hospitalier: {
                                montant: groupedActes[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                taux: ((groupedActes[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedActes[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                            },
                        }
                    }).sort((a, b) => b.total.montant - a.total.montant)
                    :
                    familleId ?
                        Object.keys(groupedBeneficiaires).map(key => {
                            return {
                                name: groupedBeneficiaires[key][0].patientName,
                                total: {
                                    montant: groupedBeneficiaires[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedBeneficiaires[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux.reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                ambulatoire: {
                                    montant: groupedBeneficiaires[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedBeneficiaires[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedBeneficiaires[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                hospitalier: {
                                    montant: groupedBeneficiaires[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedBeneficiaires[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedBeneficiaires[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                            }
                        }).sort((a, b) => b.total.montant - a.total.montant) :
                        Object.keys(groupedFamille).map(key => {
                            let groupedAssured = Object.groupBy(groupedFamille[key], ({ patientId }) => patientId);
                            
                            return {
                                name: groupedFamille[key][0].nomAdherentP || `Famille ${key}`,
                                total: {
                                    montant: groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux.reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                ambulatoire: {
                                    montant: groupedFamille[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedFamille[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                hospitalier: {
                                    montant: groupedFamille[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                    taux: ((groupedFamille[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                },
                                children: Object.keys(groupedAssured).map(keySub => {
                                    return {
                                        name: groupedAssured[keySub][0].patientName,
                                        total: {
                                            montant: groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                            taux: ((groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedFamille[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                        },
                                        ambulatoire: {
                                            montant: groupedAssured[keySub].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                            taux: ((groupedAssured[keySub].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                        },
                                        hospitalier: {
                                            montant: groupedAssured[keySub].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                                            taux: ((groupedAssured[keySub].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedAssured[keySub].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                                        },
                                    }
                                }).sort((a, b) => b.total.montant - a.total.montant)
                            }
                        }).sort((a, b) => b.total.montant - a.total.montant)
                );

                let groupedGaranties = Object.groupBy(filteredEtatsGlobaux, ({ codeGarantie }) => codeGarantie);
                setEtatsDepenseRubriqueData(Object.keys(groupedGaranties).map(key => {
                    return {
                        providerName: groupedGaranties[key][0].garantie,
                        total: {
                            montant: groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                            taux: ((groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / filteredEtatsGlobaux.reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                        },
                        ambulatoire: {
                            montant: groupedGaranties[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                            taux: ((groupedGaranties[key].filter(elt => !elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                        },
                        hospitalier: {
                            montant: groupedGaranties[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                            taux: ((groupedGaranties[key].filter(elt => elt.hospitalisationCode).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0) / groupedGaranties[key].reduce((sum, elt) => sum + elt.priceToPayByActiva, 0)) * 100).toFixed(2)
                        },
                    }
                }));

                let affections = Object.keys(Object.groupBy([].concat.apply([], filteredEtatsGlobaux.map(elt => elt.patologie)), (elt) => elt));
                setEtatsTopPathologiesData(affections.map(affection => {
                    return {
                        name: affection,
                        total: {
                            montant: filteredEtatsGlobaux.filter(elt => elt.patologie.includes(affection)).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                            nbreOccurence: filteredEtatsGlobaux.filter(elt => elt.patologie.includes(affection)).length,
                            taux: 100,
                        },
                        ambulatoire: {
                            montant: filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode && elt.patologie.includes(affection)).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                            nbreOccurence: filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode && elt.patologie.includes(affection)).length,
                            taux: ((filteredEtatsGlobaux.filter(elt => !elt.hospitalisationCode && elt.patologie.includes(affection)).length / filteredEtatsGlobaux.filter(elt => elt.patologie.includes(affection)).length) * 100).toFixed(2),
                        },
                        hospitalier: {
                            montant: filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode && elt.patologie.includes(affection)).reduce((sum, elt) => sum + elt.priceToPayByActiva, 0),
                            nbreOccurence: filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode && elt.patologie.includes(affection)).length,
                            taux: ((filteredEtatsGlobaux.filter(elt => elt.hospitalisationCode && elt.patologie.includes(affection)).length / filteredEtatsGlobaux.filter(elt => elt.patologie.includes(affection)).length) * 100).toFixed(2),
                        }
                    }
                }).sort((a, b) => b.total.nbreOccurence - a.total.nbreOccurence));

                let groupedSpecialiste = Object.groupBy(filteredEtatsGlobaux.filter(elt => elt.type === specialite), ({ prestataireId }) => prestataireId);
                setEtatsTopSpecialistesVisitesData(Object.keys(groupedSpecialiste).map(key => {
                    return {
                        providerName: groupedSpecialiste[key][0].providerName,
                        total: {
                            nbreVisites: groupedSpecialiste[key].length,
                            taux: ((groupedSpecialiste[key].length / filteredEtatsGlobaux.length) * 100).toFixed(2)
                        },
                        ambulatoire: {
                            nbreVisites: groupedSpecialiste[key].filter(elt => !elt.hospitalisationCode).length,
                            taux: ((groupedSpecialiste[key].filter(elt => !elt.hospitalisationCode).length / groupedSpecialiste[key].length) * 100).toFixed(2)
                        },
                        hospitalier: {
                            nbreVisites: groupedSpecialiste[key].filter(elt => elt.hospitalisationCode).length,
                            taux: ((groupedSpecialiste[key].filter(elt => elt.hospitalisationCode).length / groupedSpecialiste[key].length) * 100).toFixed(2)
                        },
                    }
                }));
            } else {
                setEtatsGlobauxData({});
                setEtatsConsommationData({});
                setEtatsVisitesData([]);
                setEtatsTopConsommateursData([]);
                setEtatsDepenseRubriqueData([]);
                setEtatsTopPathologiesData([]);
                setEtatsTopSpecialistesVisitesData([]);
            }
        }
    }, [filteredEtatsGlobaux]);

    useEffect(() => {
        if (hasMenu('MENREPORTING') && canDo('MENREPORTING', 'VIEWREPORT')) {
            dispatch(getSouscripteursAction());
        } else {
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        if (etatsGlobaux.length > 0) {
            setFilteredEtatsGlobaux(etatsGlobaux);
            filterEtatsGlobaux();
        } else {
            setFilteredEtatsGlobaux(clientIds.length > 1 ? {} : []);
        }
    }, [etatsGlobaux]);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if (!theMenu) {
            return false;
        } else {
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }

    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    //monskre
    const componentRef = useRef<HTMLDivElement>(null);



    return (
        <Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Body>
                            <div className='search-section'>
                                {/* <p className="text-center">Entrer les paramètres de filtre pour avoir un rapport</p>*/}
                                <form onSubmit={filterReport}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-5 mb-2">
                                            <div className="form-group form-inline rapports-select">
                                                <label className='mr-2'>
                                                    <strong>Type de rapport</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={typeRapports.filter(elt => elt.value === typeRapport)}
                                                    options={typeRapports}
                                                    placeholder="Choisir un type de rapport"
                                                    search
                                                    onChange={(val) => {
                                                        setTypeRapport(val.value);
                                                    }}
                                                    style={{
                                                        width: '100px',
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Client</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={clientIds.includes('all') ? [{ label: 'Tous les clients', value: 'all' }] : souscripteurs?.filter(elt => clientIds.includes(elt.codeAssu)).map((opt) => { return { label: opt.nom_Assu, value: opt.codeAssu } })}
                                                    options={[{ label: 'Tous les clients', value: 'all' }, ...souscripteurs?.map((opt) => { return { label: opt.nom_Assu, value: opt.codeAssu } })]}
                                                    placeholder="Choisir un client"
                                                    search
                                                    isMulti
                                                    onChange={(vals) => {
                                                        if (!vals) {
                                                            setClientIds([]);
                                                            return;
                                                        }
                                                        if (vals.find(val => val.value === 'all')) {
                                                            setClientIds(['all']);
                                                            setAllClient(true);
                                                        } else if (vals.length === 1) {
                                                            setClientIds([vals[0].value]);
                                                            dispatch(getContractPerClientAction(vals[0].value));
                                                            setAllClient(false);
                                                        } else {
                                                            setClientIds(vals ? vals.map(val => val.value) : []);
                                                            setAllClient(false);
                                                        }
                                                    }}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Police</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={contratPerClient?.filter(elt => elt.numeroPolice === policeId).map((opt) => { return { label: opt.numeroPolice, value: opt.numeroPolice } })}
                                                    options={[{ value: '', label: "Choisir" }, ...contratPerClient?.map((opt) => { return { label: opt.numeroPolice, value: opt.numeroPolice } })]}
                                                    placeholder="Choisir une police"
                                                    search
                                                    onChange={(val) => {
                                                        setPoliceId(val.value);
                                                        dispatch(getCollegePerContractAction(val.value));
                                                        dispatch(getBeneficiairesAction(val.value));
                                                    }}
                                                    isDisabled={allClient || clientIds.length > 1}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Collège</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={collegePerContrat?.filter(elt => elt.numeroCollege === collegeId).map((opt) => { return { label: opt.libelleGroupe, value: opt.numeroCollege } })}
                                                    options={[{ value: '', label: "Choisir" }, ...collegePerContrat?.map((opt) => { return { label: opt.libelleGroupe, value: opt.numeroCollege } })]}
                                                    placeholder="Choisir un collège"
                                                    search
                                                    isDisabled={allClient || clientIds.length > 1}
                                                    onChange={(val) => {
                                                        setCollegeId(val.value);
                                                        dispatch(getFamillePerCollegeAction(val.value, policeId));
                                                    }}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {collegeId && <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Famille</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={famillePerCollege?.filter(elt => elt.id === familleId).map((opt) => { return { label: opt.nom, value: opt.id, numbenef: opt.numBeneficiaire } })}
                                                    options={[{ value: '', label: "Choisir" }, ...famillePerCollege?.map((opt) => { return { label: opt.nom, value: opt.id, numbenef: opt.numBeneficiaire } })]}
                                                    placeholder="Choisir une famille"
                                                    search
                                                    isDisabled={allClient || clientIds.length > 1}
                                                    onChange={(val) => {
                                                        setFamilleId(val.value);
                                                        setNumAdhPrinc(val.numbenef);
                                                    }}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                        {!collegeId && <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Famille</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={beneficiaires?.filter(elt => elt.assurePrinc.id === familleId).map((opt) => { return { label: opt.assurePrinc.nom + ' ' + (opt.assurePrinc.prenoms || ''), value: opt.assurePrinc.id, numbenef: opt.numBeneficiaire } })}
                                                    options={[{ value: '', label: "Choisir" }, ...beneficiaires?.map((opt) => { return { label: opt.assurePrinc.nom + ' ' + (opt.assurePrinc.prenoms || ''), value: opt.assurePrinc.id, numbenef: opt.assurePrinc.numBeneficiaire } })]}
                                                    placeholder="Choisir une famille"
                                                    search
                                                    onChange={(val) => {
                                                        setFamilleId(val.value);
                                                        setNumAdhPrinc(val.numbenef);
                                                    }}
                                                    isDisabled={allClient || clientIds.length > 1}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                        <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Bénéficiaire</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={beneficiaires?.find(assur => { return assur.assurePrinc.id === familleId })?.beneficiaires.filter(elt => elt.id === beneficiaireId)
                                                        .map((opt) => { return { label: opt.nom + ' ' + (opt.prenoms || ''), value: opt.id } })}
                                                    options={[{ value: '', label: "Choisir" }, ...(beneficiaires?.find(assur => { return assur.assurePrinc.id === familleId })?.beneficiaires.map((opt) => { return { label: opt.nom + ' ' + (opt.prenoms || ''), value: opt.id } }) || [])]}
                                                    placeholder="Choisir un bénéficiaire"
                                                    search
                                                    onChange={(val) => {
                                                        setBeneficiaireId(val.value);
                                                    }}
                                                    isDisabled={allClient || clientIds.length > 1}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Date minimum</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy"
                                                        disableFuture
                                                        value={dateMin}
                                                        onChange={setDateMin}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Date maximum</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy"
                                                        disableFuture
                                                        value={dateMax}
                                                        onChange={setDateMax}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        {typeRapport === 'topSpecialistes' && <div className="col-lg-3 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1'>
                                                    <strong>Spécialistes</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={specialists.filter(elt => elt.value === specialite)}
                                                    options={specialists}
                                                    placeholder="Choisir une spécialité"
                                                    search
                                                    onChange={(val) => {
                                                        setSpecialite(val.value);
                                                    }}
                                                    style={{
                                                        width: '100px',
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                        {typeRapport === 'topConsommateurs' && <div className="col-lg-1 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Max</strong>
                                                </label>
                                                <input type='number' className='form-control' min='20' value={maxResults} onChange={e => setMaxResults(e.target.value)} />
                                            </div>
                                        </div>}
                                        {showLoading &&
                                            <div className={typeRapport === 'topConsommateurs' ? 'col-2' : 'col-3'}>
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            </div>}
                                        {!showLoading &&
                                            <div className={typeRapport === 'topConsommateurs' ? 'col-2' : 'col-3'}>
                                                <button type='submit' className='btn btn-primary btn-block'>
                                                    <i className='la la-search'></i>
                                                </button>
                                            </div>}
                                    </div>
                                </form>
                            </div>
                            <div className='print-content'>
                                {typeRapport === 'etatGlobal' &&
                                    <>
                                        {clientIds.length > 1 && <div>
                                            {Object.keys(etatsGlobauxData).map(key => {
                                                return <div className='content-div'>
                                                    <h3>{etatsGlobauxData[key].souscripteur}</h3>
                                                    <EtatsGlobaux  data={etatsGlobauxData[key].datas} showLoading={showLoading} />
                                                </div>
                                            })}

                                        </div>}

                                        {clientIds.length === 1 && <EtatsGlobaux data={etatsGlobauxData} showLoading={showLoading} />}
                                    </>}
                                {typeRapport === 'etatConsommation' && <>
                                    {clientIds.length > 1 && <div>
                                        {Object.keys(etatsConsommationData).map(key => {
                                            return <div className='content-div'>
                                                <h3>{etatsConsommationData[key].souscripteur}</h3>
                                                <EtatsConsommations  data={etatsConsommationData[key].datas} showLoading={showLoading} />
                                            </div>
                                        })}
                                    </div>}
                                    {clientIds.length === 1 && <EtatsConsommations data={etatsConsommationData} showLoading={showLoading} />}
                                </>}
                                {typeRapport === 'visitesPrestataires' && <>
                                    {clientIds.length > 1 && <div>
                                        {Object.keys(etatsVisitesData).map(key => {
                                            return <div className='content-div'>
                                                <h3>{etatsVisitesData[key].souscripteur}</h3>
                                                <EtatsVisites data={etatsVisitesData[key].datas} showLoading={showLoading} />
                                            </div>
                                        })}
                                    </div>}
                                    {clientIds.length === 1 && <EtatsVisites data={etatsVisitesData} showLoading={showLoading} />}
                                </>}
                                {typeRapport === 'topConsommateurs' && <>
                                    {clientIds.length > 1 && <div>
                                        {Object.keys(etatsTopConsommateursData).map(key => {
                                            return <div className='content-div'>
                                                <h3>{etatsTopConsommateursData[key].souscripteur}</h3>
                                                <EtatsTopConsommations data={etatsTopConsommateursData[key].datas.slice(0, maxResults + 1)} type='Famille' showLoading={showLoading} />
                                            </div>
                                        })}
                                    </div>}
                                    {clientIds.length === 1 && <EtatsTopConsommations data={etatsTopConsommateursData.slice(0, maxResults + 1)} type='Famille' showLoading={showLoading} />}
                                </>}
                                {typeRapport === 'depenseRubrique' && <>
                                    {clientIds.length > 1 && <div>
                                        {Object.keys(etatsDepenseRubriqueData).map(key => {
                                            return <div className='content-div'>
                                                <h3>{etatsDepenseRubriqueData[key].souscripteur}</h3>
                                                <EtatsDepenseRubrique data={etatsDepenseRubriqueData[key].datas} showLoading={showLoading} />
                                            </div>
                                        })}
                                    </div>}
                                    {clientIds.length === 1 && <EtatsDepenseRubrique data={etatsDepenseRubriqueData} showLoading={showLoading} />}
                                </>}
                                {typeRapport === 'topPathologies' && <>
                                    {clientIds.length > 1 && <div>
                                        {Object.keys(etatsTopPathologiesData).map(key => {
                                            return <div className='content-div'>
                                                <h3>{etatsTopPathologiesData[key].souscripteur}</h3>
                                                <EtatsTopPathologies data={etatsTopPathologiesData[key].datas} showLoading={showLoading} />
                                            </div>
                                        })}
                                    </div>}
                                    {clientIds.length === 1 && <EtatsTopPathologies data={etatsTopPathologiesData} showLoading={showLoading} />}
                                </>}
                                {typeRapport === 'topSpecialistes' && <>
                                    {clientIds.length > 1 && <div>
                                        {Object.keys(etatsTopSpecialistesVisitesData).map(key => {
                                            return <div className='content-div'>
                                                <h3>{etatsTopSpecialistesVisitesData[key].souscripteur}</h3>
                                                <EtatsVisitePrestataire data={etatsTopSpecialistesVisitesData[key].datas} showLoading={showLoading} />
                                            </div>
                                        })}
                                    </div>}
                                    {clientIds.length === 1 && <EtatsVisitePrestataire data={etatsTopSpecialistesVisitesData} showLoading={showLoading} />}
                                </>}
                            </div>
                        </Card.Body>
                    </Card>
                    <div>
                        {typeRapport === 'etatGlobal1' &&
                            <>
                                {clientIds.length > 1 && <div>
                                    {Object.keys(etatsGlobauxData).map(key => {
                                        return <div>
                                            <ExportData typeRapport='etatGlobal'  data={etatsGlobauxData[key].datas} showLoading={showLoading} />
                                        </div>
                                    })}

                                </div>}

                                {clientIds.length === 1 && <ExportData typeRapport='etatGlobal' data={etatsGlobauxData} showLoading={showLoading} />}
                            </>}

                        {typeRapport === 'etatConsommation' && <>
                            {clientIds.length > 1 && <div>
                                {Object.keys(etatsConsommationData).map(key => {
                                    return <div>
                                        <ExportData typeRapport ='etatConsommation' ref={componentRef} data={etatsConsommationData[key].datas} showLoading={showLoading} />
                                    </div>
                                })}
                            </div>}
                            {clientIds.length === 1 && <ExportData typeRapport ='etatConsommation' data={etatsConsommationData} showLoading={showLoading} />}
                        </>}
                        {typeRapport === 'visitesPrestataires' && <>
                            {clientIds.length > 1 && <div>
                                {Object.keys(etatsVisitesData).map(key => {
                                    return <div>
                                        <ExportData typeRapport= 'visitesPrestataires' data={etatsVisitesData[key].datas} showLoading={showLoading} />
                                    </div>
                                })}
                            </div>}
                            {clientIds.length === 1 && <ExportData typeRapport= 'visitesPrestataires' data={etatsVisitesData} showLoading={showLoading} />}
                        </>}

                        {typeRapport === 'topConsommateurs' && <>
                            {clientIds.length > 1 && <div>
                                {Object.keys(etatsTopConsommateursData).map(key => {
                                    return <div>
                                        <ExportData typeRapport= 'topConsommateurs' data={etatsTopConsommateursData[key].datas.slice(0, maxResults + 1)} type='Famille' showLoading={showLoading} />
                                    </div>
                                })}
                            </div>}
                            {clientIds.length === 1 && <ExportData typeRapport= 'topConsommateurs' data={etatsTopConsommateursData.slice(0, maxResults + 1)} type='Famille' showLoading={showLoading} />}
                        </>}
                        {typeRapport === 'depenseRubrique' && <>
                            {clientIds.length > 1 && <div>
                                {Object.keys(etatsDepenseRubriqueData).map(key => {
                                    return <div>
                                        <ExportData typeRapport = 'depenseRubrique' data={etatsDepenseRubriqueData[key].datas} showLoading={showLoading} />
                                    </div>
                                })}
                            </div>}
                            {clientIds.length === 1 && <ExportData typeRapport = 'depenseRubrique' data={etatsDepenseRubriqueData} showLoading={showLoading} />}
                        </>}
                        {typeRapport === 'topPathologies' && <>
                            {clientIds.length > 1 && <div>
                                {Object.keys(etatsTopPathologiesData).map(key => {
                                    return <div>
                                        <ExportData typeRapport = 'topPathologies' data={etatsTopPathologiesData[key].datas} showLoading={showLoading} />
                                    </div>
                                })}
                            </div>}
                            {clientIds.length === 1 && <ExportData typeRapport = 'topPathologies' data={etatsTopPathologiesData} showLoading={showLoading} />}
                        </>}
                        {typeRapport === 'topSpecialistes' && <>
                            {clientIds.length > 1 && <div>
                                {Object.keys(etatsTopSpecialistesVisitesData).map(key => {
                                    return <div>
                                        <ExportData typeRapport = 'topSpecialistes' data={etatsTopSpecialistesVisitesData[key].datas} showLoading={showLoading} />
                                    </div>
                                })}
                            </div>}
                            {clientIds.length === 1 && <ExportData typeRapport = 'topSpecialistes' data={etatsTopSpecialistesVisitesData} showLoading={showLoading} />}
                        </>}

                    </div>

                </Col>
            </Row>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.user.errorMessage,
        successMessage: state.user.successMessage,
        showLoading: state.user.showLoading,
        currentUser: currentUser(state),
        etatsGlobaux: state.rapport.globalClientStats,
        etatsConsommations: state.rapport.detailedClientStats,
        etatsVisites: state.rapport.visitsClientsStats,
        souscripteurs: state.contrat.souscripteurs,
        collegePerContrat: state.contrat.collegePerContrat,
        famillePerCollege: state.contrat.famillePerCollege,
        contratPerClient: state.contrat.contratPerClient,
        beneficiaires: state.contrat.beneficiaires,
        showLoading: state.rapport.showLoading
    };
};
export default connect(mapStateToProps)(EtatsPanel);