import React, {useState} from "react";
import { useDispatch } from 'react-redux';
import{withRouter} from 'react-router-dom'

/* --- BEGIN ConsultationListItem
	@param(Object): props
    description: Listing des consultations
*/
const ConsultationListItem = (props) => {
    const { history, consultation } = props;

    const [supModal, setSupModal] = useState(false);



    const dispatch = useDispatch();

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");

    /* --- BEGIN chackboxFun
        @param(string): type
        description: Action sur les checkbox de la liste
    */
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };
    /* --- END chackboxFun */

    /* --- BEGIN viewConsultation
        @param(): aucun
        description: fonction de passage vers la vue de détail d'une consultation
    */
    const viewConsultation = () => {
        history.push('/consultation-details/'+consultation.id);
    }
    /* --- END viewConsultation */

    /* --- BEGIN statusColor
        @param(String): status
        description: fonction de gestion de la couleur du statut
    */
    const statusColor = (status) => {
        if(status === "PENDING") {
            return "text-warning"
        }
        if(status === "Non démarré") {
            return "text-secondary"
        }
        if(status === "COMPLETE") {
            return "text-success"
        }
        if(status === "Expiré") {
            return "text-danger"
        }
        return "text-primary"
    }
    /* --- END statusColor */

    return (
        <tr role="row" className="odd">
            <td className="patient_checkbox">
                <div className="checkbox text-right align-self-center">
                    <div className="custom-control custom-checkbox ">
                    <input
                        type="checkbox"
                        onClick={() => chackboxFun()}
                        className="custom-control-input"
                        id="customCheckBox1"
                        required
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheckBox1"
                    />
                    </div>
                </div>
            </td>
            <td>{consultation.id}</td>
            <td>{consultation.consultationBon.beneficiaryName}</td>
            <td>{consultation.consultationBon.doctorName}</td>
            <td>{consultation.openDate}</td>
            <td>
                <div className="d-flex align-items-center">
                    <i className={"fa fa-circle "+ statusColor(consultation.consultationBon.status) + " mr-1"}></i>{" "}
                    {consultation.consultationBon.status}
                </div>
            </td>
            <td className="d-flex justify-content-between align-items-center h100">
                <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={viewConsultation}
                >
                    <i className="fa fa-eye"></i>
                </button>
            </td>
        </tr>
    );
};
/* --- END ConsultationListItem */

export default withRouter(ConsultationListItem);