import {
    START_LOADER_ACTION,
    GET_FACTURES_CONFIRMED_ACTION,
    GET_FACTURES_FAILED_ACTION,
    GET_FACTURE_CONFIRMED_ACTION,
    GET_FACTURE_FAILED_ACTION,
    GET_MOTIFS_EXCLU_CONFIRMED_ACTION,
    GET_MOTIFS_EXCLU_FAILED_ACTION,
    SAVE_REJET_FACTURE_CONFIRMED_ACTION,
    SAVE_REJET_FACTURE_FAILED_ACTION,
    RESTORE_REJET_FACTURE_CONFIRMED_ACTION,
    RESTORE_REJET_FACTURE_FAILED_ACTION,
    REQUEST_REMBOURSEMENT_CONFIRMED_ACTION,
    REQUEST_REMBOURSEMENT_FAILED_ACTION,
    GET_REMBOURSEMENTS_CONFIRMED_ACTION,
    GET_REMBOURSEMENTS_FAILED_ACTION,
    GET_REMBOURSEMENT_CONFIRMED_ACTION,
    GET_REMBOURSEMENT_FAILED_ACTION,
    UPDATE_REMBOURSEMENT_CONFIRMED_ACTION,
    UPDATE_REMBOURSEMENT_FAILED_ACTION,
    CALCULATE_REMB_BASE_CONFIRMED_ACTION,
    CALCULATE_REMB_BASE_FAILED_ACTION,
    CALCULATE_TM_CONFIRMED_ACTION,
    CALCULATE_TM_FAILED_ACTION,
    UPDATE_REMBOURSEMENT_REQUEST_CONFIRMED_ACTION,
    UPDATE_REMBOURSEMENT_REQUEST_FAILED_ACTION,
    SEND_FACTURE_PAYMENT_CONFIRMED_ACTION,
    SEND_FACTURE_PAYMENT_FAILED_ACTION,
    SEND_REMB_PAYMENT_CONFIRMED_ACTION,
    SEND_REMB_PAYMENT_FAILED_ACTION,
    GET_PRESTATIONS_CONFIRMED_ACTION,
    GET_PRESTATIONS_FAILED_ACTION,
    GET_PRESTATION_CONFIRMED_ACTION,
    GET_PRESTATION_FAILED_ACTION,
    CREATE_PRESTATION_CONFIRMED_ACTION,
    CREATE_PRESTATION_FAILED_ACTION,
    CLOSE_PRESTATION_CONFIRMED_ACTION,
    CLOSE_PRESTATION_FAILED_ACTION,
    DELETE_PRESTATION_CONFIRMED_ACTION,
    DELETE_PRESTATION_FAILED_ACTION,
    PAY_FACTURES_CONFIRMED_ACTION,
    PAY_FACTURES_FAILED_ACTION,
    PAY_REMBOURSEMENTS_CONFIRMED_ACTION,
    PAY_REMBOURSEMENTS_FAILED_ACTION,
    GET_PAIEMENTS_CONFIRMED_ACTION,
    UPDATE_PAIEMENT_CONFIRMED_ACTION,
    DELETE_PAIEMENT_CONFIRMED_ACTION,
    STOP_LOADER_ACTION
} from '../actions/FacturationAction';

const initialState = {
    factures: [],
    facture: {},
    motifExclusion: [],
    demandeRembs: [], 
    remboursements: [],
    remboursement: {},
    rembCalculDatas: [],
    ticketModDatas: [],
    prestations: [],
    prestation: {},
    paiements: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const FacturationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FACTURES_CONFIRMED_ACTION:
            return {
                ...state,
                factures: action.payload,
                errorMessage: '',
                successMessage: 'Factures gotten Successfully Completed',
                showLoading: false,
            };
        case GET_FACTURES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                facture: action.payload,
                errorMessage: '',
                successMessage: 'Facture gotten Successfully Completed',
                showLoading: false,
            };
        case GET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_MOTIFS_EXCLU_CONFIRMED_ACTION:
            return {
                ...state,
                motifExclusion: action.payload,
                errorMessage: '',
                successMessage: 'Exclusions gotten Successfully Completed',
                showLoading: false,
            };
        case GET_MOTIFS_EXCLU_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case RESTORE_REJET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Resjet restoration Successfully Completed',
                showLoading: false,
            };
        case RESTORE_REJET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case SAVE_REJET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'reject saving Successfully Completed',
                showLoading: false,
            };
        case SAVE_REJET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case REQUEST_REMBOURSEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'request remboursement Successfully Completed',
                showLoading: false,
            };
        case REQUEST_REMBOURSEMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case GET_REMBOURSEMENTS_CONFIRMED_ACTION:
            return {
                ...state,
                remboursements: action.payload,
                errorMessage: '',
                successMessage: 'Remboursements gotten Successfully Completed',
                showLoading: false,
            };
        case GET_REMBOURSEMENTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_REMBOURSEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                remboursement: action.payload,
                errorMessage: '',
                successMessage: 'Remboursement gotten Successfully Completed',
                showLoading: false,
            };
        case GET_REMBOURSEMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case UPDATE_REMBOURSEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'update remboursement Successfully Completed',
                showLoading: false,
            };
        case UPDATE_REMBOURSEMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case UPDATE_REMBOURSEMENT_REQUEST_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'update remboursement request Successfully Completed',
                showLoading: false,
            };
        case UPDATE_REMBOURSEMENT_REQUEST_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case CALCULATE_REMB_BASE_CONFIRMED_ACTION:
            return {
                ...state,
                rembCalculDatas: state.rembCalculDatas.find(elt => {return elt.index === action.payload.index}) ? state.rembCalculDatas.map((elt, index) => {
                    if(index === action.payload.index) {
                        elt = action.payload.data
                    }
                    return elt;
                }) : [...state.rembCalculDatas, action.payload],
                errorMessage: '',
                successMessage: 'Calculation Successfully Completed',
                showLoading: false,
            };
        case CALCULATE_REMB_BASE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case CALCULATE_TM_CONFIRMED_ACTION:
            return {
                ...state,
                ticketModDatas: state.ticketModDatas.find(elt => {return elt.index === action.payload.index}) ? state.ticketModDatas.map((elt, index) => {
                    if(index === action.payload.index) {
                        elt = action.payload.data
                    }
                    return elt;
                }) : [...state.ticketModDatas, action.payload],
                errorMessage: '',
                successMessage: 'Tickets Successfully Completed',
                showLoading: false,
            };
        case CALCULATE_TM_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SEND_FACTURE_PAYMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Sending Facture for payment Successfully Completed',
                showLoading: false,
            };
        case SEND_FACTURE_PAYMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SEND_REMB_PAYMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Sending Remboursement for payment Successfully Completed',
                showLoading: false,
            };
        case SEND_REMB_PAYMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_PRESTATIONS_CONFIRMED_ACTION:
            return {
                ...state,
                prestations: action.payload,
                errorMessage: '',
                successMessage: 'Prestations gotten Successfully Completed',
                showLoading: false,
            };
        case GET_PRESTATIONS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_PRESTATION_CONFIRMED_ACTION:
            return {
                ...state,
                prestation: action.payload,
                errorMessage: '',
                successMessage: 'Prestation gotten Successfully Completed',
                showLoading: false,
            };
        case GET_PRESTATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case CREATE_PRESTATION_CONFIRMED_ACTION:
            return {
                ...state,
                prestations: [...state.prestations, action.payload],
                errorMessage: '',
                successMessage: 'Prestation created Successfully Completed',
                showLoading: false,
            };
        case CREATE_PRESTATION_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case PAY_FACTURES_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Paiment Registry Successfully Completed',
                showLoading: false,
            };
        case PAY_FACTURES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case PAY_REMBOURSEMENTS_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Paiment Registry Successfully Completed',
                showLoading: false,
            };
        case PAY_REMBOURSEMENTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_PAIEMENTS_CONFIRMED_ACTION:
            return {
                ...state,
                paiements: action.payload,
                errorMessage: '',
                successMessage: 'Paiements gotten successfuly',
                showLoading: false,
            };
        case UPDATE_PAIEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Paiement updated successfuly',
                showLoading: false,
            };
        case DELETE_PAIEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Paiement deleted successfuly',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        case STOP_LOADER_ACTION:
            return {
                ...state,
                showLoading: false,
            };
        default:
            return state;
    };
};