import {
    formatError,
    getDateError,
    addActivite,
    addCity,
    addCountry,
    addExclusion,
    addProfession,
    saveSuccess,
    getParameter,
    addTypeExclusion,
    addProvince,
    getDictionnaireData,
    updateCountry,
    updateCity,
    updateExclusion,
    updateProfession,
    updateActivite,
    updateDictionaire,
    deleteCountry,
    deleteCity,
    deleteActivite,
    deleteProfession,
    deleteExclusion,
    deleteDictionaire
} from '../../services/AdministrationService';


export function addActiviteAction(data) {
    return (dispatch) => {
        addActivite(data)
        .then((response) => {
            response.data['type'] = 'activite';
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addCityAction(data) {
    return (dispatch) => {
        addCity(data)
        .then((response) => {
            response.data['type'] = 'city';
            response.data['countryId'] = data.countryId;
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addCountryAction(data) {
    return (dispatch) => {
        addCountry(data)
        .then((response) => {
            response.data['type'] = 'country';
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addExclusionAction(data) {
    return (dispatch) => {
        addExclusion(data)
        .then((response) => {
            response.data['type'] = 'exclusion';
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(addFailedAction(errorMessage));
        });
    };
}
export function addProfessionAction(data) {
    return (dispatch) => {
        addProfession(data)
        .then((response) => {
            response.data['type'] = 'profession';
            response.data['activityAreaId'] = data.activityAreaId;
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(getFailedAction(errorMessage));
        });
    };
}
export function addTypeExclusionAction(data) {
    return (dispatch) => {
        addTypeExclusion(data)
        .then((response) => {
            response.data['type'] = 'type-exclusion';
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(getFailedAction(errorMessage));
        });
    };
}
export function addProvinceAction(data) {
    return (dispatch) => {
        addProvince(data)
        .then((response) => {
            response.data['type'] = 'province';
            dispatch(confirmedAddAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(getFailedAction(errorMessage));
        });
    };
}

export function getParameterAction() {
    return (dispatch) => {
        getParameter()
        .then((response) => {
            dispatch(confirmedGetAction(response.data));
        })
        .catch((error) => {
            const errorMessage = getDateError(error);
            dispatch(getFailedAction(errorMessage));
        });
    };
}

export function getDictionnaireDataAction(type) {
    return (dispatch) => {
        getDictionnaireData(type)
        .then((response) => {
            dispatch(confirmedGetDitionAction(response.data, type));
        })
        .catch((error) => {
            const errorMessage = getDateError(error);
            dispatch(getFailedAction(errorMessage));
        });
    };
}

export function updateCountryAction(id, data) {
    return (dispatch) => {
        updateCountry(id, data)
        .then((response) => {
            response.data['type'] = 'country';
            response.data['id'] = id;
            dispatch(confirmedUpdateAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(updateFailedAction(errorMessage));
        });
    };
}

export function updateCityAction(id, data) {
    return (dispatch) => {
        updateCity(id, data)
        .then((response) => {
            response.data['type'] = 'city';
            response.data['id'] = id;
            response.data['countryId'] = data.countryId;
            dispatch(confirmedUpdateAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(updateFailedAction(errorMessage));
        });
    };
}

export function updateExclusionAction(id, data) {
    return (dispatch) => {
        updateExclusion(id, data)
        .then((response) => {
            response.data['type'] = 'exclusion';
            response.data['id'] = id;
            response.data['code'] = data.code;
            response.data['nameFr'] = data.nameFr;
            response.data['nameEn'] = data.nameEn;
            dispatch(confirmedUpdateAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(updateFailedAction(errorMessage));
        });
    };
}

export function updateProfessionAction(id, data) {
    return (dispatch) => {
        updateProfession(id, data)
        .then((response) => {
            response.data['type'] = 'profession';
            response.data['id'] = id;
            response.data['activityAreaId'] = data.activityAreaId;
            dispatch(confirmedUpdateAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(updateFailedAction(errorMessage));
        });
    };
}

export function updateActiviteAction(id, data) {
    return (dispatch) => {
        updateActivite(id, data)
        .then((response) => {
            response.data['type'] = 'activite';
            response.data['id'] = id;
            dispatch(confirmedUpdateAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(updateFailedAction(errorMessage));
        });
    };
}

export function updateDictionaireAction(id, data, type) {
    return (dispatch) => {
        updateDictionaire(id, data)
        .then((response) => {
            response.data['type'] = type;
            response.data['id'] = id;
            dispatch(confirmedUpdateAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(updateFailedAction(errorMessage));
        });
    };
}

export function deleteCountryAction(id) {
    return (dispatch) => {
        deleteCountry(id)
        .then((response) => {
            response.data['type'] = 'country';
            response.data['id'] = id;
            dispatch(confirmedDeleteAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(deleteFailedAction(errorMessage));
        });
    };
}

export function deleteCityAction(id, countryId) {
    return (dispatch) => {
        deleteCity(id)
        .then((response) => {
            response.data['type'] = 'city';
            response.data['countryId'] = countryId;
            response.data['id'] = id;
            dispatch(confirmedDeleteAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(deleteFailedAction(errorMessage));
        });
    };
}

export function deleteActiviteAction(id) {
    return (dispatch) => {
        deleteActivite(id)
        .then((response) => {
            let data = {};
            data['type'] = 'activite';
            data['id'] = id;
            dispatch(confirmedDeleteAction(data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(deleteFailedAction(errorMessage));
        });
    };
}

export function deleteProfessionAction(id, activityAreaId) {
    return (dispatch) => {
        deleteProfession(id)
        .then((response) => {
            response.data['type'] = 'profession';
            response.data['activityAreaId'] = activityAreaId;
            response.data['id'] = id;
            dispatch(confirmedDeleteAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(deleteFailedAction(errorMessage));
        });
    };
}

export function deleteExclusionAction(id) {
    return (dispatch) => {
        deleteExclusion(id)
        .then((response) => {
            response.data['type'] = 'exclusion';
            response.data['id'] = id;
            dispatch(confirmedDeleteAction(response.data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(deleteFailedAction(errorMessage));
        });
    };
}

export function deleteDictionaireAction(id, type) {
    return (dispatch) => {
        deleteDictionaire(id)
        .then((response) => {
            let data = {};
            data['type'] = type;
            data['id'] = id;
            dispatch(confirmedDeleteAction(data));
            saveSuccess()
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(deleteFailedAction(errorMessage));
        });
    };
}

export function confirmedDeleteAction(data) {
    return {
        type: "DELETE_CONFIRMED_ACTION",
        payload: data,
    }
}

export function deleteFailedAction(data) {
    return {
        type: "DELETE_FAILED_ACTION",
        payload: data,
    };
}

export function confirmedUpdateAction(data) {
    return {
        type: "UPDATE_CONFIRMED_ACTION",
        payload: data,
    }
}

export function updateFailedAction(data) {
    return {
        type: "UPDATE_FAILED_ACTION",
        payload: data,
    };
}

export function addFailedAction(data) {
    return {
        type: "ADD_FAILED_ACTION",
        payload: data,
    };
}
export function getFailedAction(data) {
    return {
        type: "GET_FAILED_ACTION",
        payload: data,
    };
}
export function confirmedAddAction(data) {
    return {
        type: "ADD_CONFIRMED_ACTION",
        payload: data,
    };
}
export function confirmedGetAction(data) {
    return {
        type: "GET_CONFIRMED_ACTION",
        payload: data,
    };
}
export function loadingToggleAction() {
    return {
        type: "LOADING_TOGGLE_ACTION"
    };
}
export function confirmedGetDitionAction(data, type){
    if(type === 'province'){
        return {
            type: "GET_DICT_PROV_ACTION",
            payload: data,
        };
    }else{
        return {
            type: "GET_DICT_TYPE_EXCL_ACTION",
            payload: data,
        };
    }
}
