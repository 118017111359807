import React, {useState} from "react";
import { useDispatch } from 'react-redux';
import { Link, withRouter } from "react-router-dom";

const NotificationListItem = (props) => {
    const { history, notification } = props;

    const dispatch = useDispatch();

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };

    const viewProvider = () => {
        history.push('/provider-view/'+notification.id);
    }


    return (
        <tr role="row" className="odd">
            <td className="patient_checkbox">
                <div className="checkbox text-right align-self-center">
                    <div className="custom-control custom-checkbox ">
                    <input
                        type="checkbox"
                        onClick={() => chackboxFun()}
                        className="custom-control-input"
                        id="customCheckBox1"
                        required
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheckBox1"
                    />
                    </div>
                </div>
            </td>
            <td>{notification?.emeteurName}</td>
            <td>{notification?.message}</td>
            <td>{notification?.date}</td>
            <td className="d-flex">
                <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={viewProvider}
                >
                    <i className="fa fa-eye"></i>
                </button>
            </td>
        </tr>
    );
};

export default withRouter(NotificationListItem);