import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Spinner, Button, Modal, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getGarantiesAction, getActesProdAction, mapActeProdGarantieAction, loadingToggleAction } from '../../../../store/actions/ContratActions';
import Select from 'react-select';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";

const ActeProduction = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [garantieId, setGarantieId] = useState('');
    const [acteId, setActeId] = useState('');
    const [acteName, setActeName] = useState('');
    const [addCard, setAddCard] = useState(false);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const garantieOptions = [{label: 'Aucune', value:'', code: ''}];

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            garantieName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'code', headerName: "Code", filterPlaceholder: t("common.filter-by-code"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'name', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'garantieName', headerName: t('common.garanty'), filterPlaceholder: t("common.filter-garanty"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATEACTEPROD') && <Link href="#" to="#" onClick={e => updateAct(e, rowData)} data-testid={'update-button-'+rowData.id} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
        </div>
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        initFilters();
        dispatch(loadingToggleAction(true)); 
        dispatch(getActesProdAction());  
        dispatch(getGarantiesAction());
    }, []);

    useEffect(() => {
        setAddCard(false);
    }, [props.actesProd]);

    function onProviderTypeSave(e) {
        e.preventDefault();
        dispatch(loadingToggleAction(true));   

        let data = [{ 
            acteId,
            garantieId
        }];
        dispatch(mapActeProdGarantieAction(data));
    }


    /*useEffect(() => {
        dispatch(loadingToggleAction(true)); 
        dispatch(getActesProdAction());
        dispatch(getGarantiesAction());
    }, [props.successMessage]);*/

    const updateAct = (e, act) => {
        e.preventDefault();
        setActeName(act.name);
        setActeId(act.id);
        setGarantieId(act.garantieId);
        setAddCard(true);
    }

    return (
        <>
            <Modal className="modal fade" size="md" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Mapper l'acte {acteName}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onProviderTypeSave} className="row">

                                <div className='form-group col-12'>
                                    <label className='mb-1'>{t('provider.acte-production')}</label>
                                    <Select
                                        className="select-search"
                                        value={props.actesProd?.filter((opt)=>{return opt.id === acteId}).map((val)=>{return {label:val.name,value:val.id, code:val.code}})[0]}
                                        isDisabled={true}
                                        required
                                        options={props.actesProd?.map((opt)=>{return {label:opt.name,value:opt.id, code: opt.code}})}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>

                                <div className='form-group col-12'>
                                    <label className='mb-1'>Garantie</label>
                                    <Select
                                        className="select-search"
                                        value={props.garanties?.filter((opt)=>{return opt.id === garantieId}).map((val)=>{return {label:val.libelle,value:val.id,code:val.code}})[0]}
                                        placeholder={t("consultation.choose-type")}
                                        onChange={
                                            (val) => {
                                                setGarantieId(val.value);
                                            }
                                        }
                                        isDisabled={props.showLoading}
                                        required
                                        options={props.garanties?.map((opt)=>{return {label:opt.libelle,value:opt.id,code:opt.code}})}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>

                                <div className='form-group align-items-center justify-content-center'>
                                    <div className=''>
                                        { props.showLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }
                                        { !props.showLoading &&
                                            <button type='submit' className='btn btn-primary'>
                                              {t('common.save')}
                                            </button> }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            
            <DataTableBase 
                data={ props.actesProd.length > 0 ? props.actesProd : []}
                emptyMessage={t("common.no-production-act")}
                filters={filters}
                globalFiltersFields={['code', 'name', 'garantieName']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Code', dataKey: 'code' },
                    { title: t('common.name'), dataKey: 'name' },
                    { title: t('common.garanty'), dataKey: 'garantiName' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"actes-production"}
                loading={props.showLoading}
                lazy={false}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        garanties: state.contrat.garanties,
        actesProd: state.contrat.actes,
        currentUser: currentUser(state),
        showLoading: state.contrat.showLoading
    };
};

export default connect(mapStateToProps)(ActeProduction);