import React, {useState} from "react";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTableBase } from '../DataTables/DataTableBase';
import { useTranslation } from "react-i18next";
        

export const PrestationsTab = (props) => {
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const {t} = useTranslation()

    const {data} = props;

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            libelleActe: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            ticketModerateur: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            plafondActe: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Réinitialiser" outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t("common.search")} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'libelleActe', headerName: t("dashboard.prestations"), filterPlaceholder: t("common.examFilter"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'ticketModerateur', dataType: 'numeric', headerName: t("common.coverage-rate"), filterPlaceholder: t("common.filter-coverage-rate"), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'plafondActe', dataType: 'numeric', headerName: t("common.top") , filterPlaceholder: t("common.filter-top"), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
        ]
    };

    const headerTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span className="font-bold">{rowData.typeBon}</span>
            </div>
        );
    };

    return (
        <>
            <DataTableBase 
                data={data}
                size="small"
                emptyMessage={t("common.no-prestation")}
                filters={filters}
                globalFiltersFields={['libelleActe', 'ticketModerateur', 'plafondActe']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
                rowGroupMode="subheader"
                groupRows="garantieName"
                rowGroupHeaderTemplate={headerTemplate}
            />
        </>
    )
};

export default PrestationsTab;