import {
    GET_SINISTRES_FAILED,
    GET_SINISTRES_CONFIRMED,
    GET_SINISTRES_BY_PERIODE_CONFIRMED,
    GET_SINISTRES_DECLARE_CONFIRMED,
    GET_EVALUATIONS_CONFIRMED,
    GET_RECOURS_CONFIRMED,
    GET_REGLEMENTS_CONFIRMED,
    GET_REGLEMENTS_BY_PERIOD_CONFIRMED,
    START_LOADER_ACTION
} from '../actions/SinistreAction';

const initialData = {
    sinistres: {},
    sinistresByPeriode: [],
    sinistresDeclares: [],
    evaluations: [],
    recours: [],
    reglements: {},
    reglementsByPeriode: [],
    showLoading: false
}

export function SinistreReducer(state = initialData, action) {
    switch (action.type) {
        case  GET_SINISTRES_CONFIRMED :
            return {
                ...state,
                sinistres: action.payload,
                showLoading: false,
            };
        case  GET_SINISTRES_BY_PERIODE_CONFIRMED :
            return {
                ...state,
                sinistresByPeriode: action.payload,
                showLoading: false,
            };
        case  GET_SINISTRES_DECLARE_CONFIRMED :
            return {
                ...state,
                sinistresDeclares: action.payload,
                showLoading: false,
            };
        case  GET_EVALUATIONS_CONFIRMED :
            return {
                ...state,
                evaluations: action.payload,
                showLoading: false,
            };
        case  GET_RECOURS_CONFIRMED :
            return {
                ...state,
                recours: action.payload,
                showLoading: false,
            };
        case  GET_REGLEMENTS_CONFIRMED :
            return {
                ...state,
                reglements: action.payload,
                showLoading: false,
            };
        case  GET_REGLEMENTS_BY_PERIOD_CONFIRMED :
            return {
                ...state,
                reglementsByPeriode: action.payload,
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true
            };
        default:
            return state;
    }
}