import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import {
    listClientsAction,
    startLoader
} from '../../../../store/actions/ClientAuthActions';
import ClientListItem from "./ClientListItem";
import ClientPagination from './ClientPagination';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
/* --- BEGIN ClientsList
	@param(Object): props
    description: Listing des clients après recherche
*/
export const ClientsList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let data = useRef([]);
    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    let max = 10;

    let totalPages = data.length > max ? data.length / max : 1;

    /* --- BEGIN loadPageData
        @param(): aucun
        description: Fonction de chargement des données lors du changement de page
    */
    const loadPageData = () => {
        let start = (currentPage - 1) * max;
        data = props.client.slice(start, max+1);
    }
    /* --- END loadPageData */

    /* --- BEGIN changePage
        @param(number): page
        description: Fonction de changement de page appellées depuis le composant de la pagination
    */
    const changePage = (page) => {
        setCurrentPage(page);
        loadPageData();
    }
    /* --- END changePage */

    
    useEffect(() => {
        dispatch(startLoader());
        dispatch(listClientsAction());
        data = props.clients;
    }, []);

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");

    /* --- BEGIN changePage
        @param(string): type
        description: Fonction de gestion des checkbox
    */
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };
    /* --- END chackboxFun */

    /* --- BEGIN handleChange
        @param(Event): e
        description: Fonction de gestion du champ de recherche et des résultats de la recherche
    */
    const handleChange = (e) => {
        if(e.target.value.length === 0) {
            data = props.clients;
            return;
        }
        setSearchValue(e.target.value);
    }
    /* --- END handleChange */

    /* --- BEGIN search
        @param(Event): e
        description: Fonction de recherche dans la liste des clients
    */
    const search = (e) => {
        e.preventDefault();
        let searchResult = props.clients.filter((client) => client.firstname == searchValue || client.lastName == searchValue);
        data = searchResult;
    }
    /* --- END search */

    return (
        <>
            { props.showLoading && <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading &&
            <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                {/*<div className="mr-auto d-none d-lg-block">
                    <Link
                    to="/patient-details"
                    className="btn btn-primary btn-rounded"
                    >
                    +{t('common.save')} un prestataire
                    </Link>
                </div>*/}
                <div className="input-group search-area ml-auto d-inline-flex mr-3">
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    value={searchValue}
                    onChange={(e) => handleChange(e)}
                    />
                    <div className="input-group-append">
                    <button type="button" className="input-group-text" onClick={(e) => search(e)}>
                        <i className="flaticon-381-search-2" />
                    </button>
                    </div>
                </div>
                <Link to="/client-list" className="settings-icon">
                    <i className="flaticon-381-settings-2 mr-0" />
                </Link>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="table-responsive">
                        <div id="patient_list" className="dataTables_wrapper no-footer">

                            <table
                                id="example5"
                                className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                                role="grid"
                                aria-describedby="example5_info">

                                <thead>
                                    <tr role="row">
                                        <th
                                            className="patient_strg"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            onClick={() => chackboxFun("all")}
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-sort="ascending"
                                            style={{ width: 24 }}
                                        >
                                            <div className="checkbox text-right align-self-center">
                                                <div className="custom-control custom-checkbox ">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="checkAll"
                                                    required
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkAll"
                                                />
                                                </div>
                                            </div>
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                            style={{ width: 79 }}
                                        >
                                            {t('patient.assured-number')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                            style={{ width: 79 }}
                                        >
                                            {t('common.name-firstname')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Date Check In: activate to sort column ascending"
                                            style={{ width: 113 }}
                                        >
                                            {t('common.city')}
                                        </th>
                                        {/*<th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient Name: activate to sort column ascending"
                                            style={{ width: 108 }}
                                        >
                                           {t('common.phone')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Doctor Assgined: activate to sort column ascending"
                                            style={{ width: 135 }}
                                        >
                                            Email
                                        </th>*/}
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                            style={{ width: 68 }}
                                        >
                                            {t('common.gender')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Status: activate to sort column ascending"
                                            style={{ width: 103 }}
                                        >
                                            {t('common.birthdate')}
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label=": activate to sort column ascending"
                                            style={{ width: 24 }}
                                        />
                                    </tr>
                                </thead>

                                <tbody>
                                    {props.clients.map((client) => {
                                        return <ClientListItem key={client.id} client={client} history={props.history} />
                                    })}
                                </tbody>

                            </table>
                            <ClientPagination totalData={data.length} total={totalPages} currentPage={currentPage} switchPage={changePage} max={max} /> 
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
        </> 
    )
};
/* --- END ClientsList */

const mapStateToProps = (state) => {
    return {
        clients: state.clientAuth.clients,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.clientAuth.showLoading,
    };
};

export default connect(mapStateToProps)(ClientsList);