import { getFacture, getFactures, getBordereauDetail, restoreRejet, saveRejet, 
getMotifExclusion, getRemboursements, getRemboursement, updateRemboursement, 
requestRemboursement, updateRemboursementRequest, calculationRembBase, 
calculationTM, sendRembForPayment, sendFactureForPayment, getPrestations,
getPrestation, createPrestations, closePrestations, deletePrestations, payFacture,
getPaiements, updatePaiement, deletePaiement, payRemboursement } from '../../services/FacturationService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';
import uploadFiles from '../../services/BlobStorageService';


export const GET_FACTURES_CONFIRMED_ACTION = '[getFactures action] confirmed getFactures';
export const GET_FACTURES_FAILED_ACTION = '[getFactures action] failed getFactures';

export const GET_FACTURE_CONFIRMED_ACTION = '[getFacture action] confirmed getFacture';
export const GET_FACTURE_FAILED_ACTION = '[getFacture action] failed getFacture';

export const GET_MOTIFS_EXCLU_CONFIRMED_ACTION = '[getMotifExclu action] confirmed getMotifExclu';
export const GET_MOTIFS_EXCLU_FAILED_ACTION = '[getMotifExclu action] failed getMotifExclu';

export const SAVE_REJET_FACTURE_CONFIRMED_ACTION = '[saveRejetFacture action] confirmed saveRejetFacture';
export const SAVE_REJET_FACTURE_FAILED_ACTION = '[saveRejetFacture action] failed saveRejetFacture';

export const RESTORE_REJET_FACTURE_CONFIRMED_ACTION = '[restoreRejetFacture action] confirmed restoreRejetFacture';
export const RESTORE_REJET_FACTURE_FAILED_ACTION = '[restoreRejetFacture action] failed restoreRejetFacture';

export const REQUEST_REMBOURSEMENT_CONFIRMED_ACTION = '[requestRemboursement action] confirmed requestRemboursement';
export const REQUEST_REMBOURSEMENT_FAILED_ACTION = '[requestRemboursement action] failed requestRemboursement';

export const GET_REMBOURSEMENTS_CONFIRMED_ACTION = '[getRemboursements action] confirmed getRemboursements';
export const GET_REMBOURSEMENTS_FAILED_ACTION = '[getRemboursements action] failed getRemboursements';

export const GET_REMBOURSEMENT_CONFIRMED_ACTION = '[getRemboursement action] confirmed getRemboursement';
export const GET_REMBOURSEMENT_FAILED_ACTION = '[getRemboursement action] failed getRemboursement';

export const UPDATE_REMBOURSEMENT_CONFIRMED_ACTION = '[updateRemboursement action] confirmed updateRemboursement';
export const UPDATE_REMBOURSEMENT_FAILED_ACTION = '[updateRemboursement action] failed updateRemboursement';

export const UPDATE_REMBOURSEMENT_REQUEST_CONFIRMED_ACTION = '[updateRemboursementRequest action] confirmed updateRemboursementRequest';
export const UPDATE_REMBOURSEMENT_REQUEST_FAILED_ACTION = '[updateRemboursementRequest action] failed updateRemboursementRequest';

export const CALCULATE_REMB_BASE_CONFIRMED_ACTION = '[CalculateRembBase action] confirmed CalculateRembBase';
export const CALCULATE_REMB_BASE_FAILED_ACTION = '[CalculateRembBase action] failed CalculateRembBase';

export const CALCULATE_TM_CONFIRMED_ACTION = '[CalculateTM action] confirmed CalculateTM';
export const CALCULATE_TM_FAILED_ACTION = '[CalculateTM action] failed CalculateTM';

export const SEND_FACTURE_PAYMENT_CONFIRMED_ACTION = '[sendFacturePayment action] confirmed sendFacturePayment';
export const SEND_FACTURE_PAYMENT_FAILED_ACTION = '[sendFacturePayment action] failed sendFacturePayment';

export const SEND_REMB_PAYMENT_CONFIRMED_ACTION = '[sendRembPayment action] confirmed sendRembPayment';
export const SEND_REMB_PAYMENT_FAILED_ACTION = '[sendRembPayment action] failed sendRembPayment';

export const GET_PRESTATIONS_CONFIRMED_ACTION = '[getPrestations action] confirmed getPrestations';
export const GET_PRESTATIONS_FAILED_ACTION = '[getPrestations action] failed getPrestations';

export const GET_PRESTATION_CONFIRMED_ACTION = '[getPrestation action] confirmed getPrestation';
export const GET_PRESTATION_FAILED_ACTION = '[getPrestation action] failed getPrestation';

export const CREATE_PRESTATION_CONFIRMED_ACTION = '[createPrestation action] confirmed createPrestation';
export const CREATE_PRESTATION_FAILED_ACTION = '[createPrestation action] failed createPrestation';

export const CLOSE_PRESTATION_CONFIRMED_ACTION = '[closePrestation action] confirmed closePrestation';
export const CLOSE_PRESTATION_FAILED_ACTION = '[closePrestation action] failed closePrestation';

export const DELETE_PRESTATION_CONFIRMED_ACTION = '[deletePrestation action] confirmed deletePrestation';
export const DELETE_PRESTATION_FAILED_ACTION = '[deletePrestation action] failed deletePrestation';

export const PAY_FACTURES_CONFIRMED_ACTION = '[payFactures action] confirmed payFactures';
export const PAY_FACTURES_FAILED_ACTION = '[payFactures action] failed payFactures';

export const PAY_REMBOURSEMENTS_CONFIRMED_ACTION = '[payRemboursements action] confirmed payRemboursements';
export const PAY_REMBOURSEMENTS_FAILED_ACTION = '[payRemboursements action] failed payRemboursements';

export const GET_PAIEMENTS_CONFIRMED_ACTION = '[getPaiements action] confirmed getPaiements';

export const UPDATE_PAIEMENT_CONFIRMED_ACTION = '[updatePaiement action] confirmed updatePaiement';

export const DELETE_PAIEMENT_CONFIRMED_ACTION = '[deletePaiement action] confirmed deletePaiement';

export const START_LOADER_ACTION = '[providerLoader action] started providerLoader';

export const STOP_LOADER_ACTION = '[providerLoader action] stoped providerLoader';

var I18n = require('react-redux-i18n').I18n;

export const getFacturesAction = (providerId) => {
   
   return (dispatch) => {
    getFactures(providerId)
       .then((response) => {
           
           dispatch(confirmedGetFacturesAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.getFactures.error')+error.response?.data?.message);
           dispatch(getFacturesFailedAction(error.response?.data?.message));
       });
   };
};

export const getFactureAction = (id) => {
   
   return (dispatch) => {
    getBordereauDetail(id)
       .then((response) => {
           
           dispatch(confirmedGetFactureAction(response.data));
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.getFactures.error_single')+error.response?.data?.message);
           dispatch(getFactureFailedAction(error.response?.data?.message));
           //history.goBack();
       });
   };
};

export const getMotifsExclusionAction = () => {
    
    return (dispatch) => {
        getMotifExclusion()
        .then((response) => {
            
            dispatch(confirmedGetMotifsExclusionAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.getMotifs.error')+error.response?.data?.message);
            dispatch(getMotifsExclusionFailedAction(error.response?.data?.message));
        });
    };
 };


export const saveRejetAction = (id, data) => {
   
   return (dispatch) => {
    saveRejet(id, data)
       .then((response) => {
           
           successToastr("Exclusion enregistrée");
           dispatch(confirmedSaveRejetFactureAction());
       })
       .catch((error) => {
           errorToastr(I18n.t('factures.rejet.save_error')+error.response?.data?.message);
           dispatch(saveRejetFactureFailedAction(error.response?.data?.message));
       });
   };
}

export const restoreRejetAction = (id) => {
    
    return (dispatch) => {
        restoreRejet(id)
        .then((response) => {
            
            successToastr("Exclusion annulée");
            dispatch(confirmedRestoreRejetFactureAction());
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rejet.restore_error')+error.response?.data?.message);
            dispatch(restoreRejetFactureFailedAction(error.response?.data?.message));
        });
    };
 }


 export const requestRemboursementAction = (data, history) => {
    
    return async (dispatch) => {
        for(let i=0; i<data.ligneAssures.length; i++) {
            for(let k=0; k<data.ligneAssures[i].benefList.length; k++) {
                for(let l=0; l<data.ligneAssures[i].benefList[k].remboursements.length; l++){
                    let billRemFiles = await uploadFiles(data.ligneAssures[i].benefList[k].remboursements[l].files, 'amp-providers-container');
                    data.ligneAssures[i].benefList[k].remboursements[l].files = billRemFiles;
                    for(let j=0; j<data.ligneAssures[i].benefList[k].remboursements[l].details.length; j++){
                        let billFiles = await uploadFiles(data.ligneAssures[i].benefList[k].remboursements[l].details[j].files, 'amp-providers-container');
                        data.ligneAssures[i].benefList[k].remboursements[l].details[j].files = billFiles;
                    }
                }
            }
        }
        requestRemboursement(data)
        .then((response) => {
            
            successToastr(I18n.t('factures.rembours.request_success'));
            dispatch(confirmedRequestRemboursementAction());
            history.push('/remboursement-list');
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.request_error')+error.response?.data?.message);
            dispatch(requestRemboursementFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getRemboursementsAction = () => {
    
    return (dispatch) => {
     getRemboursements()
        .then((response) => {
            
            dispatch(confirmedGetRemboursementsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.gets_error')+error.response?.data?.message);
            dispatch(getRemboursementsFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getRemboursementAction = (id) => {
    
    return (dispatch) => {
     getRemboursement(id)
        .then((response) => {
            
            dispatch(confirmedGetRemboursementAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.get_error')+error.response?.data?.message);
            dispatch(getRemboursementFailedAction(error.response?.data?.message));
        });
    };
 };

 export const updateRemboursementAction = (id, data, history) => {
    
    return (dispatch) => {
        updateRemboursement(id, data)
        .then((response) => {
            
            successToastr("Demande de remboursement enregistrée");
            dispatch(confirmedUpdateRemboursementAction(response.data));
            history.push('/remboursement-details/'+id);
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.update_error')+error.response?.data?.message);
            dispatch(updateRemboursementFailedAction(error.response?.data?.message));
        });
    };
 };

 export const updateRemboursementRequestAction = (id, data, history) => {
    
    return (dispatch) => {
        updateRemboursementRequest(id, data)
        .then((response) => {
            
            successToastr("Demande de remboursement mise à jour");
            dispatch(confirmedUpdateRemboursementRequestAction(response.data));
            history.push('/remboursement-details/'+id);
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.rembours.update_error')+error.response?.data?.message);
            dispatch(updateRemboursementRequestFailedAction(error.response?.data?.message));
        });
    };
 };

 export const calculationRembBaseAction = (data, index) => {
    
    return async (dispatch) => {
        calculationRembBase(data.providerId, data.patientId, data.acteId)
        .then((response) => {
            
            dispatch(confirmedCalculationRembBaseAction({data: response.data, index}));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.calculate.error_base_remb')+error.response?.data?.message);
            dispatch(updateCalculationRembBaseFailedAction(error.response?.data?.message));
        });
    };
 };

 export const calculationTMAction = (data, index) => {
    
    return (dispatch) => {
        calculationTM(data.patientId)
        .then((response) => {
            
            dispatch(confirmedCalculationTMAction({data: response.data, index}));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.calculate.error_tm')+error.response?.data?.message);
            dispatch(updateCalculationTMFailedAction(error.response?.data?.message));
        });
    };
 };

 export const sendFacturePaymentAction = (id) => {
    return (dispatch) => {
        sendFactureForPayment(id)
        .then((response) => {
            
            successToastr(I18n.t('factures.sendForPayment.success_facture'));
            dispatch(confirmedSendFacturePaymentAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.sendForPayment.error_facture')+error.response?.data?.message);
            dispatch(sendFacturePaymentFailedAction(error.response?.data?.message));
        });
    };
 };

 export const sendRembPaymentAction = (id) => {
    
    return (dispatch) => {
        sendRembForPayment(id)
        .then((response) => {
            
            dispatch(confirmedSendRembPaymentAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.sendForPayment.error_rembours')+error.response?.data?.message);
            dispatch(sendRembPaymentFailedAction(error.response?.data?.message));
        });
    };
 };

export const getPrestationsAction = () => {
    
    return (dispatch) => {
     getPrestations()
        .then((response) => {
            
            dispatch(confirmedGetPrestationsAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('factures.prestations.error_gets')+error.response?.data?.message);
            dispatch(getPrestationsFailedAction(error.response?.data?.message));
        });
    };
 };

 export const getPrestationAction = (id) => {
     
     return (dispatch) => {
      getPrestation(id)
         .then((response) => {
             
             dispatch(confirmedGetPrestationAction(response.data));
         })
         .catch((error) => {
             errorToastr(I18n.t('factures.prestations.error_get')+error.response?.data?.message);
             dispatch(getPrestationFailedAction(error.response?.data?.message));
         });
     };
  };

  export const createPrestationsAction = (data, history) => {
      
      return (dispatch) => {
       createPrestations(data)
          .then((response) => {
              
              successToastr("Prestation crée");
              history.push('/prestation/'+response.data.id);
              //dispatch(confirmedCreatePrestationsAction(response.data));
          })
          .catch((error) => {
              errorToastr(I18n.t('factures.prestations.error_create')+error.response?.data?.message);
              dispatch(createPrestationsFailedAction(error.response?.data?.message));
          });
      };
   };

   export const closePrestationsAction = (id) => {
       
       return (dispatch) => {
        closePrestations(id)
           .then((response) => {
               
               successToastr("Prestation clôturée");
               dispatch(getPrestationsAction());
               //dispatch(confirmedClosePrestationsAction(response.data));
           })
           .catch((error) => {
               errorToastr(I18n.t('factures.prestations.error_close')+error.response?.data?.message);
               dispatch(closePrestationsFailedAction(error.response?.data?.message));
           });
       };
    };

    export const deletePrestationsAction = (id) => {
        
        return (dispatch) => {
         deletePrestations(id)
            .then((response) => {
                successToastr("Prestation supprimée");
                dispatch(getPrestationsAction());
                
                //dispatch(confirmedDeletePrestationsAction(response.data));
            })
            .catch((error) => {
                errorToastr(I18n.t('factures.prestations.error_delete')+error.response?.data?.message);
                dispatch(deletePrestationsFailedAction(error.response?.data?.message));
            });
        };
     };

     

    export const payFactureAction = (data) => {
        
        return (dispatch) => {
            payFacture(data)
            .then((response) => {
                
                successToastr("Paiement des bordereaux enregistré avec succès.")
                dispatch(getFacturesAction(''));
            })
            .catch((error) => {
                errorToastr(I18n.t('factures.getFactures.error_single')+error.response?.data?.message);
                dispatch(payFactureFailedAction(error.response?.data?.message));
                //history.goBack();
            });
        };
    };

    export const payRemboursementAction = (data) => {
        
        return (dispatch) => {
            payRemboursement(data)
            .then((response) => {
                
                successToastr("Paiement des bordereaux enregistré avec succès.")
                dispatch(getRemboursementsAction(''));
            })
            .catch((error) => {
                errorToastr(I18n.t('factures.getFactures.error_single')+error.response?.data?.message);
                dispatch(payRemboursementFailedAction(error.response?.data?.message));
                //history.goBack();
            });
        };
    };

    export const getPaiementsAction = () => {
        
        return (dispatch) => {
            getPaiements()
            .then((response) => {
                
                dispatch(confirmedGetPaiementsAction(response.data));
            })
            .catch((error) => {
                errorToastr("Impossible de récupérer les paiements: "+error.response?.data?.message);
                dispatch(payFactureFailedAction(error.response?.data?.message));
                //history.goBack();
            });
        };
    };

    export const updatePaiementAction = (id, data) => {
        
        return (dispatch) => {
            updatePaiement(id, data)
            .then((response) => {
                
                dispatch(confirmedUpdatePaiementAction(response.data));
                dispatch(getPaiementsAction());
            })
            .catch((error) => {
                errorToastr("Impossible de modifier le paiement: "+error.response?.data?.message);
                dispatch(payFactureFailedAction(error.response?.data?.message));
                //history.goBack();
            });
        };
    };

    export const deletePaiementAction = (id) => {
        
        return (dispatch) => {
            deletePaiement(id)
            .then((response) => {
                
                dispatch(confirmedDeletePaiementAction(response.data));
                dispatch(getPaiementsAction());
            })
            .catch((error) => {
                errorToastr("Impossible de supprimer le paiement: "+error.response?.data?.message);
                dispatch(payFactureFailedAction(error.response?.data?.message));
                //history.goBack();
            });
        };
    };

    export function confirmedDeletePaiementAction(payload) {
       return {
           type: DELETE_PAIEMENT_CONFIRMED_ACTION,
           payload,
       };
    };

    export function confirmedUpdatePaiementAction(payload) {
       return {
           type: UPDATE_PAIEMENT_CONFIRMED_ACTION,
           payload,
       };
    };

    export function confirmedGetPaiementsAction(payload) {
       return {
           type: GET_PAIEMENTS_CONFIRMED_ACTION,
           payload,
       };
    };

    export function confirmedPayFactureAction(payload) {
       return {
           type: PAY_FACTURES_CONFIRMED_ACTION,
           payload,
       };
    };
    
    export function payFactureFailedAction(payload) {
       return {
           type: PAY_FACTURES_FAILED_ACTION,
           payload,
       };
    };

    export function confirmedPayRemboursementAction(payload) {
       return {
           type: PAY_REMBOURSEMENTS_CONFIRMED_ACTION,
           payload,
       };
    };
    
    export function payRemboursementFailedAction(payload) {
       return {
           type: PAY_REMBOURSEMENTS_FAILED_ACTION,
           payload,
       };
    };

     export function confirmedDeletePrestationsAction(payload) {
        return {
            type: DELETE_PRESTATION_CONFIRMED_ACTION,
            payload,
        };
     };
     
     export function deletePrestationsFailedAction(payload) {
        return {
            type: DELETE_PRESTATION_FAILED_ACTION,
            payload,
        };
     };

    export function confirmedClosePrestationsAction(payload) {
       return {
           type: CLOSE_PRESTATION_CONFIRMED_ACTION,
           payload,
       };
    };
    
    export function closePrestationsFailedAction(payload) {
       return {
           type: CLOSE_PRESTATION_FAILED_ACTION,
           payload,
       };
    };

   export function confirmedCreatePrestationsAction(payload) {
      return {
          type: CREATE_PRESTATION_CONFIRMED_ACTION,
          payload,
      };
   };
   
   export function createPrestationsFailedAction(payload) {
      return {
          type: CREATE_PRESTATION_FAILED_ACTION,
          payload,
      };
   };
 

  export function confirmedGetPrestationAction(payload) {
     return {
         type: GET_PRESTATION_CONFIRMED_ACTION,
         payload,
     };
  };
  
  export function getPrestationFailedAction(payload) {
     return {
         type: GET_PRESTATION_FAILED_ACTION,
         payload,
     };
  };

 export function confirmedGetPrestationsAction(payload) {
    return {
        type: GET_PRESTATIONS_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getPrestationsFailedAction(payload) {
    return {
        type: GET_PRESTATIONS_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedSendFacturePaymentAction(payload) {
    return {
        type: SEND_FACTURE_PAYMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function sendFacturePaymentFailedAction(payload) {
    return {
        type: SEND_FACTURE_PAYMENT_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedSendRembPaymentAction(payload) {
    return {
        type: SEND_REMB_PAYMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function sendRembPaymentFailedAction(payload) {
    return {
        type: SEND_REMB_PAYMENT_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedCalculationTMAction(payload) {
    return {
        type: CALCULATE_TM_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateCalculationTMFailedAction(payload) {
    return {
        type: CALCULATE_TM_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedCalculationRembBaseAction(payload) {
    return {
        type: CALCULATE_REMB_BASE_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateCalculationRembBaseFailedAction(payload) {
    return {
        type: CALCULATE_REMB_BASE_FAILED_ACTION,
        payload,
    };
 };

 export function confirmedUpdateRemboursementRequestAction(payload) {
    return {
        type: UPDATE_REMBOURSEMENT_REQUEST_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateRemboursementRequestFailedAction(payload) {
    return {
        type: UPDATE_REMBOURSEMENT_REQUEST_FAILED_ACTION,
        payload,
    };
 };


 export function confirmedUpdateRemboursementAction(payload) {
    return {
        type: UPDATE_REMBOURSEMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function updateRemboursementFailedAction(payload) {
    return {
        type: UPDATE_REMBOURSEMENT_FAILED_ACTION,
        payload,
    };
 };


 export function confirmedGetRemboursementAction(payload) {
    return {
        type: GET_REMBOURSEMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getRemboursementFailedAction(payload) {
    return {
        type: GET_REMBOURSEMENT_FAILED_ACTION,
        payload,
    };
 };


 export function confirmedGetRemboursementsAction(payload) {
    return {
        type: GET_REMBOURSEMENTS_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getRemboursementsFailedAction(payload) {
    return {
        type: GET_REMBOURSEMENTS_FAILED_ACTION,
        payload,
    };
 };


 export function confirmedRequestRemboursementAction(payload) {
    return {
        type: REQUEST_REMBOURSEMENT_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function requestRemboursementFailedAction(payload) {
    return {
        type: REQUEST_REMBOURSEMENT_FAILED_ACTION,
        payload,
    };
 };

export function confirmedGetFacturesAction(payload) {
   return {
       type: GET_FACTURES_CONFIRMED_ACTION,
       payload,
   };
};

export function getFacturesFailedAction(payload) {
   return {
       type: GET_FACTURES_FAILED_ACTION,
       payload,
   };
};

export function confirmedGetFactureAction(payload) {
   return {
       type: GET_FACTURE_CONFIRMED_ACTION,
       payload,
   };
};

export function getFactureFailedAction(payload) {
   return {
       type: GET_FACTURE_FAILED_ACTION,
       payload,
   };
};


export function confirmedGetMotifsExclusionAction(payload) {
    return {
        type: GET_MOTIFS_EXCLU_CONFIRMED_ACTION,
        payload,
    };
 };
 
 export function getMotifsExclusionFailedAction(payload) {
    return {
        type: GET_MOTIFS_EXCLU_FAILED_ACTION,
        payload,
    };
 };

export function startLoader() {
   return {
       type: START_LOADER_ACTION
   };
};

export function stopLoader() {
   return {
       type: STOP_LOADER_ACTION
   };
};

export function confirmedSaveRejetFactureAction() {
   return {
       type: SAVE_REJET_FACTURE_CONFIRMED_ACTION
   };
};

export function saveRejetFactureFailedAction(payload) {
   return {
       type: SAVE_REJET_FACTURE_FAILED_ACTION,
       payload,
   };
};

export function confirmedRestoreRejetFactureAction() {
    return {
        type: RESTORE_REJET_FACTURE_CONFIRMED_ACTION
    };
 };
 
 export function restoreRejetFactureFailedAction(payload) {
    return {
        type: RESTORE_REJET_FACTURE_FAILED_ACTION,
        payload,
    };
 };