import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getAllTarifsAction, getActsByFamiliesAction, startLoader, getProviderDataAction, listProvidersAction, updateProviderTarificationAction, getTarifsMedicamentAction,
    updateProviderMedicamentTarificationAction
 } from '../../../../store/actions/ProviderActions';
import SelectSearch from "react-select-search";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Button, Modal, ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import DateFnsUtils from "@date-io/date-fns";
import '../../Clients/Styles/selectStyle.css';
import Select from 'react-select';
import { useTranslation } from "react-i18next";

export const TarificationForm = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { providers, families, tarifs, provider, showLoading, history, tarifsMedicament } = props;
    
    const { id } = useParams();

    const typeTarifs = [
        {label: t('provider.price-act'), value: "actes"}, 
        {label: t('provider.price-medic'), value: "pharmacie"}
    ];
    //const provider = id ? providers.filter((prov) => {return prov.id === parseInt(id)})[0] : null;

    const [name, setName] = useState("");
    const [fileSrc, setFileSrc] = useState(null);
    const [dateEffet, setDateEffet] = useState(new Date());
    const [dateCloture, setDateCloture] = useState(new Date());
    const [tarificationList, setTarificationList] = useState("");
    const [tarificationsDatas, setTarificationsDatas] = useState([]);
    const [tarifUpdateMode, setTarifUpdateMode] = useState(false);
    const [tarifCreateMode, setTarifCreateMode] = useState(false);
    const [tarificationToUpdate, setTarificationToUpdate] = useState(null);
    const [newTarifData, setNewTarifData] = useState({});
    const [loadedActs, setLoadedActs] = useState([]);
    const [currentActs, setCurrentActs] = useState([]);
    const [createdTarifData, setCreatedTarifData] = useState([]);
    const [choosenFamily, setChoosenFamily] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [keySearch, setKeySearch] = useState("");
    const [typeTarif, setTypeTarif] = useState("actes");

    const switchToEditMode = () => {
        setTarifUpdateMode(!tarifUpdateMode);
        setTarifCreateMode(false);
        setName("");
        setFileSrc(null);
        setDateEffet(new Date());
        setDateCloture(new Date());
    }

    const switchToCreateMode = () => {
        setTarifCreateMode(!tarifCreateMode);
        setTarifUpdateMode(false);
        setName("");
        setFileSrc(null);
        setDateEffet(new Date());
        setDateCloture(new Date());
    }

    const chooseTarificationToUpdate = (value) => {
        setTarificationToUpdate(value);
        let tarf = provider.tarifProvider.filter(t => {return t.id === value})[0];
        setName(tarf.tarif.name);
        setTarificationsDatas(tarf.tarif.tarifActes);
        setDateEffet(tarf.dateEffet);
        setDateCloture(tarf.dateCloture);
        setNewTarifData(tarf.tarif.tarifActes.map(tarifActe => { return {tarifActeId: tarifActe.id, value: tarifActe.value} }) );
    }

    const chooseFamily = (val) => {
        setChoosenFamily(val.value);
        let fam = families.filter(f => {return f.id === val.value})[0];
        setLoadedActs(fam.actes.map(act => {
            if(createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                act["value"] = 0;
                return act;
            }else{
                return
            }
        }).filter(el => { return el}));

        setCurrentActs(fam.actes.map(act => {
            if(createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                act["value"] = 0;
                return act;
            }else{
                return
            }
        }).filter(el => { return el}));
    }

    const addConvention = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        setFileSrc(upFiles[keys[0]]);
    }

    const addDataToNewTarif = (acte) => {
        if(createdTarifData.filter(elt => { return elt.id === acte.id}).length === 0){
            let arr = createdTarifData;
            arr.push(acte);
            setLoadedActs(loadedActs.filter(elt => { return elt.id !== acte.id }));
            setCreatedTarifData(arr);
        }
    }

    const removeDataToNewTarif = (acte) => {
        if(loadedActs.filter(elt => { return elt.id === acte.id}).length === 0){
            let arr = loadedActs;
            arr.push(acte);
            setCreatedTarifData(createdTarifData.filter(elt => {return elt.id !== acte.id}));
            setLoadedActs(arr);
        }
    }

    const openConfirm = (e) => {
        e.preventDefault();
        setConfirmModal(true);
    }

    const saveTarification = (e, createNew = null) => {
        e.preventDefault();
        
        dispatch(startLoader());
        
        let data = null;

        if(tarifCreateMode){
            data = {
                providerId: provider.id,
                name,
                dateEffet,
                dateCloture,
                conventionFile: fileSrc,
                newTarif: createdTarifData.map(tarf => { return {acteId: tarf.id, value: tarf.value}})
            };
            dispatch(updateProviderTarificationAction('create', data, props.history));
        }else if(tarifUpdateMode){
            data = {
                providerId: provider.id,
                tarifProviderId: tarificationToUpdate,
                name,
                dateEffet,
                dateCloture,
                conventionFile: fileSrc,
                newTarif: newTarifData,
                createNew
            };
            dispatch(updateProviderTarificationAction('update', data, props.history));
        }else{
            if(typeTarif === "actes"){
                data = {
                    providerId: provider.id,
                    tarifId: tarificationList,
                    name,
                    dateEffet,
                    dateCloture,
                    conventionFile: fileSrc
                };
                dispatch(updateProviderTarificationAction('attribution', data, props.history));
            }else{
                data = {
                    providerId: provider.id,
                    tarifPharmacieId: tarificationList,
                    conventionFile: fileSrc
                };
                dispatch(updateProviderMedicamentTarificationAction(data, props.history));
            }
        }


    };

    const filterActs = (val) => {
        setKeySearch(val);
        if(val.trim().length > 0){
            setLoadedActs(currentActs.map(act => {
                if(createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                    return act;
                }else{
                    return
                }
            }).filter(el => { return el}).filter(acte => {return acte.nameFr.toLowerCase().includes(val.toLowerCase())}));
        }else{
            setLoadedActs(currentActs.map(act => {
                if(createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                    return act;
                }else{
                    return
                }
            }).filter(el => { return el}));
        }
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENPRESTATAIREADMIN') && canDo('MENPRESTATAIREADMIN', 'ADDPROVTARIF')){
            dispatch(startLoader());
            dispatch(getProviderDataAction(id, history));
            dispatch(getAllTarifsAction());
            dispatch(getTarifsMedicamentAction());
            dispatch(getActsByFamiliesAction());
        }else{
            props.history.push('/access-denied');
        } 
    }, []);

    const askConfirmation = () => {
        let tarf = tarifs.filter(t => { return t.id === tarificationToUpdate});
        
        return tarifUpdateMode && tarf.length > 0 && tarf[0].isShared;
    }

    return (
    <>
    { showLoading && 
        <div id="preloader">
            <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
            </div>
        </div> }

        { !showLoading &&
        <div>
            <div className="mb-3 mb-md-4 bg-white">
                <div className='auth-form'>
                    <div className='row justify-content-start'>
                        <h2 className='text-center mb-4 '>
                         {i18n.language =="en" ? provider.providerName +" "+ t("provider.provider-pricing") : t("provider.provider-pricing")  +" "+ provider.providerName }
                        </h2>
                    </div>
                    <form onSubmit={(e) => askConfirmation() ? openConfirm(e) : saveTarification(e)}>

                        <div className='row form-group row justify-content-end align-items-center'>
                            <div className='col-12'>
                                
                                <div className='d-flex justify-content-end'>
                                    {provider.tarifProvider?.length > 0 && <div className='mr-2 form-group'>
                                        <Button
                                            variant="outline-primary"
                                            type="button"
                                            size="sm"
                                            onClick={() => switchToEditMode()}
                                        >
                                            {tarifUpdateMode ? t("provider.assigning-rate") : t("provider.modify-rate")}
                                        </Button>
                                    </div>}
                                    {typeTarif === 'actes' && <div className='form-group'>
                                        <Button
                                            variant="outline-primary"
                                            type="button"
                                            size="sm"
                                            onClick={() => switchToCreateMode()}
                                        >
                                            {t("provider.create-rate")} 
                                        </Button>
                                    </div>}
                                </div>
                                {!tarifUpdateMode && !tarifCreateMode && 
                                <div>
                                    {typeTarif === 'actes' && <div className='row justify-content-between align-items-center'>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t('common.name')}</strong>
                                            </label>
                                            <input type='text' className='form-control'
                                                value={name}
                                                required={!tarifUpdateMode && !tarifCreateMode} 
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t("provider.effective-date")}</strong>
                                            </label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    label=""
                                                    clearable
                                                    format="dd/MM/yyyy"
                                                    minDate={new Date()}
                                                    disabled={props.showLoading}
                                                    value={dateEffet}
                                                    required={!tarifUpdateMode && !tarifCreateMode}
                                                    onChange={date => {
                                                        setDateEffet(date);
                                                        setDateCloture(date.setFullYear(date.getFullYear() + 1));
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t("provider.closing-date")}</strong>
                                            </label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    label=""
                                                    clearable
                                                    format="dd/MM/yyyy"
                                                    disabled
                                                    value={dateCloture}
                                                    onChange={setDateCloture}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>}

                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t("provider.type-rate")}</strong>
                                            </label>
                                            <Select
                                                value={typeTarifs?.filter((opt)=>{return typeTarif === opt.value})}
                                                placeholder={t("provider.choose-type-rate")}
                                                onChange={(val) => setTypeTarif(val.value)}
                                                options={typeTarifs}
                                                required={!tarifUpdateMode && !tarifCreateMode}
                                                disabled={tarifUpdateMode || tarifCreateMode}
                                                style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>
                                        {typeTarif === "actes" && <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t("provider.choose-the-rate")}</strong>
                                            </label>
                                            <Select
                                                value={tarifs?.filter((opt)=>{return tarificationList === opt.id}).map((val)=>{return {label:val.nameFr, value:val.id}})}
                                                placeholder={t("provider.choose-the-ratee")}
                                                onChange={(val) => setTarificationList(val.value)}
                                                options={tarifs?.map((opt)=>{return {label:opt.nameFr, value:opt.id}})}
                                                required={!tarifUpdateMode && !tarifCreateMode}
                                                disabled={tarifUpdateMode || tarifCreateMode}
                                                style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>}
                                        {typeTarif === "pharmacie" && <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t("provider.choose-rate")}</strong>
                                            </label>
                                            <Select
                                                value={tarifsMedicament?.filter((opt)=>{return tarificationList === opt.id}).map((val)=>{return {label:val.nameFr, value:val.id}})}
                                                placeholder={t("provider.choose-ratee")}
                                                onChange={(val) => setTarificationList(val.value)}
                                                options={tarifsMedicament?.map((opt)=>{return {label:opt.nameFr, value:opt.id}})}
                                                required={!tarifUpdateMode && !tarifCreateMode}
                                                disabled={tarifUpdateMode || tarifCreateMode}
                                                style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>}
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>Convention</strong>
                                            </label>
                                            <div>
                                                <div className='input-group'>
                                                    <div className='custom-file'>
                                                        <input type="file" required className='custom-file-input' onChange={(e) => addConvention(e)} />
                                                        <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : t("provider.choose")}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }


                                {tarifUpdateMode && !tarifCreateMode && 
                                    <div className="updatePanel">
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("provider.type-rate")}</strong>
                                                </label>
                                                <Select
                                                    value={typeTarifs?.filter((opt)=>{return typeTarif === val.value})}
                                                    placeholder={t("provider.choose-type-rate")}
                                                    onChange={(val) => setTypeTarif(val.value)}
                                                    options={typeTarifs}
                                                    required={!tarifUpdateMode && !tarifCreateMode}
                                                    disabled={tarifUpdateMode || tarifCreateMode}
                                                    style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("provider.choose-rate")}</strong>
                                                </label>
                                                <Select
                                                    value={provider.tarifProvider?.filter((opt)=>{return tarificationToUpdate === opt.id}).map((val)=>{return {label:val.tarif.nameFr, value:val.id}})}
                                                    placeholder={t("provider.choose-ratee")}
                                                    onChange={(val) => chooseTarificationToUpdate(val.value)}
                                                    options={provider.tarifProvider?.map((opt)=>{return {label:opt.tarif.nameFr, value:opt.id}})}
                                                    style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('common.name')}</strong>
                                                </label>
                                                <input type='text' className='form-control'
                                                    value={name}
                                                    disabled={!tarificationToUpdate}
                                                    required={tarifUpdateMode}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Convention</strong>
                                                </label>
                                                <div>
                                                    <div className='input-group'>
                                                        <div className='custom-file'>
                                                            <input type="file" required className='custom-file-input' onChange={(e) => addConvention(e)} />
                                                            <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : t("provider.choose")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("provider.effective-date")}</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy"
                                                        minDate={new Date()}
                                                        disabled={props.showLoading}
                                                        value={dateEffet}
                                                        required={tarifUpdateMode}
                                                        onChange={date => {
                                                            setDateEffet(date);
                                                            setDateCloture(date.setFullYear(date.getFullYear() + 1));
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("provider.closing-date")}</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy"
                                                        disabled
                                                        value={dateCloture}
                                                        onChange={setDateCloture}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>

                                        
                                        <h5 className='mt-4 text-primary'>{t("provider.act-price")}</h5>
                                        <div className="border-top mt-2 mb-4"></div>
                                        <div className="actesPanel">

                                            {
                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className="widget-media dz-scroll ps ps--active-y height370"
                                                >
                                                    <ListGroup as="ul" variant="flush">
                                                        {tarificationsDatas.map((tarifActe, i) => (
                                                            <Fragment key={i}>
                                                                <ListGroup.Item as="li">
                                                                    <div className="row justify-content-between">
                                                                        <div className="col-6">
                                                                            <b>{tarifActe.acte.nameFr}</b>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <input type='number' className='form-control'
                                                                                value={newTarifData[i].value}
                                                                                onChange={(e) => setNewTarifData(newTarifData.map(elt => {
                                                                                        if(elt.tarifActeId === tarifActe.id) {
                                                                                            elt.value = parseInt(e.target.value);
                                                                                        }
                                                                                        return elt;
                                                                                    })
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            </Fragment>
                                                        ))}
                                                    </ListGroup>
                                                </PerfectScrollbar>
                                            }

                                        </div>

                                    </div>
                                }


                                {tarifCreateMode && !tarifUpdateMode && 
                                    <div className="updatePanel">

                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('common.name')}</strong>
                                                </label>
                                                <input type='text' className='form-control'
                                                    value={name}
                                                    required={tarifCreateMode}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Convention</strong>
                                                </label>
                                                <div>
                                                    <div className='input-group'>
                                                        <div className='custom-file'>
                                                            <input type="file" required className='custom-file-input' onChange={(e) => addConvention(e)} />
                                                            <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : t("provider.choose")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("provider.effective-date")}</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy"
                                                        minDate={new Date()}
                                                        value={dateEffet}
                                                        required={tarifCreateMode}
                                                        onChange={date => {
                                                            setDateEffet(date);
                                                            setDateCloture(date.setFullYear(date.getFullYear() + 1));
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("provider.closing-date")}</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy"
                                                        value={dateCloture}
                                                        onChange={setDateCloture}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className='row justify-content-start align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("provider.choose-family")}</strong>
                                                </label>
                                                
                                                <Select
                                                    selected={choosenFamily}
                                                    onChange={chooseFamily}
                                                    options={families?.map((opt)=>{return {label:opt.nameFr, value:opt.id}})}
                                                    placeholder={t("provider.choose-family")}
                                                    style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                    }}
                                                />
                                                {/*<SelectSearch
                                                    className="select-search"
                                                    options={families?.map((opt)=>{return {name:opt.nameFr, value:opt.id}})}
                                                    //filterOptions={handleCityFilter}
                                                    value={choosenFamily}
                                                    name="familly"
                                                    placeholder="Choisir la famille"
                                                    search
                                                    onChange={chooseFamily}
                                                />*/}
                                            </div>
                                        </div>

                                        
                                        <h5 className='mt-4 text-primary'>{t("provider.choice-act")}</h5>

                                        <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                                            <div className="input-group search-area ml-auto d-inline-flex mr-3 ">
                                                <input
                                                type="text"
                                                className="form-control border border-primary"
                                                value={keySearch}
                                                placeholder={t("provider.filter-act")}
                                                onChange={e => filterActs(e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                <button type="button" className="input-group-text border border-primary">
                                                    <i className="flaticon-381-search-2" />
                                                </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="actesPanel row">
                                            <div className="col-6 border">
                                                {
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height370"
                                                    >
                                                        <ListGroup as="ul" variant="flush">
                                                            {loadedActs.map((acte, i) => (
                                                                <Fragment key={i}>
                                                                    <ListGroup.Item as="li">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-6">
                                                                                <b>{acte?.nameFr}</b>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <input type='number' className='form-control'
                                                                                    value={loadedActs[i]?.value}
                                                                                    onChange={(e) => setLoadedActs(loadedActs.map(elt => {
                                                                                            if(elt.id === acte.id) {
                                                                                                elt.value = parseInt(e.target.value);
                                                                                            }
                                                                                            return elt;
                                                                                        })
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => addDataToNewTarif(acte)}
                                                                                    className="btn btn-outline-primary btn-rounded mb-2 mr-1"
                                                                                >
                                                                                    +
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Fragment>
                                                            ))}
                                                        </ListGroup>
                                                    </PerfectScrollbar>
                                                }
                                            </div>
                                            
                                            
                                            <div className="col-6 border">
                                                {
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height370"
                                                    >
                                                        <ListGroup as="ul" variant="flush">
                                                            {createdTarifData.map((acte, i) => (
                                                                <Fragment key={i}>
                                                                    <ListGroup.Item as="li">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-6">
                                                                                <b>{acte.nameFr}</b>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <b>{acte.value}</b>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => removeDataToNewTarif(acte)}
                                                                                    className="btn btn-outline-primary btn-rounded mb-2 mr-1"
                                                                                >
                                                                                    -
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Fragment>
                                                            ))}
                                                        </ListGroup>
                                                    </PerfectScrollbar>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                }
                                
                            </div>
                        </div>

                        <div className='row justify-content-end mt-4'>
                            <input
                                type="submit"
                                className="btn btn-primary"
                                value={t("client.submit")}
                                

                            />

                            <Modal className="fade" show={confirmModal}>
                                <Modal.Header>
                                    <Modal.Title>{t("provider.modify-rate")}</Modal.Title>
                                    <Button
                                        onClick={(e) => setConfirmModal(false)}
                                        variant=""
                                        className="close"
                                    >
                                        <span>&times;</span>
                                    </Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        {t("provider.rate-use-text")}
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        onClick={(e) => saveTarification(e, false)}
                                        variant="danger light"
                                    >
                                        {t("provider.modify-rate")}
                                    </Button>
                                    <Button onClick={(e) => saveTarification(e, true)}  variant="primary"> {t("provider.create-rate")}</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>

            
                    </form>
                </div>
            </div>
        </div>
        }
    </>
    )
};

const mapStateToProps = (state) => {
    return {
        providers: state.provider.providers,
        provider: state.provider.provider,
        families: state.provider.families,
        tarifs: state.provider.tarifs,
        tarifsMedicament: state.provider.tarifsMedicament,
        showLoading: state.provider.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(TarificationForm));