import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const SinistreServiceRequest = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_SINISTRE_BACKEND,
    headers: headers
});

export const getSinistres = (PageNumber, PageSize) => SinistreServiceRequest.get('/Sinistre/sinistres', {params: {PageNumber, PageSize}});
export const getSinistresByPeriod = (periodeDebut, periodeFin) => SinistreServiceRequest.get('/Sinistre/sinistre-by-periode', {params: {periodeDebut, periodeFin}});
export const getSinistresDeclare = (periodeDebut, periodeFin) => SinistreServiceRequest.get('/Sinistre/sinistres-declare', {params: {periodeDebut, periodeFin}});
export const getRecours = (periodeDebut, periodeFin) => SinistreServiceRequest.get('/Sinistre/recours', {params: {periodeDebut, periodeFin}});
export const getEvaluations = (periodeDebut, periodeFin) => SinistreServiceRequest.get('/Sinistre/evaluations', {params: {periodeDebut, periodeFin}});
export const getReglements = (PageNumber, PageSize) => SinistreServiceRequest.get('/Sinistre/reglements', {params: {PageNumber, PageSize}});
export const getReglementsByPeriod = (periodeDebut, periodeFin) => SinistreServiceRequest.get('/Sinistre/reglements-by-periode', {params: {periodeDebut, periodeFin}});