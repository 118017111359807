import React from "react";
import { useTranslation } from "react-i18next";

/* --- BEGIN ClientPagination
	@param(Object): props
    description: Pagination des clients
*/
const ClientPagination = (props) => {

    const { totalData, total, currentPage, switchPage, max } = props;
    /* --- BEGIN AccordPagination
        @param(Object): props
        description: Fonction de changement de page
    */
    const goToPage = (page) => {
        switchPage(page);
    };
    /* --- END goToPage */
    const { t } = useTranslation();


    
    return (
        <div className="d-sm-flex text-center justify-content-between align-items-center">
            <div
                className="dataTables_info"
                id="example5_info"
                role="status"
                aria-live="polite"
            >
               {t("provider.showing")} {((currentPage - 1) * max) + 1 <= totalData ? ((currentPage - 1) * max) : totalData} to{" "}
                {(((currentPage - 1) * max) + 1) + max <= totalData ? (((currentPage - 1) * max) + 1) + max : totalData}{" "}
                {t("provider.of")} of {totalData} {t("provider.entries")} 
            </div>
            <div className="dataTables_paginate paging_simple_numbers d-flex  justify-content-center align-items-center pb-3">
                {currentPage > 1 && <button
                    className="paginate_button previous disabled"
                    onClick={() => goToPage(currentPage - 1)}
                >
                    {t("provider.previous")} 
                </button>}
                <span className="d-flex">
                    {Array.from(Array(total).keys()).map((number, i) => (
                        <button
                        key={i}
                        className={`paginate_button d-flex align-items-center justify-content-center ${
                            currentPage === number + 1 ? "current" : ""
                        } ${i > 0 ? "ml-1" : ""}`}
                        onClick={() => goToPage(number + 1)}
                        >
                        {number + 1}
                        </button>
                    ))}
                </span>

                {currentPage < total && <button
                    type="button"
                    className="paginate_button next disabled"
                    onClick={() =>goToPage(currentPage + 1)}
                >
                     {t("provider.next")} 
                </button>}
            </div>
        </div>
    );
};
/* --- END ClientPagination */

export default ClientPagination;