import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BasicDatatable from '../DataTable';
import {
    getLettreCleAction,
    startLoader,
    createLettreCleAction,
    importLettreCleFileAction,
    deleteDataAction,
    updateLettreCleAction
} from '../../../../store/actions/ProviderActions';
import swal from 'sweetalert';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import {Spinner, Button} from 'react-bootstrap';
import { Button as PButton } from 'primereact/button';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";


const LettreCle = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [nameFr, setNameFr] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [file, setFile] = useState(null);
    const [code, setCode] = useState('');
    const [lettreCleId, setLettreCleId] = useState('');
    const [errors, setErrors] = useState(errorsObj);
    //Modal box
    const [addCard, setAddCard] = useState(false);
    const header =["#","Code","Action"]

    const [filters, setFilters] = useState(null);
    const [updateMode, setUpdateMode] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            descriptionFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'id', headerName: "Id", style: { minWidth: '12rem' }, filter: false, bodyTemplate: null},
            {name: 'code', headerName: "Code", filterPlaceholder: t("common.code-filter"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'descriptionFr', headerName: "Description", filterPlaceholder: t('provider.desc-filter'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATELETTRECLE') && <Link href="#" to="#" data-testid={'update-button-'+rowData.id} onClick={e => updateData(rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>
            </Link>}
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'DELETELETTRECLE') && <Link href="#" to="#" data-testid={'delete-button-'+rowData.id} title="Close"
                onClick={() =>
                    swal({
                        title: t("provider.delLettreCle")+rowData.code+" ?",
                        text:
                            t('patient.delete-key'),
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            let data = {
                                type: "LETTRECLE",
                                id: rowData.id
                            }
                            dispatch(startLoader());
                            dispatch(deleteDataAction(data));
                        }
                    })
                    }>
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const updateData = (data) => {
        setNameEn(data.descriptionEn);
        setNameFr(data.descriptionFr);
        setCode(data.code);
        setLettreCleId(data.id);
        setAddCard(true);
        setUpdateMode(true);
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        dispatch(startLoader());
        initFilters();
        dispatch(getLettreCleAction());    
    }, []);


    useEffect(() => {
        setUpdateMode(false);
        setNameEn("");
        setNameFr("");
        setCode("");
        setLettreCleId("");
        setAddCard(false);  
    }, [props.lettreCles]);
    
    function onLettreCleSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importLettreCleFileAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }
        /*if (nameEn === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }*/
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());
        

        if(updateMode) {
            let obj = { descriptionFr: nameFr,descriptionEn: nameFr, code: code };
            dispatch(updateLettreCleAction(lettreCleId, obj));
        }else{
            let obj = { descriptionFr: nameFr,descriptionEn: nameFr, code: code };
            dispatch(createLettreCleAction(obj));
        }
    }

    function selectFile(event) {
        setFile(event.target.files[0])
    }


    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATELETTRECLE') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + Nouvelle Lettre Clé
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{t("provider.new-letter-key")}</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onLettreCleSave}>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>{t("provider.letter-key")}</label>
                                    <div className='col-sm-9'>
                                        <input
                                            value={code}
                                            type='text'
                                            name='code'
                                            className='form-control'
                                            placeholder='PH'
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>Description</label>
                                    <div className='col-sm-9'>
                                        <input
                                            value={nameFr}
                                            type='text'
                                            name='nameFr'
                                            className='form-control'
                                            placeholder='k'
                                            disabled={props.showLoading || file}
                                            required={props.showLoading || file}
                                            onChange={(e) => setNameFr(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/*<div className='form-group row'>
                                    <label className='col-sm-3 col-form-label'>Description En</label>
                                    <div className='col-sm-9'>
                                        <input
                                            type='text'
                                            name='nameEn'
                                            className='form-control'
                                            placeholder='K'
                                            onChange={(e) => setNameEn(e.target.value)}
                                        />
                                    </div>
                                </div>*/}
                                <hr />
                                <h4 className='text-center'>{t("common.import-csv-key")}</h4>
                                <div className='input-group mb-3'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text'>CSV</span>
                                    </div>
                                    <div className='custom-file'>
                                        <input type='file' className='custom-file-input' onChange={selectFile} />
                                        <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                    </div>
                                </div>
                                <div className='form-group align-items-center justify-content-center'>
                                    { props.showLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                        </Button> }
                                    { !props.showLoading &&
                                        <div className='col-4'>
                                            <button type='submit' className='btn btn-primary'>
                                               {t('common.save')}
                                            </button>
                                        </div> }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            <DataTableBase 
                data={props.lettreCles.length > 0 ? props.lettreCles : []}
                emptyMessage={t("common.no-key")}
                filters={filters}
                globalFiltersFields={['id', 'code', 'descriptionFr']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'ID', dataKey: 'id' },
                    { title: 'Code', dataKey: 'code' },
                    { title: 'Description', dataKey: 'descriptionFr' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"lettres-clés"}
                loading={props.showLoading}
                lazy={false}
            />

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        lettreCles: state.provider.lettreCles,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(LettreCle);