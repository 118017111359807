import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { getFacturesAction, payFactureAction, startLoader } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import{withRouter} from 'react-router-dom'
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";
import { Spinner, Modal } from "react-bootstrap";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { set } from "date-fns";
import { Link } from "react-router-dom";
import Select from 'react-select';

export const FacturationList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [addCard, setAddCard] = useState(false);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [paidBordereaux, setPaidBordereaux] = useState([]);
    const [payStatut, setPayStatut] = useState(null);
    const [payMean, setPayMean] = useState(null);
    const [numCheque, setNumCheque] = useState(null);
    const [numVirement, setNumVirement] = useState(null);
    const [reference, setReference] = useState(null);
    const [description, setDescription] = useState(null);
    const [payDate, setPayDate] = useState(new Date());
    const [payAmount, setPayAmount] = useState(0);
    const [codeBanque, setCodeBanque] = useState(null);
    const [reglementDate, setReglementDate] = useState(new Date());
    const [dateComptable, setDateComptable] = useState(new Date());

    const moyenPaiements = [
        {label: 'Chèque', value: 'cheque'},
        {label: 'Virement', value: 'virement'},
        {label: 'Espèce', value: 'espece'},
        {label: 'Paiement mobile', value: 'Paiement mobile'}
    ];

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantTotal: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateFacturation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            statusPaiement: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isClose: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            datePaiement: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'name', headerName: t('common.bill-number'), filterPlaceholder: t('facturation.bill-number-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'montantTotal', dataType: 'numeric', headerName:  t('common.amount'), filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'prestataireName', headerName: t('dashboard.provider'), filterPlaceholder: t('facturation.provider-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'dateFacturation', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.create-date'), filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateFacturationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'statusPaiement', headerName: t('dashboard.payment-status'), filterPlaceholder: t('facturation.payment-status-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'isClose', headerName: t('dashboard.invoice-status'), dataType: 'boolean', filterPlaceholder: t('facturation.bord-status-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBordBodyTemplate, filterElement: statusBordFilterTemplate},
            {name: 'datePaiement', filterField: 'dateFacturation', dataType: 'date', headerName: t('facturation.payment-date'), filterPlaceholder: t('facturation.payement-date-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                {hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'VIEWADMINFACTURE') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={(e) => viewFacture(rowData.id)}
                >
                    <i className="fa fa-eye"></i>
                </button>}
        </div>
    }

    const viewFacture = (id) => {
        props.history.push('/facturation-details/'+id);
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.factures.map(facture => {
            return facture.statusPaiement === STATUSES.nonPaye ? STATUSES.nonPaye : facture.statusPaiement
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate}  placeholder={t('common.choose')} className="p-column-filter" showClear />;
    };

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('facturation.closed')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.datePaiement);
    };

    const dateFacturationBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFacturation);
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.statusPaiement ? t('statuses.'+rowData.statusPaiement) : rowData.statusPaiement} severity={getSeverity(rowData.statusPaiement)} />;
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.isClose ? t('facturation.closed') :  t('facturation.oppened')} severity={getSeverity(rowData.isClose)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case null:
                return 'danger';
            
            case 'non payé':
                return 'danger';

            case 'payé':
                return 'success';

            case true:
                return 'success';

            case false:
                return 'warning';

            case t('facturation.closed'):
                return 'success';

            case 'ouvert':
                return 'warning';

            default:
                return 'warning';
        }
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const onProviderTypeSave = (e) => {
        e.preventDefault();
        dispatch(startLoader());
        let data = {
            bordereauPrestataires: props.factures.filter(val => paidBordereaux.includes(val.id)).map(elt => {
                return {
                    bordereauPrestataireId: elt.id,
                    montantPaye: elt.montantTotal,
                    montantRestant: 0
                }
            }),
            reference: reference,
            description: description,
            montantTotal: payAmount,
            moyenPayment: payMean,
            numCheque: numCheque,
            numVirement: numVirement,
            codeBanque: codeBanque,
            datePaiement: payDate,
            dateEnvoiePaiement: reglementDate,
            dateComptable: dateComptable
        };

        dispatch(payFactureAction(data));
        setAddCard(false);
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    
    useEffect(() => {
        if(hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'LISTADMINFACTURE')){
            dispatch(startLoader());
            dispatch(getFacturesAction(props.currentUser.providerId));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEACTE') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                     {t("common.pay")}
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{t("common.register-payment")}</h4>
                        </div>
                        <div className='card-body'>
                            <div className='basic-form'>
                                <form onSubmit={onProviderTypeSave}>
                                    <div className='row'>
                                        <div className='form-group col-12'>
                                            <label className='mb-1'>{t("facturation.invoice")}</label>
                                            <Select
                                                className="select-search"
                                                value={props.factures?.filter(elt => paidBordereaux.includes(elt.id)).map((val)=>{return {label:val.name+' - '+val.prestataireName,value:val.id, total: val.montantTotal}})}
                                                placeholder={t("facturation.choose-invoice")}
                                                onChange={(vals) => {
                                                    setPaidBordereaux(vals ? vals.map(val => val.value) : []);
                                                    setPayAmount(vals ? vals.reduce((acc, curr) => acc + curr.total, 0) : 0);
                                                }}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                options={props.factures.filter(elt => elt.isClose && elt.statusPaiement !== STATUSES.paye).map(facture => {return {label: facture.name+' - '+facture.prestataireName, value: facture.id, total: facture.montantTotal}})}
                                                isMulti
                                                style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>
                                        
                                        {/*<div className='form-group col-6'>
                                            <label className='mb-1'>Statut</label>
                                            <Select
                                                className="select-search"
                                                value={Object.keys(STATUSES).filter((key)=>{return payStatut === STATUSES[key]}).map((val)=>{return {label:val,value:STATUSES[val]}})[0]}
                                                placeholder="Choisir un statut"
                                                onChange={(val) => setPayStatut(val.value)}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                options={Object.keys(STATUSES).map((key)=>{return {label:key, value:STATUSES[key]}})}
                                                style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div> */}

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("facturation.payment-method")}</label>
                                            <Select
                                                className="select-search"
                                                value={moyenPaiements.filter((opt)=>{return opt.value === payMean})[0]}
                                                placeholder={t("facturation.choose-payment-method")}
                                                onChange={(val) => setPayMean(val.value)}
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                options={moyenPaiements}
                                                style={{
                                                lineHeight: '40px',
                                                color: '#7e7e7e',
                                                paddingLeft: ' 15px',
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("facturation.check-number")}</label>
                                            <input
                                                type='text'
                                                name='numCheque'
                                                className='form-control'
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                placeholder={t('layout.pharmacy')}
                                                value={numCheque}
                                                onChange={(e) => {
                                                    setNumCheque(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("facturation.payment-date")}</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    autoOk
                                                    label=""
                                                    clearable
                                                    value={payDate}
                                                    onChange={setPayDate}
                                                    format="dd/MM/yyyy"
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("facturation.amount")}</label>
                                            <input
                                                type='number'
                                                name='payAmount'
                                                min={0}
                                                className='form-control'
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                placeholder={t("facturation.amount")}
                                                value={payAmount}
                                                onChange={(e) => {
                                                    setPayAmount(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("provider.bank-code")}</label>
                                            <input
                                                type='text'
                                                name='codeBanque'
                                                className='form-control'
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                placeholder={t("provider.bank-code")}
                                                value={codeBanque}
                                                onChange={(e) => {
                                                    setCodeBanque(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("provider.bank-trans-num")}</label>
                                            <input
                                                type='text'
                                                name='numVirement'
                                                className='form-control'
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                placeholder={t("provider.bank-trans-num")}
                                                value={numVirement}
                                                onChange={(e) => {
                                                    setNumVirement(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("provider.reference")}</label>
                                            <input
                                                type='text'
                                                name='reference'
                                                className='form-control'
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                placeholder={t("provider.reference")}
                                                value={reference}
                                                onChange={(e) => {
                                                    setReference(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'>{t("facturation.payment-date")}</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    autoOk
                                                    label=""
                                                    clearable
                                                    value={reglementDate}
                                                    onChange={setReglementDate}
                                                    format="dd/MM/yyyy"
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        <div className='form-group col-6'>
                                            <label className='mb-1'> {t("facturation.posting-date")}</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimePicker
                                                    autoOk
                                                    label=""
                                                    clearable
                                                    value={dateComptable}
                                                    onChange={setDateComptable}
                                                    format="dd/MM/yyyy"
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        <div className='form-group col-12'>
                                            <label className='mb-1'>Description</label>
                                            <input
                                                type='text'
                                                name='description'
                                                className='form-control'
                                                disabled={props.showLoading}
                                                required={props.showLoading}
                                                placeholder="Description"
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.target.value);
                                                }}
                                            />
                                        </div>
                                        
                                    </div>

                                    <div className='form-group align-items-center justify-content-center'>
                                        <div className=''>
                                            { props.showLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </Button> }
                                            { !props.showLoading &&
                                                <button type='submit' className='btn btn-primary'>
                                                {t('common.save')}
                                                </button> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <DataTableBase 
                data={props.factures.map(facture => {
                    facture.dateFacturation = new Date(facture.dateFacturation);
                    facture.datePaiement = new Date(facture.datePaiement);
                    return facture;
                })}
                 emptyMessage={t('facturation.no-bill')} 
                filters={filters}
                globalFiltersFields={['name', 'montantTotal', 'prestataireName', 'dateFacturation', 'statusPaiement', 'datePaiement']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
                size={'small'}
                exportFilename={"decomptes"}
                exportColumns={[
                    {  title: t('common.number'), dataKey: 'name' },
                    { title: t('facturation.amount'), dataKey: 'montantTotal' },
                    {  title: t('dashboard.provider'), dataKey: 'prestataireName' },
                    { title: t('facturation.invoice-date'), dataKey: 'dateFacturation' },
                    { title: t('facturation.payment-status'), dataKey: 'statusPaiement' },
                    { title: t('facturation.payment-date'), dataKey: 'datePaiement' },
                ]}
                showExport={true}
            />
            
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        factures: state.facturation.factures,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(FacturationList));