import React, {useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Spinner, Button, Modal, ListGroup } from "react-bootstrap";
import BasicDatatable from '../DataTable';
import { Link } from "react-router-dom";
import {
    startLoader,
    createMedicamentAction,
    importMedicamentFileUploadAction,
    updateMedicamentAction,
    getMedicamentsAction,
    deleteDataAction
} from '../../../../store/actions/ProviderActions';
import { searchDrugsAction, searchDiseasesAction } from '../../../../store/actions/ConsultationActions';
import Select from 'react-select';
import swal from 'sweetalert'; 
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";

const Medicament = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [medicamentId, setMedicamentId] = useState("");
    const [name, setName] = useState('');
    const [presentation, setPresentation] = useState('');
    const [forme, setForme] = useState('');
    const [dosage, setDosage] = useState('');
    const [denomination, setDenomination] = useState('');
    const [administration, setAdministration] = useState('');
    const [refDosage, setRefDosage] = useState('');
    const [file, setFile] = useState(null);
    const [code, setCode] = useState('');
    const [unit, setUnit] = useState('');
    const [amount, setAmount] = useState(0);
    const [affections, setAffections] = useState([]);
    const [isNeedAccord, setIsNeedAccord] = useState(false);
    const [isExclu, setIsExclu] = useState(false);
    const [isActif, setIsActif] = useState(false);
    const [errors, setErrors] = useState(errorsObj);
    const [search, setSearch] = useState('');
    //Modal box
    const [addCard, setAddCard] = useState(false);
    const header =["Code", "Nom", "Unité", "Montant", "Couverture", "Action"];
    const [updateMode, setUpdateMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            unit: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            denomination: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },            
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isExclu: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        }
    });

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            denomination: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }, 
            unit: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },            
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            isExclu: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'code', headerName: "Code", filterPlaceholder: t("provider.code-filter"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'name', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'denomination', headerName: t("common.denomination"), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'unit', headerName: t('common.unit'), filterPlaceholder: t("common.filter-unity"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'amount', dataType: 'numeric', headerName:  t('common.amount'), filterPlaceholder: t('facturation.filter-by-amount'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'isExclu', headerName: t('special-acts.cover'), filterPlaceholder: t("common.filter-coverage"), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBordBodyTemplate, filterElement: statusBordFilterTemplate},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t("common.created-on"), filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);

        if(value.trim().length > 0) {
            dispatch(searchDrugsAction(value)); 
        }else{
            dispatch(searchDrugsAction("@@@"))
        }
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATEMEDICAMENT') && <Link href="#" to="#" data-testid={'update-button-'+rowData.id} onClick={e => updateAct(e, rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'DELETEMEDICAMENT') && <Link href="#" to="#" data-testid={'delete-button-'+rowData.id} title="Close"
                onClick={() =>
                    swal({
                        title: t("patient.delete-drug")+rowData.name+" ?",
                        text:
                            t("patient.delete-message-drug"),
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            let data = {
                                type: "MEDICAMENT",
                                id: rowData.id
                            }
                            dispatch(startLoader());
                            dispatch(deleteDataAction(data));
                        }
                    })
                    }>
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    Couvert
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.isExclu ? 'Non Couvert' : "Couvert"} severity={getSeverity(rowData.isExclu)} />;
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case true:
                return 'danger';
            default:
                return 'success';
        }
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const unitTypes = [
        {label: "Boîte", value: "Boîte"},
        {label: "Gellule", value: "Gellule"},
        {label: "Comrimé", value: "Comrimé"},
        {label: "Pilule", value: "Pilule"},
        {label: "Pastille", value: "Pastille"},
        {label: "Ovule", value: "Ovule"},
        {label: "Sachet", value: "Sachet"},
        {label: "Ampoule", value: "Ampoule"},,
        {label: "Flacon", value: "Flacon"},
    ]

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }


    useEffect(() => {
        initFilters();
        dispatch(getMedicamentsAction(currentPage, 10));
    }, []);

    useEffect(() => {
        if(search.trim().length >= 3) {
            dispatch(searchDrugsAction(search)); 
        }else{
            dispatch(searchDrugsAction("@@@"))
        }
    }, [search]);

    function onProviderTypeSave(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importMedicamentFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code = t("common.code-required");
            error = true;
        }
        if (name === '') {
            errorObj.name = t("common.name-required");
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
        dispatch(startLoader());
        
        if(updateMode) {
            let obj = { name, code, unit, presentation, forme,
                dosage, denomination, administration, 
                refDosage, affections, amount, isNeedAccord, isExclu, isActif };
            dispatch(updateMedicamentAction(medicamentId, obj));
        }else{
            let obj = { name, code, unit, presentation, forme,
                dosage, denomination, administration, 
                refDosage, affections, amount, isNeedAccord, isExclu, isActif };
            dispatch(createMedicamentAction(obj));
        }
    }

    function selectFile(event) {
        setFile(event.target.files[0])
    }

    const onFilterData = (rowData) => {
    }

    const onGoToPage = (event) => {
        setlazyState(event);
    }

    const onSortData = (rowData) => {
    }

    useEffect(() => {
        if(lazyState.page !== props.medicamentsPages?.pageNumber) {
            dispatch(startLoader());
            dispatch(getMedicamentsAction(lazyState.page, lazyState.rows)); 
        }
    }, [lazyState]);

    useEffect(() => {
        setUpdateMode(false);
        setMedicamentId("");
        setName("");
        setCode("");
        setUnit("");
        setPresentation("");
        setForme("");
        setDosage("");
        setDenomination("");
        setAdministration("");
        setRefDosage("");
        setAffections([]);
        setAmount(0);
        setIsExclu(false);
        setIsNeedAccord(false);
        setIsActif(false);
        setAddCard(false);
        //dispatch(getMedicamentsAction(currentPage, 10)); 
    }, [props.medicamentsPages, props.drugs]);

    const updateAct = (e, drug) => {
        e.preventDefault();
        setUpdateMode(true);
        setMedicamentId(drug.id);
        setName(drug.name);
        setCode(drug.code);
        setUnit(drug.unit);
        setPresentation(drug.presentation);
        setForme(drug.forme);
        setDosage(drug.dosage);
        setDenomination(drug.denomination);
        setAdministration(drug.administration);
        setRefDosage(drug.refDosage);
        setAffections(drug.affections ? drug.affections : []);
        setAmount(drug.amount);
        setIsExclu(drug.isExclu);
        setIsNeedAccord(drug.isNeedAccord);
        setIsActif(drug.isActif);
        setAddCard(true);
    }

    return (
        <>

            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATEMEDICAMENT') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => setAddCard(true)}
                    >
                        + {t("common.new-drug")}
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
            <div className="">
                <div className="card">
                    <div className="card-header">
                        {!updateMode && <h4 className="card-title">{t("common.create-new-drug")}</h4>}
                        {updateMode && <h4 className="card-title">{t("common.modify-drug")} {name}</h4>}
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={onProviderTypeSave} className="row">
                                <div className='form-group col-6'>
                                    <label className='mb-1'>{t('common.code')}</label>
                                    <input
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='PH'
                                        value={code}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                                
                                <div className='form-group col-6'>
                                    <label className='mb-1'>{t('common.label')}</label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder= {t("common.drug")}
                                        value={name}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                
                                <div className='form-group col-6'>
                                    <label className='mb-1'>{t("common.denomination")}</label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder={t("common.denomination")}
                                        value={denomination}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setDenomination(e.target.value)}
                                    />
                                </div>

                                <div className='form-group col-6'>
                                    <label className='mb-1'>{t("common.presentation")}</label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder={t("common.presentation")}
                                        value={presentation}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setPresentation(e.target.value)}
                                    />
                                </div>
                                
                                <div className='form-group col-6'>
                                    <label className='mb-1'>{t("common.form")}</label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder={t("common.form")}
                                        value={forme}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setForme(e.target.value)}
                                    />
                                </div>
                                
                                <div className='form-group col-6'>
                                    <label className='mb-1'>Dosage</label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder={t("common.form")}
                                        value={dosage}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setDosage(e.target.value)}
                                    />
                                </div>
                                
                                <div className='form-group col-6'>
                                    <label className='mb-1'>Administration</label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder='forme'
                                        value={administration}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setAdministration(e.target.value)}
                                    />
                                </div>
                                
                                <div className='form-group col-6'>
                                    <label className='mb-1'>Référence de dosage</label>
                                    <input
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder='Réference de dosage'
                                        value={refDosage}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setRefDosage(e.target.value)}
                                    />
                                </div>

                                <div className='form-group col-6'>
                                    <label className='mb-1'> {t('common.amount')}</label>
                                    <input
                                        type='number'
                                        name='amount'
                                        min='0'
                                        className='form-control'
                                        placeholder='0'
                                        value={amount}
                                        disabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                                
                                <div className='form-group col-6'>
                                    <label className='mb-1'>{t("common.Unit")}</label>
                                    <Select
                                        className="select-search"
                                        value={unitTypes.filter(elt => elt.value === unit)}
                                        placeholder="Choisir une unité"
                                        onChange={(val) => setUnit(val.value)}
                                        isDisabled={props.showLoading || file}
                                        required={props.showLoading || file}
                                        options={unitTypes}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                                
                                
                                <div className='form-group col-12'>
                                    <label className='mb-1'>Affections</label>
                                    <Select
                                        className="select-search"
                                        options={props.diseases.map(elt => {return {label: elt.name, value: elt.code}})}
                                        value={affections.map(elt => {return {label: elt.affectionName, value: elt.affectionCode}})}
                                        placeholder={t('consultation.enter-keyword-affliction')}
                                        search
                                        isMulti
                                        onInputChange={(val) => {
                                            if(val.length >= 1){
                                                dispatch(searchDiseasesAction(val));
                                            }
                                        }}
                                        onChange={(vals) => {
                                            setAffections(vals ? vals.map(val => {
                                                return {affectionCode: val.value, affectionName: val.label}
                                            }) : []);
                                        }}
                                    />
                                </div>
                                
                                <div className='col-4 custom-control custom-checkbox mb-3 checkbox-primary'>
                                    <input
                                    type='checkbox'
                                    checked={isExclu}
                                    disabled={props.showLoading || file}
                                    onChange={(e) => setIsExclu(e.target.checked)}
                                    className='custom-control-input'
                                    id='customCheckBox2'
                                    />
                                    <label
                                    className='custom-control-label text-primary text-bold'
                                    htmlFor='customCheckBox2'
                                    >
                                        {t("common.rejecte")}
                                    </label>
                                </div>
                                <div className='col-4 custom-control custom-checkbox mb-3 checkbox-primary'>
                                    <input
                                    type='checkbox'
                                    checked={isNeedAccord}
                                    disabled={props.showLoading || file}
                                    onChange={(e) => setIsNeedAccord(e.target.checked)}
                                    className='custom-control-input'
                                    id='customCheckBox3'
                                    />
                                    <label
                                    className='custom-control-label text-primary text-bold'
                                    htmlFor='customCheckBox3'
                                    >
                                        {t("common.agreement")}
                                    </label>
                                </div>
                                
                                <div className='col-4 custom-control custom-checkbox mb-3 checkbox-primary'>
                                    <input
                                    type='checkbox'
                                    checked={isActif}
                                    disabled={props.showLoading || file}
                                    onChange={(e) => setIsActif(e.target.checked)}
                                    className='custom-control-input'
                                    id='customCheckBox4'
                                    />
                                    <label
                                    className='custom-control-label text-primary text-bold'
                                    htmlFor='customCheckBox4'
                                    >
                                        {t("common.asset")}
                                    </label>
                                </div>
                                <hr />
                                <h4 className='text-center'>{t("common.import-csv-act")}</h4>
                                <div className='input-group mb-3'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text'>CSV</span>
                                    </div>
                                    <div className='custom-file'>
                                        <input type='file' className='custom-file-input' onChange={selectFile} />
                                        <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                    </div>
                                </div>
                                <div className='form-group align-items-center justify-content-center'>
                                    <div className=''>
                                        { props.showLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }
                                        { !props.showLoading &&
                                            <button type='submit' className='btn btn-primary'>
                                              {t('common.save')}
                                            </button> }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
            <DataTableBase 
                data={ 
                    globalFilterValue.length > 0 && props.drugs.length > 0 ? 
                    props.drugs.map(acte => {
                        acte.createdAt = new Date(acte.createdAt);
                        return acte;
                    }) :
                    (props.medicamentsPages.data && props.medicamentsPages?.data.length > 0 ? props.medicamentsPages.data.map(acte => {
                        acte.createdAt = new Date(acte.createdAt);
                        return acte;
                    }) : [])
                }
                emptyMessage={t('common.no-drug')} 
                filters={filters}
                globalFiltersFields={['code', 'name', 'unit', 'denomination', 'amount', 'isExclu', 'createdAt']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Code', dataKey: 'code' },
                    { title: t('common.name'), dataKey: 'name' },
                    { title: t("common.Unit"), dataKey: 'unit' },
                    { title: t("common.denomination"), dataKey: 'denomination' },
                    { title: t('facturation.amount'), dataKey: 'amount' },
                    { title: t("common.rejecte"), dataKey: 'isExclu' },
                    { title: t('common.create-date'), dataKey: 'createdAt' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"médicaments"}
                loading={props.showLoading}
                lazy={globalFilterValue.length === 0}
                dataSet={lazyState}
                totalRecords={props.medicamentsPages?.totalPages}
                onGoToPage={onGoToPage}
                onSortData={onSortData}
                onFilterData={onFilterData}
            />
            {/*<BasicDatatable header={header} body={tableBody} pagination={tablePages}></BasicDatatable>*/}

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        medicamentsPages: state.provider.medicamentsPages,
        drugs: state.consultation.drugs,
        diseases: state.consultation.diseases,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        successMessage: state.provider.successMessage,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Medicament);