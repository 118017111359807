import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap'
import ProviderType from './ProviderType';
import ProviderSpecialite from './ProviderSpecialite';
import TypeActe from './TypeActe';
import FamilleActe from './FamilleActe';
import Acte from './Acte';
import Acteur from './Acteur';
import LettreCle from './LettreCle';
import Tarif from './Tarif';
import TarifMedicament from './TarifMedicament';
import Medicament from './Medicament';
import ActeProduction from './ActeProduction';
import Optique from './Optique';
import ActesControls from './ActesControls';
//import '../../../../css/SelectStyle.css'
import { useTranslation } from "react-i18next";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";

const ParametragePrestataire = (props) => {
    const { t } = useTranslation();
    let errorsObj = { name: '', code: '' };
    const dispatch = useDispatch();
    const header =["Nom","code","Date de création","Action"]


    const tabData = [
        {
            label: t("provider.type"),
            name: "type de prestataire",
            icon: 'building',
            content: <ProviderType/>
        },
        {
            label: t("provider.speciality"),
            name: "spécialités",
            icon: 'stetoscope',
            content: <ProviderSpecialite/>
        },
        {
            label: t("provider.famTypeActe"),
            name: "grande famille acte",
            icon: 'ban',
            content: <FamilleActe/>
        },
        {
            label: t("provider.typeActe"),
            name: "famille acte",
            icon: 'ban',
            content: <TypeActe/>
        },
        {
            label: t("provider.acte"),
            name: "acte",
            icon: 'industry',
            content: <Acte/>
        },
        {
            label: t("provider.acteProd"),
            name: "acte production",
            icon: 'industry',
            content: <ActeProduction/>
        },
        {
            label: t("provider.tarif"),
            name: "tarif",
            icon: 'certificate',
            content: <Tarif/>
        },
        {
            label: "Tarif médicaments",
            name: "tarifmedicament",
            icon: 'certificate',
            content: <TarifMedicament/>
        },
        {
            label: t("provider.lettreCle"),
            name: "lettre clé",
            icon: 'ban',
            content: <LettreCle/>
        },
        {
            label: t("provider.drug"),
            name: "médicament",
            icon: 'pills',
            content: <Medicament/>
        },
        {
            label: t("provider.optic"),
            name: "optique",
            icon: 'glasses',
            content: <Optique/>
        },
        {
            label: 'Contrôles Actes',
            name: "controle",
            icon: 'industry',
            content: <ActesControls/>
        }
    ]
    
    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const hasAccess = (type) => {
        switch(type){
            case 'type de prestataire':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTPROVIDERTYPE')
            case 'spécialités':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTSPECIALITE')
            case 'acte':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTACTE')
            case 'acte production':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTACTEPROD')
            case 'tarif':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTTARIF')
            case 'tarifmedicament':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTTARIF')
            case 'lettre clé':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTLETTRECLE')
            case 'famille acte':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTFAMILLEACTE')
            case 'grande famille acte':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTGRANFAMACTE')
            case 'médicament':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTMEDICAMENT')
            case 'optique':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'PARAMOPTIQUE')
            case 'controle':
                return hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'LISTACTE')
            default:
                return false
            
        }
    }

    return (
        <Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{t('provider.parameter')}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {/* <!-- Nav tabs --> */}
                            <div className='custom-tab-1'>
                                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                    <Nav as='ul' className='nav-tabs'>
                                        {tabData.map((data, i) => (
                                            <Nav.Item as='li' key={i}>
                                                <Nav.Link disabled={!hasAccess(data.name.toLowerCase())} eventKey={data.name.toLowerCase()}>
                                                    <i className={`la la-${data.icon} mr-2`} />
                                                    {data.label}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                    <Tab.Content className='pt-4'>
                                        {tabData.map((data, i) => (
                                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                <div>{data.content}</div>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.admin.errorMessage,
        successMessage: state.admin.successMessage,
        showLoading: state.admin.showLoading,
        admin: state.admin.administration,
        currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(ParametragePrestataire);