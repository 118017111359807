import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { getEvaluationsAction, startLoader } from "../../../store/actions/SinistreAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Spinner } from "react-bootstrap";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import Select from 'react-select';
import{withRouter} from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import DetailsModal from "./DetailsModal";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { classNames } from 'primereact/utils';

export const Evaluations = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const dataTableRef = useRef();
    const [showDetailsTab, setShowDetailsTab] = useState(false);
    const [dataDetails, setDataDetails] = useState(null);
    const [dataDetailsText, setDataDetailsText] = useState('');

    var date = new Date();
    const [periodeDebut, setPeriodeDebut] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [periodeFin, setPeriodeFin] = useState(new Date());

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');


    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numSinistre: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            codeActe: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            codeGarantie: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            montantEvaluation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            montantDeclare: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dateEvaluation: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'numSinistre', headerName: "Numéro sinistre", filterPlaceholder: "Filtrer par numéro de sinistre", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'codeActe', headerName: "Code acte", filterPlaceholder: "Filtrer par code d'acte", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'codeGarantie', headerName: "Code garantie", filterPlaceholder: "Filtrer par code de garantie", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'montantDeclare', headerName: "Montant Déclaré", dataType: 'numeric', filterPlaceholder: "Filtrer par montant déclaré", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'montantEvaluation', headerName: "Montant Evaluation", dataType: 'numeric', filterPlaceholder: "Filtrer par montant d'évaluation", style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'dateEvaluation', filterField: 'dateEvaluation', dataType: 'date', headerName: "Date d'évaluation", filterPlaceholder: "Filtrer par date d'évaluation", style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateEffBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ];
    };

    const statusRecoursBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.isRecours, 'text-red-500 pi-times-circle': !rowData.isRecours })}></i>;
    };

    const statusRecoursFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    Avec Recours
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                {hasMenu('MENMONITORING') && canDo('MENMONITORING', 'VIEWMONITORINGDETAIL') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={(e) => {
                        setDataDetails(rowData);
                        setDataDetailsText('Infos sinistre '+rowData.numSinistre);
                        setShowDetailsTab(true);
                        return;
                    }}
                >
                    <i className="fa fa-eye"></i>
                </button>}
        </div>
    }

    const manageDetailsModal = () => {
        setShowDetailsTab(false);
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateEffBodyTemplate = (rowData) => {
        return formatDate(rowData.dateEvaluation);
    };


    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatDateReq = (value) => {
        return value.toISOString().split('T')[0];
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    useEffect(() => {
        if(hasMenu('MENMONITORING') && canDo('MENMONITORING', 'LISTMONITORING')){
            initFilters();
            dispatch(startLoader());
            dispatch(getEvaluationsAction(formatDateReq(periodeDebut), formatDateReq(periodeFin)));
        }else{
            props.history.push('/access-denied');
        }
    }, []);


    const getExportColumns = () => {
        return [
            { title: "Numéro sinistre", dataKey: 'numSinistre' },
            { title: "Code Acte", dataKey: 'codeActe' },
            { title: "Code Garantie", dataKey: 'codeGarantie' },
            { title: "Montant déclaré", dataKey: 'montantDeclare' },
            { title: "Montant évaluation", dataKey: 'montantEvaluation' },
            { title: "Date de déclaration", dataKey: 'dateDeclaration' },
        ];
    }
    
    const getCurrentFilters = () => {
        return filters;
    }

    const filterSinistres = (e) => {
        e.preventDefault();
        dispatch(startLoader());
        dispatch(getEvaluationsAction(formatDateReq(periodeDebut), formatDateReq(periodeFin)));
    }

    return (
        <Fragment>

            <form className="row justify-content-end" style={{
                alignItems: "center",
                padding: "10px",
                backgroundColor: "white",
                margin: 0
            }} onSubmit={filterSinistres}>
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date minimum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={periodeDebut}
                                onChange={setPeriodeDebut}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="col-lg-3 mb-2">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date maximum</strong>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disableFuture
                                value={periodeFin}
                                onChange={setPeriodeFin}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="col-lg-1 mb-2">
                    { props.showLoading && 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    }
                    { !props.showLoading && <>
                        <button type='submit' className='btn btn-primary'>
                            <i className='la la-search'></i>
                        </button>
                    </>}
                </div>
            </form>

            <div className="row">
                <div data-testid='data-table-base' className="col-xl-12">
                    <DataTableBase 
                            data={props.sinistresDeclares ? props.sinistresDeclares.map(sinistre => {
                                sinistre.dateEvaluation = new Date(sinistre.dateEvaluation);
                                return sinistre;
                            }) : []}
                            emptyMessage="Aucune evaluation trouvée" 
                            filters={getCurrentFilters()}
                            globalFiltersFields={['numSinistre', 'codeActe', 'codeGarantie']}
                            header={renderHeader()}
                            columns={setColumns()}
                            exportColumns={getExportColumns()}
                            rows={10} 
                            showExport={true}
                            exportFilename={"evaluations"}
                            loading={props.showLoading}
                    />
                    
           
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={showDetailsTab}
                        size="lg"
                    >
                        <DetailsModal 
                            showModal={showDetailsTab}
                            data={dataDetails}
                            headerText={dataDetailsText}
                            manageDetailsModal={manageDetailsModal}
                        />
                    </Modal>
                </div>
            </div>

        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        sinistresDeclares: state.sinistre.evaluations,
        showLoading: state.sinistre.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(Evaluations));