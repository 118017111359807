import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { Button, Spinner} from "react-bootstrap";
import { getActesConsultationAction, startLoader } from '../../../../store/actions/ConsultationActions';
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import OptiqueForm from './RemboursementFormSteps/OptiqueForm';
import HospitalisationForm from './RemboursementFormSteps/HospitalisationForm';
import ExamsForm from './RemboursementFormSteps/ExamsForm';
import SpecialActsForm from './RemboursementFormSteps/SpecialActsForm';
import PharmacyForm from './RemboursementFormSteps/PharmacyForm';
import { warningToastr } from '../../../../Utils/ToastrMessage';
import {
    listClientsAction
} from '../../../../store/actions/ClientAuthActions';
import { listProvidersAction } from '../../../../store/actions/ProviderActions';
import Select from 'react-select';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const RemboursementPane = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const {providers, actesConsultation, clients, currentUser} = props;
    const cookedRemboursement = JSON.parse(localStorage.getItem("remboursement"));
    
    const tabData = [
        {
           name: t('layout.optics'),
          icon: 'user-doctor',
          component: OptiqueForm,
        },
        {
          name: t('statuses.100'),
          icon: 'user-doctor-message',
          component: HospitalisationForm,
        },
        {
          name: t('common.exam'),
          icon: 'vial-virus',
          component: ExamsForm,
        },
    
        {
          name: 'Ordonnance',
          icon: 'prescription-bottle-pill',
          component: PharmacyForm,
        },
    
        {
          name: 'Actes spécialisés',
          icon: 'hand-holding-medical',
          component: SpecialActsForm,
        }
      ];

      const [clientId, setClientId] = useState((cookedRemboursement && cookedRemboursement.clientId) ? cookedRemboursement.clientId : '');
      const [withBon, setWithBon] = useState((cookedRemboursement && cookedRemboursement.withBon) ? cookedRemboursement.withBon : true);
      
      const [consultBonNum, setConsultBonNum] = useState((cookedRemboursement && cookedRemboursement.consultBonNum) ? cookedRemboursement.consultBonNum : "");
      const [consultationId, setConsultationId] = useState((cookedRemboursement && cookedRemboursement.consultationId) ? cookedRemboursement.consultationId : "");
      const [consultationName, setConsultationName] = useState((cookedRemboursement && cookedRemboursement.consultationName) ? cookedRemboursement.consultationName : "");
      const [consultationCode, setConsultationCode] = useState((cookedRemboursement && cookedRemboursement.consultationCode) ? cookedRemboursement.consultationCode : "");
      const [providerName, setProviderName] = useState((cookedRemboursement && cookedRemboursement.providerName) ? cookedRemboursement.providerName : "");
      const [consultationPrix, setConsultationPrix] = useState((cookedRemboursement && cookedRemboursement.consultationPrix) ? cookedRemboursement.consultationPrix : 0);
      const [consultationDate, setConsultationDate] = useState((cookedRemboursement && cookedRemboursement.consultationDate) ? cookedRemboursement.consultationDate : new Date());

    const saveDataToLocal = () => {
        let remboursement = JSON.parse(localStorage.getItem("remboursement"));

        let provider = providers.filter(prov => {return prov.providerName === providerName});

        remboursement = {
            ...remboursement,
            clientId,
            withBon,
            consultBonNum,
            consultationId,
            consultationName,
            consultationName,
            consultationCode,
            providerName,
            providerId: provider.length > 0 ? provider[0].id : null,
            consultationPrix,
            consultationDate
        };
        localStorage.setItem("remboursement", JSON.stringify(remboursement));
    }

    useEffect(() => {
        dispatch(listClientsAction());
        dispatch(getActesConsultationAction());
        dispatch(listProvidersAction());
        if(!cookedRemboursement) {
            localStorage.setItem("remboursement", JSON.stringify({}));
        }
    }, []);

    const saveRemboursement = (e) => {
        e.preventDefault();
        let remboursementDatas = JSON.parse(localStorage.getItem("remboursement"));

        if(remboursementDatas.clientId.trim().length === 0) {
            warningToastr("Vous ne pouvez pas enregistrer de remboursement sans client");
            return;
        }

        if(remboursementDatas.withBon && remboursementDatas.consultBonNum.trim().length === 0) {
            warningToastr("Vous ne pouvez pas enregistrer de remboursement sans consultation");
            return;
        }

        if(!remboursementDatas.withBon && (remboursementDatas.providerName.trim().length === 0
        || remboursementDatas.consultationName.trim().length === 0 || remboursementDatas.consultationPrix === 0 ||
        !remboursementDatas.consultationDate)) {
            warningToastr("Vous ne pouvez pas enregistrer de remboursement sans consultation");
            return;
        }
        
        //dispatch(startLoader());
        //consultationDatas.bonId = id;
        //dispatch(updateConsultationAction(consultationDatas, props.history));
    }

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className='row justify-content-end mt-4'>
                    { props.showLoading && 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />{' '}
                            <span className="visually-hidden">{t('common.await-text')}</span>
                        </Button> }
                    { !props.showLoading &&
                        <div className='col-4'>
                            <button type='button' onClick={e => saveRemboursement(e)} className='btn btn-primary btn-block'>
                               {t('common.save')} 
                            </button>
                        </div> }
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>Assuré</strong>
                            </label>
                            <Select
                                className="select-search"
                                options={clients.map(client => {
                                    return {label: client.firstName+' '+client.lastName, value: client.id}
                                })}
                                placeholder="Choisir l'assuré"
                                search
                                isRequired
                                onChange={(val) => {
                                    setClientId(val.value);
                                    saveDataToLocal();
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row justify-cont">
                    <div className="col-lg-6 mb-2">
                        <button type='button' onClick={e => {setWithBon(true); saveDataToLocal();}} className='btn btn-primary btn-block'>
                            Avec Bon de consultation 
                        </button>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <button type='button' onClick={e => {setWithBon(false); saveDataToLocal();}} className='btn btn-primary btn-block'>
                            Pas de bon de consultation 
                        </button>
                    </div>
                </div>
                {withBon && <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>Numéro de bon de consultation</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={consultBonNum}
                                required
                                onChange={(e) => {setConsultBonNum(e.target.value); saveDataToLocal();}}
                                />
                        </div>
                    </div>
                </div>}
                {!withBon && <div className="row">
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>{t('client.type')}</strong>
                            </label>
                            <Select
                                className="select-search"
                                options={actesConsultation.map(act => {
                                    return {label: act.nameFr, value: act.id, code: act.code}
                                })}
                                name="Type"
                                placeholder="Choisir un type"
                                //disabled={showLoading}
                                search
                                isRequired
                                onChange={(val) => {
                                    setConsultationId(val.value);
                                    setConsultationName(val.label);
                                    setConsultationCode(val.code);
                                    saveDataToLocal();
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>{t('dashboard.provider')}</strong>
                            </label>
                            <TextInput
                                className='form-control custom-text-input'
                                Component="input"
                                trigger={""}
                                value={providerName}
                                placeholder="Entrer un prestataire"
                                onChange={val => {setProviderName(val); saveDataToLocal();}}
                                options={providers.map(prov => {return prov.providerName})}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>{t('common.price')}</strong>
                            </label>
                            <input type='text' className='form-control'
                                value={consultationPrix}
                                required
                                onChange={(e) => {setConsultationPrix(e.target.value); saveDataToLocal();}}
                                />
                        </div>
                    </div>
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <label className='mb-1 '>
                                <strong>Date</strong>
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    autoOk
                                    label=""
                                    clearable
                                    format="dd/MM/yyyy hh:mm"
                                    value={consultationDate}
                                    disableFuture
                                    //disabled={showLoading}
                                    required
                                    onChange={val => {
                                        setConsultationDate(val);
                                        saveDataToLocal();
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>}
                <div className='custom-tab-1'>
                    <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                        <Nav as='ul' className='nav-tabs'>
                        {tabData.map((data, i) => (
                            <Nav.Item as='li' key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()}>
                                <i className={`fa fa-${data.icon} mr-2`} />
                                {data.name}
                            </Nav.Link>
                            </Nav.Item>
                        ))}
                        </Nav>
                        <Tab.Content className='pt-4'>
                        {tabData.map((data, i) => (
                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                <data.component provider={props.provider} />
                            </Tab.Pane>
                        ))}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        clients: state.clientAuth.clients,
        actesConsultation: state.consultation.actesConsultation,
        providers: state.provider.providers,
        consultation: state.consultation.consultation,
        showLoading: state.consultation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(RemboursementPane));