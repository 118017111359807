import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getCodesValidationAction, startLoader } from "../../../store/actions/UserAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { DataTableBase } from "../DataTables/DataTableBase";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Button } from 'primereact/button';
import { Button as BButton, Spinner } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from "react-i18next";

export const CodesValidation = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const dataTableRef = useRef();

    const [nomAssure, setNomAssure] = useState('');
    const [telephone, setTelephone] = useState('');
    const [numAssure, setNumAssure] = useState('');
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            idClient: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            telephone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            optVerification: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            generateAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setColumns = () => {
        return [
            {name: 'idClient', headerName: t('common.num-assure'), filterPlaceholder: t('common.num-assure-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'nom', headerName: t('common.assure'), filterPlaceholder: t('common.assure-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'telephone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'optVerification', headerName: t('common.otp-code'), filterPlaceholder: t('common.otp-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'generateAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.gen-date'), filterPlaceholder: t('common.dategen-filter'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateFacturationBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'status', headerName: t('common.status'), dataType: 'boolean',  filterPlaceholder: t('common.status-filter-text'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: statusBordBodyTemplate, filterElement: statusBordFilterTemplate},
        ]
    };

    const statusBordFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="isClose-filter" className="font-bold">
                    {t('common.used')}
                </label>
                <TriStateCheckbox inputId="isClose-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    }; 

    const getSeverity = (status) => {
        switch (status) {
            case true:
                return 'success';

            default:
                return 'warning';
        }
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateFacturationBodyTemplate = (rowData) => {
        return formatDate(rowData.generateAt);
    };

    const statusBordBodyTemplate = (rowData) => {
        return <Tag value={rowData.status ? t('common.used') : t('common.unused')} severity={getSeverity(rowData.status)} />;
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const searchAssure = (e) => {
        e.preventDefault();
        dispatch(startLoader());
        dispatch(getCodesValidationAction(
            nomAssure.trim().length > 0 ? nomAssure : null, 
            telephone.trim().length > 0 ? telephone : null, 
            numAssure.trim().length > 0 ? numAssure : null));
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENMONITORING') && canDo('MENMONITORING', 'VIEWVALIDATIONCODE')){
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    return (
        <>
            <p className="text-center">{t('common.search-message')}</p>
            <form onSubmit={e => searchAssure(e)}>
                <div className="row justify-content-center">
                    <div className="col-lg-5 mb-2">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('common.assure-name')}
                                value={nomAssure}
                                disabled={props.showLoading}
                                onChange={(e) => setNomAssure(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <PhoneInput
                                country='cd'
                                onlyCountries={['cm','ci','cd']}
                                localization='fr'
                                value={telephone}
                                disabled={props.showLoading}
                                autoFormat={telephone ? !telephone.startsWith('225') : true}
                                onChange={phone => setTelephone(phone)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3 mb-2">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('common.num-assure')}
                                disabled={props.showLoading}
                                value={numAssure}
                                onChange={(e) => setNumAssure(e.target.value)}
                            />
                        </div>
                    </div>
                    { props.showLoading && 
                        <div className='col-lg-1 mb-2'>
                            <div className="form-group">
                                <BButton variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </BButton>
                            </div> 
                        </div>}
                    { !props.showLoading &&
                        <div className='col-lg-1 mb-2'>
                            <div className="form-group">
                                <button type='submit' className='btn p-1 btn-primary btn-block' data-testid='search-button'>
                                    <i className='la la-search'></i>
                                </button>
                            </div>
                        </div> }
                </div>
            </form>
            <DataTableBase 
                data={!props.showLoading ? props.codesValidation.map(code => {
                    code.generateAt = new Date(code.generateAt);
                    code.codeUtl = code.statut ? t('common.used') : t('common.unused');
                    return code;
                }) : []}
                emptyMessage={t("common.no-code-f")}
                filters={filters}
                globalFiltersFields={['idClient', 'nom', 'email', 'telephone', 'optVerification', 'generateAt', 'status']}
                header={renderHeader()}
                columns={setColumns()}
                rows={10} 
                loading={props.showLoading}
                size={'small'}
                exportFilename={"codes_validation"}
                exportColumns={[
                    { title: t('common.name'), dataKey: 'idClient' },
                    { title: t('common.num-assure'), dataKey: 'nom' },
                    { title: t('common.phone'), dataKey: 'telephone' },
                    { title: t('common.otp-code'), dataKey: 'optVerification' },
                    { title: t('common.gen-date'), dataKey: 'createdAt' },
                    { title: t('common.status'), dataKey: 'codeUtl' }
                ]}
                showExport={true}
            />
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        codesValidation: state.user.codesValidation,
        showLoading: state.user.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(CodesValidation));
