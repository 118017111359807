import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { useReactToPrint } from "react-to-print";
import { Button } from 'primereact/button';
import { Row } from 'primereact/row';
import HorizontalBarChart from '../chart/HorizontalBarChart';
import './EtatsGlobaux.css';
import { usePDF, Margin } from 'react-to-pdf';
import generatePDF from 'react-to-pdf';
import { DownloadTableExcel } from 'react-export-table-to-excel';


const EtatsVisitePrestataire = (props) => {
    const { t } = useTranslation();
    const { data, showLoading } = props;
    const dt = useRef(null);

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    const [dataExcel, setDataExcel] = useState([])

    useEffect(() => {
        if (data.length > 0) {
            setStatsDatas(data.map(key => {
                return {
                    statName: key.providerName,
                    totalMontant: key["total"]["nbreVisites"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["nbreVisites"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["nbreVisites"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),

                }
            }))

            let prestsAmbu = data.filter(elt => elt.ambulatoire.nbreVisites > 0).sort((a, b) => b.ambulatoire.nbreVisites - a.ambulatoire.nbreVisites);
            let prestsHospi = data.filter(elt => elt.hospitalier.nbreVisites > 0).sort((a, b) => b.hospitalier.nbreVisites - a.hospitalier.nbreVisites);

            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["nbreVisites"]
                })
            }]);

            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["nbreVisites"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.providerName));
            setDataHospiLabels(prestsHospi.map(key => key.providerName));
        }
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Prestataire" rowSpan={2} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Nombre" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Nombre" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Nombre" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );



   
    // Fonction pour exporter en Excel
    const transformData = (data) => {
        return data.map(item => ({
            prestataire: item.statName,
            Ambulance_Montant: item.ambuMontant,
            Ambulance_Pourcentage: item.ambuPourcent,
            Hospitalisation_Montant: item.hospiMontant,
            Hospitalisation_Pourcentage: item.hospiPourcent,
            Total_Montant: item.totalMontant,
            Total_Pourcentage: item.totalPourcent
        }));
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {

            const worksheet = xlsx.utils.json_to_sheet(transformData(statsDatas), { skipHeader: false });
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'Top_Specialistes_Visités');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    //PDF
    const { toPDF, targetRef } = usePDF({ filename: 'Top_Specialistes_Visités.pdf',  page:{margin: Margin.SMALL}})

    //fichier csv
    const exportToCSVe = (datacsv) => {
        const csvRows = [];
        const headers = [
            'Prestataires',
            'Total Nbre Visites',
            'Total Taux',
            'Ambulatoire Nbre Visites',
            'Ambulatoire Taux',
            'Hospitalier Nbre Visites',
            'Hospitalier Taux'
        ];
        csvRows.push(headers.join(','));

        datacsv.forEach(item => {
            const row = [
                item.providerName,
                item.total.nbreVisites,
                item.total.taux,
                item.ambulatoire.nbreVisites,
                item.ambulatoire.taux,
                item.hospitalier.nbreVisites,
                item.hospitalier.taux
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Top_Specialistes_Visités.csv');
        a.click();
    };

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
            </span>
            }
            {Object.keys(data).length > 0 && <div className='results-section'>
                <div className='row justify-content-center my-4'>
                    <div className='form-group mb-0'>
                        <label className='radio-inline mr-5'>
                            <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => { setGraph(!e.target.checked) }} /> Données
                        </label>
                        <label className='radio-inline'>
                            <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => { setGraph(e.target.checked) }} /> Graphique
                        </label>
                    </div>
                </div>
                {!graph &&
                    <>
                        <div ref={targetRef}>
                            <DataTable
                                value={statsDatas}
                                showGridlines
                                headerColumnGroup={headerGroup}
                                tableStyle={{ minWidth: '50rem' }}

                                ref={dt}
                            >
                                <Column field="statName"></Column>
                                <Column field="totalMontant"></Column>
                                <Column field="totalPourcent"></Column>
                                <Column field="ambuMontant"></Column>
                                <Column field="ambuPourcent"></Column>
                                <Column field="hospiMontant"></Column>
                                <Column field="hospiPourcent"></Column>
                            </DataTable>
                        </div>
                        <div className="flex align-items-center justify-content-end gap-2">
                            <Button type="button" icon="pi pi-file" rounded onClick={() => exportToCSVe(data)} data-pr-tooltip="CSV" />
                            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => toPDF()} data-pr-tooltip="PDF" />
                        </div>
                    </>
                }
                {graph &&
                    <div className='row'>
                        <div className='col-6'>
                            <h5>Fréquentations en Ambulatoire</h5>
                            <HorizontalBarChart series={dataAmbuSeries} labels={dataAmbuLabels} height="150" />
                        </div>
                        <div className='col-6'>
                            <h5>Admission en Hospitalisation</h5>
                            <HorizontalBarChart series={dataHospiSeries} labels={dataHospiLabels} height="150" />
                        </div>
                    </div>
                }
            </div>}
        </>
    )
};
export default EtatsVisitePrestataire;