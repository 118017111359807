import {format} from 'date-fns';
import { withTranslation, useTranslation } from 'react-i18next';

 export const COLUMNS=() => {
	const {t}= useTranslation()
	return [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
	},
	{
		Header :  t("common.started-on") ,
		Footer : t("common.started-on"),
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		accessor: 'start_date',
	},
	{
		Header : t("common.finished-on"),
		Footer :t("common.finished-on"),
		Cell: ({ value }) => {return format(new Date(value), 'dd/MM/yyyy')},
		accessor: 'end_date',
	},
	{
		Header : t("common.status"),
		Footer :t("common.status"),
		accessor: 'status',
	},
	{
		Header : t("common.designation"),
		Footer : t("common.designation"),
		accessor: 'name',
	},
	{
		Header : t("common.dosage"),
		Footer :  t("common.dosage"),
		accessor: 'posologie',
	},
];
}


