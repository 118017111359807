import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { loadingToggleAction, getContratHistoAction, getBeneficiaireHistoAction, getSouscripteurHistoAction,
getAllSouscripteursAction, getContratsAction, getAllBeneficiairesAction } from "../../../store/actions/ContratActions";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import Select from 'react-select';
import{withRouter, useParams} from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import DetailsModal from "./DetailsModal";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const HistoriqueAvenants = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const dataTableRef = useRef();
    const { keyword, currentData } = useParams();
    //const [currentData, setCurrentData] = useState('contrats');
    //const [keyword, setKeyword] = useState('');
    const [currentLoadData, setCurrentLoadData] = useState([]);
    const [showDetailsTab, setShowDetailsTab] = useState(false);
    const [dataDetails, setDataDetails] = useState(null);
    //const dataTypeList = ['contrats', 'souscripteurs', 'bénéficiaires'];

    const [contratFilters, setContratFilters] = useState(null);
    const [globalContratFilterValue, setGlobalContratFilterValue] = useState('');

    const [souscriptFilters, setSouscriptFilters] = useState(null);
    const [globalSouscriptFilterValue, setGlobalSouscriptFilterValue] = useState('');

    const [benefFilters, setBenefFilters] = useState(null);
    const [globalBenefFilterValue, setGlobalBenefFilterValue] = useState('');

    const [contratLazyState, setContratLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numAvenant: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            UpdatedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        }
    });

    const [souscriptLazyState, setSouscriptLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numAvenant: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            UpdatedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        }
    });

    const [benefLazyState, setBenefLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numAvenant: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            UpdatedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        }
    });

    const initFilters = () => {
        setContratFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numAvenant: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            UpdatedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        });
        setGlobalContratFilterValue("");
        setBenefFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numAvenant: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            UpdatedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        });
        setGlobalBenefFilterValue("");
        setSouscriptFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numAvenant: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            updatedAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            UpdatedBy: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
        });
        setGlobalSouscriptFilterValue("");
    };

    const renderHeader = () => {
        switch(currentData) {
            case 'souscripteurs':
                return (
                    <div className="flex justify-content-between">
                        <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalSouscriptFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                        </span>
                    </div>
                );
            case 'bénéficiaires':
                return (
                    <div className="flex justify-content-between">
                        <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalBenefFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                        </span>
                    </div>
                );
            default:
                return (
                    <div className="flex justify-content-between">
                        <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalContratFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                        </span>
                    </div>
                );
        }
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        switch(currentData) {
            case 'souscripteurs':
                let _filtersS = { ...souscriptFilters };

                _filtersS['global'].value = value;

                setSouscriptFilters(_filtersS);
                setGlobalSouscriptFilterValue(value);
                dispatch(loadingToggleAction(true));
                dispatch(getSouscripteurHistoAction(1, 10, value));
                return;
            case 'bénéficiaires':
                let _filtersB = { ...benefFilters };

                _filtersB['global'].value = value;

                setBenefFilters(_filtersB);
                setGlobalBenefFilterValue(value);
                dispatch(loadingToggleAction(true));
                dispatch(getBeneficiaireHistoAction(1, 10, value));
                return;
            default:
                let _filters = { ...contratFilters };

                _filters['global'].value = value;

                setContratFilters(_filters);
                setGlobalContratFilterValue(value);
                dispatch(loadingToggleAction(true));
                dispatch(getContratHistoAction(1, 10, value));
                return;
        }
    };

    const setColumns = () => {
        switch(currentData) {
            case 'souscripteurs':
                return [
                    {name: 'numAvenant', headerName: t('common.numero-avenant'), filterPlaceholder: t("common.filter-num-amend"), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'updatedAt', filterField: t('common.update-name'), dataType: 'date', headerName: t('common.update-name'), filterPlaceholder: t("common.filter-date-modi"), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    //{name: 'updatedBy', headerName: "Modifié par", filterPlaceholder: 'Filtrer par modificateur', style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
                ]
            case 'bénéficiaires':
                return [
                    {name: 'numAvenant', headerName: t('common.numero-avenant'), filterPlaceholder: t("common.filter-num-amend"), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'updatedAt', filterField: t('common.update-name'), dataType: 'date', headerName: t('common.update-name'), filterPlaceholder:  t("common.filter-date-modi"), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    //{name: 'updatedBy', headerName: "Modifié par", filterPlaceholder: 'Filtrer par modificateur', style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
                ]
            default:
                return [
                    {name: 'numAvenant', headerName: t('common.numero-avenant'), filterPlaceholder: t("common.filter-num-amend"), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'updatedAt', filterField: t('common.update-name'), dataType: 'date', headerName: t('common.update-name'), filterPlaceholder:  t("common.filter-date-modi"), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    //{name: 'updatedBy', headerName: "Modifié par", filterPlaceholder: 'Filtrer par modificateur', style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
                ]
        }
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                {hasMenu('MENMONITORING') && canDo('MENMONITORING', 'VIEWAVENANTDETAIL') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={(e) => {
                        setDataDetails(rowData);
                        setShowDetailsTab(true);
                    }}
                >
                    <i className="fa fa-eye"></i>
                </button>}
        </div>
    }

    const manageDetailsModal = () => {
        setShowDetailsTab(false);
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateNaissBodyTemplate = (rowData) => {
        return rowData.updatedAt ? formatDate(rowData.updatedAt) : "";
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const onGoToPage = (event) => {
        switch(currentData) {
            case 'souscripteurs':
                setSouscriptLazyState(event)
                return;
            case 'bénéficiaires':
                setBenefLazyState(event)
                return;
            default:
                setContratLazyState(event)
                return;
        };
    }

    const onSortData = (event) => {
        switch(currentData) {
            case 'souscripteurs':
                setSouscriptLazyState(event)
                return;
            case 'bénéficiaires':
                setBenefLazyState(event)
                return;
            default:
                setContratLazyState(event)
                return;
        };
    }

    useEffect(() => {
        if(keyword.length > 0){
            switch(currentData) {
                case 'souscripteurs':
                    dispatch(loadingToggleAction(true));
                    dispatch(getSouscripteurHistoAction(souscriptLazyState.page + 1, souscriptLazyState.rows, keyword)); 
                    return;
                case 'bénéficiaires':
                    dispatch(loadingToggleAction(true));
                    dispatch(getBeneficiaireHistoAction(benefLazyState.page + 1, benefLazyState.rows, keyword)); 
                    return;
                default:
                    if(contratLazyState.page) {
                        dispatch(loadingToggleAction(true));
                        dispatch(getContratHistoAction(contratLazyState.page + 1, contratLazyState.rows, keyword)); 
                    }else if(contratLazyState.sortField && contratLazyState.sortOrder) {
                        // Get the filtered data from the client side.
                        const sortedData = props.contratsHisto.data.sort((a, b) => {
                            return contratLazyState.sortOrder === 1 ? a[contratLazyState.sortField] - b[contratLazyState.sortField] : b[contratLazyState.sortField] - a[contratLazyState.sortField];
                        });
                        // Update the data table with the filtered data.
                        //dataTableRef.current?.updateData(filteredData);
                    }
                    return;
            };
        }
    }, [contratLazyState, souscriptLazyState, benefLazyState]);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    useEffect(() => {
        if(hasMenu('MENMONITORING') && canDo('MENMONITORING', 'LISTAVENANTHIST')){
            initFilters();
            dispatch(loadingToggleAction(true));
            switch(currentData) {
                case 'souscripteurs':
                    initFilters();
                    dispatch(getSouscripteurHistoAction(1, 10, keyword));
                    return;
                case 'bénéficiaires':
                    initFilters();
                    dispatch(getBeneficiaireHistoAction(1, 10, keyword));
                    return;
                default:
                    initFilters();
                    dispatch(getContratHistoAction(1, 10, keyword));
                    return;
            }
        }else{
            props.history.push('/access-denied');
        }
        //dispatch(getContratsAction());
        //dispatch(getAllSouscripteursAction());
        //dispatch(getAllBeneficiairesAction());
    }, []);

    
    /*useEffect(() => {
        setKeyword('');
    }, [currentData]);*/

    /*useEffect(() => {
        if(keyword.length > 0) {
            dispatch(loadingToggleAction(true));
            switch(currentData) {
                case 'souscripteurs':
                    initFilters();
                    dispatch(getSouscripteurHistoAction(1, 10, keyword));
                    return;
                case 'bénéficiaires':
                    initFilters();
                    dispatch(getBeneficiaireHistoAction(1, 10, keyword));
                    return;
                default:
                    initFilters();
                    dispatch(getContratHistoAction(1, 10, keyword));
                    return;
            }
        }
    }, [keyword]);*/

    const getCurrentData = () => {
        switch(currentData) {
            case 'souscripteurs':
                return props.souscripteursHisto.data ? props.souscripteursHisto.data.map(souscript => {
                    souscript.updatedAt = souscript.updatedAt ? new Date(souscript.updatedAt) : null;
                    return souscript;
                }) : [];
            case 'bénéficiaires':
                return props.beneficiairesHisto.data ? props.beneficiairesHisto.data.map(benef => {
                    benef.updatedAt = benef.updatedAt ? new Date(benef.updatedAt) : null;
                    return benef;
                }) : [];
            default:
                return props.contratsHisto.data ? props.contratsHisto.data.map(contrat => {
                    contrat.updatedAt = contrat.updatedAt ? new Date(contrat.updatedAt) : null;
                    return contrat;
                }) : [];
        }
    }

    const getExportColumns = () => {
        switch(currentData) {
            case 'souscripteurs':
                return [
                    { title: t('common.numero-avenant'), dataKey: 'numAvenant' },
                    { title: t('common.update-name'), dataKey: 'updatedAt' },
                    //{ title: 'Modificateur', dataKey: 'updatedBy' },
                ];
            case 'bénéficiaires':
                return [
                    { title: t('common.numero-avenant'), dataKey: 'numAvenant' },
                    { title: t('common.update-name'), dataKey: 'updatedAt' },
                    //{ title: 'Modificateur', dataKey: 'updatedBy' },
                ];
            default:
                return [
                    { title: t('common.numero-avenant'), dataKey: 'numAvenant' },
                    { title: t('common.update-name'), dataKey: 'updatedAt' },
                    //{ title: 'Modificateur', dataKey: 'updatedBy' },
                ];
        }
    }

    const getCurrentLazyState = () => {
        switch(currentData) {
            case 'souscripteurs':
                return souscriptLazyState;
            case 'bénéficiaires':
                return benefLazyState;
            default:
                return contratLazyState;
        }
    }

    const getCurrentTotalDataState = () => {
        switch(currentData) {
            case 'souscripteurs':
                return props.allSouscripteurs?.totalPages;
            case 'bénéficiaires':
                return props.allBeneficiaires?.totalPages;
            default:
                return props.allContrats?.totalPages;
        }
    }
    
    const getCurrentFilters = () => {
        switch(currentData) {
            case 'souscripteurs':
                return souscriptFilters;
            case 'bénéficiaires':
                return benefFilters;
            default:
                return contratFilters;
        }
    }

    return (
        <Fragment>
            {/*<div className="form-head mb-4" style={{width: '50%'}}>
                <div className="row">
                    <div className="col-6">
                        <Select
                            className="select-search"
                            value={{label: currentData, value: currentData}}
                            placeholder="Type de données"
                            onChange={(val) => setCurrentData(val.value)}
                            disabled={props.showLoading}
                            required
                            options={dataTypeList.map((opt)=>{return {label:opt,value:opt}})}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>
                    {currentData === 'contrats' && <div className="col-6">
                        <Select
                            className="select-search"
                            value={props.allContrats?.data?.filter(elt => elt.numeroPolice === keyword)?.map((elt)=>{return {label:elt.numeroPolice,value:elt.numeroPolice}})}
                            placeholder="choisir la police"
                            onChange={(val) => setKeyword(val.value)}
                            disabled={props.showLoading}
                            required
                            options={props.allContrats?.data?.map((elt)=>{return {label:elt.numeroPolice,value:elt.numeroPolice}})}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>}
                    {currentData === 'souscripteurs' && <div className="col-6">
                        <Select
                            className="select-search"
                            value={props.allSouscripteurs?.data?.filter(elt => elt.nom_Assu === keyword)?.map((elt)=>{return {label:elt.nom_Assu,value:elt.nom_Assu}})}
                            placeholder="choisir le souscripteur"
                            onChange={(val) => setKeyword(val.value)}
                            disabled={props.showLoading}
                            required
                            options={props.allSouscripteurs?.data?.map((elt)=>{return {label:elt.nom_Assu,value:elt.nom_Assu}})}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>}
                    {currentData === 'bénéficiaires' && <div className="col-6">
                        <Select
                            className="select-search"
                            value={props.allBeneficiaires?.data?.filter(elt => elt.numBeneficiaire === keyword).map(elt => {return {label: elt.nom, value: elt.numBeneficiaire}})}
                            placeholder="choisir le bénéficiaire"
                            onChange={(val) => setKeyword(val.value)}
                            disabled={props.showLoading}
                            required
                            options={props.allBeneficiaires?.data?.map(elt => {return {label: elt.nom, value: elt.numBeneficiaire}})}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>}
                </div>
            </div>*/}
            <div className="row">
                <div className="col-xl-12">
                    <DataTableBase 
                        data={getCurrentData()}
                        emptyMessage={"0 "+currentData} 
                        filters={getCurrentFilters}
                        //globalFiltersFields={['code', 'nameFr', 'familyText', 'libelleGarantie', 'formule', 'createdAt']}
                        header={renderHeader()}
                        columns={setColumns()}
                        exportColumns={getExportColumns()}
                        rows={10} 
                        showExport={true}
                        exportFilename={currentData}
                        loading={props.showLoading}
                        lazy={true}
                        dataSet={getCurrentLazyState()}
                        totalRecords={getCurrentTotalDataState()}
                        onGoToPage={onGoToPage}
                        onSortData={onSortData}
                        removableSort={true}
                    />
                    
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={showDetailsTab}
                        size="lg"
                    >
                        <DetailsModal 
                            showModal={showDetailsTab}
                            data={dataDetails}
                            numAvenant={dataDetails?.numAvenant}
                            dateAvenant={dataDetails?.updatedAt}
                            manageDetailsModal={manageDetailsModal}
                        />
                    </Modal>
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        contratsHisto: state.contrat.contratsHisto,
        beneficiairesHisto: state.contrat.beneficiairesHisto,
        souscripteursHisto: state.contrat.souscripteursHisto,
        allSouscripteurs: state.contrat.allSouscripteurs,
        allContrats: state.contrat.allContrats,
        allBeneficiaires: state.contrat.allBeneficiaires,
        showLoading: state.contrat.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(HistoriqueAvenants));