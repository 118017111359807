import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import {
    loadingToggleAction,
} from '../../../../store/actions/AuthActions';
import { ProgressBar } from 'react-bootstrap';
import uploadFiles from '../../../../services/BlobStorageService';
import { useTranslation } from "react-i18next";
/* --- BEGIN ClientGroupRegistration
	@param(Object): props
    description: Enrollement groupé des clients à partir de leurs fichiers image et leurs ids
*/
function ClientGroupRegistration(props) {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    let errorsObj = { files: ''};
    const [errors, setErrors] = useState(errorsObj);
    const { t } = useTranslation();
    /* --- BEGIN saveFiles
        @param(Event): e
        description: Gestion des fichiers chargés
    */
    const saveFiles = (e) => {
        let upFiles = e.target.files;
        const fileList = [];
        const keys = Object.keys(upFiles);
        for (let i = 0; i < keys.length; i += 1) {
            fileList.push(upFiles[keys[i]]);
        }

        setFiles(fileList);
    }
    /* --- END saveFiles */

    /* --- BEGIN onSignUp
        @param(Event): e
        description: Enrollement groupé des clients
    */
    async function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (files.length === 0) {
            errorObj.files = t('client.upload-files');
            error = true;
        }

        setErrors(errorObj);

        if (error) return;

        setUploading(true);

        const blobUrls = await uploadFiles(files, 'amp-container');

    }
    /* --- END onSignUp */

  return (
    <div>
        <div className="form-head d-flex mb-3 mb-md-4 align-items-start justify-content-center bg-white">
            <div className='auth-form'>
                {/* <div className='text-center mb-3'>
                    <img src={logo} alt="" />
                </div> */}

                <h4 className='text-center mb-4 '>{t('client.register-client-group')}</h4>
                <form onSubmit={onSignUp}>
                    {/*<div className='form-group'>
                        <label className='mb-1 '>
                            <strong>Images</strong>
                        </label>
                        <input type="file" multiple className="form-control"
                            onChange={(e) =>
                                setFiles(e.target.files)
                            }
                        />
                    </div>*/}
                    <div className='input-group'>
                        <div className='custom-file'>
                            <input type='file' multiple onChange={(e) => saveFiles(e)} className='custom-file-input' />
                            <label className='custom-file-label'>{t('common.select')}</label>
                        </div>
                    </div>
                    {errors.files && <div className="text-danger fs-12">{errors.files}</div>}
                    {files.map((file, i) => (
                        <div>
                            <label className='mt-2'>{file.name}</label>
                            <ProgressBar
                            now={uploading ? 100 : 0}
                            variant='primary'
                            key={i}
                            className='my-2'
                            />
                        </div>
                    ))}


                    <div className='text-center mt-4'>
                        <input type='submit' className='btn btn-primary btn-block'/>
                    </div>
                </form>
                <div className='new-account mt-3 '>
                    <p>
                        <Link className='text-primary' to='/client-register'>
                            {t('client.register-new-client')}
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
/* --- END ClientGroupRegistration */

const mapStateToProps = (state) => {
    return {
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.clientAuth.showLoading,
    };
};

export default connect(mapStateToProps)(ClientGroupRegistration);
