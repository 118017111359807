import React, { useState, useEffect,useRef } from 'react'
import { useTranslation } from "react-i18next";
import PieChart from '../chart/PieChart';
import './EtatsGlobaux.css';
import { Margin, usePDF } from 'react-to-pdf';
import { Button } from 'primereact/button';



const EtatsGlobaux = (props) => {
    const { t } = useTranslation();
    const {data, showLoading} = props;
    const dt = useRef(null);


    const [dataSeries, setDataSeries] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    useEffect(() => {
        if(Object.keys(data).length > 0){
            setDataSeries([parseFloat(data.totalRejete), parseFloat(data.totalPaye)]);
            setDataLabels(["Montant Rejeté", "Montant Payé"]);
        }
    }, [data]);


    const exportExcel = () => {
        const rows = []
        rows.push(data)
        import('xlsx').then((xlsx) => {

            const worksheet = xlsx.utils.json_to_sheet(rows, { skipHeader: false });
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'Etats_Globaux_des_Consommations');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    //fichier csv
    const exportToCSVe = () => {
        const csvRows = [];
        const rowData = []
        rowData.push(data)
        const headers = [
            'Montant total des prestations',
            'Montant total à payer',
            'Nombre total de prestataires',
            'Nombre total de visites',
            
        ];
        csvRows.push(headers.join(','));

        rowData.forEach(item => {
            const row = [
                item.totalPrestations,
                item.totalRejete,
                item.totalPaye,
                item.nbrePrestataire,
                item.nbreVisite,
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Etats_Globaux_des_Consommations.csv');
        a.click();
    };
   
     //pdf
   const { toPDF, targetRef } = usePDF({ filename: 'Etats_Globaux_des_Consommations.pdf', page:{margin: Margin.SMALL}})


    return (
            <>
                {Object.keys(data).length === 0 && <span>
                    Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
                </span>
                }
                {Object.keys(data).length > 0 && <div className='results-section'>
                    <div className='radio-section row justify-content-center my-4'>
                        <div className='form-group mb-0'>
                            <label className='radio-inline mr-5'>
                                <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => {setGraph(!e.target.checked)}}/> Données
                            </label>
                            <label className='radio-inline'>
                                <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => {setGraph(e.target.checked)}}/> Graphique
                            </label>
                        </div>
                    </div>
                    {!graph &&
                    <>
                    <div ref={targetRef}>
                    <ul ref={dt}>
                        <li>
                            <h4>Montant total des prestations:</h4>
                            <span>{data.totalPrestations}</span>
                        </li>
                        <li>
                            <h4>Montant total rejeté:</h4>
                            <span>{data.totalRejete}</span>
                        </li>
                        <li>
                            <h4>Montant total à payer:</h4>
                            <span>{data.totalPaye}</span>
                        </li>
                        <li>
                            <h4>Nombre total de prestataires:</h4>
                            <span>{data.nbrePrestataire}</span>
                        </li>
                        <li>
                            <h4>Nombre total de visites:</h4>
                            <span>{data.nbreVisite}</span>
                        </li>
                    </ul>
                    </div>
                    <div className="flex align-items-center justify-content-end gap-2">
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportToCSVe(data)} data-pr-tooltip="CSV" />
                    <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={()=>exportExcel()} data-pr-tooltip="XLS" />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => toPDF()} data-pr-tooltip="PDF" />
                </div>
                </>
                    }
                    {graph && <PieChart series={dataSeries} pieWidth="380" labels={dataLabels} legendPosition="bottom" />}
                </div>}
            </> 
    )
}
export default EtatsGlobaux;