import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { getFactureAction, startLoader, getMotifsExclusionAction, restoreRejetAction, sendFacturePaymentAction } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { useReactToPrint } from 'react-to-print';
import MotifRejetModal from "./MotifRejetModal";
import { Modal } from "react-bootstrap";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import { TreeTableBase } from "../DataTables/TreeTableBase";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { STATUSES } from '../../globals';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { useTranslation } from "react-i18next";

class FactureTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentKey: ''
        }
    }

    statusColor = () => {
        return "text-secondary"
    };

    getTableColumns = () => {
        return [
            {field: 'intitule', header: this.props.t('common.entitle'), expander: true, headerClassName: 'text-xs'},
            {field: 'prixConv', header: this.props.t('provider.tarif'), expander: false, headerClassName: 'text-xs'},
            {field: 'plafond', header: this.props.t('common.top'), expander: false, headerClassName: 'text-xs'},
            {field: 'montant', header: this.props.t('common.abr-tier-payant'), expander: false, headerClassName: 'text-xs'},
            {field: 'quantite', header: this.props.t('quantity.top'), expander: false, headerClassName: 'text-xs'},
            {field: 'montantTotal', header: this.props.t('common.total'), expander: false, headerClassName: 'text-xs'},
            {field: 'montantPatient', header: this.props.t('common.abr-ticket-mod'), expander: false, headerClassName: 'text-xs'},
            {field: 'montantAssureur', header: this.props.t('common.to-pay'), expander: false, headerClassName: 'text-xs'},
            {field: 'medecin', header: this.props.t('common.doctor'), expander: false, headerClassName: 'text-xs'},
            {field: 'date', header: 'Date', expander: false, headerClassName: 'text-xs'},
            {field: 'montantRejete', header: this.props.t('appointment.Rejected'), expander: false, headerClassName: 'text-xs'},
            {field: 'motifRejet', header: this.props.t('appointment.reason'), expander: false, headerClassName: 'text-xs'},
            {field: '', header: 'Actions', expander: false, headerClassName: 'text-xs', body: this.actionTemplate},
        ];
    }

    actionTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {this.props.hasMenu('MENFACTUREADMIN') && this.props.canDo('MENFACTUREADMIN', 'EXCLUDEADMINFACTURE') && rowData.key.includes('acteh') && !rowData.data.motifRejet && this.props.facture.statusPaiement === STATUSES.nonPaye && 
                <>
                    <button
                        onClick={e => this.setState(state => ({...state, currentKey: rowData.key}))}
                        className="btn btn-danger btn-rounded mr-2"
                        data-pr-tooltip="Exclure"
                        data-pr-position="bottom"
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={this.state.currentKey === rowData.key}
                        size="lg"
                    >
                        <MotifRejetModal 
                            showModal={this.state.currentKey === rowData.key}
                            acte={rowData.data}
                            manageCommentModal={this.manageCommentModal}
                            history={this.props.history}
                            exclusions={this.props.motifExclusion}
                            motifRejet={rowData.motifRejet}
                            commentaireRejet={rowData.commentaireRejet}
                        />
                    </Modal>
                </>
            }
            {this.props.hasMenu('MENFACTUREADMIN') && this.props.canDo('MENFACTUREADMIN', 'RESTOREADMINFACTURE') && rowData.key.includes('acteh') && rowData.data.motifRejet && this.props.facture.statusPaiement === STATUSES.nonPaye &&
                <button
                    onClick={e => this.cancelDeletion(rowData.data.id)}
                    className="btn btn-success btn-rounded"
                    data-pr-tooltip="Annuler l'exclusion"
                    data-pr-position="bottom"
                    title={rowData.data.motifRejet +' - '+rowData.data.commentaireRejet}
                >
                    <i className="la la-trash-restore"></i>
                </button>
            }
            {this.props.hasMenu('MENCONSULTADMIN') && this.props.canDo('MENCONSULTADMIN', 'VIEWADMINMEDFOLDER') && rowData.key.includes('Assuré') &&
                <Link
                    to={"/dossier-medical/"+rowData.data.patientId}
                    target="_blank"
                    className="btn btn-warning btn-rounded text-white"
                    data-pr-tooltip="Dossier médical"
                    data-pr-position="bottom"
                >
                    <i className="fa fa-eye"></i>
                </Link>
            }
            {this.props.hasMenu('MENCONSULTADMIN') && this.props.canDo('MENCONSULTADMIN', 'VIEWCONSULTADMIN') && rowData.key.includes('facture') && <Link
                    to={"/consultation-details/"+rowData.data.consultationId}
                    target="_blank"
                    className="btn btn-primary btn-rounded text-white"
                    data-pr-tooltip="Feuille de soin"
                    data-pr-position="bottom"
                >
                    <i className="la la-stethoscope"></i>
                </Link>
            }
        </div>
    }

    rowClassName = (node) => {
        return { 'p-highlight': (node.key.split('').includes('é')),
                 'bg-red-300 text-white': (node.data.motifRejet),
                 'text-sm': true, 
                 'text-xs': (node.key.split('').includes('h')),
                 'font-bold': (!node.key.split('').includes('h')),
                 'p-0': true,
                };
    }

    setDatas = (arr) => {
        let res = arr?.map((elt, i) => {
            let levOne = {
                key: 'Assuré'+i,
                data: {
                    intitule: elt.patientName,
                    prixConv: elt.totalPriceConvention?.toFixed(2),
                    plafond: elt.totalPricePolice?.toFixed(2),
                    montant: elt.totalBaseRemboursement?.toFixed(2),
                    quantite: '-',
                    montantTotal: elt.totalAmount?.toFixed(2),
                    montantPatient: elt.totalTm?.toFixed(2),
                    montantAssureur: elt.totalAssureur?.toFixed(2),
                    medecin: '-',
                    date: '-',
                    montantRejete: elt.totalMontantRejecte?.toFixed(2),
                    motifRejet: '',
                    patientId: elt.patientId,
                },
                children: elt.factures?.map((fact, j) => {
                    return {
                        key: 'facture'+j,
                        data: {
                            intitule: 'Facture du '+fact?.dateFacturation,
                            prixConv: fact.totalPriceConvention?.toFixed(2),
                            plafond: fact.totalPricePolice?.toFixed(2),
                            montant: fact.totalBaseRemboursement?.toFixed(2),
                            quantite: '-',
                            montantTotal: fact.totalAmount?.toFixed(2),
                            montantPatient: fact.totalTm?.toFixed(2),
                            montantAssureur: fact.totalAssureur?.toFixed(2),
                            medecin: fact.nomMedecin,
                            date: fact.dateFacturation,
                            montantRejete: fact.totalMontantRejecte?.toFixed(2),
                            motifRejet: '',
                            consultationId: fact.consultationCode,
                        },
                        children: fact.factureDetails?.map((factDetail, k) => {
                            return {
                                key: 'bon'+k,
                                data: {
                                    intitule: factDetail.type,
                                    prixConv: factDetail.totalPriceConvention?.toFixed(2),
                                    plafond: factDetail.totalPricePolice?.toFixed(2),
                                    montant: factDetail.totalBaseRemboursement?.toFixed(2),
                                    quantite: '-',
                                    montantTotal: factDetail.totalAmount?.toFixed(2),
                                    montantPatient: factDetail.totalTm?.toFixed(2),
                                    montantAssureur: factDetail.totalAssureur?.toFixed(2),
                                    medecin: '-',
                                    date: fact.dateFacturation,
                                    montantRejete: factDetail.totalMontantRejecte?.toFixed(2),
                                    motifRejet: '',
                                },
                                children: factDetail.bonDetails?.map((bon, l) => {
                                    return {
                                        key: 'acteh'+l+bon.acteId,
                                        data: {
                                            intitule: bon.acteName,
                                            prixConv: bon.priceConvention?.toFixed(2),
                                            plafond: bon.pricePolice?.toFixed(2),
                                            montant: bon.baseRemboursement?.toFixed(2),
                                            quantite: bon.quantite,
                                            montantTotal: bon.prixTotal?.toFixed(2),
                                            montantPatient: bon.priceToPayByPatient?.toFixed(2),
                                            montantAssureur: bon.priceToPayByActiva.toFixed( 2),
                                            medecin: '-',
                                            date: fact.dateFacturation,
                                            montantRejete: bon.montantRejete?.toFixed(2),
                                            motifRejet: bon.motifRejet,
                                            id: bon.factureDetailId,
                                            libelle: bon.acteName,
                                        },
                                        children: []
                                    }
                                })
                            }
                        })
                    }
                })
            }
            return levOne;
        }); 
        return res;    
    };

    header = () => {
        return <div className="text-xl font-bold">{t("common.file-viewer")}</div>;
    }

    groupElements = (arr) => {
        return arr.reduce((group, product) => {
            const { patientName } = product;
            group[patientName] = group[patientName] ?? [];
            group[patientName].push(product);
            return group;
        }, {});
    }

    manageCommentModal = () => {
        this.setState(state => ({...state, currentKey: ''}));
    }

    cancelDeletion = (lineId) => {
        this.props.dispatch(startLoader());
        this.props.dispatch(restoreRejetAction(lineId));
    }

    render() {
        return (<div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-3">
                        <div className="card-header">
                            {" "}
                            <strong>{this.props.facture.periode}</strong>{" "}
                            <span className="float-right">
                            <strong>{this.props.t('common.payment-status')}:</strong> <span className={this.statusColor()}>{this.props.t('statuses.'+this.props.facture.statusPaiement)+(this.props.facture.isClose ? " - Décompte cloturé" : " - Décompte non cloturé")}</span>
                            </span>{" "}
                        </div>
                        <div className="card-body">
                            {/*<div className="row mb-5">
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <h6>{this.props.t('common.from')}:</h6>
                                <div>
                                {" "}
                                <strong>{this.props.facture.prestataireName}</strong>{" "}
                                </div>
                                <div>Provider Rep</div>
                                <div>Provider City, Provider Country</div>
                                <div>{this.props.t('common.email')}: Provider Email</div>
                                <div>{this.props.t('common.phone')}: +Provider Phone</div>
                            </div>
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-end justify-content-end">
                                <h6>À:</h6>
                                <div>
                                {" "}
                                <strong>Activa Assurances</strong>{" "}
                                </div>
                                <div>{this.props.t('common.paymet-service')}</div>
                                <div>Rue Prince de Galles - Douala, Cameroun</div>
                                <div>{this.props.t('common.email')}: paiement@group-active.com</div>
                                <div>{this.props.t('common.phone')}: +237692880000</div>
                            </div>
                            </div>*/}
                            <div className="table-responsive">
                                
                                <TreeTableBase
                                    data={this.setDatas(this.props.facture.patients)}
                                    style={{ minWidth: '50rem', fontSize: '12px !important' }}
                                    columns={this.getTableColumns()}
                                    rowClassName={this.rowClassName}
                                />
                            </div>
                            <div className="row">
                            <div className="col-lg-4 col-sm-5"> </div>
                            <div className="col-lg-4 col-sm-5 ml-auto">
                                <table className="table table-clear">
                                <tbody>
                                    {/*<tr>
                                        <td className="left">
                                            <strong>Sous-total</strong>
                                        </td>
                                        <td className="right">
                                            {this.props.facture.montantTotal} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            <strong>Exclusions</strong>
                                        </td>
                                        <td className="right">{this.props.facture.facturePrestataireDetails?.reduce((partialSum, a) => partialSum + (a.isRejet ? a.prixTotal : 0), 0)}</td>
                                    </tr>*/}
                                    <tr>
                                        <td className="left">
                                            <strong>{this.props.t('common.total')}</strong>
                                        </td>
                                        <td className="right">
                                            <strong>{this.props.facture.montantTotal}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>

                        </div>
                        </div>
                    </div>
            </div>
        );
    };

}

class FacturePrintTemplate extends React.Component {
    constructor(props) {
        super(props);
    }

    footerGroup = (datas) => {
        return <ColumnGroup>
            <Row>
                <Column footer="TOTAL" colSpan={6} footerStyle={{ textAlign: 'center' }} />
                <Column footer={datas.reduce((sum, elt) => sum + elt.totalAmount, 0)} />
                <Column footer={datas.reduce((sum, elt) => sum + elt.totalTm, 0)} />
                <Column footer={datas.reduce((sum, elt) => sum + elt.totalMontantRejecte, 0)} />
                <Column footer={datas.reduce((sum, elt) => sum + elt.totalAssureur, 0)} />
            </Row>
        </ColumnGroup>
    };

    render() {
   

        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card mt-3">
                        <div className="card-header">
                            <h4><i>{this.props.t("common.activa-insurance")}</i></h4>
                            <h5><i> {this.props.t("common.statement-account")} N°{this.props.bordId} - {this.props.t("dashboard.provider")} {this.props.facture.prestataire}</i></h5>
                            <span><b>{this.props.t("common.periode")}:</b> {this.props.facture.periode}</span>
                            <span><b>{this.props.t('statuses.'+this.props.facture.statusPaiement)}</b></span>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                {this.props.facture.patients?.length > 0 && <div className='results-section'>
                                    <DataTable 
                                        value={this.props.facture.patients || []} 
                                        dataKey="id"
                                        showGridlines 
                                        tableStyle={{ minWidth: '50rem' }}
                                        footerColumnGroup={this.footerGroup(this.props.facture.patients)}
                                    >
                                        <Column header={this.props.t("common.policies")} field="numeroPolice" />
                                        <Column header={this.props.t("dashboard.souscipt")} field="souscripteur" />
                                        <Column header= {this.props.t("dashboard.member")} field="adherentName" />
                                        <Column header={this.props.t("common.benef-N")} field="numeroBeneficiaire" />
                                        <Column header={this.props.t("common.beneficiary")} field="patientName" />
                                        <Column header={this.props.t("common.bill")}  field="factureId" />
                                        <Column header={this.props.t("facturation.amount")} field="totalAmount" />
                                        <Column header="Ticket Mod" field="totalTm" />
                                        <Column header={this.props.t("dashboard.rejected-amount")} field="totalMontantRejecte" />
                                        <Column header={this.props.t("common.to-pay")} field="totalAssureur" />
                                    </DataTable>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const FacturationDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const {facture, currentUser, successMessage, errorMessage, history, motifExclusion} = props;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const sendForPayment = () => {
        dispatch(startLoader());
        dispatch(sendFacturePaymentAction(id));
    }

    const isUserDoctor = () => {
        let profls = currentUser.menus?.map((menu) => { return menu.code});
        return profls?.includes("MENADMIN001");
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    useEffect(() => {
        if(hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'VIEWADMINFACTURE')){
            dispatch(startLoader());
            dispatch(getFactureAction(id));
            dispatch(getMotifsExclusionAction());
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        dispatch(startLoader());
        dispatch(getFactureAction(id));
    }, [successMessage, errorMessage])


    return (
        <Fragment> 
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading && 
                <>
                    <div className="form-head d-flex mb-3 mb-md-4 align-items-start justify-content-between">
                        <div className="mr-auto d-none d-lg-block">
                            {hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'PRINTFACTURE') && <button
                            onClick={e => handlePrint()}
                            className="btn btn-primary btn-rounded mr-3"
                            >
                            {t('common.print')}
                            </button>}
                            
                            {hasMenu('MENPRESTATAIREADMIN') && canDo('MENPRESTATAIREADMIN', 'VIEWPRESTATIREDETAIL') && <Link
                                to={"/provider-view/"+facture.prestataireId}
                                target="_blank"
                                className="btn btn-primary btn-rounded"
                            >
                                <i className="la la-clinic-medical"></i>
                                {t('dashboard.provider')} 
                            </Link>}
                        </div>
                        {hasMenu('MENFACTUREADMIN') && canDo('MENFACTUREADMIN', 'SENDFACTUREPAIEMENT') && facture.statusPaiement === STATUSES.nonPaye && facture.isClose && <div className="mr-auto d-none d-lg-block">
                            <button
                            onClick={e => sendForPayment()}
                            className="btn btn-success btn-rounded mr-3"
                            >
                                {t('common.send-for-payment')}
                            </button>
                        </div>}
                    </div>

                    <FactureTemplate facture={facture} history={history} t={t} motifExclusion={motifExclusion} currentUser={currentUser} ref={componentRef} dispatch={dispatch} hasMenu={hasMenu} canDo={canDo} />
                    <div style={{display: 'none'}}>
                        <FacturePrintTemplate facture={facture} bordId={id} t={t} ref={componentRef} />
                    </div>
                </>
            }
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        facture: state.facturation.facture,
        motifExclusion: state.facturation.motifExclusion,
        successMessage: state.facturation.successMessage,
        errorMessage: state.facturation.errorMessage,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(FacturationDetails);
