import React, { useState, useEffect } from 'react';
import { Badge, Dropdown, Button, ListGroup } from "react-bootstrap";
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect, useDispatch } from 'react-redux';
import { searchDrugsAction } from '../../../../../store/actions/ConsultationActions';
import Select from 'react-select';
import './ConsultationStyle.css';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const PharmacyForm = (props) => {
    
    const dispatch = useDispatch();
    let remboursement = JSON.parse(localStorage.getItem("remboursement"));

    const [drugs, setDrugs] = useState((remboursement && remboursement.pharmacie) ? remboursement.pharmacie : []);
    
    const [keyword, setKeyword] = useState("");

    const selectExam = (e, val) => {
        e.preventDefault();
        let selectedExam = props.drugList.filter(ex => {return ex.code === val.code})[0];
        if(drugs.filter(act => {return act.code === selectedExam.code}).length === 0) {
            setDrugs([...drugs, selectedExam]);
            saveUpdates();
        }
    }

    const removeElt = (val, e, type) => {
        if(e.type === "click" && type === "remove") {
            setDrugs(drugs.filter(diag => {return diag.code !== val}));
            saveUpdates();
        }
    }

    const saveUpdates = () => {
        let remboursement = JSON.parse(localStorage.getItem("remboursement"));
        remboursement.pharmacie = drugs.map(exam => {
            let provider = props.providers.filter(prov => {return prov.providerName === exam.providerName});
            exam.providerId = provider.length > 0 ? provider[0].id : null;
            return exam;
        });
        localStorage.setItem("remboursement", JSON.stringify(remboursement));
    }

    useEffect(() => {
        if(keyword.trim().length >= 3){
            dispatch(searchDrugsAction(keyword));
        }else{
            dispatch(searchDrugsAction('@@@'));
        }
    }, [keyword]);

    /*const saveUpdates = () => {
        let datas = {
            drugs,
        };
        let consultation = JSON.parse(localStorage.getItem("consultation"));
        consultation.medocs = drugs;
        localStorage.setItem("consultation", JSON.stringify(consultation));
    }*/

    return (
        <>
          <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                  <div className='card'>
                      <div className='card-header'>
                          <h4 className='card-title'>Prescription Pharmaceutique</h4>
                      </div>
                      <div className='card-body'>
                          <div className='basic-form'>
                              <form onSubmit={(e) => e.preventDefault()}>
                                  <div className='form-group'>
                                      <div className='row'>
                                          <div className='col-4'>
                                              <div className='form-group'>
                                                  <label className='mb-1 '>
                                                      <strong>Recherche le médicament</strong>
                                                  </label>
                                                  <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-4' /> 
  
                                                  <PerfectScrollbar
                                                      id="DZ_W_Todo2"
                                                      className="widget-media dz-scroll ps ps--active-y height300"
                                                  >
                                                      <div className="basic-list-group">
                                                          <ListGroup>
                                                              {props.drugList.map((disease, j) => {
                                                                  return <ListGroup.Item
                                                                      action
                                                                      active={drugs.filter(exam => {return exam.code === disease.code}).length > 0}
                                                                      key={j}
                                                                      onClick={e => selectExam(e, disease)}
                                                                      className="flex-column align-items-start"
                                                                      >
                                                                      <div className={"d-flex w-100 justify-content-between"}>
                                                                          <h6 className={drugs.filter(exam => {return exam.code === disease.code}).length > 0 ? "text-white" : "text-dark"} >
                                                                              {disease.code} - <small>{disease.name}</small>
                                                                              <small className={'text-' + (disease.coverage ? 'danger' : 'success')}>{disease.coverage ? '(Exclu)' : '(Couvert)'}</small>
                                                                          </h6>
                                                                      </div>
                                                                      </ListGroup.Item>
                                                                  })
                                                              }
                                                          </ListGroup>
                                                      </div>
                                                  </PerfectScrollbar>
                                              </div>
                                          </div>
                                          <div className='col-8'>
                                              <h5 className='mt-4 text-primary'>médicaments</h5>
                                              <div className="border-top mt-2 mb-4"></div>
                                              {drugs.map((diag, i) => {
                                                  return (
                                                      <div key={i}>
                                                          <div className='row justify-content-between align-items-center'>
                                                              <h5 style={{'maxWidth': '600px !important'}}>{diag.code} {' - '} <small>{diag.name}</small></h5>
                                                              <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                  onClick={(e) => removeElt(diag.code, e, "remove")}
                                                              >
                                                                  <i className="fa fa-close"></i>
                                                              </button>
                                                          </div>
                                                          <div className="row">
                                                              <div className="col-lg-4 mb-2">
                                                                  <div className="form-group">
                                                                      <label className='mb-1 '>
                                                                          <strong>{t('dashboard.provider')}</strong>
                                                                      </label>
                                                                      <TextInput
                                                                          className='form-control custom-text-input'
                                                                          Component="input"
                                                                          trigger={""}
                                                                          value={diag.providerName}
                                                                          placeholder="Entrer un prestataire"
                                                                          onChange={val => {
                                                                              setDrugs(drugs.map(exam => {
                                                                                  if(exam.id === diag.id) {
                                                                                      exam.providerName = val;
                                                                                  }
                                                                                  return exam
                                                                              })); 
                                                                              saveUpdates();
                                                                          }}
                                                                          options={props.providers.map(prov => {return prov.providerName})}
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-lg-4 mb-2">
                                                                  <div className="form-group">
                                                                      <label className='mb-1 '>
                                                                          <strong>{t('common.price')}</strong>
                                                                      </label>
                                                                      <input type='text' className='form-control'
                                                                          value={diag.consultationPrix}
                                                                          required
                                                                          onChange={(e) => {
                                                                            setDrugs(drugs.map(exam => {
                                                                                  if(exam.id === diag.id) {
                                                                                      exam.consultationPrix = e.target.value;
                                                                                  }
                                                                                  return exam
                                                                              }));
                                                                              saveUpdates();
                                                                          }}
                                                                          />
                                                                  </div>
                                                              </div>
                                                              <div className="col-lg-4 mb-2">
                                                                  <div className="form-group">
                                                                      <label className='mb-1 '>
                                                                          <strong>Date</strong>
                                                                      </label>
                                                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                          <DateTimePicker
                                                                              autoOk
                                                                              label=""
                                                                              clearable
                                                                              format="dd/MM/yyyy hh:mm"
                                                                              value={diag.consultationDate}
                                                                              disableFuture
                                                                              //disabled={showLoading}
                                                                              required
                                                                              onChange={val => {
                                                                                setDrugs(drugs.map(exam => {
                                                                                      if(exam.id === diag.id) {
                                                                                          exam.consultationDate = val;
                                                                                      }
                                                                                      return exam
                                                                                  }));
                                                                                  saveUpdates();
                                                                              }}
                                                                          />
                                                                      </MuiPickersUtilsProvider>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )
                                              })}
                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </>
      )


    /*return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Prescription Pharmacie</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='row '>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Choisir le médicament</strong>
                                                </label>
                                                <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-2' /> 

                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className={"widget-media dz-scroll ps ps--active-y "+ (props.drugList.length > 0 ? "height300" : "height0")}
                                                >
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {props.drugList.map((disease, j) => {
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={drugs.includes(disease)}
                                                                    key={j}
                                                                    onClick={e => addDrugs(e, disease)}
                                                                    className="flex-column align-items-start"
                                                                    >
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <span className="text-dark" >
                                                                            {disease.name}
                                                                        </span>
                                                                        
                                                                        <span>[ {disease.code} ]</span>
                                                                    </div>
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                            
                                            <PerfectScrollbar
                                                id="DZ_W_Todo2"
                                                className="widget-media dz-scroll ps ps--active-y height300 text-center"
                                            >
                                                {drugs.length === 0 && <span className='text-light'>Ajouter des médicaments...</span>}
                                                {drugs.length > 0 && 
                                                    
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {drugs.map((drug, j) => {
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={drug.active}
                                                                    key={j}
                                                                    className="flex-column align-items-start"
                                                                    onClick={(e) => e.preventDefault()}
                                                                    >
                                                                    <div className="d-flex w-100 justify-content-between">
                                                                        <h5 className="text-dark" 
                                                                        onClick={(e) => focusOnDrug(drug.code, e)}>
                                                                            {drug.name}
                                                                            {' '}
                                                                            <small className={'text-' + (drug.coverage ? 'danger' : 'success')}>{drug.coverage ? '(Exclu)' : '(Couvert)'}</small>
                                                                        </h5>
                                                                        
                                                                        <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                            onClick={(e) => removeDrug(drug.code)}
                                                                        >
                                                                            <i className="fa fa-close"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div className='col-2 input-group mb-3 mx-0 input-secondary'>
                                                                        <input
                                                                            type='number'
                                                                            value={drug.quantity}
                                                                            className='form-control'
                                                                            onChange={(e) => setDrugs(drugs.map(drg => { 
                                                                                if(drg.code === drug.code){
                                                                                    drg.quantity = e.target.value
                                                                                }
                                                                                return drg;
                                                                            }))}
                                                                        />
                                                                        <div className='input-group-append'>
                                                                            <span className='input-group-text'>{drug.unit}</span>
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-1">
                                                                        {drug.posologie.map((pos, i) => {
                                                                            return <Badge variant="dark light mr-1 mb-1" key={i}>
                                                                                <span>{composePosologie(pos)}</span>
                                                                                <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                                    onClick={(e) => removePosologie(e, drug.code, i)}
                                                                                >
                                                                                    <i className="fa fa-close"></i>
                                                                                </button>
                                                                            </Badge>
                                                                        })
                                                                        }
                                                                    </p>
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                }
                                            </PerfectScrollbar>

                                            <div className='row mt-5 px-3'>
                                                <Badge variant="dark light mr-1 mb-1">
                                                    <span className='text-danger'>{composePosologie({quantite,unit,frequence,periode, duration,durationUnit,repas: repas.value,voie: voie.value,})}</span>
                                                    <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                        
                                                    >
                                                        <i className="fa fa-heart"></i>
                                                    </button>
                                                </Badge>
                                            </div>

                                            <div className='row mt-1 mb-1'>

                                                <div className='col-2 mb-1'>
                                                    <label className='mb-1 '>
                                                        <strong>Quantité</strong>
                                                    </label>
                                                    <div className='input-group'>
                                                        <input
                                                            value={quantite}
                                                            type='number'
                                                            min='0.1'
                                                            width={"50px"}
                                                            className='form-control input-group-prepend'
                                                            onChange={e => setQuantite(e.target.value)}
                                                        />
                                                        
                                                        <Dropdown className='input-group-prepend'>
                                                            <Dropdown.Toggle
                                                                variant=''
                                                                className='btn btn-primary p-1 dropdown-toggle'
                                                                type='button'
                                                                data-toggle='dropdown'
                                                            >
                                                                {unit}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className='dropdown-menu'>
                                                                { units.map(unit => { 
                                                                    return <Dropdown.Item className='dropdown-item' to='#' onClick={(e) => setUnit(unit.value)}>{unit.label}</Dropdown.Item>
                                                                    })
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>

                                                <div className='col-3 mb-1'>
                                                    <label className='mb-1 '>
                                                        <strong>Fréquence</strong>
                                                    </label>
                                                    <div className='input-group'>
                                                        <input
                                                            value={frequence}
                                                            type='number'
                                                            min='1'
                                                            disabled={!periode.includes("heure") && !periode.includes("intervalle d'heure")}
                                                            className='form-control input-default '
                                                            onChange={e => setFrequence(e.target.value)}
                                                        />
                                                        
                                                        <DropdownMultiselect
                                                            className='input-group-prepend'
                                                            selected={periode}
                                                            buttonClass='btn btn-primary px-2 rounded-0'
                                                            options={frequency.map(freq => {return freq.value})}
                                                            handleOnChange={managePeriod}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-2 mb-1'>
                                                    <label className='mb-1 '>
                                                        <strong>Durée</strong>
                                                    </label>
                                                    <div className='input-group'>
                                                        <input
                                                            value={duration}
                                                            type='number'
                                                            min='1'
                                                            className='form-control input-default '
                                                            onChange={e => setDuration(e.target.value)}
                                                        />
                                                        
                                                        <Dropdown className='input-group-prepend'>
                                                            <Dropdown.Toggle
                                                                variant=''
                                                                className='btn btn-primary p-1 dropdown-toggle'
                                                                type='button'
                                                                data-toggle='dropdown'
                                                            >
                                                                {durationUnit}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className='dropdown-menu'>
                                                                { durationUnits.map(unit => { 
                                                                    return <Dropdown.Item className='dropdown-item' to='#' onClick={(e) => setDurationUnit(unit.value)}>{unit.label}</Dropdown.Item>
                                                                    })
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>

                                                <div className='col-2 mb-1'>
                                                    <label className='mb-1 '>
                                                        <strong>Repas</strong>
                                                    </label>
                                                    <Select
                                                        selected={repas}
                                                        onChange={setRepas}
                                                        options={meals}
                                                        style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-2 mb-1'>
                                                    <label className='mb-1 '>
                                                        <strong>Voie administration</strong>
                                                    </label>
                                                    <Select
                                                        selected={voie}
                                                        onChange={setVoie}
                                                        options={ways}
                                                        style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                        }}
                                                    />
                                                </div>
                                                
                                                <div className='col-1 mb-1'>
                                                    <label className='mb-1 text-white'>
                                                        <strong>Ajouter</strong>
                                                    </label>
                                                    <Button variant='primary' onClick={e => addPosologie(e)}>
                                                        <i className='fa fa-check' />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )*/
}
const mapStateToProps = (state) => {
    return {
        drugList: state.consultation.drugs,
        providers: state.provider.providers,
    };
};

export default connect(mapStateToProps)(PharmacyForm);
