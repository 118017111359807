import React, { Fragment,useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import SelectSearch from 'react-select-search';
import swal from "sweetalert";
import { Row, Col, Card, Tab, Nav, Button, Spinner } from 'react-bootstrap'
import { Link } from "react-router-dom";
import BasicDatatable from './DataTable';
import { loadingToggleAction,addActiviteAction,addCountryAction,
    addExclusionAction,addProfessionAction,addCityAction,getParameterAction, 
    addProvinceAction, addTypeExclusionAction, getDictionnaireDataAction,
    updateActiviteAction, updateCityAction, updateCountryAction, updateExclusionAction,
    updateProfessionAction, updateDictionaireAction, deleteCountryAction,
    deleteCityAction, deleteActiviteAction, deleteProfessionAction,
    deleteExclusionAction, deleteDictionaireAction
} from '../../../store/actions/AdministrationAction';
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { startLoader, importPaysFileUploadAction, importVilleFileUploadAction, importActiviteFileUploadAction,
    importProfessionFileUploadAction, importExclusionFileUploadAction, importProvincesFileUploadAction } from '../../../store/actions/ProviderActions';
import '../../../css/selectStyle.css';
import Select from 'react-select';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../DataTables/DataTableBase';
import { useTranslation } from "react-i18next";

const Parametrage = (props) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [idCountry, setIdCountry] = useState('')
    const [idProvince, setIdProvince] = useState('')
    const [idActivity, setIdActivity] = useState('')
    let errorsObj = { name: '', code: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [code, setCode] = useState('');
    const dispatch = useDispatch();
    const [nameEn, setNameEn] = useState('');
    const [nameFr, setNameFr] = useState('');
    const [typeExclusion, setTypeExclusion] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [dataUpdateId, setDataUpdateId] = useState(null);
    const [file, setFile] = useState(null);
    const header =["code","Nom","Date de création","Action"];

    const [paysFilters, setPaysFilters] = useState(null);
    const [villeFilters, setVilleFilters] = useState(null);
    const [secAcFilters, setSecAcFilters] = useState(null);
    const [typExcFilters, setTypExcFilters] = useState(null);
    const [provinceFilters, setProvinceFilters] = useState(null);
    const [acFilters, setAcFilters] = useState(null);
    const [motifExcFilters, setMotifExcFilters] = useState(null);
    const [globalPaysFilterValue, setGlobalPaysFilterValue] = useState('');
    const [globalVilleFilterValue, setGlobalVilleFilterValue] = useState('');
    const [globalSecAcFilterValue, setGlobalSecAcFilterValue] = useState('');
    const [globalExcFilterValue, setGlobalExcFilterValue] = useState('');
    const [globalProvinceFilterValue, setGlobalProvinceFilterValue] = useState('');
    const [globalAcFilterValue, setGlobalAcFilterValue] = useState('');
    const [globalMotifExcFilterValue, setGlobalMotifExcFilterValue] = useState('');

    const initFilters = () => {
        setPaysFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setVilleFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setSecAcFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setTypExcFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setProvinceFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setAcFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setMotifExcFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            //code: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalPaysFilterValue("");
        setGlobalVilleFilterValue("");
        setGlobalSecAcFilterValue("");
        setGlobalExcFilterValue("");
        setGlobalProvinceFilterValue("");
        setGlobalAcFilterValue("");
        setGlobalMotifExcFilterValue("");
    };

    const renderHeader = (type) => {
        switch (type){
            case 'pays':
                return (
                    <div className="flex justify-content-between">
                        <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalPaysFilterValue} onChange={(e) => onGlobalFilterChange(e, 'pays')} placeholder={t('common.search')}/>
                        </span>
                    </div>
                );
            case 'ville':
                return (
                    <div className="flex justify-content-between">
                        <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalVilleFilterValue} onChange={(e) => onGlobalFilterChange(e, 'ville')} placeholder={t('common.search')}/>
                        </span>
                    </div>
                );
            
            case 'secteur-activite':
                return (
                    <div className="flex justify-content-between">
                        <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalSecAcFilterValue} onChange={(e) => onGlobalFilterChange(e, 'secteur-activite')} placeholder={t('common.search')}/>
                        </span>
                    </div>
                );
            
            case 'exclusion':
                return (
                    <div className="flex justify-content-between">
                        <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalExcFilterValue} onChange={(e) => onGlobalFilterChange(e, 'exclusion')} placeholder={t('common.search')}/>
                        </span>
                    </div>
                );
            
            case 'province':
                return (
                    <div className="flex justify-content-between">
                        <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalProvinceFilterValue} onChange={(e) => onGlobalFilterChange(e, 'province')} placeholder={t('common.search')}/>
                        </span>
                    </div>
                );
            
            case 'activite':
                return (
                    <div className="flex justify-content-between">
                        <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalAcFilterValue} onChange={(e) => onGlobalFilterChange(e, 'activite')} placeholder={t('common.search')}/>
                        </span>
                    </div>
                );
            default: 
                return (
                    <div className="flex justify-content-between">
                        <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalMotifExcFilterValue} onChange={(e) => onGlobalFilterChange(e, 'motif-exclusion')} placeholder={t('common.search')}/>
                        </span>
                    </div>
                );
        }
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = (type) => {
        switch (type){
            case 'pays':
                return [
                    //{name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'name', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: data => getActionsTemplate(data, 'pays')},
                ]
            case 'ville':
                return [
                    //{name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'name', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: data => getActionsTemplate(data, 'ville')},
                ]
            
            case 'secteur-activite':
                return [
                    //{name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: data => getActionsTemplate(data, 'secteur-activite')},
                ]
            
            case 'exclusion':
                return [
                    //{name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: data => getActionsTemplate(data, 'exclusion')},
                ]
            
            case 'province':
                return [
                    //{name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: data => getActionsTemplate(data, 'province')},
                ]
            
            case 'activite':
                return [
                    //{name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: data => getActionsTemplate(data, 'activite')},
                ]
            default: 
                return [
                    //{name: 'code', headerName: "Code", filterPlaceholder: 'Filtrer par code', style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
                    {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t('common.created-on'), filterPlaceholder: t('common.filter-by-creation'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: data => getActionsTemplate(data, 'motif d\'exclusion')},
                ]
        }
    };

    const onGlobalFilterChange = (e, type) => {
        const value = e.target.value;

        switch (type){
            case 'pays':
                let _filtersP = { ...paysFilters };

                _filtersP['global'].value = value;

                setPaysFilters(_filtersP);
                setGlobalPaysFilterValue(value);
                return;
            case 'ville':
                let _filtersV = { ...villeFilters };

                _filtersV['global'].value = value;

                setVilleFilters(_filtersV);
                setGlobalVilleFilterValue(value);
                return;
            
            case 'secteur-activite':
                let _filtersS = { ...secAcFilters };

                _filtersS['global'].value = value;

                setSecAcFilters(_filtersS);
                setGlobalSecAcFilterValue(value);
                return;
            
            case 'exclusion':
                let _filtersE = { ...typExcFilters };

                _filtersE['global'].value = value;

                setTypExcFilters(_filtersE);
                setGlobalExcFilterValue(value);
                return;
            
            case 'province':
                let _filtersPr = { ...provinceFilters };

                _filtersPr['global'].value = value;

                setProvinceFilters(_filtersPr);
                setGlobalProvinceFilterValue(value);
                return;
            
            case 'activite':
                let _filtersA = { ...acFilters };

                _filtersA['global'].value = value;

                setAcFilters(_filtersA);
                setGlobalAcFilterValue(value);
                return;
            default: 
                let _filtersM = { ...motifExcFilters };

                _filtersM['global'].value = value;

                setMotifExcFilters(_filtersM);
                setGlobalMotifExcFilterValue(value);
                return;
        }
    };

    const getActionsTemplate = (rowData, type) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAM') && canDo('MENPARAM', 'UPDATEPARAM') && <Link href="#" to="#" onClick={e => updateData(rowData, type)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
            {hasMenu('MENPARAM') && canDo('MENPARAM', 'DELETEPARAM') && <Link href="#" to="#" title="Close"
                onClick={() =>
                    swal({
                    title: t('common.delete-the')+type+" "+(rowData.nameFr ? rowData.nameFr : rowData.name)+" ?",
                    text:
                        t('common.once')+type+t('common.content-deleted'),
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((willDelete) => {
                    if (willDelete) {
                        let data = {
                            type: type,
                            id: rowData.id
                        }
                        if(type === 'pays'){
                            dispatch(deleteCountryAction(rowData.id));
                        }else if(type === 'ville'){
                            dispatch(deleteCityAction(rowData.id, rowData.countryId));
                        }else if(type === 'secteur-activite'){
                            dispatch(deleteActiviteAction(rowData.id));
                        }else if(type === 'activite'){
                            dispatch(deleteProfessionAction(rowData.id, rowData.activityAreaId));
                        }else if(type === 'motif d\'exclusion'){
                            dispatch(deleteExclusionAction(rowData.id));
                        }else if(type === 'exclusion'){
                            dispatch(deleteDictionaireAction(rowData.id, 'type-exclusion'));
                        }else{
                            dispatch(deleteDictionaireAction(rowData.id, 'province'));
                        }
                    }
                    })
                }>
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const updateData = (data, type) => {
        if(data.nameFr){
            setNameFr(data.nameFr);
        }

        if(data.nameEn){
            setNameFr(data.nameEn);
        }
        
        if(data.name){
            setName(data.name);
        }
        
        if(data.countryId){
            setIdCountry(data.countryId);
        }
        
        if(data.province && data.province.id){
            setIdProvince(data.province.id);
        }
        
        if(data.activityAreaId){
            setIdActivity(data.activityAreaId);
        }
        
        if(data.code){
            setCode(data.code);
        }
        
        if(data.typeExclusion && data.typeExclusion.id){
            setTypeExclusion(data.typeExclusion.id);
        }
        setUpdateMode(true);
        setDataUpdateId(data.id);
        window.scrollTo(0, 0);
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENPARAM') && canDo('MENPARAM', 'VIEWPARAM')){
            initFilters();
            dispatch(loadingToggleAction());
            dispatch(getParameterAction());
            dispatch(getDictionnaireDataAction('type-exclusion'));
            dispatch(getDictionnaireDataAction('province'));
        }else{
            props.history.push('/access-denied');
        }
     }, []);

     useEffect(() => {
        setUpdateMode('');
        setDataUpdateId(null);
        setName('');
        setCode('');
        setIdCountry('');
        setIdProvince('');
        setIdActivity('');
        errorsObj = { name: '', code: '' };
        setErrors('');
        setNameEn('');
        setNameFr('');
        setTypeExclusion('');
        setFile(null);
        window.scrollTo(0, document.body.scrollHeight);
     }, [props.admin]);

    const abortUpdate = () => {
        setUpdateMode('');
        setDataUpdateId(null);
        setName('');
        setCode('');
        setIdCountry('');
        setIdProvince('');
        setIdActivity('');
        errorsObj = { name: '', code: '' };
        setErrors('');
        setNameEn('');
        setNameFr('');
        setTypeExclusion('');
        setFile(null);
        window.scrollTo(0, document.body.scrollHeight);
    }

    let paysOption = props.admin.countries || [];
    let activiiesOption = props.admin.activities || [];
    let exclusions = props.admin.exclusions || [];
    let filterCountries = paysOption.filter(country => { 
        return country.id === idCountry;
    });
    let filterActivities = activiiesOption.filter(activity => { 
        return activity.id === idActivity;
    });

    
    function selectFile(event) {
        setFile(event.target.files[0]);
    }


    function onCountry(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importPaysFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (name === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
		dispatch(loadingToggleAction(true));

        if(updateMode) {
            let obj = { name: name, code: code };
            dispatch(updateCountryAction(dataUpdateId, obj));
        }else{
            let obj = { name: name, code: code };
            dispatch(addCountryAction(obj));
        }
    }
    function onCity(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importVilleFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (name === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }
        if (idCountry === '') {
            errorObj.idCountry = 'Pays est réqui';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
            return ;
		}
		dispatch(loadingToggleAction(true));

        if(updateMode) {
            let obj = { name: name, code: code, countryId: idCountry, provinceId: idProvince};
            dispatch(updateCityAction(dataUpdateId, obj));
        }else{
            let obj = { name: name, code: code, countryId: idCountry, provinceId: idProvince};
            dispatch(addCityAction(obj));
        }
    }
    function onProfession(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importProfessionFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.nameFr =t('common.name-required');
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields')+JSON.stringify(errorObj), "infos");
			return ;
		}
		dispatch(loadingToggleAction(true));

        if(updateMode) {
            let obj = { nameFr: nameFr, nameEn: nameFr, code: code, activityAreaId: idActivity.length === 0 ? activiiesOption[0]?.id : idActivity };
            dispatch(updateProfessionAction(dataUpdateId, obj));
        }else{
            let obj = { nameFr: nameFr, nameEn: nameFr, code: code, activityAreaId: idActivity.length === 0 ? activiiesOption[0]?.id : idActivity };
            dispatch(addProfessionAction(obj));
        }
    }
    function onExclusion(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importExclusionFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.name =t('common.name-required');
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
		dispatch(loadingToggleAction(true));

        if(updateMode) {
            let obj = { nameFr: nameFr, nameEn: nameFr, code: code, typeExclusionId: typeExclusion };
            dispatch(updateExclusionAction(dataUpdateId, obj));
        }else{
            let obj = { nameFr: nameFr, nameEn: nameFr, code: code, typeExclusionId: typeExclusion };
            dispatch(addExclusionAction(obj));
        }
    }
    function onActivity(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importActiviteFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code =t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.nameFr =t('common.name-required');
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
		dispatch(loadingToggleAction(true));

        if(updateMode) {
            let obj = { nameFr: nameFr, nameEn: nameFr, code: code };
            dispatch(updateActiviteAction(dataUpdateId, obj));
        }else{
            let obj = { nameFr: nameFr, nameEn: nameFr, code: code };
            dispatch(addActiviteAction(obj));
        }
    }
    function onTypeExclusion(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importProvincesFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code = t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.name = t('common.name-required');
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
		dispatch(loadingToggleAction(true));

        if(updateMode) {
            let obj = { nameFr, code, nameEn: nameFr, reference: 'type-exclusion' };
            dispatch(updateDictionaireAction(dataUpdateId, obj, 'type-exclusion'));
        }else{
            let obj = { nameFr, code, nameEn, reference: 'type-exclusion' };
            dispatch(addTypeExclusionAction(obj));
        }
    }

    function onProvince(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if(file){
            dispatch(startLoader());
            dispatch(importProvincesFileUploadAction(file));
            return ;
        }
        if (code === '') {
            errorObj.code = t('common.code-required');
            error = true;
        }
        if (nameFr === '') {
            errorObj.name = t('common.name-required');
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            swal('Oops', t('common.check-form-fields'), "infos");
			return ;
		}
		dispatch(loadingToggleAction(true));

        if(updateMode) {
            let obj = { nameFr: nameFr, nameEn: nameFr, reference: 'province', code };
            dispatch(updateDictionaireAction(dataUpdateId, obj, 'province'));
        }else{
            let obj = { nameFr: nameFr, nameEn: nameFr, reference: 'province', code };
            dispatch(addProvinceAction(obj)); 
        }
    }
    const paysForm = <>
        <div className="">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{updateMode ? t('common.modify-country')+name : t('common.new-country')}</h4>
                </div>
                <div className='card-body'>
                    <div className='basic-form'>
                        <form onSubmit={onCountry}>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.country-code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={code}
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='CM'
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.country-code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={name}
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder='Cameroun'
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <hr />
                            <h4 className='text-center'>{t('common.import-csv')}</h4>
                            <div className='input-group mb-3'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>CSV</span>
                                </div>
                                <div className='custom-file'>
                                    <input type='file' className='custom-file-input' onChange={selectFile} />
                                    <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                </div>
                            </div>
                            <div className='form-group align-items-center justify-content-center'>
                                { (props.showLoading || props.showProvLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showProvLoading && <div>
                                        <button type='submit' className='mr-2 btn btn-primary'>
                                            {t('common.save')}
                                        </button>
                                        {updateMode && <button type='button' onClick={e => abortUpdate()} className='btn btn-primary'>
                                        {t('common.cancel')}
                                        </button>}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <DataTableBase 
            data={paysOption.map(elt => {
                elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                return elt;
            })}
            emptyMessage="Aucun pays trouvé" 
            filters={paysFilters}  
            globalFiltersFields={['name', 'createdAt']}
            header={renderHeader('pays')}
            columns={setColumns('pays')}
            exportColumns={[
                //{ title: 'Code', dataKey: 'code' },
                { title: t('common.name'), dataKey: 'name' },
                { title: t('common.create-date'), dataKey: 'createdAt' },
            ]}
            rows={10} 
            showExport={true}
            exportFilename={"pays"}
            loading={props.showLoading}
            lazy={false}
        />
        
    </>
    const VilleForm = <>
        <div className="">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{updateMode ? t('common.modify-city')+name :  t('common.new-city')}</h4>
                </div>
                <div className='card-body'>
                    <div className='basic-form'>
                        <form onSubmit={onCity}>

                        <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.country')}</label>
                                <div className='col-sm-9'>
                                    <Select
                                        className="select-search"
                                        placeholder={t('common.choose-country')}
                                        value={paysOption.filter(elt => elt.id === idCountry)?.map(elt => {return {label: elt.name, value: elt.id}})}
                                        onChange={(val) => setIdCountry(val.value)}
                                        disabled={props.showLoading}
                                        required
                                        options={paysOption.map(res => {return {label: res.name, value: res.id}})}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.region')}</label>
                                <div className='col-sm-9'>
                                    <Select
                                        className="select-search"
                                        placeholder={t('common.choose-region')}
                                        value={props.provinces.filter(elt => elt.id === idProvince)?.map(elt => {return {label: elt.nameFr, value: elt.id}})}
                                        onChange={(val) => setIdProvince(val.value)}
                                        disabled={props.showLoading}
                                        required
                                        options={props.provinces.map(res => {return {label: res.nameFr, value: res.id}})}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={code}
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='DLA'
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.label')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={name}
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder='Douala'
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <hr />
                            <h4 className='text-center'>{t('common.import-csv-city')}</h4>
                            <div className='input-group mb-3'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>CSV</span>
                                </div>
                                <div className='custom-file'>
                                    <input type='file' className='custom-file-input' onChange={selectFile} />
                                    <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                </div>
                            </div>
                            <div className='form-group align-items-center justify-content-center'>
                                { (props.showLoading || props.showProvLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showProvLoading && <div>
                                        <button type='submit' className='mr-2 btn btn-primary'>
                                            {t('common.save')}
                                        </button>
                                        {updateMode && <button type='button' onClick={e => abortUpdate()} className='btn btn-primary'>
                                        {t('common.cancel')}
                                        </button>}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className='form-group row'>
                <div className='col-sm-3'>
                    <Select
                        className="select-search"
                        placeholder={t('common.choose-country')}
                        value={paysOption.filter(elt => elt.id === (idCountry ? idCountry : paysOption[0]?.id))?.map(elt => {return {label: elt.name, value: elt.id}})}
                        onChange={(val) => setIdCountry(val.value)}
                        disabled={props.showLoading}
                        required
                        options={paysOption.map(res => {return {label: res.name, value: res.id}})}
                        style={{
                            lineHeight: '40px',
                            color: '#7e7e7e',
                            paddingLeft: ' 15px',
                        }}
                    />
                </div>
            </div>
            <DataTableBase 
                data={idCountry ? (filterCountries.length > 0 ? 
                    filterCountries[0]?.city.map(elt => {
                        elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                        return elt;
                    }) :
                    []) : (paysOption.length > 0 ? paysOption[0]?.city.map(elt => {
                        elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                        return elt;
                    }) : [])
                }
                emptyMessage={t('common.no-city')} 
                filters={villeFilters} 
                globalFiltersFields={['name', 'createdAt']}
                header={renderHeader('ville')}
                columns={setColumns('ville')}
                exportColumns={[
                    //{ title: 'Code', dataKey: 'code' },
                    { title: t('common.name'), dataKey: 'name' },
                    { title: t('common.create-date'), dataKey: 'createdAt' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"villes"+(filterCountries.length ? '-'+filterCountries.name : '')}
                loading={props.showLoading}
                lazy={false}
            />
        </div>

    </>
    const ActivityForm = <>
        <div className="">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{updateMode ? t('common.modify-sector')+nameFr : t('common.new-sector')}</h4>
                </div>
                <div className='card-body'>
                    <div className='basic-form'>
                        <form onSubmit={onActivity}>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={code}
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='IT'
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.label')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={nameFr}
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder='Ingénieur Informatique'
                                        onChange={(e) => setNameFr(e.target.value)}
                                    />
                                </div>
                            </div>
                            <hr />
                            <h4 className='text-center'>{t('common.import-csv-activity')}</h4>
                            <div className='input-group mb-3'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>CSV</span>
                                </div>
                                <div className='custom-file'>
                                    <input type='file' className='custom-file-input' onChange={selectFile} />
                                    <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                </div>
                            </div>
                            <div className='form-group align-items-center justify-content-center'>
                                { (props.showLoading || props.showProvLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showProvLoading && <div>
                                        <button type='submit' className='mr-2 btn btn-primary'>
                                        {t('common.save')}
                                        </button>
                                        {updateMode && <button type='button' onClick={e => abortUpdate()} className='btn btn-primary'>
                                        {t('common.cancel')}
                                        </button>}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <DataTableBase 
            data={activiiesOption.map(elt => {
                elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                return elt;
            })}
            emptyMessage={t('common.no-sactivity')} 
            filters={secAcFilters} 
            globalFiltersFields={['name', 'createdAt']}
            header={renderHeader('secteur-activite')}
            columns={setColumns('secteur-activite')}
            exportColumns={[
                //{ title: 'Code', dataKey: 'code' },
                { title: t('common.name'), dataKey: 'name' },
                { title: t('common.create-date'), dataKey: 'createdAt' },
            ]}
            rows={10} 
            showExport={true}
            exportFilename={"secteurs-activité"}
            loading={props.showLoading}
            lazy={false}
        />
    </>
    const TypeExclusionForm = <>
        <div className="">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{updateMode ? t('common.update-exl')+nameFr : t('common.new-exl')}</h4>
                </div>
                <div className='card-body'>
                    <div className='basic-form'>
                        <form onSubmit={onTypeExclusion}>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={code}
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='IT'
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.name')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={nameFr}
                                        type='text'
                                        name='nameFr'
                                        className='form-control'
                                        placeholder='Ingénieur Informatique'
                                        onChange={(e) => setNameFr(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*<div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>Nom En</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={nameEn}
                                        type='text'
                                        name='nameEn'
                                        className='form-control'
                                        placeholder='Ingénieur Informatique'
                                        onChange={(e) => setNameEn(e.target.value)}
                                    />
                                </div>
                            </div>*/}
                            <hr />
                            <h4 className='text-center'>{t('common.import-csv-activity')}</h4>
                            <div className='input-group mb-3'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>CSV</span>
                                </div>
                                <div className='custom-file'>
                                    <input type='file' className='custom-file-input' onChange={selectFile} />
                                    <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                </div>
                            </div>
                            <div className='form-group align-items-center justify-content-center'>
                                { (props.showLoading || props.showProvLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showProvLoading && <div>
                                        <button type='submit' className='mr-2 btn btn-primary'>
                                           {t('common.save')}
                                        </button>
                                        {updateMode && <button type='button' onClick={e => abortUpdate()} className='btn btn-primary'>
                                           {t('common.cancel')}
                                        </button>}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <DataTableBase 
            data={props.typesExclusions.map(elt => {
                elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                return elt;
            }) || []}
            emptyMessage="Aucun type d'exclusion trouvé" 
            filters={typExcFilters} 
            globalFiltersFields={['name', 'createdAt']}
            header={renderHeader('exclusion')}
            columns={setColumns('exclusion')}
            exportColumns={[
                //{ title: 'Code', dataKey: 'code' },
                { title: t('common.name'), dataKey: 'name' },
                { title: t('common.create-date'), dataKey: 'createdAt' },
            ]}
            rows={10} 
            showExport={true}
            exportFilename={"types-exclusion"}
            loading={props.showLoading}
            lazy={false}
        />
    </>
    const ProvinceForm = <>
        <div className="">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{updateMode ? t('common.update-region')+nameFr : t('common.new-region')}</h4>
                </div>
                <div className='card-body'>
                    <div className='basic-form'>
                        <form onSubmit={onProvince}>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={code}
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='IT'
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.name')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={nameFr}
                                        type='text'
                                        name='nameFr'
                                        className='form-control'
                                        placeholder='Province'
                                        onChange={(e) => setNameFr(e.target.value)}
                                    />
                                </div>
                            </div>
                            <hr />
                            <h4 className='text-center'>{t('common.import-csv-activity')}</h4>
                            <div className='input-group mb-3'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>CSV</span>
                                </div>
                                <div className='custom-file'>
                                    <input type='file' className='custom-file-input' onChange={selectFile} />
                                    <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                </div>
                            </div>
                            <div className='form-group align-items-center justify-content-center'>
                                { (props.showLoading || props.showProvLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showProvLoading && <div>
                                        <button type='submit' className='mr-2 btn btn-primary'>
                                           {t('common.save')}
                                        </button>
                                        {updateMode && <button type='button' onClick={e => abortUpdate()} className='btn btn-primary'>
                                           {t('common.cancel')}
                                        </button>}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <DataTableBase 
            data={props.provinces.map(elt => {
                elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                return elt;
            }) || []}
            emptyMessage="Aucune province trouvée" 
            filters={provinceFilters} 
            globalFiltersFields={['nameFr', 'createdAt']}
            header={renderHeader('province')}
            columns={setColumns('province')}
            exportColumns={[
                //{ title: 'Code', dataKey: 'code' },
                { title: t('common.name'), dataKey: 'nameFr' },
                { title: t('common.create-date'), dataKey: 'createdAt' },
            ]}
            rows={10} 
            showExport={true}
            exportFilename={"provinces"}
            loading={props.showLoading}
            lazy={false}
        />
    </>
    const ProfessionForm = <>
        <div className="">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{updateMode ? t('common.update-job')+nameFr : t('common.new-job')}</h4>
                </div>
                <div className='card-body'>
                    <div className='basic-form'>
                        <form onSubmit={onProfession}>
                        <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'> {t("client.workingField")}</label>
                                <div className='col-sm-9'>
                                    <Select
                                        className="select-search"
                                        placeholder={t('client.chooseWorkingField')}
                                        value={activiiesOption.filter(elt => elt.id === (idActivity ? idActivity : activiiesOption[0]?.id))?.map(elt => {return {label: elt.nameFr, value: elt.id}})}
                                        onChange={(val) => setIdActivity(val.value)}
                                        disabled={props.showLoading}
                                        required
                                        options={activiiesOption.map(res => {return {label: res.nameFr, value: res.id}})}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={code}
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='DEV'
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.label')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={nameFr}
                                        type='text'
                                        name='name'
                                        className='form-control'
                                        placeholder='Développeur'
                                        onChange={(e) => setNameFr(e.target.value)}
                                    />
                                </div>
                            </div>
                            <hr />
                            <h4 className='text-center'>{t('common.import-csv-job')}</h4>
                            <div className='input-group mb-3'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>CSV</span>
                                </div>
                                <div className='custom-file'>
                                    <input type='file' className='custom-file-input' onChange={selectFile} />
                                    <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                </div>
                            </div>
                            <div className='form-group align-items-center justify-content-center'>
                                { (props.showLoading || props.showProvLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showProvLoading && <div>
                                        <button type='submit' className='mr-2 btn btn-primary'>
                                           {t('common.save')}
                                        </button>
                                        {updateMode && <button type='button' onClick={e => abortUpdate()} className='btn btn-primary'>
                                           {t('common.cancel')}
                                        </button>}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className='form-group row'>
            <div className='col-sm-3'>
                <Select
                    className="select-search"
                    placeholder={t('client.chooseWorkingField')}
                    value={activiiesOption.filter(elt => elt.id === (idActivity ? idActivity : activiiesOption[0]?.id))?.map(elt => {return {label: elt.nameFr, value: elt.id}})}
                    onChange={(val) => setIdActivity(val.value)}
                    disabled={props.showLoading}
                    required
                    options={activiiesOption.map(res => {return {label: res.nameFr, value: res.id}})}
                    style={{
                        lineHeight: '40px',
                        color: '#7e7e7e',
                        paddingLeft: ' 15px',
                    }}
                />
            </div>
        </div>
        
        <DataTableBase 
            data={idActivity ? (filterActivities.length>0 ?
                filterActivities[0]?.prefession.map(elt => {
                    elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                    return elt;}) : 
                []) : (activiiesOption.length>0 ?
                activiiesOption[0]?.prefession.map(elt => {
                    elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                    return elt;}) : 
                []
                )
            }
            emptyMessage={t('common.no-job')} 
            filters={acFilters} 
            globalFiltersFields={['nameFr', 'createdAt']}
            header={renderHeader('activite')}
            columns={setColumns('activite')}
            exportColumns={[
                //{ title: 'Code', dataKey: 'code' },
                { title: t('common.name'), dataKey: 'nameFr' },
                { title: t('common.create-date'), dataKey: 'createdAt' },
            ]}
            rows={10} 
            showExport={true}
            exportFilename={"professions"+(filterActivities.length>0 ? '-'+filterActivities.nameFr : '')}
            loading={props.showLoading}
            lazy={false}
        />
    </>
    const ExclusionForm = <>
        <div className="">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{updateMode ? t('common.update-exl-val')+nameFr : t('common.new-exl-val')}</h4>
                </div>
                <div className='card-body'>
                    <div className='basic-form'>
                        <form onSubmit={onExclusion}>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.type-exl')}</label>
                                <div className='col-sm-9'>
                                    <Select
                                        className="select-search"
                                        placeholder={t('common.choose-type')}
                                        value={props.typesExclusions?.filter(elt => elt.id === typeExclusion)?.map(elt => {return {label: elt.nameFr, value: elt.id}})}
                                        onChange={(val) => setTypeExclusion(val.value)}
                                        disabled={props.showLoading}
                                        required
                                        options={props.typesExclusions?.map(res => {return {label: res.nameFr, value: res.id}})}
                                        style={{
                                            lineHeight: '40px',
                                            color: '#7e7e7e',
                                            paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.code')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={code}
                                        type='text'
                                        name='code'
                                        className='form-control'
                                        placeholder='DEV'
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.label')}</label>
                                <div className='col-sm-9'>
                                    <input
                                        value={nameFr}
                                        type='text'
                                        name='nameFr'
                                        className='form-control'
                                        placeholder={t('common.uncovered-drug')}
                                        onChange={(e) => setNameFr(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/*<div className='form-group row'>
                                <label className='col-sm-3 col-form-label'>{t('common.label')} En</label>
                                <div className='col-sm-9'>
                                    <input
                                        type='text'
                                        name='nameEn'
                                        className='form-control'
                                        placeholder='Unhandled drug'
                                        onChange={(e) => setNameEn(e.target.value)}
                                    />
                                </div>
                            </div>*/}
                            <hr />
                            <h4 className='text-center'>{t('common.import-excl')}</h4>
                            <div className='input-group mb-3'>
                                <div className='input-group-prepend'>
                                    <span className='input-group-text'>CSV</span>
                                </div>
                                <div className='custom-file'>
                                    <input type='file' className='custom-file-input' onChange={selectFile} />
                                    <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                </div>
                            </div>
                            <div className='form-group align-items-center justify-content-center'>
                                { (props.showLoading || props.showProvLoading) && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">{t('common.await-text')}</span>
                                    </Button> }
                                { !props.showLoading && !props.showProvLoading && <div>
                                        <button type='submit' className='mr-2 btn btn-primary'>
                                           {t('common.save')}
                                        </button>
                                        {updateMode && <button type='button' onClick={e => abortUpdate()} className='btn btn-primary'>
                                           {t('common.cancel')}
                                        </button>}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <DataTableBase 
            data={exclusions.map(elt => {
                    elt.createdAt = new Date(elt.createdAt ? elt.createdAt : elt.created );
                    return elt;
                }
            )}
            emptyMessage={t('common.no-excl')} 
            filters={motifExcFilters}
            globalFiltersFields={['nameFr', 'createdAt']}
            header={renderHeader('motif-excl')}
            columns={setColumns('motif-excl')}
            exportColumns={[
                //{ title: 'Code', dataKey: 'code' },
                { title: t('common.name'), dataKey: 'nameFr' },
                { title: t('common.create-date'), dataKey: 'createdAt' },
            ]}
            rows={10} 
            showExport={true}
            exportFilename={"motifs-exclusion"}
            loading={props.showLoading}
            lazy={false}
        />
    </>
    const tabData = [
        {
            label: t('common.country'),
            name: 'pays',
            icon: 'globe',
            content: paysForm
        },
        {
            label: t('common.region'),
            name: 'province',
            icon: 'globe',
            content: ProvinceForm
        },
        {
            label: t('common.city'),
            name: 'ville',
            icon: 'city',
            content: VilleForm
        },
        {
            label: t('common.activity'),
            name: 'activité',
            icon: 'industry',
            content: ActivityForm
        },
        {
            label: t('common.job'),
            name: 'profession',
            icon: 'certificate',
            content: ProfessionForm
        },
        {
            label: t('common.typ-motif-exl'),
            name: 'types de motif exclusion',
            icon: 'ban',
            content: TypeExclusionForm
        },
        {
            label: t('common.motif-exl'),
            name: 'motif exclusion',
            icon: 'ban',
            content: ExclusionForm
        },
    ]

    const hasAccess = (type) => {
        switch(type){
            case 'pays':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'ACCESSPAYSPARAM')
            case 'province':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'ACCESSPROVINCEPARAM')
            case 'ville':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'ACCESSVILLEPARAM')
            case 'activité':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'ACCESSACTIVITEPARAM')
            case 'profession':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'ACCESSPROFESSIONPARAM')
            case 'types de motif exclusion':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'ACCESSTYPEMOTIFEXCLUPARAM')
            case 'motif exclusion':
                return hasMenu('MENPARAM') && canDo('MENPARAM', 'ACCESSMOTIFEXCLUPARAM')
            default:
                return false
            
        }
    }

    return (
        <Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title> {t("common.parameters")} </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {/* <!-- Nav tabs --> */}
                            <div className='custom-tab-1'>
                                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                    <Nav as='ul' className='nav-tabs'>
                                        {tabData.map((data, i) => (
                                            <Nav.Item as='li' key={i}>
                                                <Nav.Link disabled={updateMode || file || !hasAccess(data.name.toLowerCase())} eventKey={data.name.toLowerCase()}>
                                                    <i className={`la la-${data.icon} mr-2`} />
                                                    {data.label}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                    <Tab.Content className='pt-4'>
                                        {tabData.map((data, i) => (
                                            <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                <div>{data.content}</div>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.admin.errorMessage,
        successMessage: state.admin.successMessage,
        showLoading: state.admin.showLoading,
        admin: state.admin.administration,
        provinces: state.admin.provinces,
        typesExclusions: state.admin.typesExclusions,
        showProvLoading: state.provider.showLoading,
        currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(Parametrage);