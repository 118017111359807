import {updateClient, listClients, registerClientFace, identifyClientFace,
checkClientRegistered, getClient, createClient, getHistoIdentifications, 
partialEnroll, searchClients, getClientById} from '../../services/ClientAuthService';
import {verify} from '../../services/PatientService';
import { errorToastr, successToastr, warningToastr } from '../../Utils/ToastrMessage';
import uploadFiles from '../../services/BlobStorageService';
import { loadingToggleAction, identificationAssuAction } from './PatientAction';
import swal from "sweetalert";

export const REGISTER_CLIENT_CONFIRMED_ACTION = '[registerClient action] confirmed registerClient';
export const REGISTER_CLIENT_FAILED_ACTION = '[registerClient action] failed registerClient';
export const IDENTIFY_CLIENT_CONFIRMED_ACTION = '[identifyClient client action] confirmed identifyClient';
export const IDENTIFY_CLIENT_FAILED_ACTION = '[identifyClient client action] failed identifyClient';
export const LIST_CLIENT_CONFIRMED_ACTION = '[listClient client action] confirmed listClient';
export const LIST_CLIENT_FAILED_ACTION = '[listClient client action] failed listClient';
export const UPDATE_CLIENT_DATAS = '[updateClient client action] started listClient';
export const START_LOADER_ACTION = '[clientLoader action] started clientLoader';
export const CLIENT_REGISTER_CHECK = '[checkClientRegister client action] started checkClientRegister';
export const CLIENT_REGISTER_CHECK_FAILED = '[checkClientRegister action] failed checkClientRegister';
export const GET_CLIENT_CONFIRMED_ACTION = '[getClient client action] started checkgetClientClientRegister';
export const GET_CLIENT_FAILED_ACTION = '[getClient action] failed getClient';
export const GET_CLIENT_DATA_CONFIRMED_ACTION = '[getClientData action] failed getClientData';
export const GET_AUTH_HISTORY_CONFIRMED_ACTION = '[getAuthHistory action] confirmed getAuthHistory';
export const GET_AUTH_HISTORY_FAILED_ACTION = '[getAuthHistory action] failed getAuthHistory';
export const SEARCH_CLIENT_CONFIRMED_ACTION = '[searchClient action] failed searchClient'

var I18n = require('react-redux-i18n').I18n;

export const registerClientAction = (imageFile, clientId, clientData, history, imgSrc) => {
    return async (dispatch) => {
        verify(imgSrc)
        .then((response) => {
            errorToastr(I18n.t('client.register.error_face_enrolled')+res.data.username);
            dispatch(clientRegisterFailedAction("patient déjà enrôlé"));
        })
        .catch(async (error) => {
            if(error.response?.data?.statusCode === "ERR-FACE-NOT-EXIST" || error.response?.data?.statusCode === "ERR-COGNITIVE") {
                
                clientData.idClient = clientId;
                let resImg = await uploadFiles([imageFile], 'amp-providers-container');
                clientData.clientPhoto = resImg[0];
                createClient(clientData)
                .then((response) => {
                    if(error.response?.data?.statusCode === "ERR-COGNITIVE") {
                        warningToastr(I18n.t('client.register.error_no_face'));
                    }
                    
                    let client = response.data;

                    const faceClientFormData = new FormData();
                    faceClientFormData.append('image', imageFile);

                    const config = {     
                        headers: { 
                            'content-type': 'multipart/form-data',
                            'username': clientId,
                            'x-api-key': '1b101fdddd8f8d7f38dee64a9362cebb2d38e06cb8439622278f9584', 
                        }
                    }

                    registerClientFace(faceClientFormData, config).then((response) => {
                        successToastr(I18n.t('client.register.face_enroll_success'));
                        
                        dispatch(confirmedClientRegisterAction(client));
                        history.push('/client-search/');
                    }).catch((error) => {
                        warningToastr(I18n.t('client.register.face_enroll_error'));
                        
                        dispatch(confirmedClientRegisterAction(client));
                        //dispatch(clientRegisterFailedAction(error.response?.data?.message.message));
                        history.push('/client-search/');
                    })
                })
                .catch((error) => {
                    errorToastr(I18n.t('client.register.client_enroll_error')+error.response?.data?.message);
                    dispatch(clientRegisterFailedAction(error.response?.data?.message));
                });
            }else{
                errorToastr(I18n.t('client.register.face_verif_error')+error.response?.data?.message);
                dispatch(clientRegisterFailedAction(error.response?.data?.message));
            }

            
        });
    };
};

export const updateClientAction = (id, imageFile, clientId, clientData, history, imgSrc) => {
    return async (dispatch) => {
        /*verify(imgSrc)
        .then((response) => {
            errorToastr(I18n.t('client.register.error_face_enrolled')+res.data.username);
            dispatch(clientRegisterFailedAction("patient déjà enrôlé"));
        })
        .catch(async (error) => {
            if(error.response?.data?.statusCode === "ERR-FACE-NOT-EXIST" || error.response?.data?.statusCode === "ERR-COGNITIVE") {
                
                clientData.idClient = clientId;
                let resImg = await uploadFiles([imageFile], 'amp-providers-container');
                clientData.clientPhoto = resImg[0];*/
                clientData.idClient = clientId;
                updateClient(id, clientData)
                .then((response) => {
                    /*if(error.response?.data?.statusCode === "ERR-COGNITIVE") {
                        warningToastr(I18n.t('client.register.error_no_face'));
                    }*/
                    
                    let client = response.data;

                    successToastr("Enrôlement mis à jour avec succès");
                    dispatch(confirmedClientRegisterAction(client));
                    history.push('/assure-search');

                    /*const faceClientFormData = new FormData();
                    faceClientFormData.append('image', imageFile);

                    const config = {     
                        headers: { 
                            'content-type': 'multipart/form-data',
                            'username': clientId,
                            'x-api-key': '1b101fdddd8f8d7f38dee64a9362cebb2d38e06cb8439622278f9584', 
                        }
                    }

                    registerClientFace(faceClientFormData, config).then((response) => {
                        successToastr(I18n.t('client.register.face_enroll_success'));
                        
                        dispatch(confirmedClientRegisterAction(client));
                        history.push('/assure-search');
                    }).catch((error) => {
                        warningToastr(I18n.t('client.register.face_enroll_error'));
                        
                        dispatch(confirmedClientRegisterAction(client));
                        //dispatch(clientRegisterFailedAction(error.response?.data?.message.message));
                        history.push('/assure-search');
                    })*/
                })
                .catch((error) => {
                    errorToastr(I18n.t('client.register.client_enroll_error')+error.response?.data?.message);
                    dispatch(clientRegisterFailedAction(error.response?.data?.message));
                });
            /*}else{
                errorToastr(I18n.t('client.register.face_verif_error')+error.response?.data?.message);
                dispatch(clientRegisterFailedAction(error.response?.data?.message));
            }

            
        });*/
    };
};

export const searchClientsAction = (nomBenef, numPolice) => {
    return (dispatch) => {

        searchClients(nomBenef, numPolice)
        .then((response) => {
            dispatch(confirmedClientSearchListingAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('client.register.client_listing_error')+error.response?.data?.message);
            
            dispatch(clientListingFailedAction(error.response?.data?.message));
        });
    };
};

export const listClientsAction = (nomBenef, numPolice, numSouscrip) => {
    return (dispatch) => {

        listClients(nomBenef, numPolice, numSouscrip)
        .then((response) => {
            dispatch(confirmedClientListingAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('client.register.client_listing_error')+error.response?.data?.message);
            
            dispatch(clientListingFailedAction(error.response?.data?.message));
        });
    };
};

export const checkClientRegistrationAction = (id, history) => {

    return (dispatch) => {

        const config = {     
            headers: { 
                'content-type': 'multipart/form-data',
                'username': id,
                'x-api-key': '1b101fdddd8f8d7f38dee64a9362cebb2d38e06cb8439622278f9584', 
            }
        }

        checkClientRegistered(config).then((response) => {
            if(response.data.userFound === "true") {
                warningToastr(I18n.t('client.register.client_alread_enrolled_error'));
                /*swal({
                    title: "Renvoie des paramètres",
                    text:"Cet assuré a déjà été enrôlé. Souhaitez-vous lui renvoyer ses paramètre ?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    }).then((hasPaid) => {
                        if (hasPaid) {
                        }
                })*/
            }else{
                history.push('/client-register/'+id);
            }
            dispatch(confirmedClientRegisterCheckAction(response.data));
        }).catch((error) => {
            errorToastr(I18n.t('client.register.client_enroll_verif_error'));
            
            dispatch(clientRegisterCheckFailedAction(error.response?.data?.message));
        });
    };
};

export const checkClientRegistrationFromClientAction = (id, history) => {
    
    return (dispatch) => {

        getClient(id)
        .then((response) => {
            warningToastr(I18n.t('client.register.client_alread_enrolled_error')); 
            /*swal({
                title: "Renvoie des paramètres",
                text:"Cet assuré a déjà été enrôlé. Souhaitez-vous lui renvoyer ses paramètre ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                }).then((hasPaid) => {
                    if (hasPaid) {
                        resendClientParams(id)
                        .then((response) => {
                            dispatch(confirmedClientRegisterCheckAction(response.data));
                        })
                        .catch((error) => {
                            errorToastr("Erreur lors du renvoie des paramètres du client: "+error.response?.data?.message);
                            
                            dispatch(confirmedClientRegisterCheckAction(response.data));
                        });
                    }else{
                        dispatch(confirmedClientRegisterCheckAction(response.data));
                    }
            })*/
            //dispatch(confirmedClientRegisterCheckAction(response.data));
            //history.push('/client-register/'+id+'/1');
        })
        .catch((error) => {
            if(error.response?.status === 404){
                successToastr(I18n.t('client.register.enroll_start'));
                dispatch(clientRegisterCheckFailedAction(error.response?.data?.message));
                history.push('/client-register/'+id+'/0');
            }else{
                errorToastr(I18n.t('client.register.client_enroll_verif_error')+error.response?.data?.message);     
                
                dispatch(clientRegisterCheckFailedAction(error.response?.data?.message));
            }
        });
    };
};

export const getClientAction = (id, history) => {
    return (dispatch) => {

        getClient(id)
        .then((response) => {
            dispatch(confirmedGetClientAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('client.register.get_client_error')+error.response?.data?.message);
            
            dispatch(getClientFailedAction(error.response?.data?.message));
            history.push('/assure-search');
        });
    };
};

export const getAuthenticationHistoryAction = (providerId, date) => {
    return (dispatch) => {

        getHistoIdentifications(providerId, date)
        .then((response) => {
            dispatch(confirmedGetHistoryAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('client.register.login_hist_error')+error.response?.data?.message);
            
            dispatch(getHistoryFailedAction(error.response?.data?.message));
        });
    };
};

export const partialEnrollAction = (data, history) => {
    return (dispatch) => {

        partialEnroll(data)
        .then((response) => {
            successToastr("Enrôlement compléter avec succès");
            dispatch(confirmedClientRegisterAction(data.idClient));
            dispatch(loadingToggleAction(true));
            dispatch(identificationAssuAction(data.idClient));
            history.push('/identification-assure');
        })
        .catch((error) => {
            errorToastr("Erreur lors de l'enrôlement de l'assuré: "+error.response?.data?.message);
            
            dispatch(clientRegisterFailedAction(error.response?.data?.message));
        });
    };
};

export const getClientDataAction = (id, history) => {
    return (dispatch) => {

        getClientById(id)
        .then((response) => {
            dispatch(confirmedGetClientDataAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('client.register.get_client_error')+error.response?.data?.message);
            
            dispatch(getClientFailedAction(error.response?.data?.message));
            history.push('/assure-search');
        });
    };
};

export function confirmedClientSearchListingAction(payload) {
    return {
        type: SEARCH_CLIENT_CONFIRMED_ACTION,
        payload,
    };
}

export function confirmedGetHistoryAction(payload) {
    return {
        type: GET_AUTH_HISTORY_CONFIRMED_ACTION,
        payload,
    };
};

export function getHistoryFailedAction(payload) {
    return {
        type: GET_AUTH_HISTORY_FAILED_ACTION,
        payload,
    };
};

export function confirmedClientRegisterAction(payload) {
    return {
        type: REGISTER_CLIENT_CONFIRMED_ACTION,
        payload,
    };
};

export function clientRegisterFailedAction(payload) {
    return {
        type: REGISTER_CLIENT_FAILED_ACTION,
        payload,
    };
};

export function confirmedClientIdentificationAction(payload) {
    return {
        type: IDENTIFY_CLIENT_CONFIRMED_ACTION,
        payload,
    };
};

export function clientIdentificationFailedAction(payload) {
    return {
        type: IDENTIFY_CLIENT_FAILED_ACTION,
        payload,
    };
};

export function confirmedClientListingAction(payload) {
    return {
        type: LIST_CLIENT_CONFIRMED_ACTION,
        payload,
    };
};

export function clientListingFailedAction(payload) {
    return {
        type: LIST_CLIENT_FAILED_ACTION,
        payload,
    };
};

export function updateClientData(payload) {
    return {
        type: UPDATE_CLIENT_DATAS,
        payload,
    };
}

export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};

export function confirmedClientRegisterCheckAction(payload) {
    return {
        type: CLIENT_REGISTER_CHECK,
        payload,
    };
};

export function clientRegisterCheckFailedAction(payload) {
    return {
        type: CLIENT_REGISTER_CHECK_FAILED,
        payload,
    };
};



export function confirmedGetClientAction(payload) {
    return {
        type: GET_CLIENT_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedGetClientDataAction(payload) {
    return {
        type: GET_CLIENT_DATA_CONFIRMED_ACTION,
        payload,
    };
};

export function getClientFailedAction(payload) {
    return {
        type: GET_CLIENT_FAILED_ACTION,
        payload,
    };
};