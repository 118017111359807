import React, { useState, useEffect } from 'react';
import { Badge, ListGroup } from "react-bootstrap";
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from 'react-select';
import { searchActesAction } from '../../../../../store/actions/ProviderActions';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ExamsForm = (props) => {
    const dispatch = useDispatch();

    let remboursement = JSON.parse(localStorage.getItem("remboursement"));

    const [examens, setExamens] = useState((remboursement && remboursement.examens) ? remboursement.examens : []);
    const [keyword, setKeyword] = useState("");

    const selectExam = (e, val) => {
        e.preventDefault();
        let selectedExam = props.examensList.filter(ex => {return ex.code === val.code})[0];
        if(examens.filter(act => {return act.code === selectedExam.code}).length === 0) {
            setExamens([...examens, selectedExam]);
            saveUpdates();
        }
    }

    const removeElt = (val, e, type) => {
        if(e.type === "click" && type === "remove") {
            setExamens(examens.filter(diag => {return diag.code !== val}));
            saveUpdates();
        }
    }

    const saveUpdates = () => {
        let remboursement = JSON.parse(localStorage.getItem("remboursement"));
        remboursement.examens = examens.map(exam => {
            let provider = props.providers.filter(prov => {return prov.providerName === exam.providerName});
            exam.providerId = provider.length > 0 ? provider[0].id : null;
            return exam;
        });
        localStorage.setItem("remboursement", JSON.stringify(remboursement));
    }

    useEffect(() => {
        if(keyword.trim().length >= 3){
            dispatch(searchActesAction(keyword));
        }else{
            dispatch(searchActesAction('@@@'));
        }
    }, [keyword]);

    return (
      <>
        <div className='row'>
            <div className='col-xl-12 col-lg-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Prescription Examens</h4>
                    </div>
                    <div className='card-body'>
                        <div className='basic-form'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('common.search-exam')}</strong>
                                                </label>
                                                <input type="search" value={keyword} onChange={e => setKeyword(e.target.value)} className='form-control input-default mb-4' /> 

                                                <PerfectScrollbar
                                                    id="DZ_W_Todo2"
                                                    className="widget-media dz-scroll ps ps--active-y height300"
                                                >
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {props.examensList.map((disease, j) => {
                                                                return <ListGroup.Item
                                                                    action
                                                                    active={examens.filter(exam => {return exam.code === disease.code}).length > 0}
                                                                    key={j}
                                                                    onClick={e => selectExam(e, disease)}
                                                                    className="flex-column align-items-start"
                                                                    >
                                                                    <div className={"d-flex w-100 justify-content-between"}>
                                                                        <h6 className={examens.filter(exam => {return exam.code === disease.code}).length > 0 ? "text-white" : "text-dark"} >
                                                                            {disease.code} - <small>{disease.nameFr}</small>
                                                                            {disease.isAccord && <small className="text-danger">(Accord Préalable)</small>}
                                                                        </h6>
                                                                        
                                                                        <span>[ {disease.code} ]</span>
                                                                    </div>
                                                                    </ListGroup.Item>
                                                                })
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </PerfectScrollbar>
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <h5 className='mt-4 text-primary'>{t('common.exam')}</h5>
                                            <div className="border-top mt-2 mb-4"></div>
                                            {examens.map((diag, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div className='row justify-content-between align-items-center'>
                                                            <h5 style={{'maxWidth': '600px !important'}}>{diag.code} {' - '} <small>{diag.nameFr}</small></h5>
                                                            <button type="button" className="btn btn-dark shadow btn-xs sharp mx-1"
                                                                onClick={(e) => removeElt(diag.code, e, "remove")}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4 mb-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('dashboard.provider')}</strong>
                                                                    </label>
                                                                    <TextInput
                                                                        className='form-control custom-text-input'
                                                                        Component="input"
                                                                        trigger={""}
                                                                        value={diag.providerName}
                                                                        placeholder="Entrer un prestataire"
                                                                        onChange={val => {
                                                                            setExamens(examens.map(exam => {
                                                                                if(exam.id === diag.id) {
                                                                                    exam.providerName = val;
                                                                                }
                                                                                return exam
                                                                            })); 
                                                                            saveUpdates();
                                                                        }}
                                                                        options={props.providers.map(prov => {return prov.providerName})}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 mb-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('common.price')}</strong>
                                                                    </label>
                                                                    <input type='text' className='form-control'
                                                                        value={diag.consultationPrix}
                                                                        required
                                                                        onChange={(e) => {
                                                                            setExamens(examens.map(exam => {
                                                                                if(exam.id === diag.id) {
                                                                                    exam.consultationPrix = e.target.value;
                                                                                }
                                                                                return exam
                                                                            }));
                                                                            saveUpdates();
                                                                        }}
                                                                        />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 mb-2">
                                                                <div className="form-group">
                                                                    <label className='mb-1 '>
                                                                        <strong>Date</strong>
                                                                    </label>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <DateTimePicker
                                                                            autoOk
                                                                            label=""
                                                                            clearable
                                                                            format="dd/MM/yyyy hh:mm"
                                                                            value={diag.consultationDate}
                                                                            disableFuture
                                                                            //disabled={showLoading}
                                                                            required
                                                                            onChange={val => {
                                                                                setExamens(examens.map(exam => {
                                                                                    if(exam.id === diag.id) {
                                                                                        exam.consultationDate = val;
                                                                                    }
                                                                                    return exam
                                                                                }));
                                                                                saveUpdates();
                                                                            }}
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}
const mapStateToProps = (state) => {
    return {
        examensList: state.provider.actesRes,
        providers: state.provider.providers,
    };
};

export default connect(mapStateToProps)(ExamsForm);
