import React, { useState, useEffect,useRef } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import HorizontalBarChart from '../chart/HorizontalBarChart';
import './EtatsGlobaux.css';
import { usePDF, Margin } from 'react-to-pdf';
import { Button } from 'primereact/button';

const EtatsTopConsommations = (props) => {
    const { t } = useTranslation();
    const {data, type, showLoading} = props;
    const dt = useRef(null);

    const [statsDatas, setStatsDatas] = useState([]);
    const [dataAmbuSeries, setDataAmbuSeries] = useState([]);
    const [dataHospiSeries, setDataHospiSeries] = useState([]);
    const [dataAmbuLabels, setDataAmbuLabels] = useState([]);
    const [dataHospiLabels, setDataHospiLabels] = useState([]);
    const [graph, setGraph] = useState(false);
    const [bolPDF, setBolPDF] = useState(true);
    const [expandedRows, setExpandedRows] = useState(null);

    useEffect(() => {
        if(data.length > 0){
            setStatsDatas(data.map((key, i) => {
                return {
                    id: i,
                    statName: key.name,
                    totalMontant: key["total"]["montant"],
                    totalPourcent: parseFloat(key["total"]["taux"]),
                    ambuMontant: key["ambulatoire"]["montant"],
                    ambuPourcent: parseFloat(key["ambulatoire"]["taux"]),
                    hospiMontant: key["hospitalier"]["montant"],
                    hospiPourcent: parseFloat(key["hospitalier"]["taux"]),
                    children: key.children?.map((child, j) => {
                        return {
                            id: j,
                            statName: child.name,
                            totalMontant: child["total"]["montant"],
                            totalPourcent: parseFloat(child["total"]["taux"]),
                            ambuMontant: child["ambulatoire"]["montant"],
                            ambuPourcent: parseFloat(child["ambulatoire"]["taux"]),
                            hospiMontant: child["hospitalier"]["montant"],
                            hospiPourcent: parseFloat(child["hospitalier"]["taux"])
                        }
                    })
                }
            })) 

            let prestsAmbu = data.filter(elt => elt.ambulatoire.montant > 0);
            let prestsHospi = data.filter(elt => elt.hospitalier.montant > 0);
            
            setDataAmbuSeries([{
                data: prestsAmbu.map(key => {
                    return key["ambulatoire"]["montant"]
                })
            }]);
            
            setDataHospiSeries([{
                data: prestsHospi.map(key => {
                    return key["hospitalier"]["montant"]
                })
            }]);
            setDataAmbuLabels(prestsAmbu.map(key => key.name));
            setDataHospiLabels(prestsHospi.map(key => key.name));
        }
    }, [data]);

    const rowExpansionTemplate = (data) => {
        const childHeaderGroup = (
            <ColumnGroup>
                <Row>
                    <Column header="Bénéficiaire" rowSpan={2}/>
                    <Column header="Total" colSpan={2} />
                    <Column header="Ambulatoire" colSpan={2} />
                    <Column header="Hospitalier" colSpan={2} />
                </Row>
                <Row>
                    <Column header="Montant" field="totalMontant" />
                    <Column header="Taux en %" field="totalPourcen" />
                    <Column header="Montant" field="ambuMontant" />
                    <Column header="Taux en %" field="ambuPourcen" />
                    <Column header="Montant" field="hospiMontant" />
                    <Column header="Taux en %" field="hospiPourcen" />
                </Row>
            </ColumnGroup>
        );
        
        return (
            <div>
                <DataTable 
                    dataKey="id"
                    showGridlines 
                    headerColumnGroup={childHeaderGroup}
                    value={data.children}
                >
                    <Column field="statName"></Column>
                    <Column field="totalMontant"></Column>
                    <Column field="totalPourcent"></Column>
                    <Column field="ambuMontant"></Column>
                    <Column field="ambuPourcent"></Column>
                    <Column field="hospiMontant"></Column>
                    <Column field="hospiPourcent"></Column>
                </DataTable>
            </div>
        );
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header={type} rowSpan={2} colSpan={type === "Famille" && bolPDF ? 2 : 1} />
                <Column header="Total" colSpan={2} />
                <Column header="Ambulatoire" colSpan={2} />
                <Column header="Hospitalier" colSpan={2} />
            </Row>
            <Row>
                <Column header="Montant" field="totalMontant" />
                <Column header="Taux en %" field="totalPourcen" />
                <Column header="Montant" field="ambuMontant" />
                <Column header="Taux en %" field="ambuPourcen" />
                <Column header="Montant" field="hospiMontant" />
                <Column header="Taux en %" field="hospiPourcen" />
            </Row>
        </ColumnGroup>
    );

    const allowExpansion = (rowData) => {
        return rowData.children && rowData.children.length > 0;
    };

    const onRowExpand = (event) => {
    };

    const onRowCollapse = (event) => {
    };


      // Fonction pour exporter en Excel
      const transformData = (data) => {
        return data.map(item => ({
            prestataire: item.statName,
            Total_Montant: item.totalMontant,
            Total_Pourcentage: item.totalPourcent,
            Ambulance_Montant: item.ambuMontant,
            Ambulance_Pourcentage: item.ambuPourcent,
            Hospitalisation_Montant: item.hospiMontant,
            Hospitalisation_Pourcentage: item.hospiPourcent,
            
        }));
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {

            const worksheet = xlsx.utils.json_to_sheet(transformData(statsDatas), { skipHeader: false });
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'Top_Consommations');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    //PDF
    const { toPDF, targetRef } = usePDF({ filename: 'Top_Consommations.pdf',  page:{margin: Margin.SMALL}})
    function exportPDF (){
        setBolPDF(false)
        setTimeout(()=>{
           toPDF()
        }, 300)

        setTimeout(()=>{
        setBolPDF(true)
        }, 500)
    }

    //fichier csv
    const exportToCSVe = (datacsv) => {
        const csvRows = [];
        const headers = [
            'familles',
            'Total montant',
            'Total Taux',
            'Ambulatoire montant',
            'Ambulatoire Taux',
            'Hospitalier montant',
            'Hospitalier Taux'
        ];
        csvRows.push(headers.join(','));

        datacsv.forEach(item => {
            const row = [
                item.name,
                item.total.montant,
                item.total.taux,
                item.ambulatoire.montant,
                item.ambulatoire.taux,
                item.hospitalier.montant,
                item.hospitalier.taux
            ];
            csvRows.push(row.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Top_Consommations.csv');
        a.click();
    };

    return (
        <>
            {Object.keys(data).length === 0 && <span>
                Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
            </span>
            }
            {Object.keys(data).length > 0 && <div className='results-section'>
                <div className='row justify-content-center my-4'>
                    <div className='form-group mb-0'>
                        <label className='radio-inline mr-5'>
                            <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => {setGraph(!e.target.checked)}}/> Données
                        </label>
                        <label className='radio-inline'>
                            <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => {setGraph(e.target.checked)}}/> Graphique
                        </label>
                    </div>
                </div>
                {!graph &&
                 <>
                    <div ref={targetRef}>
                <DataTable 
                    value={statsDatas} 
                    dataKey="id"
                    showGridlines 
                    headerColumnGroup={headerGroup} 
                    tableStyle={{ minWidth: '50rem' }}
                    expandedRows={expandedRows}
                    onRowExpand={onRowExpand} 
                    onRowCollapse={onRowCollapse} 
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    ref={dt}
                >
                    {type === "Famille" && bolPDF && <Column expander={allowExpansion} style={{ width: '5rem' }} />}
                    <Column field="statName"></Column>
                    <Column field="totalMontant"></Column>
                    <Column field="totalPourcent"></Column>
                    <Column field="ambuMontant"></Column>
                    <Column field="ambuPourcent"></Column>
                    <Column field="hospiMontant"></Column>
                    <Column field="hospiPourcent"></Column>
                </DataTable>
                 </div>
                 <div className="flex align-items-center justify-content-end gap-2">
                     <Button type="button" icon="pi pi-file" rounded onClick={() => exportToCSVe(data)} data-pr-tooltip="CSV" />
                     <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                     <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportPDF()} data-pr-tooltip="PDF" />
                 </div>
             </>
                }
                {graph && 
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{type === 'Actes' ? 'Actes en Ambulatoire' : 'Consommateurs en Ambulatoire'}</h5>
                            <HorizontalBarChart series={dataAmbuSeries} labels={dataAmbuLabels} height="150" />
                        </div>
                        <div className='col-6'>
                            <h5>{type === 'Actes' ? 'Actes en Hospitalisation' : 'Consommateurs en Hospitalisation'}</h5>
                            <HorizontalBarChart series={dataHospiSeries} labels={dataHospiLabels} height="150" />
                        </div>
                    </div>
                }
            </div>}
        </>
    )
}
export default EtatsTopConsommations;