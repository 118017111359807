import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import loadable from "@loadable/component";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { getRapportDepenseParFamilleAction } from "../../../store/actions/RapportAction";
import { getAssurePrincipauxAction } from "../../../store/actions/ContratActions";
import '../Clients/Styles/selectStyle.css';
import Select from 'react-select';
const RapportDepense = loadable(() => import("./Souscripteur/RapportDepense"));

export const AnalyseConsommation = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { history, showLoading } = props;
    const [searchQuery, setSearchQuery] = useState('');

    const [searchData, setSearchData] = useState({
        numeroPolice: '',
        numeroAssure: '',
        dateDebut: '',
        dateFin: '',
    });

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const startSearch = (e) => {
        //e.preventDefault();
        //dispatch(loadingToggleAction());
        dispatch(getRapportDepenseParFamilleAction(searchData));
    }
    const handleSearchSubmit = async (event) => {
        event.preventDefault();
        startSearch(event);
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearchData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Effectuer la recherche via l'API
        startSearch(event);
    };

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENREPORTING') && canDo('MENREPORTING', 'VIEWREPORT')){
            dispatch(getAssurePrincipauxAction());
            //dispatch(getRapportDepenseParFamilleAction("100221000002"));
            //const jsonData = props.dataDepenseFami;
            //const jsonData = dataJson
        }else{
            props.history.push('/access-denied');
        } 
    }, []);
    function uniq(array, field) {
        return array.reduce((accumulator, current) => {
            if(!accumulator.includes(current[field])) {
              accumulator.push(current[field])
            }
            return accumulator;
          }, []
        )
      }
      
      const polices = uniq(props.assuresPrincipaux, 'numeroPolice');
    const pluck = (arr, key) => arr.map(i => i[key]);

    return (
        <Fragment>
            <style>
                {`
                    input {
                        width: 100% !important;
                    }
                `}
            </style>
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="bg-black card-header">

                            <form onSubmit={handleSubmit} className="form-inline">
                                <legend className="align-self-start">
                                    <h4 className="card-title">Information sur l'assuré</h4>
                                </legend>
                                <div className="form-group m-2">
                                    <Select
                                        className="select-search form-control"
                                        value={polices?.filter(elt => elt === searchData.numeroPolice)?.map(elt => { return { label: elt, value: elt } })}
                                        placeholder="Numéro de police"
                                        onChange={(val) => {
                                            setSearchData({
                                                ...searchData,
                                                numeroPolice: val.value
                                            })
                                        }}
                                        options={polices?.map((elt) => { return { label: elt, value: elt } })}
                                    />
                                    {/* <input
                                        type='text'
                                        id='numeroPolice'
                                        name='numeroPolice'
                                        required
                                        value={searchData.numeroPolice}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Numéro de police"
                                    /> */}
                                </div>
                                <div className="form-group m-2">
                                    <Select
                                        className="select-search form-control"
                                        value={props.assuresPrincipaux?.filter(elt => elt.numBeneficiaire === searchData.numeroAssure)?.map(elt => { return { label: elt.nom, value: elt.numBeneficiaire } })}
                                        placeholder="Assuré principal"
                                        onChange={(val) => {
                                            setSearchData({
                                                ...searchData,
                                                numeroAssure: val.value
                                            })
                                        }}
                                        options={props.assuresPrincipaux?.filter(elt => elt.numeroPolice === searchData.numeroPolice)?.map((elt) => { return { label: elt.nom, value: elt.numBeneficiaire } })}
                                    />
                                    {/*<input
                                        type='text'
                                        id='numeroAssure'
                                        name='numeroAssure'
                                        value={searchData.numeroAssure}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder={t('common.num-assure')}
                                    />*/}
                                </div>
                                <div className="form-group m-2">
                                    <input
                                        type='date'
                                        id='dateDebut'
                                        name='dateDebut'
                                        value={searchData.dateDebut}
                                        onChange={handleInputChange}
                                        className="form-control sm"
                                        placeholder="Date début"
                                    />
                                </div>
                                <div className="form-group m-2">
                                    <input
                                        type='date'
                                        id='dateFin'
                                        name='dateFin'
                                        value={searchData.dateFin}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        placeholder="Date fin"
                                    />
                                </div>
                                <button type='submit' className="btn btn-primary">Rechercher</button>
                            </form>
                        </div>
                        <div className="card-body">
                            <div>
                                <RapportDepense data={props.dataDepenseFami} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>

    )
};

const mapStateToProps = (state) => {
    return {
        beneficiaires: state.contrat.beneficiaires,
        souscripteurs: state.contrat.souscripteurs,
        assureBenefs: state.contrat.assureBenefs,
        searchedContrats: state.contrat.searchedContrats.dis,
        assuresPrincipaux: state.contrat.assuresPrincipaux,
        showLoading: state.facturation.showLoading,
        showContratLoading: state.contrat.showLoading,
        dataDepenseFami: state.rapport.dataDepenseFami,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(AnalyseConsommation);