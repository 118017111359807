import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect, useDispatch } from 'react-redux';
import { getAllBeneficiairesAction, getContratsAction, getAllSouscripteursAction, 
loadingToggleAction, getSouscripteursAction, getBeneficiaireAction,
getAllAssuresPrincipauxAction } from "../../../store/actions/ContratActions";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import Select from 'react-select';
import{withRouter} from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import DetailsModal from "./DetailsModal";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const Monitoring = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const dataTableRef = useRef();
    const [currentData, setCurrentData] = useState('contrats');
    const [currentLoadData, setCurrentLoadData] = useState([]);
    const [showDetailsTab, setShowDetailsTab] = useState(false);
    const [dataDetails, setDataDetails] = useState(null);
    const [dataDetailsText, setDataDetailsText] = useState('');

    const dataTypeList = ['contrats', 'souscripteurs', 'assurés principaux', 'bénéficiaires'];

    const [contratFilters, setContratFilters] = useState(null);
    const [globalContratFilterValue, setGlobalContratFilterValue] = useState('');

    const [souscriptFilters, setSouscriptFilters] = useState(null);
    const [globalSouscriptFilterValue, setGlobalSouscriptFilterValue] = useState('');

    const [assPrincFilters, setAssPrincFilters] = useState(null);
    const [globalAssPrincFilterValue, setGlobalAssPrincFilterValue] = useState('');

    const [benefFilters, setBenefFilters] = useState(null);
    const [globalBenefFilterValue, setGlobalBenefFilterValue] = useState('');

    const [contratLazyState, setContratLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateSouscription: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateEffet: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        }
    });

    const [souscriptLazyState, setSouscriptLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateSouscription: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateEffet: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        }
    });

    const [assPrincLazyState, setAssPrincLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateSouscription: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateEffet: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        }
    });

    const [benefLazyState, setBenefLazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateSouscription: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateEffet: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        }
    });

    const initFilters = () => {
        setContratFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateSouscription: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateEffet: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalContratFilterValue("");
        setBenefFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            sexe: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            telephone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateNaissance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalBenefFilterValue("");
        setAssPrincFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            sexe: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            telephone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateNaissance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalAssPrincFilterValue("");
        setSouscriptFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            nom_Assu: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            prenAssu: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            teleAssu: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            maiLAssu: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            adreAssu: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateNais: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalSouscriptFilterValue("");
    };

    const renderHeader = () => {
        switch(currentData) {
            case 'souscripteurs':
                return (
                    <div className="flex justify-content-between">
                        <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalSouscriptFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                        </span>
                    </div>
                );
            case 'assurés principaux':
                return (
                    <div className="flex justify-content-between">
                        <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalAssPrincFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                        </span>
                    </div>
                );
            case 'bénéficiaires':
                return (
                    <div className="flex justify-content-between">
                        <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalBenefFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                        </span>
                    </div>
                );
            default:
                return (
                    <div className="flex justify-content-between">
                        <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalContratFilterValue} onChange={onGlobalFilterChange}  placeholder={t('common.search')} />
                        </span>
                    </div>
                );
        }
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        switch(currentData) {
            case 'souscripteurs':
                let _filtersS = { ...souscriptFilters };

                _filtersS['global'].value = value;

                setSouscriptFilters(_filtersS);
                setGlobalSouscriptFilterValue(value);
                dispatch(loadingToggleAction(true));
                dispatch(getAllSouscripteursAction(1, 10, value));
                return;
            case 'assurés principaux':
                let _filtersA = { ...assPrincFilters };

                _filtersA['global'].value = value;

                setAssPrincFilters(_filtersA);
                setGlobalAssPrincFilterValue(value);
                dispatch(loadingToggleAction(true));
                dispatch(getAllAssuresPrincipauxAction(1, 10, value));
                return;
            case 'bénéficiaires':
                let _filtersB = { ...benefFilters };

                _filtersB['global'].value = value;

                setBenefFilters(_filtersB);
                setGlobalBenefFilterValue(value);
                dispatch(loadingToggleAction(true));
                dispatch(getAllBeneficiairesAction(1, 10, value));
                return;
            default:
                let _filters = { ...contratFilters };

                _filters['global'].value = value;

                setContratFilters(_filters);
                setGlobalContratFilterValue(value);
                dispatch(loadingToggleAction(true));
                dispatch(getContratsAction(1, 10, value));
                return;
        }
    };

    const setColumns = () => {
        switch(currentData) {
            case 'souscripteurs':
                return [
                    {name: 'nom_Assu', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'prenAssu', headerName: t('common.firstname'), filterPlaceholder: t('common.filter-firstname'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'teleAssu', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'maiLAssu', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'adreAssu', headerName: t('common.address'), filterPlaceholder: t('common.filter-adress'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'dateNais', filterField: 'dateNais', dataType: 'date', headerName: t('common.birthdate'), filterPlaceholder: t('common.filter-datenaiss'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
                ]
            case 'assurés principaux':
                return [
                    {name: 'nom', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'sexe', headerName: t('common.firstname'), filterPlaceholder: t('common.filter-firstname'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'telephone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'numeroPolice', headerName: t('common.numpolice'), filterPlaceholder: t('common.filter-policenum'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'dateNaissance', filterField: 'dateNais', dataType: 'date', headerName: t('common.birthdate'), filterPlaceholder: t('common.filter-datenaiss'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
                ]
            case 'bénéficiaires':
                return [
                    {name: 'nom', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'sexe', headerName: t('common.gender'), filterPlaceholder: t('common.filter-sex'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'telephone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'numeroPolice', headerName: t('common.numpolice'), filterPlaceholder: t('common.filter-policenum'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'dateNaissance', filterField: 'dateNais', dataType: 'date', headerName: t('common.birthdate'), filterPlaceholder: t('common.filter-datenaiss'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
                ]
            default:
                return [
                    {name: 'numeroPolice', headerName: t('common.numpolice'), filterPlaceholder: t('facturation.bill-number-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
                    {name: 'dateSouscription', filterField: 'dateFacturation', dataType: 'date', headerName: t('common.souscriptdate'), filterPlaceholder: t('common.filter-souscriptdate'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateSouscriptBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    {name: 'dateEffet', filterField: 'dateFacturation', dataType: 'date', headerName: t('common.effetdate'), filterPlaceholder: t('common.filter-effetdate'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateEffBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    {name: 'dateExpiration', filterField: 'dateFacturation', dataType: 'date', headerName: t('common.expirationdate'), filterPlaceholder: t('common.filter-expirationdate'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateExpBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
                    {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
                ]
        }
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                {hasMenu('MENMONITORING') && canDo('MENMONITORING', 'VIEWMONITORINGDETAIL') && <button className="btn btn-danger shadow btn-xs sharp mr-2"
                    onClick={(e) => {
                        setDataDetails(rowData);
                        switch(currentData) {
                            case 'souscripteurs':
                                setDataDetailsText('Infos souscripteur '+rowData.nom_Assu);
                                setShowDetailsTab(true);
                                return;
                            case 'assurés principaux':
                                setDataDetailsText('Infos assuré '+rowData.nom);
                                setShowDetailsTab(true);
                                return;
                            case 'bénéficiaires':
                                setDataDetailsText('Infos bénéficiaire '+rowData.nom);
                                setShowDetailsTab(true);
                                return;
                            default:
                                setDataDetailsText('Infos contrat '+rowData.numeroPolice);
                                setShowDetailsTab(true);
                                return;
                        };
                    }}
                >
                    <i className="fa fa-eye"></i>
                </button>}
                {hasMenu('MENMONITORING') && canDo('MENMONITORING', 'VIEWMONITORINGHIST') && currentData!=='assurés principaux' && <button className="btn btn-info shadow btn-xs sharp mr-2"
                    onClick={(e) => {
                        setDataDetails(rowData);
                        switch(currentData) {
                            case 'souscripteurs':
                                props.history.push('/historiques-avenants/'+rowData.nom_Assu+'/'+currentData);
                                return;
                            case 'bénéficiaires':
                                props.history.push('/historiques-avenants/'+rowData.id+'/'+currentData);
                                return;
                            default:
                                props.history.push('/historiques-avenants/'+rowData.numeroPolice+'/'+currentData);
                                return;
                        };
                    }}
                >
                    <i className="fa fa-history"></i>
                </button>}
        </div>
    }

    const manageDetailsModal = () => {
        setShowDetailsTab(false);
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const dateSouscriptBodyTemplate = (rowData) => {
        return formatDate(rowData.dateSouscription);
    };

    const dateEffBodyTemplate = (rowData) => {
        return formatDate(rowData.dateEffet);
    };

    const dateExpBodyTemplate = (rowData) => {
        return formatDate(rowData.dateExpiration);
    };

    const dateNaissBodyTemplate = (rowData) => {
        return rowData.dateNais ? formatDate(rowData.dateNais) : formatDate(rowData.dateNaissance);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const onGoToPage = (event) => {
        switch(currentData) {
            case 'souscripteurs':
                setSouscriptLazyState(event)
                return;
            case 'assurés principaux':
                setAssPrincLazyState(event)
                return;
            case 'bénéficiaires':
                setBenefLazyState(event)
                return;
            default:
                setContratLazyState(event)
                return;
        };
    }

    const onSortData = (event) => {
        switch(currentData) {
            case 'souscripteurs':
                setSouscriptLazyState(event)
                return;
            case 'assurés principaux':
                setAssPrincLazyState(event)
                return;
            case 'bénéficiaires':
                setBenefLazyState(event)
                return;
            default:
                setContratLazyState(event)
                return;
        };
    }

    useEffect(() => {
        switch(currentData) {
            case 'souscripteurs':
                dispatch(loadingToggleAction(true));
                dispatch(getAllSouscripteursAction(souscriptLazyState.page + 1, souscriptLazyState.rows, globalSouscriptFilterValue)); 
                return;
            case 'assurés principaux':
                dispatch(loadingToggleAction(true));
                dispatch(getAllAssuresPrincipauxAction(assPrincLazyState.page + 1, assPrincLazyState.rows, globalAssPrincFilterValue)); 
                return;
            case 'bénéficiaires':
                dispatch(loadingToggleAction(true));
                dispatch(getAllBeneficiairesAction(benefLazyState.page + 1, benefLazyState.rows, globalBenefFilterValue)); 
                return;
            default:
                if(contratLazyState.page) {
                    dispatch(loadingToggleAction(true));
                    dispatch(getContratsAction(contratLazyState.page + 1, contratLazyState.rows, globalContratFilterValue)); 
                }else if(contratLazyState.sortField && contratLazyState.sortOrder) {
                    // Get the filtered data from the client side.
                    const sortedData = props.allContrats.data.sort((a, b) => {
                        return contratLazyState.sortOrder === 1 ? a[contratLazyState.sortField] - b[contratLazyState.sortField] : b[contratLazyState.sortField] - a[contratLazyState.sortField];
                    });
                    // Update the data table with the filtered data.
                    //dataTableRef.current?.updateData(filteredData);
                }
                return;
        };
    }, [contratLazyState, souscriptLazyState, benefLazyState, assPrincLazyState]);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }
    
    useEffect(() => {
        if(hasMenu('MENMONITORING') && canDo('MENMONITORING', 'LISTMONITORING')){
            initFilters();
            dispatch(loadingToggleAction(true));
            dispatch(getContratsAction(1, 10, ''));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    
    useEffect(() => {
            dispatch(loadingToggleAction(true));
            switch(currentData) {
                case 'souscripteurs':
                    initFilters();
                    dispatch(getAllSouscripteursAction(1, 10, ''));
                    return;
                case 'assurés principaux':
                    initFilters();
                    dispatch(getAllAssuresPrincipauxAction(1, 10, ''));
                    return;
                case 'bénéficiaires':
                    initFilters();
                    dispatch(getAllBeneficiairesAction(1, 10, ''));
                    return;
                default:
                    initFilters();
                    dispatch(getContratsAction(1, 10, ''));
                    return;
            }
    }, [currentData]);

    const getCurrentData = () => {
        switch(currentData) {
            case 'souscripteurs':
                return props.allSouscripteurs.data ? props.allSouscripteurs.data.map(souscript => {
                    souscript.dateNais = new Date(souscript.dateNais);
                    return souscript;
                }) : [];
            case 'assurés principaux':
                return props.allAssuresPrincipaux.data ? props.allAssuresPrincipaux.data.map(assure => {
                    assure.dateNais = new Date(assure.dateNais);
                    return assure;
                }) : [];
            case 'bénéficiaires':
                return props.allBeneficiaires.data ? props.allBeneficiaires.data.map(benef => {
                    benef.dateNaissance = new Date(benef.dateNaissance);
                    return benef;
                }) : [];
            default:
                return props.allContrats.data ? props.allContrats.data.map(contrat => {
                    contrat.dateSouscription = new Date(contrat.dateSouscription);
                    contrat.dateEffet = new Date(contrat.dateEffet);
                    contrat.dateExpiration = new Date(contrat.dateExpiration);
                    return contrat;
                }) : [];
        }
    }

    const getExportColumns = () => {
        switch(currentData) {
            case 'souscripteurs':
                return [
                    { title: 'Code', dataKey: 'nom_Assu' },
                    { title: t('common.name'), dataKey: 'prenAssu' },
                    { title: t('common.formula'), dataKey: 'teleAssu' },
                    { title: t('common.garanty'), dataKey: 'maiLAssu' },
                    { title: t('client.type'), dataKey: 'dateNais' },
                ];
            case 'assurés principaux':
                return [
                    { title: t('common.name'), dataKey: 'nom' },
                    { title: t('common.gender'), dataKey: 'sexe' },
                    { title: t('common.phone'), dataKey: 'telephone' },
                    { title: 'Email', dataKey: 'email' },
                    { title: t('common.numpolice'), dataKey: 'numeroPolice' },
                    { title: t('common.family'), dataKey: 'dateNaissance' },
                ];
            case 'bénéficiaires':
                return [
                    { title: t('common.name'), dataKey: 'nom' },
                    { title: t('common.gender'), dataKey: 'sexe' },
                    { title: t('common.phone'), dataKey: 'telephone' },
                    { title: 'Email', dataKey: 'email' },
                    { title: t('common.numpolice'), dataKey: 'numeroPolice' },
                    { title: t('common.family'), dataKey: 'dateNaissance' },
                ];
            default:
                return [
                    { title: t('common.numpolice'), dataKey: 'numeroPolice' },
                    { title: t('common.souscriptdate'), dataKey: 'dateSouscription' },
                    { title: t('common.effetdate'), dataKey: 'dateEffet' },
                    { title: t('common.expirationdate'), dataKey: 'dateExpiration' },
                ];
        }
    }

    const getCurrentLazyState = () => {
        switch(currentData) {
            case 'souscripteurs':
                return souscriptLazyState;
            case 'assurés principaux':
                return assPrincLazyState;
            case 'bénéficiaires':
                return benefLazyState;
            default:
                return contratLazyState;
        }
    }

    const getCurrentTotalDataState = () => {
        switch(currentData) {
            case 'souscripteurs':
                return props.allSouscripteurs?.totalRecords;
            case 'assurés principaux':
                return props.allAssuresPrincipaux?.totalRecords;
            case 'bénéficiaires':
                return props.allBeneficiaires?.totalRecords;
            default:
                return props.allContrats?.totalRecords;
        }
    }
    
    const getCurrentFilters = () => {
        switch(currentData) {
            case 'souscripteurs':
                return souscriptFilters;
            case 'assurés principaux':
                return assPrincFilters;
            case 'bénéficiaires':
                return benefFilters;
            default:
                return contratFilters;
        }
    }

    return (
        <Fragment>
            <div className="form-head mb-4" style={{width: '50%'}}>
                <div className="row">
                    <div data-testid='data-select' className="col-6">
                        <Select
                            className="select-search"
                            value={{label: currentData, value: currentData}}
                            placeholder={t("common.type-data")}
                            onChange={(val) => setCurrentData(val.value)}
                            disabled={props.showLoading}
                            required
                            options={dataTypeList.map((opt)=>{return {label:opt,value:opt}})}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div data-testid='data-table-base' className="col-xl-12">
                    <DataTableBase 
                        data={getCurrentData()}
                        emptyMessage={"0 "+currentData} 
                        filters={getCurrentFilters}
                        //globalFiltersFields={['code', 'nameFr', 'familyText', 'libelleGarantie', 'formule', 'createdAt']}
                        header={renderHeader()}
                        columns={setColumns()}
                        exportColumns={getExportColumns()}
                        rows={10} 
                        showExport={true}
                        exportFilename={currentData}
                        loading={props.showLoading}
                        lazy={true}
                        dataSet={getCurrentLazyState()}
                        totalRecords={getCurrentTotalDataState()}
                        onGoToPage={onGoToPage}
                        onSortData={onSortData}
                        removableSort={true}
                    />
                    
           
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={showDetailsTab}
                        size="lg"
                    >
                        <DetailsModal 
                            showModal={showDetailsTab}
                            data={dataDetails}
                            headerText={dataDetailsText}
                            manageDetailsModal={manageDetailsModal}
                        />
                    </Modal>
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        allSouscripteurs: state.contrat.allSouscripteurs,
        allContrats: state.contrat.allContrats,
        allAssuresPrincipaux: state.contrat.allAssuresPrincipaux,
        allBeneficiaires: state.contrat.allBeneficiaires,
        showLoading: state.contrat.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(Monitoring));