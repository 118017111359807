import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { getAccordAction, sendAccordMessageAction, validateAccordAction, loadingToggleAction } from "../../../../store/actions/AccordPreaActions";
import { getConsultationDataAction, startLoader } from "../../../../store/actions/ConsultationActions";
import { getSubstitutionRequestsAction, substituteDrugAction } from "../../../../store/actions/PharmacieActions";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import MotifRejetModal from "./MotifRejetModal";
import { Link, useParams, withRouter } from "react-router-dom";
import { Row, Col, Card, Accordion, Button, Modal, Badge } from 'react-bootstrap'
import PerfectScrollbar from "react-perfect-scrollbar";
import avatar1 from '../../../../images/avatar/5.png';
import avatar2 from '../../../../images/avatar/2.png';
import widget8 from "../../../../images/avatar/5.png";
import task6 from "../../../../images/task/img6.jpg";
import './AccordPreaView.css';
import { TabView, TabPanel } from 'primereact/tabview';
import { ExamenBonsTable } from './ExamenBonsTable';
import { ActesSpecialisesTable } from './ActesSpecialisesTable';
import { MedicamentsTable } from './MedicamentsTable';
import { OptiqueBonsTable } from './OptiqueBonsTable';
import { HospiBonTable } from './HospiBonTable';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";
import {format} from 'date-fns';

/* --- BEGIN AccordPreaView
		   @param(Object): props
		   description : Composant du détail des accords préalables
*/
export const AccordPreaView = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { idConsult } = useParams();
    const { accordPrea, showLoading, consultation, showLoadingConslt, currentUser, history } = props;
    const [message, setMessage] = useState('');
    const [fileSrc, setFileSrc] = useState(null);
    const [supModal, setSupModal] = useState(false);
    const [activeBordered, setActiveBordered] = useState(0)
    const suggestions = [
        t('common.suggest-accord-4'),
        t('common.suggest-accord-5'),
        t('common.suggest-accord-6'),
    ];
    const defaultAccordion = [
        {
            title: t('common.exam'),
            bg: 'primary',
        },
        {
            title: t('layout.pharmacy'),
            bg: 'info',
        },
        {
            title: t('layout.specialized-acts'),
            bg: 'success',
        },
        {
            title: t('statuses.100'),
            bg: 'success',
        },
    ];

    /* --- BEGIN loadFile
		   @param(Event): e
		   description : Fonction de gestion de l'ajout d'un fichier lors des échanges dans accords préalables
    */
    const loadFile = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        setFileSrc(upFiles[keys[0]]);
    }
    /* --- END AccordPreaView */

    const respondDemande = (status, demandeId) => {
        dispatch(substituteDrugAction(status, demandeId));
    }

    /* --- BEGIN sendMessage
		   @param(Event): e
		   description: Fonction d'envoie de message dans un accord préalable
    */
    const sendMessage = (e) => {
        e.preventDefault();
        let data = {
            senderId: currentUser.id,
            accordPreableId: id,
            message,
            fileSrc,
        };
        dispatch(loadingToggleAction(true));
        dispatch(sendAccordMessageAction(data, history));
    }
    /* --- END AccordPreaView */


    /* --- BEGIN validateAct
		   @param(): aucun
		   description: Fonction de validation d'un acte préalable
    */
    const validateAct = () => {
        dispatch(validateAccordAction(id, history));
    }
    /* --- END AccordPreaView */


    /* --- BEGIN manageSupModal
		   @param(boolean, boolean): val, reload
		   description: Fonction de gestion de la modale de motif de rejet de l'accord préalable appellée depuis celle-ci
    */
    const manageSupModal = (val, reload) => {
        setSupModal(val);
        /*if(reload){
            window.location.reload(false);
        };*/
    }
    /* --- END manageSupModal */

    const dayHospiString = () => {
        let str = "";
        let diff = Math.abs(new Date() - new Date(consultation.dateDebutHospi));
        let diff2 = Math.abs(new Date(consultation.dateFinHospi) - new Date());

        let days = Math.ceil(diff / (1000*60*60*24));
        let remainDays = Math.ceil(diff2 / (1000*60*60*24));
        
        if(days <= 0){
            str = t('accord.first-day-hospitalisation');
        }else{
            str = days + t('accord.day-hospitalisation');
        }

        if(remainDays < 0){
            str += t('accord.patient-out');
        }else if(remainDays === 0){
            str += t('accord.today-out');
        }else{
            str += t('accord.patient-out')+(remainDays - 1)+t('accord.day')
        }

        return str;

    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENACCORD') && canDo('MENACCORD', 'VIEWACCORD')){
            dispatch(startLoader());
            dispatch(loadingToggleAction(true));
            dispatch(getAccordAction(id));
            dispatch(getConsultationDataAction(idConsult));
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        setMessage('');
    }, [accordPrea]);

    return (
        <>
            { showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !showLoading &&
                <>
                {accordPrea.status === STATUSES.pending && <div className="row justify-content-end my-3">
                    {hasMenu('MENACCORD') && canDo('MENACCORD', 'VALIDATEACCORD') && <Button
                        variant="success"
                        type="button"
                        size="sm"
                        onClick={() => validateAct()}
                        className="mr-3"
                    >
                        {t('common.validate')}
                    </Button>}
                    {hasMenu('MENACCORD') && canDo('MENACCORD', 'REJECTACCORD') && <Button
                        variant="danger"
                        type="button"
                        size="sm"
                        onClick={() => setSupModal(true)}
                    >
                        {t('common.rejected')}
                    </Button>}
                    <Modal
                        className="fade bd-example-modal-lg"
                        show={supModal}
                        size="lg"
                    >
                        <MotifRejetModal 
                            showModal={supModal} 
                            accordRef={accordPrea} 
                            manageSupModal={manageSupModal}
                            history={history}
                        />
                    </Modal>
                </div>}
                <div className="row custom-chatbox">

                        { showLoadingConslt && 
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div> }

                        { !showLoadingConslt &&<div className="col-8">

                            <div className='d-md-flex d-block mb-3 align-items-center justify-content-between'>
                                <div className="d-flex flex-column">
                                    <div className="widget-timeline-icon py-3 py-md-2 px-1 overflow-auto">
                                        <ul className="timeline">
                                            <li>
                                                <div className="icon bg-warning" />
                                                <Link
                                                    className="timeline-panel text-muted"
                                                    to="#"
                                                >
                                                    <h4 className="mb-2 mt-0 text-warning fs-16 font-w600">
                                                    {t('patient.in-consultation')}
                                                    </h4>
                                                    {consultation.openDate && <p className="fs-14 mb-0 ">
                                                        {format(new Date(consultation.openDate), 'dd/MM/yyyy hh:mm')}
                                                    </p>}
                                                </Link>
                                            </li>
                                            {consultation.isHospi && 
                                            <>
                                                <li className="border-info">
                                                    <div className="icon bg-info" />
                                                    <Link
                                                        className="timeline-panel text-muted"
                                                        to="#"
                                                    >
                                                        <h4 className="mb-2 mt-0 text-info fs-16 font-w600">
                                                        {t('patient.in-hospitalisation')}
                                                        </h4>
                                                        {consultation.dateDebutHospi && <p className="fs-14 mb-0 ">
                                                            {format(new Date(consultation.dateDebutHospi), 'dd/MM/yyyy hh:mm')}
                                                        </p>}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <div className="icon bg-primary" />
                                                    <Link
                                                        className="timeline-panel text-muted"
                                                        to="#"
                                                    >
                                                        <h4 className="mb-2 text-primary mt-0 fs-16 font-w600">
                                                        {t('patient.out-on')}
                                                        </h4>
                                                        {consultation.dateFinHospi && <p className="fs-14 mb-0 ">
                                                            {format(new Date(consultation.dateFinHospi), 'dd/MM/yyyy hh:mm')}
                                                        </p>}
                                                    </Link>
                                                </li>
                                            </>
                                            }
                                        </ul>
                                    </div>
                                    {consultation.isHospi === true && consultation.isclose === false && <div className="my-2">
                                        <span className="text-info font-weight-bold">{dayHospiString()}</span>
                                    </div>}
                                </div>
                                <div>
                                    {hasMenu('MENCONSULT') && canDo('MENCONSULT', 'ACVIEWMEDFOLD') && <Link
                                    to={"/dossier-medical/"+consultation.consultationBon?.beneficiaryId}
                                    className="btn btn-success btn-rounded wspace-no mx-2"
                                    >
                                    <i className="las scale5 la-folder-plus mr-2" /> {t('patient.medical-folder')}
                                    </Link>}
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="media d-sm-flex d-block text-center text-sm-left pb-4 mb-4 border-bottom">
                                                <img
                                                alt="widget"
                                                className="rounded mr-sm-4 mr-0"
                                                width={130}
                                                src={task6}
                                                />
                                                <div className="media-body align-items-center">
                                                    <div className="d-sm-flex d-block justify-content-between my-3 my-sm-0">
                                                        <div>
                                                            <h3 className="fs-22 text-black font-w600 mb-0">
                                                                {consultation.consultationBon?.beneficiaryName}
                                                            </h3>
                                                            <h5 className="my-2 my-sm-2 text-danger">
                                                                <b>{t('appointment.reason')} </b>: {consultation.motif}
                                                            </h5>
                                                            <small className="mb-2 mb-sm-2 text-info">
                                                                <b>{t('common.consultation-date')}</b>: {consultation.consultationBon?.date}
                                                            </small>
                                                        </div>
                                                        <span>{t('statuses.'+consultation.consultationBon?.status)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-temperature-high text-primary" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                      <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                               {t('common.temperature')}
                                                            </span>
                                                            <p className="fs-16 text-dark">
                                                                <strong>{consultation.healhtParameter?.temperature}° C</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-text-height text-primary" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                            {t('common.height')}
                                                            </span>
                                                            <p className="fs-16 text-dark">
                                                                <strong>{consultation.healhtParameter?.height} Cm</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-weight text-primary" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                            {t('common.weight')}
                                                            </span>
                                                            <p className="fs-16 text-dark">
                                                                <strong>{consultation.healhtParameter?.weight} Kg</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-heartbeat text-primary" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                            {t('client.systolicPressure')}
                                                            </span>
                                                            <p className="fs-16 text-dark">
                                                                <strong>{consultation.healhtParameter?.bloodpressionS} mmHg</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-heartbeat text-primary" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                            {t('client.DiastolicPressure')}
                                                            </span>
                                                            <p className="fs-16 text-dark">
                                                                <strong>{consultation.healhtParameter?.bloodPressionD} mmHg</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="media">
                                                        <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                            <i className="las la-balance-scale text-primary" style={{fontSize: '30px'}}></i>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="d-block text-primary mb-2">
                                                               {t('common.imc')}
                                                            </span>
                                                            <p className="fs-16 text-dark">
                                                                <strong>{consultation.healhtParameter?.imc}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-xl-4 col-xxl-4 col-lg-4">
                                    <div className="card">
                                        <div className="card-header border-0 pb-0 d-flex justify-content-between">
                                            <h4 className="fs-20 font-w600 mb-0">
                                                {t('common.intervenants')}
                                            </h4>
                                        </div>
                                        <div className="card-body pt-4">
                                            <PerfectScrollbar
                                                id="DZ_W_Todo2"
                                                className="widget-media dz-scroll ps ps--active-y height370"
                                            >
                                                <ul className="timeline">
                                                    <li>
                                                        <div className="timeline-panel bgl-dark flex-wrap border-0 p-3 rounded">
                                                            <div className="media bg-transparent mr-2">
                                                                <img
                                                                className="rounded-circle"
                                                                alt="widget"
                                                                width={48}
                                                                src={widget8}
                                                                />
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="mb-1 fs-18">{consultation.consultationBon?.doctorName}</h5>
                                                                <span>{t('common.doctor')}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-8 col-xxl-8 col-lg-8">
                                    <div className="card">
                                        <div className="card-header border-0 pb-0 d-flex justify-content-between">
                                            <h4 className="fs-20 font-w600 mb-0">
                                            {t('accord.consultation-data')}
                                            </h4>
                                        </div>
                                        <div className="card-body pt-4">
                                            <PerfectScrollbar
                                                id="DZ_W_Todo2"
                                                className="widget-media dz-scroll ps ps--active-y height370"
                                            >
                                                <div className="row">
                                                
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-allergies" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('common.allergies')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.allergies.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-users" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('consultation.family-history')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.famAnts.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-user" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                {t('consultation.personal-history')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.persAnts.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-smoking" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                {t('consultation.habits')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.habits.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-briefcase" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('common.job')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.identities.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-search" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('common.inspection')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.inspections.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-hand-paper" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('common.palpation')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.palpations.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-stethoscope" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('common.auscultation')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.oscultations.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-gavel" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('common.percussion')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    <strong>{consultation.consultationQuestion?.percussions.toString()}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="media">
                                                            <span className="p-2 border border-primary-light rounded-circle mr-3">
                                                                <i className="las la-gavel" style={{fontSize: '30px'}}></i>
                                                            </span>
                                                            <div className="media-body">
                                                                <span className="d-block text-primary mb-2">
                                                                    {t('common.observations')}
                                                                </span>
                                                                <p className="fs-14 text-dark">
                                                                    {consultation.consultationQuestion?.observations}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">{t('common.prescriptions')}</h4>
                                        </div>
                                        <div className="card-body">
                                            <TabView>
                                                <TabPanel header={"Laboratoire("+consultation.consultationBon?.prescription.examensBon?.reduce((sum, elt) => {return sum + elt.examenBonDetail.length}, 0)+')'}>
                                                    {consultation.consultationBon?.prescription.examensBon?.map(bon => {
                                                        return <>
                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.code}</h3>
                                                            <div className="border-top my-2"></div>
                                                            <ExamenBonsTable data={bon.examenBonDetail} history={history} />
                                                        </>
                                                    })}
                                                </TabPanel>
                                                <TabPanel header={"Pharmacie("+consultation.consultationBon?.prescription.pharmacieBon?.reduce((sum, elt) => {return sum + elt.pharmacieBonDetails.length}, 0)+')'}>
                                                    {consultation.consultationBon?.prescription.pharmacieBon?.map(bon => {
                                                        return <>
                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.code}</h3>
                                                            <div className="border-top my-2"></div>
                                                            <MedicamentsTable data={bon.pharmacieBonDetails} respondDemande={respondDemande} />
                                                        </>
                                                    })}
                                                </TabPanel>
                                                <TabPanel header={"Actes Spécialisés("+consultation.consultationBon?.prescription.acteSpecialiseBon?.reduce((sum, elt) => {return sum + elt.specialiseBonDetails.length}, 0)+')'}>
                                                    {consultation.consultationBon?.prescription.acteSpecialiseBon?.map(bon => {
                                                        return <>
                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.code}</h3>
                                                            <div className="border-top my-2"></div>
                                                            <ActesSpecialisesTable data={bon.specialiseBonDetails} />
                                                        </>
                                                    })}
                                                </TabPanel>
                                                <TabPanel header={"Optique("+consultation.consultationBon?.prescription.opticBon?.reduce((sum, elt) => {return sum + elt.opticBonDetails.length}, 0)+')'}>
                                                    {consultation.consultationBon?.prescription.opticBon?.map(bon => {
                                                        return <>
                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.code}</h3>
                                                            <div className="border-top my-2"></div>
                                                            <OptiqueBonsTable data={bon.opticBonDetails} />
                                                        </>
                                                    })}
                                                </TabPanel>
                                                <TabPanel header={"Hospitalisation("+consultation.consultationBon?.prescription.hospitalisationBon?.reduce((sum, elt) => {return sum + elt.hospitalisationBonDetail.length}, 0)+')'}>
                                                    {consultation.consultationBon?.prescription.hospitalisationBon?.map(bon => {
                                                        return <>
                                                            <h3 className='mt-2 text-dark text-start'>{t('common.bonNum')}: {bon.code}</h3>
                                                            <div className="border-top my-2"></div>
                                                            <HospiBonTable data={bon.hospitalisationBonDetail} />
                                                        </>
                                                    })}
                                                </TabPanel>
                                            </TabView>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>}




                        <div
                            className="col-4 card chat dz-chat-history-box"
                        >
                            <div className="card-header chat-list-header justify-content-center text-center">
                                <div>
                                    <h6 className="mb-1">{accordPrea.dossierConsultation?.consultationBon?.doctorName}</h6>
                                    <p className="mb-0 text-success">{accordPrea.acteName}</p>
                                </div>
                            </div>
                            <PerfectScrollbar
                                className="card-body msg_card_body dz-scroll ps ps--active-y height370"
                                id="DZ_W_Contacts_Body3"
                                scrollTop="100"
                            >
                                {  accordPrea.accordPreableDisccusions?.map(messag => {
                                    if(messag.senderId === currentUser.id){
                                        return <div className="d-flex justify-content-end mb-4">
                                                    <div className="msg_cotainer_send">
                                                        {messag.message}
                                                        {messag.docUrl && <a
                                                            href={messag.docUrl}
                                                            target="_blank"
                                                            className="btn btn-light btn-rounded py-1"
                                                            >
                                                            <i className="las la-file-download mr-2"></i>
                                                            <small>{t('common.document')}</small>
                                                        </a>}
                                                        <span className="msg_time_send">{messag.createdAt}</span>
                                                    </div>
                                                    <div className="img_cont_msg">
                                                        <img
                                                            src={avatar2}
                                                            className="rounded-circle user_img_msg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                    }else{
                                        return <div className="d-flex justify-content-start mb-4">
                                        <div className="img_cont_msg">
                                            <img
                                                src={avatar1}
                                                className="rounded-circle user_img_msg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="msg_cotainer">
                                            {messag.message}
                                            {messag.docUrl && <a
                                                href={messag.docUrl}
                                                target="_blank"
                                                className="btn btn-light btn-rounded py-1"
                                                >
                                                <i className="las la-file-download mr-2"></i>
                                                <small>{t('common.document')}</small>
                                            </a>}
                                            <span className="msg_time">{messag.createdAt}</span>
                                        </div>
                                    </div>

                                    }
                                })}
                                {accordPrea.status === STATUSES.resused  && <div className="d-flex justify-content-end mb-4">
                                        <div className="msg_cotainer_send bg-danger">
                                            {t('accord.rejected-for')}: {accordPrea.motifRejet}
                                            <span className="msg_time_send">{accordPrea.updatedAt}</span>
                                        </div>
                                        <div className="img_cont_msg">
                                            <img
                                                src={avatar2}
                                                className="rounded-circle user_img_msg"
                                                alt=""
                                            />
                                        </div>
                                </div>}
                                {accordPrea.status === STATUSES.accepted  && <div className="d-flex justify-content-end mb-4">
                                        <div className="msg_cotainer_send bg-success">
                                        {t('accord.valided-accord')}
                                            <span className="msg_time_send">{accordPrea.updatedAt}</span>
                                        </div>
                                        <div className="img_cont_msg">
                                            <img
                                                src={avatar2}
                                                className="rounded-circle user_img_msg"
                                                alt=""
                                            />
                                        </div>
                                </div>}
                            </PerfectScrollbar>
                            {accordPrea.status === STATUSES.pending && <div className="card-footer">
                                <div className="mb-2">
                                    {suggestions.map((suggestion, i) => {
                                        return (
                                            <Badge variant="dark light mr-1 mb-1" key={i} onClick={(e) => setMessage(suggestion)}>
                                                <span>{suggestion}</span>
                                            </Badge>
                                        )
                                    })}
                                </div>
                                <form onSubmit={e => sendMessage(e)}>
                                    <div className="row">
                                        <textarea
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="form-control mb-2"
                                            cols={20}
                                            required
                                            placeholder="Type your message..."
                                        ></textarea>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='custom-file'>
                                            <input type="file" className='custom-file-input' onChange={(e) => loadFile(e)} />
                                            <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                        </div>
                                    </div>
                                    <div className=" row input-group-append d-flex justify-content-end">
                                        <button type="submit" className="btn btn-primary">
                                            <i className="fa fa-location-arrow"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>}
                        </div>
                </div>
                </>
            }
        </>
    )
};

/* --- END AccordPreaView */

const mapStateToProps = (state) => {
    return {
        accordPrea: state.accordPrea.accordPrea,
        consultation: state.consultation.consultation,
        showLoadingConslt: state.consultation.showLoading,
        showLoading: state.accordPrea.showLoading,
        currentUser: currentUser(state),
    };
};

export default withRouter(connect(mapStateToProps)(AccordPreaView));