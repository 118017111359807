import React, {Fragment, useState,useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import BasicDatatable from '../DataTable';
import { Link } from "react-router-dom";
import {
    getTarifsAction,
    startLoader,
    getActsByFamiliesAction,
    createTarifAction,
    updateTarifAction,
    deleteDataAction,
    importTarifFileUploadAction
} from '../../../../store/actions/ProviderActions';

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Spinner, Button, Modal, ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import DateFnsUtils from "@date-io/date-fns";
import Select from 'react-select';
import swal from "sweetalert";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTableBase } from '../../DataTables/DataTableBase';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { useTranslation } from "react-i18next";


const Tarif = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let errorsObj = { name: '', code: '' };
    const [errors, setErrors] = useState(errorsObj);

    const { families, tarifs, showLoading, history } = props;

    const [tarifId, setTarifId] = useState("");
    const [typePrestaId, setTypePrestaId] = useState("");
    const [nameFr, setNameFr] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [newTarifData, setNewTarifData] = useState({});
    const [loadedActs, setLoadedActs] = useState([]);
    const [currentActs, setCurrentActs] = useState([]);
    const [createdTarifData, setCreatedTarifData] = useState([]);
    const [choosenFamily, setChoosenFamily] = useState("");
    const [keySearch, setKeySearch] = useState("");
    const [updateMode, setUpdateMode] = useState(false);
    const [providerTypeId, setProviderTypeId] = useState("");
    const [lettreCleValue, setLettreCleValue] = useState([]);
    const [typeTarif, setTypeTarif] = useState('VALLETTERKEY');
    const [file, setFile] = useState(null);
    //Modal box
    const [addCard, setAddCard] = useState(false);
    const header =["Code",t("common.name"),t("common.create-date"),"Action"];
    const typesTarifs = [
        {label: "Tarif par lettre clé", value: "VALLETTERKEY"},
        {label: "Tarif par valeur", value: "VALMONTANTACTE"}
    ];

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nameFr: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            totalActes: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {name: 'id', headerName: "Code", filterPlaceholder: t("provider.code-filter"), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'nameFr', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: null},
            {name: 'totalActes', dataType: 'numeric', headerName: t("common.total-act"), filterPlaceholder: t("common.drug-total-act"), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: null},
            {name: 'createdAt', filterField: 'createdAt', dataType: 'date', headerName: t("patient.created-on"), filterPlaceholder: t('facturation.create-date-filter'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: datePaiementBodyTemplate, filterElement: dateFilterTemplate},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };
    
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'UPDATETARIF') && <Link href="#" to="#" data-testid={'update-button-'+rowData.id} onClick={e => updateTarif(e, rowData)} className="mr-4" title="Edit">
                <i className="fa fa-pencil color-muted"></i>{" "}
            </Link>}
            {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'DELETETARIF') && <Link href="#" to="#" data-testid={'delete-button-'+rowData.id} title="Close"
                onClick={() =>
                    swal({
                        title: t("provider.delete-rate")+rowData.nameFr+" ?",
                        text:
                            t("patient.delete-rate-mes"),
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            let data = {
                                type: "TARIF",
                                id: rowData.id
                            }
                            dispatch(startLoader());
                            dispatch(deleteDataAction(data));
                        }
                    })
                    }
            >
                <i className="fa fa-close color-danger"></i>
            </Link>}
        </div>
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const datePaiementBodyTemplate = (rowData) => {
        return formatDate(rowData.createdAt);
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    function selectFile(event) {
        setFile(event.target.files[0])
    }

    const chooseFamily = (val) => {
        setChoosenFamily(val.value);
        let fam = families.filter(f => {return f.id === val.value})[0];
        setLoadedActs(fam.actes.map(act => {
            if(act.isMultiple || createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                act["value"] = 0;
                return act;
            }else{
                return
            }
        }).filter(el => { return el}));

        setCurrentActs(fam.actes.map(act => {
            if(act.isMultiple || createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                act["value"] = 0;
                return act;
            }else{
                return
            }
        }).filter(el => { return el}));

        let temp = [];
        setLettreCleValue(fam.actes.map(act => {
            let res = null;
            if(!temp.includes(act.lettreCle.code)){
                res = {keyLetter: act.lettreCle.code, price: 0}
                temp.push(act.lettreCle.code);
            }
            return res;
        }).filter(el => { return el}));
    }

    const addDataToNewTarif = (acte) => {
        if(acte.isMultiple){
            if(createdTarifData.filter(elt => { return elt.id === acte.id}).length === 0){
                let newAct = {
                    "id": acte.id,
                    "typeActeId": acte.typeActeId,
                    "lettreCleId": acte.lettreCleId,
                    "garantieId": acte.garantieId,
                    "acteProdCode": acte.acteProdCode,
                    "acteProdName": acte.acteProdName,
                    "coefLettre": acte.coefLettre,
                    "code": acte.code,
                    "type": acte.type,
                    "nameFr": acte.nameFr,
                    "nameEn": acte.nameEn,
                    "delayCarence": acte.delayCarence,
                    "isActif": acte.isActif,
                    "isAccord": acte.isAccord,
                    "isAlert": acte.isAlert,
                    "isExclu": acte.isExclu,
                    "isMultiple": acte.isMultiple,
                    "isMultiple": acte.isMultiple,
                    "isAmbulatoire": acte.isAmbulatoire,
                    "isHospitalisation": acte.isHospitalisation,
                    "lettreCle": {
                        "id": acte.lettreCle.id,
                        "code": acte.lettreCle.code,
                        "value": acte.lettreCle.value
                    },
                    "sousActes": acte.sousActes,
                    "acteParTypePrestataire": acte.acteParTypePrestataire,
                    "value": acte.value
                };
                setCreatedTarifData([...createdTarifData, newAct]);
            }else{
                let allPrices = createdTarifData.filter(elt => { return elt.id === acte.id}).map(elt => elt.value);
                if(!allPrices.includes(acte.value)){
                    let newAct = {
                        "id": acte.id,
                        "typeActeId": acte.typeActeId,
                        "lettreCleId": acte.lettreCleId,
                        "garantieId": acte.garantieId,
                        "acteProdCode": acte.acteProdCode,
                        "acteProdName": acte.acteProdName,
                        "coefLettre": acte.coefLettre,
                        "code": acte.code,
                        "type": acte.type,
                        "nameFr": acte.nameFr + ' ' + allPrices.length,
                        "nameEn": acte.nameEn + ' ' + allPrices.length,
                        "delayCarence": acte.delayCarence,
                        "isActif": acte.isActif,
                        "isAccord": acte.isAccord,
                        "isAlert": acte.isAlert,
                        "isExclu": acte.isExclu,
                        "isMultiple": acte.isMultiple,
                        "isMultiple": acte.isMultiple,
                        "isAmbulatoire": acte.isAmbulatoire,
                        "isHospitalisation": acte.isHospitalisation,
                        "lettreCle": {
                            "id": acte.lettreCle.id,
                            "code": acte.lettreCle.code,
                            "value": acte.lettreCle.value
                        },
                        "sousActes": acte.sousActes,
                        "acteParTypePrestataire": acte.acteParTypePrestataire,
                        "value": acte.value
                    };
                    setCreatedTarifData([...createdTarifData, newAct]);
                }
            }
        }else{
            if(createdTarifData.filter(elt => { return elt.id === acte.id}).length === 0){
                setLoadedActs(loadedActs.filter(elt => { return elt.id !== acte.id }));
                let newAct = {
                    "id": acte.id,
                    "typeActeId": acte.typeActeId,
                    "lettreCleId": acte.lettreCleId,
                    "garantieId": acte.garantieId,
                    "acteProdCode": acte.acteProdCode,
                    "acteProdName": acte.acteProdName,
                    "coefLettre": acte.coefLettre,
                    "code": acte.code,
                    "type": acte.type,
                    "nameFr": acte.nameFr,
                    "nameEn": acte.nameEn,
                    "delayCarence": acte.delayCarence,
                    "isActif": acte.isActif,
                    "isAccord": acte.isAccord,
                    "isAlert": acte.isAlert,
                    "isExclu": acte.isExclu,
                    "isMultiple": acte.isMultiple,
                    "isMultiple": acte.isMultiple,
                    "isAmbulatoire": acte.isAmbulatoire,
                    "isHospitalisation": acte.isHospitalisation,
                    "lettreCle": {
                        "id": acte.lettreCle.id,
                        "code": acte.lettreCle.code,
                        "value": acte.lettreCle.value
                    },
                    "sousActes": acte.sousActes,
                    "acteParTypePrestataire": acte.acteParTypePrestataire,
                    "value": acte.value
                };
                setCreatedTarifData([...createdTarifData, newAct]);
            }
        }
    }

    const removeDataToNewTarif = (acte) => {
        if(!acte.isMultiple){
            if(loadedActs.filter(elt => { return elt.id === acte.id}).length === 0){
                setCreatedTarifData(createdTarifData.filter(elt => {return elt.id !== acte.id}));
                setLoadedActs([...loadedActs, acte]);
            }
        }else{
            setCreatedTarifData(createdTarifData.filter(elt => {return (elt.nameFr !== acte.nameFr)}
            ));
        }
    }

    const filterActs = (val) => {
        setKeySearch(val);
        if(val.trim().length > 0){
            setLoadedActs(currentActs.map(act => {
                if(createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                    return act;
                }else{
                    return
                }
            }).filter(el => { return el}).filter(acte => {return acte.nameFr.toLowerCase().includes(val.toLowerCase())}));
        }else{
            setLoadedActs(currentActs.map(act => {
                if(createdTarifData.filter(elt => { return elt.id === act.id}).length === 0){
                    return act;
                }else{
                    return
                }
            }).filter(el => { return el}));
        }
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        initFilters();
        dispatch(startLoader());
        dispatch(getTarifsAction());  
        dispatch(getActsByFamiliesAction());
    }, []);

    function onTarifSave(e) {
        e.preventDefault();
        dispatch(startLoader());
        
        if(file){
            dispatch(importTarifFileUploadAction(file));
            return ;
        }

        if(updateMode) {
            let data = {
                nameFr,
                nameEn: nameFr,
                providerTypeId,
                typeTarif,
                newTarif: createdTarifData.map(tarf => { return {acteId: tarf.id, value: tarf.value}})
            };
            dispatch(updateTarifAction(tarifId, data));
        }else{
            let data = {
                nameFr,
                nameEn: nameFr,
                providerTypeId,
                typeTarif,
                newTarif: createdTarifData.map(tarf => { return {acteId: tarf.id, value: tarf.value}})
            };
            dispatch(createTarifAction(data));
        }
    }

    useEffect(() => {
        setUpdateMode(false);
        setTarifId("");
        setNameFr("");
        setNameEn("");
        setCreatedTarifData([]);
        setAddCard(false);
        setTypeTarif('VALLETTERKEY');
        setLoadedActs([]);
        setCurrentActs([]);
        setCreatedTarifData([]);
        setChoosenFamily("");
        setKeySearch("");
        setLettreCleValue([]);
        setAddCard(false);
        //dispatch(startLoader());
        //dispatch(getTarifsAction()); 
    }, [props.tarifs]);

    const updateTarif = (e, tarif) => {
        e.preventDefault();
        setUpdateMode(true);
        setTarifId(tarif.id);
        setNameFr(tarif.nameFr);
        setNameEn(tarif.nameEn);
        setProviderTypeId(tarif.providerTypeId);
        setCreatedTarifData(tarif.tarifActes.map(tarf => {
            return {
                id: tarf.acteId,
                value: tarf.value, 
                nameFr: tarf.acte.nameFr, 
                nameEn: tarf.acte.nameEn, 
                acte: tarf.acte,
                coefLettre: tarf.acte.coefLettre
            }
        }));
        setAddCard(true);
        setTypeTarif(tarif.typeTarif);
    }

    const openCard = () => {
        setUpdateMode(false);
        setTarifId("");
        setNameFr("");
        setNameEn("");
        setCreatedTarifData([]);
        setAddCard(false);
        setTypeTarif('VALLETTERKEY');
        setLoadedActs([]);
        setCurrentActs([]);
        setCreatedTarifData([]);
        setChoosenFamily("");
        setKeySearch("");
        setLettreCleValue([]);
        setAddCard(true);
    }

    return (
        <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    {hasMenu('MENPARAMACTES') && canDo('MENPARAMACTES', 'CREATETARIF') && <Link
                        to="#"
                        className="btn btn-primary btn-rounded"
                        onClick={() => openCard()}
                    >
                        + Nouveau Tarif
                    </Link>}
                </div>
            </div>
            <Modal className="modal fade" size="lg" show={addCard} onHide={setAddCard} >
                <div className="">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">{updateMode ? 'Modifier un tarif' : 'Créer un nouveau tarif'}</h4>
                        </div>
                        <div className='card-body'>
                            <div className='basic-form'>
                                <form onSubmit={onTarifSave}>
                                    <div className="updatePanel">
                                        <div className='row justify-content-between align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('client.type')} de tarif</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={typesTarifs.filter((opt)=>{return typeTarif === opt.value}).map((val)=>{return {label:val.label,value:val.value}})}
                                                    placeholder="Choisir un type"
                                                    onChange={(val) => setTypeTarif(val.value)}
                                                    isDisabled={props.showLoading || file}
                                                    required={props.showLoading || file}
                                                    options={typesTarifs}
                                                    style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('common.name')}</strong>
                                                </label>
                                                <input type='text' 
                                                    className='form-control'
                                                    placeholder='Tarif'
                                                    value={nameFr}
                                                    disabled={props.showLoading || file}
                                                    required={props.showLoading || file}
                                                    onChange={(e) => setNameFr(e.target.value)}
                                                />
                                            </div>
                                            {/*<div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>Nom En</strong>
                                                </label>
                                                <input type='text' className='form-control'
                                                    value={nameEn}
                                                    required
                                                    onChange={(e) => setNameEn(e.target.value)}
                                                />
                                            </div>**/}
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t('common.type-provider')}</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={props.providerTypes?.filter((opt)=>{return providerTypeId === opt.id}).map((val)=>{return {label:val.nameFr,value:val.id}})}
                                                    placeholder={t("consultation.choose-type")}
                                                    onChange={(val) => setProviderTypeId(val.value)}
                                                    isDisabled={props.showLoading || file}
                                                    required={props.showLoading || file}
                                                    options={props.providerTypes?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                                    style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row justify-content-start align-items-center'>
                                            <div className='col-6 form-group'>
                                                <label className='mb-1 '>
                                                    <strong>{t("common.choose-family")}</strong>
                                                </label>
                                                
                                                <Select
                                                    selected={choosenFamily}
                                                    onChange={chooseFamily}
                                                    options={families?.map((opt)=>{return {label:opt.nameFr, value:opt.id}})}
                                                    placeholder={t("common.choose-family")}
                                                    style={{
                                                    lineHeight: '40px',
                                                    color: '#7e7e7e',
                                                    paddingLeft: ' 15px',
                                                    }}
                                                />
                                                {/*<SelectSearch
                                                    className="select-search"
                                                    options={families?.map((opt)=>{return {name:opt.nameFr, value:opt.id}})}
                                                    //filterOptions={handleCityFilter}
                                                    value={choosenFamily}
                                                    name="familly"
                                                    placeholder="Choisir la famille"
                                                    search
                                                    onChange={chooseFamily}
                                                />*/}
                                            </div>
                                        </div>


                                        <h5 className='mt-4 text-primary'>{t("provider.choice-act")}</h5>

                                        {typeTarif === "VALLETTERKEY" && lettreCleValue.length > 0 && <div className='row'>
                                            {lettreCleValue.map((lettreCle, i) => {
                                                return  <div className='col-2 form-group'>
                                                            <label className='mb-1 '>
                                                                <strong>{lettreCle.keyLetter}</strong>
                                                            </label>
                                                            <input type='number' className='form-control'
                                                                value={lettreCleValue[i].price}
                                                                required
                                                                onChange={(e) => {
                                                                    setLettreCleValue(lettreCleValue.map(elt => {
                                                                        if(elt.keyLetter === lettreCle.keyLetter){
                                                                            elt.price = e.target.value
                                                                        }
                                                                        return elt
                                                                    }))

                                                                    setLoadedActs(loadedActs.map(act => {
                                                                        if(act.lettreCle.code === lettreCle.keyLetter){
                                                                            act.value = e.target.value
                                                                        }
                                                                        return act;
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                            })}
                                        </div>}

                                        <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                                            <div className="input-group search-area ml-auto d-inline-flex mr-3 ">
                                                <input
                                                type="text"
                                                className="form-control border border-primary"
                                                value={keySearch}
                                                placeholder={t("provider.filter-act")}
                                                onChange={e => filterActs(e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                <button type="button" className="input-group-text border border-primary">
                                                    <i className="flaticon-381-search-2" />
                                                </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="actesPanel row">
                                            <div className="col-6 border">
                                                {
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height370"
                                                    >
                                                        <ListGroup as="ul" variant="flush">
                                                            {loadedActs.map((acte, i) => (
                                                                <Fragment key={i}>
                                                                    <ListGroup.Item as="li">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-6">
                                                                                <b>{acte?.nameFr}</b>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                {typeTarif === "VALLETTERKEY" && <b>{acte.lettreCle?.code} * {acte.coefLettre} = {acte.value * acte.coefLettre}</b>}
                                                                                {typeTarif === "VALMONTANTACTE" && <input type='number' className='form-control'
                                                                                    value={loadedActs[i]?.value
                                                                                        //* loadedActs[i]?.coefLettre
                                                                                    }
                                                                                    onChange={(e) => setLoadedActs(loadedActs.map(elt => {
                                                                                            if(elt.id === acte.id) {
                                                                                                elt.value = e.target.value;
                                                                                            }
                                                                                            return elt;
                                                                                        })
                                                                                    )}
                                                                                />}
                                                                            </div>
                                                                            <div className="col-2">
                                                                                {((typeTarif === "VALLETTERKEY" && (acte.value * acte.coefLettre) > 0) || (typeTarif === "VALMONTANTACTE" && loadedActs[i]?.value > 0)) && <button
                                                                                    type="button"
                                                                                    onClick={() => addDataToNewTarif(acte)}
                                                                                    className="btn btn-outline-primary btn-rounded mb-2 mr-1"
                                                                                >
                                                                                    +
                                                                                </button>}
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Fragment>
                                                            ))}
                                                        </ListGroup>
                                                    </PerfectScrollbar>
                                                }
                                            </div>
                                            
                                            
                                            <div className="col-6 border">
                                                {
                                                    <PerfectScrollbar
                                                        id="DZ_W_Todo2"
                                                        className="widget-media dz-scroll ps ps--active-y height370"
                                                    >
                                                        <ListGroup as="ul" variant="flush">
                                                            {createdTarifData.map((acte, i) => (
                                                                <Fragment key={i}>
                                                                    <ListGroup.Item as="li">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-6">
                                                                                <b>{acte.nameFr}</b>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                {typeTarif === "VALLETTERKEY" && <b>{acte.value * parseInt(acte.coefLettre)}</b>}
                                                                                {typeTarif === "VALMONTANTACTE" && <b>{acte.value}</b>}
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => removeDataToNewTarif(acte)}
                                                                                    className="btn btn-outline-primary btn-rounded mb-2 mr-1"
                                                                                >
                                                                                    -
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Fragment>
                                                            ))}
                                                        </ListGroup>
                                                    </PerfectScrollbar>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                    <hr />
                                    <div className='row text-center'>
                                        <h4 className='text-center'>{t("common.import-csv-rate")}</h4>
                                    </div>
                                    <div className='input-group mb-3'>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text'>CSV</span>
                                        </div>
                                        <div className='custom-file'>
                                            <input type='file' className='custom-file-input' onChange={selectFile} />
                                            <label className='custom-file-label'>{file ? file.name : t('common.choose-file')}</label>
                                        </div>
                                    </div>
                                    <div className='form-group align-items-center justify-content-center'>
                                        <div className=''>
                                            { props.showLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                                </Button> }
                                            { !props.showLoading &&
                                                <button type='submit' className='btn btn-primary'>
                                                  {t('common.save')}
                                                </button> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            
            <DataTableBase 
                data={props.tarifs.length > 0 ? props.tarifs.map(acte => {
                        acte.createdAt = new Date(acte.createdAt);
                        acte.totalActes = acte.tarifActes.length;
                        return acte;
                }) : []}
                emptyMessage={t("common.no-rate")}
                filters={filters}
                globalFiltersFields={['id', 'nameFr', 'totalActes', 'createdAt']}
                header={renderHeader()}
                columns={setColumns()}
                exportColumns={[
                    { title: 'Code', dataKey: 'id' },
                    { title: t('common.name'), dataKey: 'nameFr' },
                    { title: t("common.total-act"), dataKey: 'totalActes' },
                    { title: t('common.create-date'), dataKey: 'createdAt' },
                ]}
                rows={10} 
                showExport={true}
                exportFilename={"tarifs"}
                loading={props.showLoading}
                lazy={false}
            />
            {/*<BasicDatatable header={header} body={tableBody}></BasicDatatable>*/}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        families: state.provider.families,
        tarifs: state.provider.tarifs,
        providerTypes: state.provider.providerTypes,
        showLoading: state.provider.showLoading,
        successMessage: state.provider.successMessage,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Tarif);