import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    updatePassword
} from '../../services/AuthService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

var I18n = require('react-redux-i18n').I18n;

export function signupAction(email, password, history) {
    /*return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/');
        })
        .catch((error) => {
            const errorMessage = formatError(error);
            dispatch(signupFailedAction(errorMessage));
        });
    };*/
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((res) => {
                //saveTokenInLocalStorage(response.data);
                let response = {
                    data: {
                        message: "Welcome "+res.data.username, 
                        statusCode: "LOGIN-SUCCESS", 
                        token: res.data.token,
                        expiresIn: 60000,
                        id: res.data.id,
                        username: res.data.username,
                        email: res.data.email,
                        roles: res.data.roles,
                        profils: res.data.profils,
                        menus: res.data.menus,
                        sasurl: res.data.sasurl,
                    }
                };
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                
                dispatch(loginConfirmedAction(response.data));
                
                successToastr(I18n.t('auth.welcome')+res.data.username);
				history.push('/dashboard');

                window.location.reload();
                
            })
            .catch((error) => {
                /*let response = {
                    data: {
                        message: "Welcome ", 
                        statusCode: "LOGIN-SUCCESS", 
                        token: "123456789456",
                        expiresIn: 60000,
                        username: "res.data.username",
                        email: "res.data.email",
                        roles: [],
                        providerId: "res.data.providerId",
                        profils: [],
                        menus: []
                    }
                };
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
				history.push('/dashboard');*/
                
                errorToastr(I18n.t('auth.error')+error.response?.data?.message );
                //const errorMessage = formatError(error);
                dispatch(loginFailedAction(error.response?.data?.message));
            });
    };
}

export function updatePasswordAction(data, history) {
    return (dispatch) => {
        updatePassword(data)
            .then((res) => {
                dispatch(logout(history));
                successToastr(I18n.t('auth.updatePass.success'));
            })
            .catch((error) => {
                errorToastr(I18n.t('auth.updatePass.error')+error.response?.data?.message);
                dispatch(loadingToggleAction(false));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
