import React, { useState, useEffect, useRef } from 'react';
import {Spinner, Button} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
    createProviderAction,
    updateProviderAction,
    startLoader,
    getProvidersTypesAction,
    getProviderDataAction,
} from '../../../../store/actions/ProviderActions';
import { getProfilAction } from '../../../../store/actions/UserAction';
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { getParameterAction
} from '../../../../store/actions/AdministrationAction';
import SelectSearch from "react-select-search";
import '../../Clients/Styles/selectStyle.css';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Steps } from 'primereact/steps';
import { useTranslation } from "react-i18next";
//import './ProviderForm.css';

const ProviderForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const { id } = useParams();
    const { providers, provider, history, showLoading } = props;

    const items = [
        {
            label: t("client.gen-info")
        },
        {
            label: t("common.admin-info")
        },
        {
            label: t("client.localization")
        },
        {
            label: t("common.payemnt-info")
        },
        {
            label: t('client.confirmation')
        }
    ];

    const [choosenCountry, setChoosenCountry] = useState([]);
    

    const [providerTypeId, setProviderTypeId] = useState([]);
    const [providerName, setProviderName] = useState("");
    const [numeroAutorisation, setNumeroAutorisation] = useState("");
    const [numeroAutorisationGouv, setNumeroAutorisationGouv] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [whatsappPhone, setWhatsappPhone] = useState("");
    const [email, setEmail] = useState("");
    const [imgSrc, setImgSrc] = useState(null);

    const [lon, setLon] = useState(0);
    const [lat, setLat] = useState(0);
    const [postal, setPostal] = useState("");
    const [idCity, setIdCity] = useState("");
    const [idCountry, setIdCountry] = useState("");
    
    const [conventionNumber, setConventionNumber] = useState("");
    const [niu, setNiu] = useState("");
    const [taxType, setTaxType] = useState("");
    const [taxValue, setTaxValue] = useState(0);
    const [contribuable, setContribuable] = useState("");
    const [exerciceYear, setExerciceYear] = useState(2022);
    
    const [identity, setIdentity] = useState('Médecin');
    const [profilIds, setProfilIds] = useState([]);

    const [bankName, setBankName] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [swift, setSwift] = useState("");
    const [numeroBanque, setNumeroBanque] = useState("");
    const [cleRib, setCleRib] = useState("");
    const [intituleBanque, setIntituleBanque] = useState("");
    const [fileSrc, setFileSrc] = useState(null);
 
    const [paymentMeans, setPaymentMeans] = useState([]);
    

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if(hasMenu('MENPRESTATAIREADMIN') && (canDo('MENPRESTATAIREADMIN', 'CREATEPRESTATAIRE') || canDo('MENPRESTATAIREADMIN', 'UPDATEPRESTATAIRE'))){
            if(id){
                dispatch(startLoader());
                dispatch(getProviderDataAction(id, history));
            }
            dispatch(getParameterAction());
            dispatch(getProvidersTypesAction());  
            dispatch(getProfilAction());
        }else{
            props.history.push('/access-denied');
        }  
    }, []);

    useEffect(() => {
    }, [props.profilsData]);

    useEffect(() => {
        if(id){
            setProviderTypeId(provider.type?.map(elt => {return elt.providerTypeId}));
            setProviderName(provider.providerName);
            setNumeroAutorisation(provider.numeroAutorisation);
            setNumeroAutorisationGouv(provider.numeroAutorisationGouv);
            setPhoneNumber(provider.phoneNumber);
            setWhatsappPhone(provider.whatsappPhone);
            setEmail(provider.email);
        
            setLon(provider.lon);
            setLat(provider.lat);
            setPostal(provider.postal);
            setIdCity(provider.cityId);
            setIdCountry(provider.countryId);
            //setChoosenCountry(props.admin.countries?.filter((country) =>{return country.id === provider.countryId}));
            
            setConventionNumber(provider.conventionNumber);
            setNiu(provider.niu);
            setTaxType(provider.details ? provider.details[provider.details.length - 1]?.taxType : "");
            setTaxValue(provider.details ? provider.details[provider.details?.length - 1]?.taxValue : 0);
            setContribuable(provider.contribuable);
            setExerciceYear(provider.details ? provider.details[provider.details?.length - 1]?.exerciceYear : 2022); 
            setProfilIds(provider.profils?.map(elt => {return elt.id}) || []) ; 
        }
    }, [provider]);

    useEffect(() => {
        if(id && provider && props.admin.countries) {
            setChoosenCountry(props.admin.countries?.filter((country) =>{return country.id === provider.countryId}));
        }
    }, [props.admin, provider]);


    const saveFile = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        setImgSrc(upFiles[keys[0]]);
    }

    async function onSave(e) {
        e.preventDefault();
        
        dispatch(startLoader());


        if(id) {
            let providerDatas = {
                providerTypeId,
                providerName,
                phoneNumber,
                whatsappPhone,
                numeroAutorisation,
                numeroAutorisationGouv,
                lon,
                lat,
                postal,
                cityId: idCity,
                countryId: idCountry,
                email,
                conventionNumber,
                niu,
                contribuable,
                details: {
                    detailId: provider.details[provider.details.length - 1]?.id,
                    taxType,
                    taxValue,
                    exerciceYear,
                },
                paymentMeans
            };
            dispatch(updateProviderAction(imgSrc, id, providerDatas, props.history));
        }else{
            let providerDatas = {
                providerTypeId,
                providerName,
                phoneNumber,
                whatsappPhone,
                numeroAutorisation,
                numeroAutorisationGouv,
                lon,
                lat,
                postal,
                cityId: idCity,
                countryId: idCountry,
                email,
                conventionNumber,
                niu,
                contribuable,
                details: {
                    taxType,
                    taxValue,
                    exerciceYear,
                },
                profils: profilIds,
                bankDetail: {
                    bankName,
                    fileSrc,
                    bankCode,
                    swift,
                    rib: cleRib,
                    accountName: intituleBanque,
                    accountNumber: numeroBanque,
                },
                paymentMeans,
            };
            dispatch(createProviderAction(imgSrc, providerDatas, props.history));
        }
    }

    const addRIB = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        setFileSrc(upFiles[keys[0]]);
    }

    const loadIdentityFile = (e, paymentMean) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        setPaymentMeans(paymentMeans.map(payM => { 
            if(payM.id === paymentMean.id){
                payM.file = upFiles[keys[0]];
            }
            return payM;
        }));
    }

    const canGoNext = (index) => {
        switch (index) {
            case 0:
                return providerName?.trim().length > 0 &&
                        providerTypeId?.length > 0 && 
                        numeroAutorisation?.trim().length > 0 &&
                        email?.trim().length > 0 &&
                        phoneNumber?.trim().length > 0 &&
                        (id ? true : profilIds.length > 0);
            case 1:
                return conventionNumber?.trim().length > 0 &&
                        niu?.trim().length > 0 && 
                        taxType?.trim().length > 0 &&
                        taxValue >= 0 &&
                        exerciceYear >= 2022 &&
                        contribuable?.trim().length > 0;
            case 2:
                return idCity && idCountry
            case 3:
                if(bankName.trim().length > 0 && (bankCode.trim().length === 0 || swift.trim().length === 0 || numeroBanque.trim().length === 0 || !fileSrc)){
                    return false;
                }

                if(paymentMeans.length > 0 && paymentMeans.find(elt => (elt.operator.trim().length === 0 || elt.phonenumber.trim().length === 0 || elt.beneficialName.trim().length === 0 || elt.idType.trim().length === 0 || elt.idNumber.trim().length === 0 || !elt.file))) {
                    return false;
                }
        
                return true
            default:
                return false;
        }
    }

    const canSubmitForm = () => {
        return canGoNext(0) && canGoNext(1) && canGoNext(2) && canGoNext(3);
    }

    return (
        <>
            <div className="card py-4">
                <h3 className='text-center mb-4 '>
                    {
                        id ?  "Mise à jour du prestataire: "+ provider.providerName : "Nouveau prestataire"
                    }
                </h3>
                <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={!canSubmitForm()} />
                
                <form onSubmit={onSave}>
                    {activeIndex === 0 && <div className='px-5 pt-5'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.promoter")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <select
                                    defaultValue={identity}
                                    className='form-control form-control-lg'
                                    onChange={(e) => setIdentity(e.target.value)}
                                >
                                    <option value='option'>
                                       {t("common.choose")}
                                    </option>
                                    <option value='Promoteur'>{t('common.doctor')}</option>
                                    <option value='Médecin'>{t('common.no')} {t('common.doctor')}</option>
                                </select>
                            </div>

                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.denomination")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='text' className='form-control'
                                    value={providerName}
                                    disabled={props.showLoading}
                                    required
                                    onChange={(e) => setProviderName(e.target.value)}
                                    />
                            </div>
                                    
                            {(identity === 'Promoteur') && <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.gouv-number")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='text' className='form-control'
                                    value={numeroAutorisationGouv}
                                    disabled={props.showLoading}
                                    required={identity === 'Promoteur'}
                                    onChange={(e) => setNumeroAutorisationGouv(e.target.value)}
                                    />
                            </div>}
                        </div>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.type')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                
                                <Select
                                    className="select-search"
                                    value={providerTypeId ? props.providerTypes?.filter(type => providerTypeId.includes(type.id))?.map(elt => {return {label:elt.nameFr,value:elt.id}}) : []}
                                    isMulti
                                    placeholder={t("provider.choose-type-rate")}
                                    onChange={(vals) => {
                                        setProviderTypeId(vals ? vals.map(val => val.value) : [])
                                    }}
                                    disabled={props.showLoading}
                                    required
                                    options={props.providerTypes?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                    style={{
                                    lineHeight: '40px',
                                    color: '#7e7e7e',
                                    paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.number-autho")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='text' className='form-control'
                                    value={numeroAutorisation}
                                    disabled={props.showLoading}
                                    required
                                    onChange={(e) => setNumeroAutorisation(e.target.value)}
                                    />
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.email')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div className='input-group mb-3 input-success-o'>
                                    <div className='input-group-prepend'>
                                        <span className='input-group-text'>
                                            <i className='fa fa-at' />
                                        </span>
                                    </div>
                                    <input type='email' className='form-control'
                                        value={email}
                                        disabled={props.showLoading}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                        />
                                </div>
                            </div>
                        </div> 
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>Whatsapp</strong>
                                </label>
                                
                                <div className='input-group mb-3 input-success-o'>
                                    <PhoneInput
                                        country='cd'
                                        onlyCountries={['cm','ci','cd']}
                                        localization='fr'
                                        value={whatsappPhone}
                                        autoFormat={whatsappPhone ? !whatsappPhone.startsWith('225') : true}
                                        onChange={phone => setWhatsappPhone(phone)}
                                    />
                                </div>
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("client.phone")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div className='input-group mb-3 input-success-o'>
                                    <PhoneInput
                                        country='cd'
                                        onlyCountries={['cm','ci','cd']}
                                        localization='fr'
                                        value={phoneNumber}
                                        autoFormat={phoneNumber ? !phoneNumber.startsWith('225') : true}
                                        onChange={phone => setPhoneNumber(phone)}
                                    />
                                </div>
                            </div>
                            {!id && <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.default-user")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <Select
                                    className="select-search"
                                    value={props.profilsData?.filter(elt => profilIds.includes(elt.id)).map(res => {return {label: res.nameFr, value: res.id}})}
                                    disabled={props.showLoading}
                                    placeholder={t("provider.choose-profil")}
                                    isMulti
                                    required
                                    onChange={(vals) => setProfilIds(vals ? vals.map(val => { return val.value }) : [])}
                                    options={props.profilsData?.map((opt)=>{return {label:opt.nameFr,value:opt.id}})}
                                    style={{
                                    lineHeight: '40px',
                                    color: '#7e7e7e',
                                    paddingLeft: ' 15px',
                                    }}
                                />
                            </div>}
                            {!id && <div className='col-4 form-group'></div>}

                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.picture")} {t('dashboard.provider')}</strong>
                                </label>
                                <div>
                                    <div className='input-group'>
                                        <div className='custom-file'>
                                            <input type="file" disabled={props.showLoading} className='custom-file-input' onChange={(e) => saveFile(e)} />
                                            <label className='custom-file-label'>{imgSrc ? <small>{imgSrc.name}</small> : 'Choisir'}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 form-group'></div>
                        </div>
                            
                        <div className='row justify-content-end px-2 align-items-center'>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>Suivant</button>
                        </div>
                    </div>}
                    {activeIndex === 1 && <div className='px-5 pt-5'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.convention-number")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='text' className='form-control'
                                    value={conventionNumber}
                                    disabled={props.showLoading}
                                    required
                                    onChange={(e) => setConventionNumber(e.target.value)}
                                />
                            </div>

                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>NIU</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='text' className='form-control'
                                    value={niu}
                                    disabled={props.showLoading}
                                    required
                                    onChange={(e) => setNiu(e.target.value)}
                                    />
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.type-tax')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <select
                                    defaultValue={taxType}
                                    disabled={props.showLoading}
                                    className='form-control form-control-lg'
                                    required
                                    onChange={(e) => setTaxType(e.target.value)}
                                >
                                    <option value='option'>
                                        {t("common.choose")}
                                    </option>
                                    <option value='fonciere'> {t("provider.property")}</option>
                                    <option value='luxe'>{t("provider.luxury")}</option>
                                    <option value='audiovisuelle'>{t("provider.audiovisual")}</option>
                                </select>
                            </div>
                        </div>

                        <div className='row justify-content-between align-items-center'>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.tax-value")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input disabled={props.showLoading} type='number' className='form-control'
                                value={taxValue}
                                required
                                onChange={(e) => setTaxValue(e.target.value)}
                                />
                                
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t("provider.taxpayer-number")}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input disabled={props.showLoading} type='text' className='form-control'
                                    value={contribuable}
                                    required
                                    onChange={(e) => setContribuable(e.target.value)}
                                    />
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.years-tax-validity')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input required disabled={props.showLoading} type='number' className='form-control'
                                    value={exerciceYear}
                                    onChange={(e) => setExerciceYear(e.target.value)}
                                    />
                            </div>
                        </div>

                        <div className='row justify-content-end px-3 align-items-center'>
                            <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t('provider.previous')}</button>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>Suivant</button>
                        </div>
                    </div>}
                    {activeIndex === 2 && <div className='px-5 pt-5'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-5 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('common.country')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <Select
                                    className="select-search"
                                    value={props.admin.countries?.filter(elt => elt.id === idCountry).map((opt)=>{return {label:opt.name,value:opt.id}})}
                                    isDisabled={props.showLoading}
                                    placeholder={t('common.choose-country')}
                                    required
                                    onChange={(val) => {
                                        if(val !== '') {
                                            setChoosenCountry(props.admin.countries?.filter((country) =>{return country.id === val.value}))
                                            setIdCountry(val.value);
                                        }
                                    }}
                                    options={props.admin.countries?.map((opt)=>{return {label:opt.name,value:opt.id}})}
                                    style={{
                                    lineHeight: '40px',
                                    color: '#7e7e7e',
                                    paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                            <div className='col-5 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.city')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <Select
                                    className="select-search"
                                    value={choosenCountry[0]?.city.filter(elt => elt.id === idCity).map((opt)=>{return {label:opt.name,value:opt.id}})}
                                    isDisabled={props.showLoading || choosenCountry.length === 0}
                                    placeholder={t('common.choose-city')}
                                    required
                                    onChange={(val) => {
                                        setIdCity(val.value)
                                    }}
                                    options={choosenCountry[0]?.city.map((opt)=>{return {label:opt.name,value:opt.id}})}
                                    style={{
                                    lineHeight: '40px',
                                    color: '#7e7e7e',
                                    paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                            
                            <div className='col-2 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('client.postalCode')}</strong>
                                </label>
                                <input disabled={props.showLoading} type='text' className='form-control'
                                    value={postal}
                                    onChange={(e) => setPostal(e.target.value)}
                                    />
                            </div>
                        </div>
                        
                        <div className='row justify-content-between align-items-center'>

                            <div className='col-5 form-group'>
                                <label className='mb-1 '>
                                    <strong>Latitude</strong>
                                </label>
                                <input  disabled={props.showLoading} type='number' className='form-control'
                                    value={lat}
                                    onChange={(e) => setLat(e.target.value)}
                                    />
                            </div>
                            <div className='col-5 form-group'>
                                <label className='mb-1 '>
                                    <strong>Longitude</strong>
                                </label>
                                <input  disabled={props.showLoading} type='number' className='form-control'
                                    value={lon}
                                    onChange={(e) => setLon(e.target.value)}
                                    />
                            </div>
                        </div>
                        <div className='row justify-content-end px-3 align-items-center'>
                            <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t('provider.previous')}</button>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>Suivant</button>
                        </div>
                    </div>}
                    {activeIndex === 3 && <div className='px-5 pt-5'>
                        <h5 className='mt-4 text-primary'>Banque</h5>
                        <div className="border-top mt-2 mb-4"></div>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.bank')}</strong>
                                </label>
                                <input type='text' className='form-control'
                                    value={bankName}
                                    onChange={(e) => setBankName(e.target.value)}
                                />
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.title-account')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <input type='text' className='form-control'
                                    value={intituleBanque}
                                    onChange={(e) => setIntituleBanque(e.target.value)}
                                />
                            </div>
                            <div className='col-4 form-group'>
                                <label className='mb-1 '>
                                    <strong>RIB</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <div>
                                    <div className='input-group'>
                                        <div className='custom-file'>
                                            <input type="file" required={bankName.length > 0} className='custom-file-input' onChange={(e) => addRIB(e)} />
                                            <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.account-number')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <input type='text' className='form-control'
                                    value={numeroBanque}
                                    required={bankName.length > 0}
                                    disabled={bankName.length === 0}
                                    onChange={(e) => setNumeroBanque(e.target.value)}
                                />
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.bank-code')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <input type='text' className='form-control'
                                    value={bankCode}
                                    required={bankName.length > 0}
                                    disabled={bankName.length === 0}
                                    onChange={(e) => setBankCode(e.target.value)}
                                />
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.key-RIB')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <input type='text' className='form-control'
                                    value={cleRib}
                                    required={bankName.length > 0}
                                    disabled={bankName.length === 0}
                                    onChange={(e) => setCleRib(e.target.value)}
                                />
                            </div>
                            <div className='col-3 form-group'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.integ-mobil')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <input type='text' className='form-control'
                                    value={swift}
                                    required={bankName.length > 0}
                                    disabled={bankName.length === 0}
                                    onChange={(e) => setSwift(e.target.value)}
                                />
                            </div>
                        </div>

                        <h5 className='mt-4 text-primary'>{t('provider.swift-code')}</h5>
                        <div className="border-top my-2 mb-4"></div>
                        {
                            paymentMeans.map((paymentMean, index) => {
                                return <>
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t('provider.operator')}</strong>
                                                <span className='text-danger ml-1'>*</span>
                                            </label>
                                            <select
                                                defaultValue={paymentMean.operator}
                                                className='form-control form-control-lg'
                                                onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                        if(payM.id === paymentMean.id){
                                                            payM.operator = e.target.value;
                                                        }
                                                        return payM;
                                                    }))
                                                }
                                            >
                                                <option value='option'>
                                                {t('common.choose')}
                                                </option>
                                                <option value='om'>Orange Money</option>
                                                <option value='momo'>MTN Mobile Money</option>
                                            </select>
                                        </div>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong> {t('provider.phone-number')}</strong>
                                                <span className='text-danger ml-1'>*</span>
                                            </label>
                                            <PhoneInput
                                                country='cd'
                                                onlyCountries={['cm','ci','cd']}
                                                localization='fr'
                                                value={paymentMean.phonenumber}
                                                autoFormat={paymentMean.phonenumber ? !paymentMean.phonenumber.startsWith('225') : true}
                                                onChange={phone => setPaymentMeans(paymentMeans.map(payM => { 
                                                    if(payM.id === paymentMean.id){
                                                        payM.phonenumber = phone;
                                                    }
                                                    return payM;
                                                }))}
                                            />
                                        </div>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong> {t('provider.ownerfullname')}</strong>
                                                <span className='text-danger ml-1'>*</span>
                                            </label>
                                            <input type='phone' className='form-control'
                                                value={paymentMean.beneficialName}
                                                required={paymentMean.operator.length > 0}
                                                onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                        if(payM.id === paymentMean.id){
                                                            payM.beneficialName = e.target.value;
                                                        }
                                                        return payM;
                                                    }))
                                                }
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-3 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t('provider.typeIdenDoc')}</strong>
                                                <span className='text-danger ml-1'>*</span>
                                            </label>
                                            <select
                                                defaultValue={paymentMean.idType}
                                                className='form-control form-control-lg'
                                                required={paymentMean.operator.length > 0}
                                                onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                        if(payM.id === paymentMean.id){
                                                            payM.idType = e.target.value;
                                                        }
                                                        return payM;
                                                    }))
                                                }
                                            >
                                                <option value='option'>
                                                {t('common.choose')}
                                                </option>
                                                <option value='cni'>{t('provider.id-card')}</option>
                                                <option value='passeport'>{t('provider.passport')}</option>
                                                <option value='contribuable'>{t('provider.taxpayer')}</option>
                                            </select>
                                        </div>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t('provider.identifier-number')}</strong>
                                                <span className='text-danger ml-1'>*</span>
                                            </label>
                                            <input type='phone' className='form-control'
                                                value={paymentMean.idNumber}
                                                required={paymentMean.operator.length > 0}
                                                onChange={(e) => setPaymentMeans(paymentMeans.map(payM => { 
                                                        if(payM.id === paymentMean.id){
                                                            payM.idNumber = e.target.value;
                                                        }
                                                        return payM;
                                                    }))
                                                }
                                            />
                                        </div>
                                        <div className='col-4 form-group'>
                                            <label className='mb-1 '>
                                                <strong>{t('provider.ident-doc')}</strong>
                                                <span className='text-danger ml-1'>*</span>
                                            </label>
                                            <div>
                                                <div className='input-group'>
                                                    <div className='custom-file'>
                                                        <input type="file" required={paymentMean.operator.length > 0} className='custom-file-input' onChange={(e) => loadIdentityFile(e, paymentMean)} />
                                                        <label className='custom-file-label'>{paymentMean.file ? <small>{paymentMean.file.name}</small> : 'Choisir'}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-1 form-group'>
                                            <Button
                                                variant="secondary"
                                                className="sm ml-2"
                                                style={{height: '45px', padding: '10px 20px', fontSize: '15px'}}
                                                onClick={() => setPaymentMeans(paymentMeans.filter((elt, i) => i !== index))}
                                            >
                                                -
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            })
                        }
                        
                        <div className='row justify-content-start mb-2 align-items-center'>
                            <Button
                                variant="secondary"
                                className="sm ml-2"
                                style={{height: '30px', padding: '3px 12px', fontSize: '15px'}}
                                onClick={() => setPaymentMeans([...paymentMeans, 
                                    {operator: "", phonenumber: "", beneficialName: "", idType: "", idNumber: "", file: null}])}
                            >
                                + {t('provider.add-mobile-paie')}
                            </Button>
                        </div>

                        <div className='row justify-content-end px-3 align-items-center'>
                            <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t('provider.choose')}</button>
                            <button className='btn btn-primary' disabled={!canGoNext(activeIndex)} onClick={e => setActiveIndex(activeIndex + 1)}>Suivant</button>
                        </div>
                    </div>}
                    {activeIndex === 4 && <div className='px-5 pt-5'>
                        <div className='row justify-content-start mb-2 align-items-center'>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.promoter')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{identity}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.denomination')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{providerName}</span>
                                </div>
                            </div>
                            {(identity === 'Promoteur') && <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.gouv-number')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{numeroAutorisationGouv}</span>
                                </div>
                            </div>}
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.number-autho')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{numeroAutorisation}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('client.type')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{props.providerTypes?.filter(elt => providerTypeId.includes(elt.id))?.map(elt => {return elt.nameFr}).join(', ')}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('common.email')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{email}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>Whatsapp</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{whatsappPhone}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.phone')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{phoneNumber}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.default-user')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{props.profilsData?.filter(elt => profilIds.includes(elt.id))?.map(elt => {return elt.nameFr}).join(', ')}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.picture')}</strong>
                                </label>
                                <div>
                                    <span className='text-primary'>{imgSrc?.name}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.convention-number')}</strong>
                                </label>
                                <div>
                                    <span className='text-primary'>{conventionNumber}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>NIU</strong>
                                </label>
                                <div>
                                    <span className='text-primary'>{niu}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.type-tax')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{taxType}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.tax-value')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{taxValue}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.taxpayer-number')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{contribuable}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.years-tax-validity')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{exerciceYear}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.country')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{choosenCountry[0]?.name}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong> {t('provider.city')}</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <div>
                                    <span className='text-primary'>{choosenCountry[0]?.city?.find(elt => elt.id === idCity)?.name}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.postal-code')}</strong>
                                </label>
                                <div>
                                    <span className='text-primary'>{postal}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>Latitude</strong>
                                </label>
                                <div>
                                    <span className='text-primary'>{lat}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>Longitude</strong>
                                </label>
                                <div>
                                    <span className='text-primary'>{lon}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.bank')}</strong>
                                </label>
                                <div>
                                    <span className='text-primary'>{bankName}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.title-account')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <div>
                                    <span className='text-primary'>{intituleBanque}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>RIB</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <div>
                                    <span className='text-primary'>{fileSrc?.name}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.account-number')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <div>
                                    <span className='text-primary'>{numeroBanque}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.bank-code')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <div>
                                    <span className='text-primary'>{bankCode}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.key-RIB')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <div>
                                    <span className='text-primary'>{cleRib}</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='mb-1 '>
                                    <strong>{t('provider.swift-code')}</strong>
                                    {bankName.trim().length > 0 && <span className='text-danger ml-1'>*</span>}
                                </label>
                                <div>
                                    <span className='text-primary'>{swift}</span>
                                </div>
                            </div>
                            {paymentMeans.length > 0 && 
                                <div className='col-12'>
                                    {paymentMeans.map(elt => {
                                        return <div className='row'>
                                            <div className='col-3'>
                                                <label className='mb-1 '>
                                                    <strong>{t('provider.operator')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <div>
                                                    <span className='text-primary'>{elt.operator}</span>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <label className='mb-1 '>
                                                    <strong>{t('provider.phone-number')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <div>
                                                    <span className='text-primary'>{elt.operator}</span>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <label className='mb-1 '>
                                                    <strong> {t('provider.ownerfullname')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <div>
                                                    <span className='text-primary'>{elt.beneficialName}</span>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <label className='mb-1 '>
                                                    <strong>{t('provider.typeIdenDoc')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <div>
                                                    <span className='text-primary'>{elt.idType}</span>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <label className='mb-1 '>
                                                    <strong>{t('provider.identifier-number')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <div>
                                                    <span className='text-primary'>{elt.idNumber}</span>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <label className='mb-1 '>
                                                    <strong>{t('provider.ident-doc')}</strong>
                                                    <span className='text-danger ml-1'>*</span>
                                                </label>
                                                <div>
                                                    <span className='text-primary'>{elt.file?.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            }
                        </div>
                        
                        <div className='row justify-content-end px-3 align-items-center'>
                            { props.showLoading && 
                            <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}
                                <span className="visually-hidden">{t('common.await-text')}</span>
                            </Button> }
                            { !props.showLoading &&
                            <>
                                <button className='btn btn-info mr-2' onClick={e => setActiveIndex(activeIndex - 1)}>{t('provider.previous')}</button>
                                <input type='submit' disabled={!canSubmitForm()} value={t('common.save')} className='btn btn-primary'/>
                            </> }
                        </div>
                    </div>}
                </form>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        providers: state.provider.providers,
        countries: state.parameters.countries,
        admin: state.admin.administration,
        providerTypes: state.provider.providerTypes,
        errorMessage: state.clientAuth.errorMessage,
        successMessage: state.clientAuth.successMessage,
        showLoading: state.provider.showLoading,
        profilsData: state.user.profils,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ProviderForm));