import React from "react";
import { Modal, Button } from "react-bootstrap";
import {format} from 'date-fns';
import './DetailsModal.css';
import { useTranslation } from "react-i18next";

const DetailsModal = (props) => {
    const { t } = useTranslation();
    const {data, headerText, numAvenant, dateAvenant, manageDetailsModal} = props;

    const transformCammel = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    return (
        <>
        <Modal.Header>
            {dateAvenant && <Modal.Title className="text-center" >
                   {t('common.aven-number')} 
                    <b className="text-primary" >{numAvenant}</b>
                    {t('common.of')}
                    <b className="text-primary" >{dateAvenant ? format(new Date(dateAvenant), 'dd/MM/yyyy h:m') : ''}</b>
            </Modal.Title>}
            {headerText && <Modal.Title className="text-center" >
                    <b className="text-primary" >{headerText}</b>
            </Modal.Title>}
            <Button
                variant=""
                className="close"
                data-testid="close-button"
                onClick={() => manageDetailsModal()}
            >
                <span>&times;</span>
            </Button>
        </Modal.Header>
        <Modal.Body>
            
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-body">
                            <table className="doc-table">
                                <thead>
                                    <tr>
                                        <th>{t('common.label')}</th>
                                        <th>{t("common.value")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(data).map((key, i) => {
                                        return <tr key={i}>
                                            <td>{transformCammel(key)}</td>
                                            {Object.prototype.toString.call(data[key]) === "[object Date]" &&
                                                <td>{format(data[key], 'dd/MM/yyyy h:m')}</td>
                                            }
                                            {Object.prototype.toString.call(data[key]) !== "[object Date]" &&
                                                <>
                                                    {typeof(data[key]) === 'string' && data[key].includes('-') && data[key].includes(':') &&
                                                        <td>{format(new Date(data[key]), 'dd/MM/yyyy h:m')}</td>
                                                    }
                                                    {!(typeof(data[key]) === 'string' && data[key].includes('-') && data[key].includes(':')) &&
                                                        <td>{data[key]}</td>
                                                    }
                                                </>
                                            }
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
    </>
   );
};

export default DetailsModal;
