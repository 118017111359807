import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import Select from 'react-select';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
    listClientsAction
} from '../../../../store/actions/ClientAuthActions';
import { getRemboursementAction, updateRemboursementAction, getMotifsExclusionAction, startLoader, calculationRembBaseAction, calculationTMAction, stopLoader } from "../../../../store/actions/FacturationAction";
import { calculationRembBase, calculationNewRembBase, calculationTM } from "../../../../services/FacturationService";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Button, Spinner } from "react-bootstrap";
import { ProgressBar } from 'react-bootstrap';
import {
    getLettreCleAction,
} from '../../../../store/actions/ProviderActions';
import { listProvidersAction } from '../../../../store/actions/ProviderActions';
import { getBeneficiairesAction, getAssuresBeneficiairesAction, getSouscripteursAction, searchContratAction, getGarantiesAction } from "../../../../store/actions/ContratActions";
import {
    startLoader as startLoaderProvider,
    getProvidersTypesAction,
    getActesAction,
    searchActesAction
} from '../../../../store/actions/ProviderActions';
import { searchDrugsAction, searchDiseasesAction, getActesConsultationAction, getActesByTypeAction } from "../../../../store/actions/ConsultationActions";
import TextInput from 'react-autocomplete-input';
import { STATUSES } from '../../../globals';
import { successToastr, errorToastr } from "../../../../Utils/ToastrMessage";
import { useTranslation } from "react-i18next";

export const RemboursementForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { history, showLoading, clients, lettreCles, providers,
        motifExclusion, beneficiaires, souscripteurs, assureBenefs,
        remboursement, garanties, actes, actesConsultation, rembCalculDatas, drugs,
        diseases, ticketModDatas, actesByType, searchedContrats } = props;

    const [contratTrouver, setContratTrouver] = useState('');
    const [dateEffetTrouver, setDateEffetTrouver] = useState('');
    const [dateExpiTrouver, setDateExpiTrouver] = useState('');

    const [souscriptId, setSouscriptId] = useState('');
    const [souscriptName, setSouscriptName] = useState('');
    const [souscriptCodeAssu, setSouscriptCodeAssu] = useState('');
    const [numeroPolice, setNumeroPolice] = useState('');
    const [codeProduit, setCodeProduit] = useState('');
    const [codeIntermediaire, setCodeIntermediaire] = useState('');
    const [ligneAssures, setLigneAssures] = useState([
        {
            assurePrincId: "",
            assurePrincName: "",
            numeroAdherentP: "",
            montantReclame: 0,
            benefList: [
                {
                    assureId: "",
                    assureName: "",
                    numBeneficiaire: "",
                    montantReclame: 0,
                    remboursements: [
                        {
                            prestatairePrescriptId: "",
                            prestatairePrescriptName: "",
                            prestatairePrescriptType: "",
                            prestataireExecId: "",
                            prestataireExecName: "",
                            prestataireExecType: "",
                            categorieId: "",
                            categorieName: "",
                            montant: 0,
                            medecin: "",
                            date: new Date(),
                            montantExclu: 0,
                            motifExclu: "",
                            affectionPresum: "",
                            affectionIdent: "",
                            files: [],
                            actes: [],
                            details: [
                                {
                                    prestataireExecId: "",
                                    prestataireExecName: "",
                                    prestataireExecType: "",
                                    categorieId: "",
                                    categorieName: "",
                                    montant: 0,
                                    montantExclu: 0,
                                    motifExclu: "",
                                    date: new Date(),
                                    actes: [],
                                    files: []
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]);

    const requestRemboursement = (e) => {
        e.preventDefault();
        dispatch(startLoader());

        for (let i = 0; i < ligneAssures.length; i++) {
            for (let j = 0; j < ligneAssures[i].benefList.length; j++) {
                for (let k = 0; k < ligneAssures[i].benefList[j].remboursements.length; k++) {
                    let remboursement = ligneAssures[i].benefList[j].remboursements[k];
                    if (remboursement.actes.length === 0) {
                        dispatch(stopLoader());
                        errorToastr("Veuillez selectionner un acte pour chaque consultation");
                        return;
                    }
                    if (!remboursement.affectionIdent || remboursement.affectionIdent === "") {
                        dispatch(stopLoader());
                        errorToastr("Veuillez renseigner l'affection identifiée pour chaque consultation");
                        return;
                    }

                    for (let l = 0; l < ligneAssures[i].benefList[j].remboursements[k].actes.length; l++) {
                        let acte = ligneAssures[i].benefList[j].remboursements[k].actes[l];
                        if (!acte.acteId || acte.acteId === "") {
                            dispatch(stopLoader());
                            errorToastr("Veuillez reseigner l'acte de consultation pour chaque remboursement");
                            return;
                        }
                        if (acte.montantExclu > 0 && !acte.motifExclu) {
                            dispatch(stopLoader());
                            errorToastr("Veuillez reseigner un motif d'exclusion pour chaque acte dont le montant exclu est supérieur à 0");
                            return;
                        }
                        /*if(!acte.montantPaye || acte.montantPaye === 0) {
                            dispatch(stopLoader());
                            errorToastr("Veuillez renseigner le montant payé pour chaque acte");
                            return;
                        }*/

                    }
                    for (let m = 0; m < ligneAssures[i].benefList[j].remboursements[k].details.length; m++) {
                        let detail = ligneAssures[i].benefList[j].remboursements[k].details[m];
                        if (!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && detail.actes.length === 0) {
                            dispatch(stopLoader());
                            errorToastr("Veuillez selectionner les actes pour toutes les rubriques autres que pharmacie");
                            return;
                        }
                        if (detail.categorieName.toLocaleLowerCase().includes("pharmacie") && detail.actes.length === 0) {
                            dispatch(stopLoader());
                            errorToastr("Veuillez selectionner l'acte pharmacie pour toutes les rubriques pharmacie");
                            return;
                        }
                        if (detail.date < remboursement.date) {
                            dispatch(stopLoader());
                            errorToastr("La date de soin est antérieure à la date de consultation pour le remboursement n°" + (i + 1) + (j + 1) + (k + 1));
                            return;
                        }
                        for (let n = 0; n < ligneAssures[i].benefList[j].remboursements[k].details[m].actes.length; n++) {
                            let acte = ligneAssures[i].benefList[j].remboursements[k].details[m].actes[n];
                            if (!acte.acteId || acte.acteId === "") {
                                dispatch(stopLoader());
                                errorToastr("Veuillez selectionner un acte pour chaque remboursement");
                                return;
                            }
                            if (acte.montantExclu > 0 && !acte.motifExclu) {
                                dispatch(stopLoader());
                                errorToastr("Veuillez reseigner un motif d'exclusion pour chaque acte dont le montant exclu est supérieur à 0");
                                return;
                            }
                            /*if(!acte.montantPaye || acte.montantPaye === 0) {
                                dispatch(stopLoader());
                                errorToastr("Veuillez renseigner le montant payé pour chaque acte");
                                return;
                            }*/
                        }
                    }
                }
            }
        }

        let data = {
            remboursementId: id,
            souscriptId,
            souscriptName,
            codeAssu: souscriptCodeAssu,
            codeProduit,
            codeIntermediaire,
            numeroPolice,
            ligneAssures
        }


        dispatch(updateRemboursementAction(id, data, history));
    }

    const addRembActeLine = (e, i, j, k) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, l) => {
            if (l === i) {
                assure.benefList[j].remboursements[k].actes = [...assure.benefList[j].remboursements[k].actes, {
                    acteId: "",
                    acteName: "",
                    code: "",
                    acteProdCode: "",
                    montantFacture: 0,
                    motifExclu: "",
                    baseRemb: 0,
                    tm: 0,
                    montantPaye: 0,
                    montantExclu: 0
                }];
            }
            return assure;
        }))
    }

    const addActeLine = (e, i, j, k, m) => {
        e.preventDefault();
        if (ligneAssures[i].benefList[j].remboursements[k].details[m].categorieName.toLocaleLowerCase().includes("pharmacie") && ligneAssures[i].benefList[j].remboursements[k].details[m].actes.length === 0) {
            errorToastr("Veuillez d'abord selectionner l'acte pharmacie avant d'ajouter un médicament");
            return;
        }
        setLigneAssures(ligneAssures.map((assure, l) => {
            if (l === i) {
                assure.benefList[j].remboursements[k].details[m].actes = [...assure.benefList[j].remboursements[k].details[m].actes, {
                    acteId: "",
                    acteName: "",
                    code: "",
                    acteProdCode: "",
                    acteAmount: 0,
                    montantFacture: 0,
                    quantite: 1,
                    montantExclu: 0,
                    motifExclu: "",
                    baseRemb: 0,
                    tm: 0,
                    montantPaye: 0,
                    montantExclu: 0
                }];
            }
            return assure;
        }))
    }

    const removeRembActeLine = (e, l, i, p, o) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if (k === l) {
                assure.benefList[i].remboursements[p].actes = assure.benefList[i].remboursements[p].actes.filter((acte, j) => { return j !== o });
                assure.benefList[i].remboursements[p].montantExclu = assure.benefList[i].remboursements[p].actes.length > 0 ?
                    assure.benefList[i].remboursements[p].montant - assure.benefList[i].remboursements[p].actes.filter((acte, j) => { return j !== o }).reduce((sum, elt) => sum + elt.montantPaye, 0) :
                    0;
            }
            return assure;
        }));
    }

    const removeActeLine = (e, l, i, p, o, q) => {
        e.preventDefault();
        setLigneAssures(ligneAssures.map((assure, k) => {
            if (k === l) {
                assure.benefList[i].remboursements[p].details[o].actes = assure.benefList[i].remboursements[p].details[o].actes.filter((acte, j) => { return j !== q });
                assure.benefList[i].remboursements[p].details[o].montantExclu = assure.benefList[i].remboursements[p].details[o].actes.length > 0 ?
                    assure.benefList[i].remboursements[p].details[o].montant - assure.benefList[i].remboursements[p].details[o].actes.filter((acte, j) => { return j !== o }).reduce((sum, elt) => sum + elt.montantPaye, 0) :
                    0;
            }
            return assure;
        }));
    }

    const calculateAmounts = (acteId, prestaId, patientId, montant, i, j, k, l) => {
        if (prestaId) {
            let data = {
                providerId: prestaId,
                patientId,
                acteId
            }
            dispatch(calculationRembBaseAction(data, i + ',' + j + ',' + k + ',' + l));
        }
    };

    const calculateSubAmounts = (acteId, prestaId, patientId, montant, i, j, k, l, m) => {
        let data = {
            providerId: prestaId,
            patientId,
            acteId
        }
        dispatch(calculationRembBaseAction(data, i + ',' + j + ',' + k + ',' + l + ',' + m));
    };


    const calculateTM = (garantieId, patientId, i, j, k, l, m) => {
        let data = {
            garantieId,
            patientId
        }
        dispatch(calculationTMAction(data, i + ',' + j + ',' + k + ',' + l + ',' + m));
    };

    const calculateSums = (l, j) => {
        let assure = ligneAssures[l];
        let benef = ligneAssures[l].benefList[j];
        benef.montantReclame = benef.remboursements.reduce((sum, remb) => {
            return sum + parseInt(remb.montant) + remb.details.reduce((subSum, detail) => subSum + parseInt(detail.montant), 0)
        }, 0);

        assure.montantReclame = assure.benefList.reduce((sumT, ben) => sumT + ben.montantReclame, 0);

        assure.benefList[j] = benef;

        setLigneAssures((line, i) => {
            if (i === l) {
                line = assure;
            }
            return line
        })
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if (!theMenu) {
            return false;
        } else {
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }

    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    useEffect(() => {
        if (hasMenu('MENREMBOURS') && canDo('MENREMBOURS', 'VALIDATEREMBOURS')) {
            dispatch(getRemboursementAction(id));
            dispatch(getGarantiesAction());
            dispatch(getActesConsultationAction());
            dispatch(getActesByTypeAction('pharmacie'));
            dispatch(getProvidersTypesAction());
            //dispatch(getActesAction());
            //dispatch(listClientsAction());
            //dispatch(getSouscripteursAction());
            dispatch(listProvidersAction());
            //dispatch(getLettreCleAction());
            dispatch(getMotifsExclusionAction());
        } else {
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        let temp = ligneAssures;
        for (let i = 0; i < rembCalculDatas.length; i++) {
            let elt = rembCalculDatas[i];
            let indexes = elt.index.split(',');
            indexes = indexes.map(idx => {
                return parseInt(idx);
            })
            if (indexes.length === 4) {

                let montantRec = temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].montant;
                let baseRembours = 0;

                baseRembours = Math.min(montantRec, elt.data.montantPolice);

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].actes[indexes[3]].tm = elt.data.montantPaye;
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].actes[indexes[3]].baseRemb = baseRembours;
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].actes[indexes[3]].montantPaye = (baseRembours - elt.data.montantPaye) > 0 ? baseRembours - elt.data.montantPaye : 0;
                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].montantExclu = montantRec - ((baseRembours - elt.data.montantPaye) > 0 ? baseRembours - elt.data.montantPaye : 0);
                
            } else {
                

                let montantFact = temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture;

                let baseRembours = 0;
                baseRembours = Math.min(montantFact, elt.data.montantPolice);

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].tm =
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours - elt.data.montantPaye;

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].baseRemb =
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours;

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantPaye =
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours -
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * elt.data.montantPaye;

                temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].montantExclu =
                    temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].montant - elt.data.montantPaye -
                    (temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * baseRembours -
                        temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * elt.data.montantPaye);

                
            }
        }
        setLigneAssures(temp);
        
    }, [rembCalculDatas]);

    useEffect(() => {
        
        let temp = ligneAssures;
        for (let i = 0; i < ticketModDatas.length; i++) {
            let elt = ticketModDatas[i];
            
            let indexes = elt.index.split(',');
            indexes = indexes.map(idx => {
                return parseInt(idx);
            })
            
            temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].tm =
                (temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture) * elt.data.tm / 100;
            temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].montantExclu =
                (temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture * temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite) -
                (Math.min(temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].montantFacture, elt.data.plafond) * temp[indexes[0]].benefList[indexes[1]].remboursements[indexes[2]].details[indexes[3]].actes[indexes[4]].quantite * elt.data.tm / 100)
            
        }
        setLigneAssures(temp);
        
    }, [ticketModDatas]);



    useEffect(() => {
        if (Object.keys(remboursement).length > 0) {

            dispatch(searchContratAction(remboursement.numeroPolice));

            setSouscriptId(remboursement.souscripteurId);
            setSouscriptName(remboursement.souscripteurName);
            setNumeroPolice(remboursement.numeroPolice);

            setSouscriptCodeAssu(remboursement.codeAssu);
            setCodeProduit(remboursement.codeProduit);
            setCodeIntermediaire(remboursement.codeIntermediaire);

            let rembDetails = remboursement.factureRemboursementDetails;

            let res = [];

            let visitedIds = [];

            let myRes = rembDetails.map(remb => {
                let obj = {};
                if (!visitedIds.includes(remb.assurerPrincipalId)) {
                    obj.assurePrincId = remb.assurerPrincipalId;
                    obj.assurePrincName = remb.assurerPrincipalName;
                    obj.numeroAdherentP = remb.numeroAdherentP;
                    obj.montantReclame = remb.montantReclame;
                    let benefs = rembDetails.filter(elt => { return elt.assurerPrincipalId === remb.assurerPrincipalId });
                    
                    let visitedBenefIds = [];
                    obj.benefList = benefs.map(benef => {
                        let res = {};
                        if (!visitedBenefIds.includes(benef.assurerId)) {
                            res.assureId = benef.assurerId;
                            res.assureName = benef.assurerName;
                            res.numBeneficiaire = benef.numeroBeneficiaire;
                            res.montantReclame = benef.montantReclame;
                            let presta = rembDetails.filter(elt => { return elt.assurerId === benef.assurerId });
                            
                            res.remboursements = presta.map(prest => {
                                let resP = {};
                                if (prest.type && prest.type.toLowerCase().includes("consultation")) {
                                    resP.id = prest.id;
                                    resP.consultationCode = prest.consultationCode;
                                    resP.prestatairePrescriptId = prest.prescripteurId || 0;
                                    resP.prestatairePrescriptName = prest.prescripteurName;
                                    resP.prestatairePrescriptType = prest.prestatairePrescriptType;
                                    resP.prestataireExecId = prest.executeurId || 0;
                                    resP.prestataireExecName = prest.executeurName;
                                    resP.prestataireExecType = prest.prestataireExecType;
                                    resP.categorieId = prest.categorieId || prest.codeGarantie;
                                    resP.categorieName = prest.categorieName;
                                    resP.montant = prest.montantPrestationReclame;
                                    resP.medecin = prest.medecin;
                                    resP.date = new Date(prest.dateExecution);
                                    resP.montantExclu = 0;
                                    resP.motifExclu = prest.motifExclu;
                                    resP.affectionPresum = prest.affectionPresum;
                                    resP.affectionIdent = prest.affectionIdent;
                                    resP.files = [prest.files];
                                    resP.actes = prest.factureRejets;
                                    let detPrest = rembDetails.filter(elt => { return (elt.consultationCode === prest.consultationCode && !elt.type) })
                                    
                                    resP.details = detPrest.map(detP => {
                                        return {
                                            id: detP.id,
                                            consultationCode: detP.consultationCode,
                                            prestataireExecId: detP.executeurId || 0,
                                            prestataireExecName: detP.executeurName,
                                            prestataireExecType: detP.prestataireExecType,
                                            categorieId: detP.categorieId || detP.codeGarantie,
                                            categorieName: detP.categorieName,
                                            montant: detP.montantPrestationReclame,
                                            date: new Date(detP.dateExecution),
                                            montantExclu: 0,
                                            motifExclu: detP.motifExclu,
                                            actes: detP.factureRejets,
                                            files: [detP.files]
                                        }
                                    });
                                }
                                return resP;
                            });
                            res.remboursements = res.remboursements.filter(elt => { return Object.keys(elt).length > 0 });
                            visitedBenefIds.push(benef.assurerId);
                        }
                        return res;
                    });
                    obj.benefList = obj.benefList.filter(elt => { return Object.keys(elt).length > 0 });
                    visitedIds.push(remb.assurerPrincipalId);
                }
                return obj;
            });

            

            setLigneAssures(myRes.filter(elt => { return Object.keys(elt).length > 0 }));
        }
    }, [remboursement]);

    useEffect(() => {
        if (Object.keys(searchedContrats).length > 0) {
            setDateEffetTrouver(searchedContrats[0].police.dateEffet)
            setDateExpiTrouver(searchedContrats[0].police.dateExpiration)
        }
    }, [searchedContrats])


    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Demande de remboursement</h4>
                        </div>
                        <div className="card-body">
                            <h5 className='mt-4 text-primary'>Règlement Médical</h5>
                            <div className="border-top mt-2 mb-4"></div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p><b>{t('dashboard.souscipt-name')}: </b>{souscriptName}</p>
                                    <p><b>N° Police: </b>{remboursement.numeroPolice}</p>
                                    <p><b>Date d'effet: </b>{dateEffetTrouver}</p>
                                    <p><b>Date d'expiration: </b>{dateExpiTrouver}</p>
                                </div>
                                <div>
                                    <p><b>Date de demande: </b>{remboursement.dateFacturation}</p>
                                    <p><b>Montant total du remboursement: </b>{remboursement.montantTotal}</p>
                                    <p><b>{t('common.status')}: </b>{remboursement.statusPaiement ? t('statuses.' + remboursement.statusPaiement) : remboursement.statusPaiement}</p>
                                </div>
                            </div>
                            <div className="border-top mt-2 mb-4"></div>
                            <form
                                onSubmit={(e) => requestRemboursement(e)}
                            >
                                <section>
                                    {ligneAssures.map((ligne, i) => {
                                        return <>
                                            <div className="row">
                                                <div className="col-lg-5 mb-2">
                                                    <div className="form-group">
                                                        <label className='mb-1 '>
                                                            <strong>Assuré principal</strong>
                                                        </label>
                                                        <input type='text' className='form-control'
                                                            value={ligne.assurePrincName}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 mb-2">
                                                    <div className="form-group">
                                                        <label className='mb-1 '>
                                                            <strong>Montant total</strong>
                                                        </label>
                                                        <input type='number' className='form-control'
                                                            value={ligne.montantReclame}
                                                            required
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {ligne.benefList.map((line, j) => {
                                                return <>
                                                    <div className="row ml-2" key={j}>
                                                        <div className="col-lg-5 mb-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Bénéficiaire</strong>
                                                                </label>
                                                                <input type='text' className='form-control'
                                                                    value={line.assureName}
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5 mb-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>Montant total</strong>
                                                                </label>
                                                                <input type='number' className='form-control'
                                                                    value={line.montantReclame}
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {line.remboursements.map((remboursement, k) => {
                                                        return <>
                                                            <h6 className="ml-4 text-secondary">REMBOURSEMENT N°{" "}{i + 1}{j + 1}{k + 1}</h6>
                                                            <div className="border-top border-secondary my-2 ml-4"></div>
                                                            <div className="row mb-2 ml-2" key={k}>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Categorie</strong>
                                                                        </label>
                                                                        <input type='text' className='form-control'
                                                                            value={ligne.benefList[j].remboursements[k].categorieName}
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Centre prescripteur</strong>
                                                                        </label>
                                                                        <TextInput
                                                                            className='form-control custom-text-input border'
                                                                            Component="input"
                                                                            required
                                                                            trigger={""}
                                                                            value={remboursement.prestatairePrescriptName}
                                                                            placeholder="Entrer un prestataire"
                                                                            onChange={val => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].prestatairePrescriptName = val;
                                                                                        let provider = providers.filter(prov => { return prov.providerName.trim() === val.trim() })[0];
                                                                                        linA.benefList[j].remboursements[k].prestatairePrescriptId = provider ? provider.id : null;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            options={providers.map(prov => { return prov.providerName })}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {!remboursement.prestatairePrescriptId && <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>{t('client.type')} Prescipteur</strong>
                                                                            <span className='text-danger ml-1'>*</span>
                                                                        </label>

                                                                        <Select
                                                                            className="select-search"
                                                                            value={remboursement.prestatairePrescriptType ? props.providerTypes?.filter(type => type.id === remboursement.prestatairePrescriptType)?.map(elt => { return { label: elt.nameFr, value: elt.id } }) : []}
                                                                            placeholder="Choisir un type"
                                                                            onChange={(val) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].prestatairePrescriptType = val;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            disabled={props.showLoading}
                                                                            required
                                                                            options={props.providerTypes?.map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                                                            style={{
                                                                                lineHeight: '40px',
                                                                                color: '#7e7e7e',
                                                                                paddingLeft: ' 15px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>}

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>{t('common.executant')}</strong>
                                                                        </label>
                                                                        <TextInput
                                                                            className='form-control custom-text-input border'
                                                                            Component="input"
                                                                            required
                                                                            trigger={""}
                                                                            value={remboursement.prestataireExecName}
                                                                            placeholder="Entrer un prestataire"
                                                                            onChange={val => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].prestataireExecName = val;
                                                                                        let provider = providers.filter(prov => { return prov.providerName.trim() === val.trim() })[0];
                                                                                        linA.benefList[j].remboursements[k].prestataireExecId = provider ? provider.id : null;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            options={providers.map(prov => { return prov.providerName })}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {!remboursement.prestataireExecId && <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>{t('client.type')} Exécutant</strong>
                                                                            <span className='text-danger ml-1'>*</span>
                                                                        </label>

                                                                        <Select
                                                                            className="select-search"
                                                                            value={remboursement.prestataireExecType ? props.providerTypes?.filter(type => type.id === remboursement.prestataireExecType)?.map(elt => { return { label: elt.nameFr, value: elt.id } }) : []}
                                                                            placeholder="Choisir un type"
                                                                            onChange={(val) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].prestataireExecType = val;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                            disabled={props.showLoading}
                                                                            required
                                                                            options={props.providerTypes?.map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                                                            style={{
                                                                                lineHeight: '40px',
                                                                                color: '#7e7e7e',
                                                                                paddingLeft: ' 15px',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>}

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>{t('common.doctor')}</strong>
                                                                        </label>
                                                                        <input type='text' className='form-control'
                                                                            value={remboursement.medecin}
                                                                            required
                                                                            onChange={(e) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].medecin = e.target.value;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Affection présumée</strong>
                                                                        </label>
                                                                        <Select
                                                                            className="select-search"
                                                                            value={{ label: remboursement.affectionPresum, value: remboursement.affectionPresum }}
                                                                            options={diseases.map(elt => { return { label: elt.code, value: elt.code } })}
                                                                            placeholder={t('common.choose')}
                                                                            search
                                                                            isRequired
                                                                            name="affectionPresumée"
                                                                            onInputChange={(val) => {
                                                                                if (val.length >= 3) {
                                                                                    dispatch(searchDiseasesAction(val))
                                                                                }
                                                                            }}
                                                                            onChange={(val) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].affectionPresum = val.label;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Affection identifiée</strong>
                                                                        </label>
                                                                        <Select
                                                                            className="select-search"
                                                                            name="affectionIdentifiée"
                                                                            value={{ label: remboursement.affectionIdent, value: remboursement.affectionIdent }}
                                                                            options={diseases.map(elt => { return { label: elt.code, value: elt.code } })}
                                                                            placeholder={t('common.choose')}
                                                                            search
                                                                            isRequired
                                                                            onInputChange={(val) => {
                                                                                if (val.length >= 3) {
                                                                                    dispatch(searchDiseasesAction(val))
                                                                                }
                                                                            }}
                                                                            onChange={(val) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].affectionIdent = val.label;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Montant réclamé</strong>
                                                                        </label>
                                                                        <input type='number' className='form-control'
                                                                            value={remboursement.montant}
                                                                            required
                                                                            onChange={(e) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].montant = e.target.value;
                                                                                    }
                                                                                    return linA;
                                                                                }));

                                                                                if (remboursement.actes.length > 0) {
                                                                                    let data = [
                                                                                        {
                                                                                            dateSurvenance: remboursement.date,
                                                                                            idActe: remboursement.actes[0].acteId,
                                                                                            codeActe: remboursement.actes[0].acteCode,
                                                                                            quantite: 1,
                                                                                            codeActeProd: remboursement.actes[0].acteProdCode,
                                                                                            montantReclame: e.target.value,
                                                                                            numeroPolice: numeroPolice,
                                                                                            numeroAssure: line.numBeneficiaire,
                                                                                            patientId: line.assureId,
                                                                                            idPrestataire: remboursement.prestataireExecId,
                                                                                            providerTypeId: remboursement.prestataireExecType
                                                                                        }
                                                                                    ];

                                                                                    dispatch(startLoader());
                                                                                    calculationNewRembBase(data)
                                                                                        .then((response) => {
                                                                                            let resp = response.data[0];
                                                                                            let baseRembours = 0;

                                                                                            baseRembours = Math.min(linA.benefList[j].remboursements[k].montant, resp.montantPolice, resp.montantConvention);

                                                                                            linA.benefList[j].remboursements[k].actes[r].tm = baseRembours * (100 - resp.tm) / 100;
                                                                                            linA.benefList[j].remboursements[k].actes[r].baseRemb = baseRembours;
                                                                                            linA.benefList[j].remboursements[k].actes[r].montantPaye = resp.montantPaye;
                                                                                            linA.benefList[j].remboursements[k].actes[r].montantExclu = linA.benefList[j].remboursements[k].montant - resp.montantPaye;
                                                                                            linA.benefList[j].remboursements[k].montantExclu = linA.benefList[j].remboursements[k].montant - resp.montantPaye;
                                                                                            dispatch(stopLoader());
                                                                                        })
                                                                                        .catch((error) => {
                                                                                            errorToastr("Erreur lors du calcul des montants", error);
                                                                                            dispatch(stopLoader());
                                                                                        });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Montant exclu</strong>
                                                                        </label>
                                                                        <input type='number' className='form-control'
                                                                            value={remboursement.montantExclu}
                                                                            required
                                                                            onChange={(e) => {
                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                    if (i === l) {
                                                                                        linA.benefList[j].remboursements[k].montantExclu = e.target.value;
                                                                                    }
                                                                                    return linA;
                                                                                }))
                                                                                if (e.target.value > 0 && remboursement.actes.length === 0) {
                                                                                    addRembActeLine(e, i, j, k)
                                                                                }
                                                                            }}
                                                                        />
                                                                        {remboursement.montantExclu > 0 && remboursement.actes.length === 0 && <small className="text-warning">
                                                                            veuillez préciser l'acte
                                                                        </small>}
                                                                    </div>
                                                                </div>

                                                                {/*<div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('appointment.reason')} Exclusion</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={motifExclusion.filter(cat => {return cat.nameFr === remboursement.motifExclu})
                                                                    .map(elt => {return {label:elt.nameFr,value:elt.id}})}
                                                                    options={motifExclusion.map(opt => {return {label:opt.nameFr,value:opt.id}})}
                                                                     placeholder={t('common.choose')}
                                                                    search
                                                                    isRequired
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].motifExclu = val.label;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                />
                                                                {remboursement.montantExclu > 0 && !remboursement.motifExclu && <small className="text-warning">
                                                                    veuillez préciser le motif d'exclusion
                                                                </small>}
                                                            </div>
                                                        </div>*/}


                                                                <div className="col-lg-2">
                                                                    <div className="form-group">
                                                                        <label className='mb-1 '>
                                                                            <strong>Date de soin</strong>
                                                                        </label>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <DateTimePicker
                                                                                autoOk
                                                                                label=""
                                                                                clearable
                                                                                format="dd/MM/yyyy hh:mm"
                                                                                value={remboursement.date}
                                                                                disableFuture
                                                                                required
                                                                                onChange={(val) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if (i === l) {
                                                                                            linA.benefList[j].remboursements[k].date = val;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                </div>



                                                                {remboursement.files.length > 0 && remboursement.files[0] && <div className="col-lg-2">
                                                                    <Button
                                                                        as='a'
                                                                        variant='success light'
                                                                        href={remboursement.files[0]}
                                                                        target="_blank"
                                                                        className='btn-card mt-3 mr-3'
                                                                    >
                                                                        <i className="la la-file-download"></i>
                                                                        facture
                                                                    </Button>
                                                                </div>}

                                                            </div>

                                                            {remboursement.actes.map((acte, r) => {
                                                                return <div className="row mb-2 ml-5" key={r}>
                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>{t('provider.acte')}</strong>
                                                                            </label>
                                                                            <Select
                                                                                className="select-search"
                                                                                value={actesConsultation.filter(cat => { return cat.id === acte.acteId })
                                                                                    .map(elt => { return { label: elt.nameFr, value: elt.id, acteProd: elt.acteProdCode, code: elt.code } })}
                                                                                options={actesConsultation.map(elt => { return { label: elt.nameFr, value: elt.id, acteProd: elt.acteProdCode, code: elt.code } })}
                                                                                placeholder={t('common.choose')}
                                                                                search
                                                                                isRequired
                                                                                onChange={(val) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if (i === l) {
                                                                                            linA.benefList[j].remboursements[k].actes[r].acteId = val.value;
                                                                                            linA.benefList[j].remboursements[k].actes[r].acteName = val.label;
                                                                                            linA.benefList[j].remboursements[k].actes[r].acteCode = val.code;
                                                                                            linA.benefList[j].remboursements[k].actes[r].acteProdCode = val.acteProd;
                                                                                            linA.benefList[j].remboursements[k].actes[r].montantFacture = remboursement.montant;

                                                                                            let data = [
                                                                                                {
                                                                                                    dateSurvenance: remboursement.date,
                                                                                                    idActe: val.value,
                                                                                                    codeActe: val.code,
                                                                                                    quantite: 1,
                                                                                                    codeActeProd: val.acteProd,
                                                                                                    montantReclame: remboursement.montant,
                                                                                                    numeroPolice: numeroPolice,
                                                                                                    numeroAssure: line.numBeneficiaire,
                                                                                                    patientId: line.assureId,
                                                                                                    idPrestataire: remboursement.prestataireExecId,
                                                                                                    providerTypeId: remboursement.prestataireExecType
                                                                                                }
                                                                                            ];

                                                                                            dispatch(startLoader());
                                                                                            calculationNewRembBase(data)
                                                                                                .then((response) => {
                                                                                                    let resp = response.data[0];
                                                                                                    let baseRembours = 0;

                                                                                                    baseRembours = Math.min(linA.benefList[j].remboursements[k].montant, resp.montantPolice, resp.montantConvention);

                                                                                                    linA.benefList[j].remboursements[k].actes[r].tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                    linA.benefList[j].remboursements[k].actes[r].baseRemb = baseRembours;
                                                                                                    linA.benefList[j].remboursements[k].actes[r].montantPaye = resp.montantPaye;
                                                                                                    linA.benefList[j].remboursements[k].actes[r].montantExclu = linA.benefList[j].remboursements[k].montant - resp.montantPaye;
                                                                                                    linA.benefList[j].remboursements[k].montantExclu = linA.benefList[j].remboursements[k].montant - resp.montantPaye;
                                                                                                    dispatch(stopLoader());
                                                                                                })
                                                                                                .catch((error) => {
                                                                                                    errorToastr("Erreur lors du calcul des montants", error);
                                                                                                    dispatch(stopLoader());
                                                                                                });
                                                                                            //}
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Base de remb.</strong>
                                                                            </label>
                                                                            <input type='number' className='form-control'
                                                                                value={acte.baseRemb}
                                                                                required
                                                                                disabled={false}
                                                                                onChange={(e) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if (i === l) {
                                                                                            linA.benefList[j].remboursements[k].actes[r].baseRemb = e.target.value;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>{t('common.tm')}</strong>
                                                                            </label>
                                                                            <input type='number' className='form-control'
                                                                                value={acte.tm}
                                                                                required
                                                                                disabled={!remboursement.prestataireExecId}
                                                                                onChange={(e) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if (i === l) {
                                                                                            linA.benefList[j].remboursements[k].actes[r].tm = e.target.value;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Montant à payer</strong>
                                                                            </label>
                                                                            <input type='number' className='form-control'
                                                                                value={acte.montantPaye}
                                                                                required
                                                                                disabled={!remboursement.prestataireExecId}
                                                                                onChange={(e) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if (i === l) {
                                                                                            linA.benefList[j].remboursements[k].actes[r].montantPaye = e.target.value;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>Montant exclu</strong>
                                                                            </label>
                                                                            <input type='number' className='form-control'
                                                                                value={acte.montantExclu}
                                                                                required
                                                                                onChange={(e) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if (i === l) {
                                                                                            linA.benefList[j].remboursements[k].actes[r].montantExclu = e.target.value;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                    if (e.target.value > 0 && remboursement.actes.length === 0) {
                                                                                        addRembActeLine(e, i, j, k)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <div className="form-group">
                                                                            <label className='mb-1 '>
                                                                                <strong>{t('appointment.reason')} Exclusion</strong>
                                                                            </label>
                                                                            <Select
                                                                                className="select-search"
                                                                                value={motifExclusion.filter(cat => { return cat.nameFr === acte.motifExclu })
                                                                                    .map(elt => { return { label: elt.nameFr, value: elt.id } })}
                                                                                options={motifExclusion.map(opt => { return { label: opt.nameFr, value: opt.id } })}
                                                                                placeholder={t('common.choose')}
                                                                                search
                                                                                isRequired
                                                                                onChange={(val) => {
                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                        if (i === l) {
                                                                                            linA.benefList[j].remboursements[k].actes[r].motifExclu = val.label;
                                                                                        }
                                                                                        return linA;
                                                                                    }))
                                                                                }}
                                                                            />

                                                                            {acte.montantExclu > 0 && !acte.motifExclu && <small className="text-warning">
                                                                                veuillez préciser le motif d'exclusion
                                                                            </small>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-1">
                                                                        <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                            onClick={(e) => removeRembActeLine(e, i, j, k, r)}
                                                                        >
                                                                            <i className="fa fa-close"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            })}

                                                            {remboursement.actes.length === 0 && <div className="d-flex justify-content-end">
                                                                <Button variant="danger" onClick={e => addRembActeLine(e, i, j, k)}>
                                                                    Ajouter un acte de consultation
                                                                </Button>
                                                            </div>}
                                                            <div className="border-top mt-2 mb-4"></div>

                                                            {remboursement.details.map((detail, m) => {
                                                                return <>
                                                                    <div className="row mb-2 ml-4" key={m}>
                                                                        <div className="col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className='mb-1 '>
                                                                                    <strong>Categorie</strong>
                                                                                </label>
                                                                                <Select
                                                                                    className="select-search"
                                                                                    value={garanties.filter(cat => { return cat.libelle === detail.categorieName })
                                                                                        .map(elt => { return { label: elt.libelle, value: elt.id } })}
                                                                                    options={garanties.filter(cat => { return cat.id !== remboursement.categorieId })
                                                                                        .map(elt => { return { label: elt.libelle, value: elt.id } })}
                                                                                    placeholder={t('common.choose')}
                                                                                    search
                                                                                    isRequired
                                                                                    onChange={(val) => {
                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                            if (i === l) {
                                                                                                linA.benefList[j].remboursements[k].details[m].categorieId = val.value;
                                                                                                linA.benefList[j].remboursements[k].details[m].categorieName = val.label;
                                                                                            }
                                                                                            return linA;
                                                                                        }))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <div className="col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className='mb-1 '>
                                                                                    <strong>Acte pharmacie</strong>
                                                                                </label>
                                                                                <Select
                                                                                    className="select-search"
                                                                                    value={{ label: detail.actes[0]?.acteName, value: detail.actes[0]?.acteId, acteProd: detail.actes[0]?.acteProdCode, code: detail.actes[0]?.code }}
                                                                                    options={actesByType.map(elt => { return { label: elt.nameFr, value: elt.id, acteProd: elt.acteProdCode, code: elt.code } })}
                                                                                    placeholder={t('common.choose')}
                                                                                    search
                                                                                    isRequired
                                                                                    onInputChange={(val) => {
                                                                                        if (val.length >= 3) {
                                                                                            dispatch(searchActesAction(val));
                                                                                        }
                                                                                    }}
                                                                                    onChange={(val) => {
                                                                                        if (detail.actes.length === 0) {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes = [{
                                                                                                        acteId: val.value,
                                                                                                        acteName: val.label,
                                                                                                        code: val.code,
                                                                                                        acteProdCode: val.acteProd,
                                                                                                        acteAmount: -1,
                                                                                                        montantFacture: detail.montant,
                                                                                                        quantite: 1,
                                                                                                        montantExclu: 0,
                                                                                                        motifExclu: "",
                                                                                                        baseRemb: 0,
                                                                                                        tm: 0,
                                                                                                        montantPaye: 0
                                                                                                    }];
                                                                                                }
                                                                                                return linA;
                                                                                            }));
                                                                                        } else {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes = details.actes.map(act => {
                                                                                                        act.code = val.code;
                                                                                                        act.acteProdCode = val.acteProd;
                                                                                                        return act;
                                                                                                    })
                                                                                                }
                                                                                                return linA;
                                                                                            }));
                                                                                        }

                                                                                        let data = detail.actes.map(act => {
                                                                                            return {
                                                                                                dateSurvenance: detail.date,
                                                                                                idActe: act.acteId,
                                                                                                codeActe: act.code,
                                                                                                quantite: 1,
                                                                                                codeActeProd: act.acteProdCode,
                                                                                                montantReclame: act.montantFacture * act.quantite,
                                                                                                numeroPolice: numeroPolice,
                                                                                                numeroAssure: line.numBeneficiaire,
                                                                                                patientId: line.assureId,
                                                                                                idPrestataire: detail.prestataireExecId,
                                                                                                providerTypeId: detail.prestataireExecType
                                                                                            }
                                                                                        });
                                                                                        dispatch(startLoader());
                                                                                        calculationNewRembBase(data)
                                                                                            .then((response) => {
                                                                                                let responseData = response.data;
                                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                    if (i === l) {
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                            let resp = responseData[i];
                                                                                                            if (resp) {
                                                                                                                let baseRembours = 0;

                                                                                                                baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                act.baseRemb = baseRembours;
                                                                                                                act.montantPaye = resp.montantPaye;
                                                                                                                act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                            }
                                                                                                            return act;

                                                                                                        });

                                                                                                        linA.benefList[j].remboursements[k].details[m].montantExclu = linA.benefList[j].remboursements[k].details[m].montant - responseData[0].montantPaye;
                                                                                                    }
                                                                                                    return linA;
                                                                                                }));
                                                                                                dispatch(stopLoader());
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                errorToastr("Erreur lors du calcul des montants", error);
                                                                                                dispatch(stopLoader());
                                                                                            });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>}

                                                                        <div className="col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className='mb-1 '>
                                                                                    <strong>{t('common.executant')}</strong>
                                                                                </label>
                                                                                <TextInput
                                                                                    className='form-control custom-text-input border'
                                                                                    Component="input"
                                                                                    required
                                                                                    trigger={""}
                                                                                    value={detail.prestataireExecName}
                                                                                    placeholder="Entrer un prestataire"
                                                                                    onChange={val => {
                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                            if (i === l) {
                                                                                                linA.benefList[j].remboursements[k].details[m].prestataireExecName = val;
                                                                                                let provider = providers.filter(prov => { return prov.providerName.trim() === val.trim() })[0];
                                                                                                linA.benefList[j].remboursements[k].details[m].prestataireExecId = provider ? provider.id : null;
                                                                                            }
                                                                                            return linA;
                                                                                        }))
                                                                                    }}
                                                                                    options={providers.map(prov => { return prov.providerName })}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {!detail.prestataireExecId && <div className="col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className='mb-1 '>
                                                                                    <strong>{t('client.type')} Exécutant</strong>
                                                                                    <span className='text-danger ml-1'>*</span>
                                                                                </label>

                                                                                <Select
                                                                                    className="select-search"
                                                                                    value={detail.prestataireExecType ? props.providerTypes?.filter(type => type.id === detail.prestataireExecType)?.map(elt => { return { label: elt.nameFr, value: elt.id } }) : []}
                                                                                    placeholder="Choisir un type"
                                                                                    onChange={(val) => {
                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                            if (i === l) {
                                                                                                linA.benefList[j].remboursements[k].details[m].prestataireExecType = val;
                                                                                            }
                                                                                            return linA;
                                                                                        }))
                                                                                    }}
                                                                                    disabled={props.showLoading}
                                                                                    required
                                                                                    options={props.providerTypes?.map((opt) => { return { label: opt.nameFr, value: opt.id } })}
                                                                                    style={{
                                                                                        lineHeight: '40px',
                                                                                        color: '#7e7e7e',
                                                                                        paddingLeft: ' 15px',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>}

                                                                        <div className="col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className='mb-1 '>
                                                                                    <strong>Montant réclamé</strong>
                                                                                </label>
                                                                                <input type='number' className='form-control'
                                                                                    value={detail.montant}
                                                                                    required
                                                                                    onChange={(e) => {
                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                            if (i === l) {
                                                                                                linA.benefList[j].remboursements[k].details[m].montant = e.target.value;
                                                                                                calculateSums(l, j);
                                                                                            }
                                                                                            return linA;
                                                                                        }));

                                                                                        if (detail.categorieName.toLocaleLowerCase().includes("pharmacie")) {
                                                                                            let data = [{
                                                                                                dateSurvenance: detail.date,
                                                                                                idActe: 0,
                                                                                                codeActe: "PHAR",
                                                                                                quantite: 1,
                                                                                                codeActeProd: detail.categorieId,
                                                                                                montantReclame: e.target.value,
                                                                                                numeroPolice: numeroPolice,
                                                                                                numeroAssure: line.numBeneficiaire,
                                                                                                patientId: line.assureId,
                                                                                                idPrestataire: detail.prestataireExecId,
                                                                                                providerTypeId: detail.prestataireExecType
                                                                                            }];
                                                                                            dispatch(startLoader());
                                                                                            calculationNewRembBase(data)
                                                                                                .then((response) => {
                                                                                                    let responseData = response.data[0];

                                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                        if (i === l) {
                                                                                                            linA.benefList[j].remboursements[k].details[m].montantExclu = linA.benefList[j].remboursements[k].details[m].montant - responseData.montantPaye;
                                                                                                        }
                                                                                                        return linA;
                                                                                                    }));

                                                                                                    dispatch(stopLoader());
                                                                                                })
                                                                                                .catch((error) => {
                                                                                                    errorToastr("Erreur lors du calcul des montants", error);
                                                                                                    dispatch(stopLoader());
                                                                                                });
                                                                                        } else {
                                                                                            if (detail.actes.length > 0) {
                                                                                                let data = detail.actes.map(act => {
                                                                                                    return {
                                                                                                        dateSurvenance: detail.date,
                                                                                                        idActe: act.acteId,
                                                                                                        codeActe: act.acteCode,
                                                                                                        quantite: acte.quantite,
                                                                                                        codeActeProd: act.acteProdCode,
                                                                                                        montantReclame: act.montantFacture * act.quantite,
                                                                                                        numeroPolice: numeroPolice,
                                                                                                        numeroAssure: line.numBeneficiaire,
                                                                                                        patientId: line.assureId,
                                                                                                        idPrestataire: detail.prestataireExecId,
                                                                                                        providerTypeId: detail.prestataireExecType
                                                                                                    }
                                                                                                });
                                                                                                dispatch(startLoader());
                                                                                                calculationNewRembBase(data)
                                                                                                    .then((response) => {
                                                                                                        let responseData = response.data;
                                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                            if (i === l) {
                                                                                                                linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                                    let resp = responseData[i];
                                                                                                                    if (resp) {
                                                                                                                        let baseRembours = 0;

                                                                                                                        baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                        act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                        act.baseRemb = baseRembours;
                                                                                                                        act.montantPaye = resp.montantPaye;
                                                                                                                        act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                                    }
                                                                                                                    return act;

                                                                                                                });

                                                                                                                linA.benefList[j].remboursements[k].details[m].montantExclu = linA.benefList[j].remboursements[k].details[m].montant - responseData.reduce((acc, val) => acc + val.montantPaye, 0);
                                                                                                            }
                                                                                                            return linA;
                                                                                                        }));
                                                                                                        dispatch(stopLoader());
                                                                                                    })
                                                                                                    .catch((error) => {
                                                                                                        errorToastr("Erreur lors du calcul des montants", error);
                                                                                                        dispatch(stopLoader());
                                                                                                    });
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className='mb-1 '>
                                                                                    <strong>Montant exclu</strong>
                                                                                </label>
                                                                                <input type='number' className='form-control'
                                                                                    value={detail.montantExclu}
                                                                                    required
                                                                                    onChange={(e) => {
                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                            if (i === l) {
                                                                                                linA.benefList[j].remboursements[k].details[m].montantExclu = e.target.value;
                                                                                            }
                                                                                            return linA;
                                                                                        }))
                                                                                        if (e.target.value > 0 && detail.actes.length === 0) {
                                                                                            addActeLine(e, i, j, k, m)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && detail.montantExclu > 0 && detail.actes.length === 0 && <small className="text-warning">
                                                                                    veuillez préciser au moins 1 acte
                                                                                </small>}
                                                                            </div>
                                                                        </div>

                                                                        {/*<div className="col-lg-2">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('appointment.reason')} Exclusion</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={motifExclusion.filter(cat => {return cat.nameFr === detail.motifExclu})
                                                                    .map(elt => {return {label:elt.nameFr,value:elt.id}})}
                                                                    options={motifExclusion.map(opt => {return {label:opt.nameFr,value:opt.id}})}
                                                                     placeholder={t('common.choose')}
                                                                    search
                                                                    isRequired
                                                                    onChange={(val) => {
                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                            if(i === l) {
                                                                                linA.benefList[j].remboursements[k].details[m].motifExclu = val.label;
                                                                            }
                                                                            return linA;
                                                                        }))
                                                                    }}
                                                                />
                                                                {detail.montantExclu > 0 && !detail.motifExclu && <small className="text-danger">
                                                                    veuillez préciser le motif
                                                                </small>}
                                                            </div>
                                                        </div>*/}

                                                                        <div className="col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className='mb-1 '>
                                                                                    <strong>Date de soin</strong>
                                                                                </label>
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <DateTimePicker
                                                                                        autoOk
                                                                                        label=""
                                                                                        clearable
                                                                                        format="dd/MM/yyyy hh:mm"
                                                                                        value={detail.date}
                                                                                        disableFuture
                                                                                        required
                                                                                        onChange={(val) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].date = val;
                                                                                                }
                                                                                                return linA;
                                                                                            }))
                                                                                        }}
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                                {detail.date < remboursement.date && <small className="text-danger">
                                                                                    La date de soin est antérieure à la date de consultation
                                                                                </small>}
                                                                            </div>
                                                                        </div>

                                                                        {detail.files.length > 0 && remboursement.files[0] && <div className="col-lg-2">
                                                                            <Button
                                                                                as='a'
                                                                                variant='success light'
                                                                                href={detail.files[0]}
                                                                                target="_blank"
                                                                                className='btn-card mt-3 mr-3'
                                                                            >
                                                                                <i className="la la-file-download"></i>
                                                                                facture
                                                                            </Button>
                                                                        </div>}
                                                                    </div>

                                                                    {detail.actes.map((acte, n) => {
                                                                        return !acte.acteName.toLocaleLowerCase().includes("pharmacie") ? <div className="row mb-2 ml-5" key={n}>
                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") &&
                                                                                            <strong>{t('provider.acte')}</strong>
                                                                                        }
                                                                                        {detail.categorieName.toLocaleLowerCase().includes("pharmacie") &&
                                                                                            <strong>Médicament</strong>
                                                                                        }
                                                                                    </label>
                                                                                    {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <Select
                                                                                        className="select-search"
                                                                                        value={{ label: acte.acteName, value: acte.acteId, acteProd: acte.acteProdCode, code: acte.code }}
                                                                                        options={actes.map(elt => { return { label: elt.nameFr, value: elt.id, acteProd: elt.acteProdCode, code: elt.code } })}
                                                                                        placeholder={t('common.choose')}
                                                                                        search
                                                                                        isRequired
                                                                                        onInputChange={(val) => {
                                                                                            if (val.length >= 3) {
                                                                                                dispatch(searchActesAction(val));
                                                                                            }
                                                                                        }}
                                                                                        onChange={(val) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteId = val.value;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteName = val.label;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteCode = val.code;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteProdCode = val.acteProd;
                                                                                                }
                                                                                                return linA;
                                                                                            }));

                                                                                            let data = detail.actes.map(act => {
                                                                                                return {
                                                                                                    dateSurvenance: detail.date,
                                                                                                    idActe: act.acteId,
                                                                                                    codeActe: act.acteCode,
                                                                                                    quantite: acte.quantite,
                                                                                                    codeActeProd: act.acteProdCode,
                                                                                                    montantReclame: act.montantFacture * act.quantite,
                                                                                                    numeroPolice: numeroPolice,
                                                                                                    numeroAssure: line.numBeneficiaire,
                                                                                                    patientId: line.assureId,
                                                                                                    idPrestataire: detail.prestataireExecId,
                                                                                                    providerTypeId: detail.prestataireExecType
                                                                                                }
                                                                                            });
                                                                                            dispatch(startLoader());
                                                                                            calculationNewRembBase(data)
                                                                                                .then((response) => {
                                                                                                    let responseData = response.data;
                                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                        if (i === l) {
                                                                                                            linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                                let resp = responseData[i];
                                                                                                                if (resp) {
                                                                                                                    let baseRembours = 0;

                                                                                                                    baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                    act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                    act.baseRemb = baseRembours;
                                                                                                                    act.montantPaye = resp.montantPaye;
                                                                                                                    act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                                }
                                                                                                                return act;

                                                                                                            });

                                                                                                            linA.benefList[j].remboursements[k].details[m].montantExclu = linA.benefList[j].remboursements[k].details[m].montant - responseData.reduce((acc, val) => acc + val.montantPaye, 0);
                                                                                                        }
                                                                                                        return linA;
                                                                                                    }));
                                                                                                    dispatch(stopLoader());
                                                                                                })
                                                                                                .catch((error) => {
                                                                                                    errorToastr("Erreur lors du calcul des montants", error);
                                                                                                    dispatch(stopLoader());
                                                                                                });
                                                                                        }}
                                                                                    />}
                                                                                    {detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <Select
                                                                                        className="select-search"
                                                                                        value={{ label: acte.acteName, value: acte.acteId, amount: acte.amount, isExclu: acte.isExclu, isAccord: acte.isAccord }}
                                                                                        options={drugs.map(elt => { return { label: elt.name, value: elt.id, amount: elt.amount, isExclu: elt.isExclu, isAccord: elt.isNeedAccord } })}
                                                                                        placeholder={t('common.choose')}
                                                                                        search
                                                                                        isRequired
                                                                                        onInputChange={(val) => {
                                                                                            if (val.length >= 3) {
                                                                                                dispatch(searchDrugsAction(val));
                                                                                            }
                                                                                        }}
                                                                                        onChange={(val) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteId = val.value;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteName = val.label;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteAmount = val.amount;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantFacture = val.amount;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].code = detail.actes[0].code;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].acteProdCode = detail.actes[0].acteProdCode;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].isExclu = val.isExclu;
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].isAccord = val.isAccord;
                                                                                                }
                                                                                                return linA;
                                                                                            }));

                                                                                            if (val.isExclu || val.isAccord) {
                                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                    if (i === l) {
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].baseRemb = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].montantPaye = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].tm = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].montantExclu = acte.montantFacture * acte.quantite;
                                                                                                        linA.benefList[j].remboursements[k].details[m].montantExclu = detail.actes.reduce((acc, val) => acc + val.montantExclu, 0);
                                                                                                    }
                                                                                                    return linA;
                                                                                                }));
                                                                                            } else {

                                                                                                let data = detail.actes.map(act => {
                                                                                                    return {
                                                                                                        dateSurvenance: detail.date,
                                                                                                        idActe: act.acteId,
                                                                                                        codeActe: act.code,
                                                                                                        quantite: acte.quantite,
                                                                                                        codeActeProd: act.acteProdCode,
                                                                                                        montantReclame: act.montantFacture * act.quantite,
                                                                                                        numeroPolice: numeroPolice,
                                                                                                        numeroAssure: line.numBeneficiaire,
                                                                                                        patientId: line.assureId,
                                                                                                        idPrestataire: detail.prestataireExecId,
                                                                                                        providerTypeId: detail.prestataireExecType
                                                                                                    }
                                                                                                });
                                                                                                dispatch(startLoader());
                                                                                                calculationNewRembBase(data)
                                                                                                    .then((response) => {
                                                                                                        let responseData = response.data;
                                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                            if (i === l) {
                                                                                                                let totExcl = 0;
                                                                                                                linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                                    let resp = responseData[i];
                                                                                                                    if (resp && !act.isExclu && !act.isAccord) {
                                                                                                                        let baseRembours = 0;

                                                                                                                        baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                        act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                        act.baseRemb = baseRembours;
                                                                                                                        act.montantPaye = resp.montantPaye;
                                                                                                                        act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                                    }
                                                                                                                    totExcl += act.montantExclu;
                                                                                                                    return act;

                                                                                                                });

                                                                                                                linA.benefList[j].remboursements[k].details[m].montantExclu = totExcl;
                                                                                                            }
                                                                                                            return linA;
                                                                                                        }));
                                                                                                        dispatch(stopLoader());
                                                                                                    })
                                                                                                    .catch((error) => {
                                                                                                        errorToastr("Erreur lors du calcul des montants", error);
                                                                                                        dispatch(stopLoader());
                                                                                                    });
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                    />}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        <strong>Montant Facture</strong>
                                                                                    </label>
                                                                                    {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <input type='number' className='form-control'
                                                                                        value={acte.montantFacture}
                                                                                        required
                                                                                        onChange={(e) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    let totalDetails = detail.actes.filter(elt => elt.acteId !== acte.acteId).reduce((acc, val) => acc + (val.montantFacture * val.quantite), 0);
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantFacture = (totalDetails + (e.target.value * acte.quantite)) > detail.montant ?
                                                                                                        (detail.montant - totalDetails) / acte.quantite :
                                                                                                        e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }));

                                                                                            let data = detail.actes.map(act => {
                                                                                                return {
                                                                                                    dateSurvenance: detail.date,
                                                                                                    idActe: act.acteId,
                                                                                                    codeActe: act.acteCode,
                                                                                                    quantite: acte.quantite,
                                                                                                    codeActeProd: act.acteProdCode,
                                                                                                    montantReclame: act.montantFacture * act.quantite,
                                                                                                    numeroPolice: numeroPolice,
                                                                                                    numeroAssure: line.numBeneficiaire,
                                                                                                    patientId: line.assureId,
                                                                                                    idPrestataire: detail.prestataireExecId,
                                                                                                    providerTypeId: detail.prestataireExecType
                                                                                                }
                                                                                            });
                                                                                            dispatch(startLoader());
                                                                                            calculationNewRembBase(data)
                                                                                                .then((response) => {
                                                                                                    let responseData = response.data;
                                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                        if (i === l) {
                                                                                                            linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                                let resp = responseData[i];
                                                                                                                if (resp) {
                                                                                                                    let baseRembours = 0;

                                                                                                                    baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                    act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                    act.baseRemb = baseRembours;
                                                                                                                    act.montantPaye = resp.montantPaye;
                                                                                                                    act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                                }
                                                                                                                return act;

                                                                                                            });

                                                                                                            linA.benefList[j].remboursements[k].details[m].montantExclu = linA.benefList[j].remboursements[k].details[m].montant - responseData.reduce((acc, val) => acc + val.montantPaye, 0);
                                                                                                        }
                                                                                                        return linA;
                                                                                                    }));
                                                                                                    dispatch(stopLoader());
                                                                                                })
                                                                                                .catch((error) => {
                                                                                                    errorToastr("Erreur lors du calcul des montants", error);
                                                                                                    dispatch(stopLoader());
                                                                                                });
                                                                                        }}
                                                                                    />}
                                                                                    {detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <input type='number' className='form-control'
                                                                                        value={acte.montantFacture}
                                                                                        required
                                                                                        onChange={(e) => {

                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    let totalDetails = detail.actes.filter((elt, idx) => (idx !== 0 && elt.acteId !== acte.acteId)).reduce((acc, val) => acc + (val.montantFacture * val.quantite), 0);
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantFacture = (totalDetails + (e.target.value * acte.quantite)) > detail.montant ?
                                                                                                        (detail.montant - totalDetails) / acte.quantite :
                                                                                                        e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }));

                                                                                            if (acte.isExclu || acte.isAccord) {
                                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                    if (i === l) {
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].baseRemb = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].montantPaye = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].tm = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].montantExclu = acte.montantFacture * acte.quantite;
                                                                                                        linA.benefList[j].remboursements[k].details[m].montantExclu = detail.actes.reduce((acc, val) => acc + val.montantExclu, 0);
                                                                                                    }
                                                                                                    return linA;
                                                                                                }));
                                                                                            } else {

                                                                                                let data = detail.actes.map(act => {
                                                                                                    return {
                                                                                                        dateSurvenance: detail.date,
                                                                                                        idActe: act.acteId,
                                                                                                        codeActe: act.code,
                                                                                                        quantite: acte.quantite,
                                                                                                        codeActeProd: act.acteProdCode,
                                                                                                        montantReclame: act.montantFacture * act.quantite,
                                                                                                        numeroPolice: numeroPolice,
                                                                                                        numeroAssure: line.numBeneficiaire,
                                                                                                        patientId: line.assureId,
                                                                                                        idPrestataire: detail.prestataireExecId,
                                                                                                        providerTypeId: detail.prestataireExecType
                                                                                                    }
                                                                                                });
                                                                                                dispatch(startLoader());
                                                                                                calculationNewRembBase(data)
                                                                                                    .then((response) => {
                                                                                                        let responseData = response.data;
                                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                            if (i === l) {
                                                                                                                let totExcl = 0;
                                                                                                                linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                                    let resp = responseData[i];
                                                                                                                    if (resp && !act.isExclu && !act.isAccord) {
                                                                                                                        let baseRembours = 0;

                                                                                                                        baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                        act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                        act.baseRemb = baseRembours;
                                                                                                                        act.montantPaye = resp.montantPaye;
                                                                                                                        act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                                    }
                                                                                                                    totExcl += act.montantExclu;
                                                                                                                    return act;

                                                                                                                });

                                                                                                                linA.benefList[j].remboursements[k].details[m].montantExclu = totExcl;
                                                                                                            }
                                                                                                            return linA;
                                                                                                        }));
                                                                                                        dispatch(stopLoader());
                                                                                                    })
                                                                                                    .catch((error) => {
                                                                                                        errorToastr("Erreur lors du calcul des montants", error);
                                                                                                        dispatch(stopLoader());
                                                                                                    });
                                                                                            }
                                                                                        }}
                                                                                    />}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        <strong>Quantité</strong>
                                                                                    </label>
                                                                                    {!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <input type='number' className='form-control'
                                                                                        value={acte.quantite}
                                                                                        required
                                                                                        onChange={(e) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {

                                                                                                    let totalDetails = detail.actes.filter(elt => elt.acteId !== acte.acteId).reduce((acc, val) => acc + (val.montantFacture * val.quantite), 0);

                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantFacture = (totalDetails + (e.target.value * acte.montantFacture)) > detail.montant ?
                                                                                                        (detail.montant - totalDetails) / e.target.value :
                                                                                                        acte.montantFacture;

                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].quantite = e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }));

                                                                                            let data = detail.actes.map(act => {
                                                                                                return {
                                                                                                    dateSurvenance: detail.date,
                                                                                                    idActe: act.acteId,
                                                                                                    codeActe: act.acteCode,
                                                                                                    quantite: acte.quantite,
                                                                                                    codeActeProd: act.acteProdCode,
                                                                                                    montantReclame: act.montantFacture * act.quantite,
                                                                                                    numeroPolice: numeroPolice,
                                                                                                    numeroAssure: line.numBeneficiaire,
                                                                                                    patientId: line.assureId,
                                                                                                    idPrestataire: detail.prestataireExecId,
                                                                                                    providerTypeId: detail.prestataireExecType
                                                                                                }
                                                                                            });
                                                                                            dispatch(startLoader());
                                                                                            calculationNewRembBase(data)
                                                                                                .then((response) => {
                                                                                                    let responseData = response.data;
                                                                                                    setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                        if (i === l) {
                                                                                                            linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                                let resp = responseData[i];
                                                                                                                if (resp) {
                                                                                                                    let baseRembours = 0;

                                                                                                                    baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                    act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                    act.baseRemb = baseRembours;
                                                                                                                    act.montantPaye = resp.montantPaye;
                                                                                                                    act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                                }
                                                                                                                return act;

                                                                                                            });

                                                                                                            linA.benefList[j].remboursements[k].details[m].montantExclu = linA.benefList[j].remboursements[k].details[m].montant - responseData.reduce((acc, val) => acc + val.montantPaye, 0);
                                                                                                        }
                                                                                                        return linA;
                                                                                                    }));
                                                                                                    dispatch(stopLoader());
                                                                                                })
                                                                                                .catch((error) => {
                                                                                                    errorToastr("Erreur lors du calcul des montants", error);
                                                                                                    dispatch(stopLoader());
                                                                                                });
                                                                                        }}
                                                                                    />}
                                                                                    {detail.categorieName.toLocaleLowerCase().includes("pharmacie") && <input type='number' className='form-control'
                                                                                        value={acte.quantite}
                                                                                        required
                                                                                        onChange={(e) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {

                                                                                                    let totalDetails = detail.actes.filter((elt, idx) => (idx !== 0 && elt.acteId !== acte.acteId)).reduce((acc, val) => acc + (val.montantFacture * val.quantite), 0);
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantFacture = (totalDetails + (e.target.value * acte.montantFacture)) > detail.montant ?
                                                                                                        (detail.montant - totalDetails) / e.target.value :
                                                                                                        acte.montantFacture;

                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].quantite = e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }));


                                                                                            if (acte.isExclu || acte.isAccord) {
                                                                                                setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                    if (i === l) {
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].baseRemb = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].montantPaye = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].tm = 0;
                                                                                                        linA.benefList[j].remboursements[k].details[m].actes[n].montantExclu = acte.montantFacture * acte.quantite;
                                                                                                        linA.benefList[j].remboursements[k].details[m].montantExclu = detail.actes.reduce((acc, val) => acc + val.montantExclu, 0);
                                                                                                    }
                                                                                                    return linA;
                                                                                                }));
                                                                                            } else {

                                                                                                let data = detail.actes.map(act => {
                                                                                                    return {
                                                                                                        dateSurvenance: detail.date,
                                                                                                        idActe: act.acteId,
                                                                                                        codeActe: act.code,
                                                                                                        quantite: acte.quantite,
                                                                                                        codeActeProd: act.acteProdCode,
                                                                                                        montantReclame: act.montantFacture * act.quantite,
                                                                                                        numeroPolice: numeroPolice,
                                                                                                        numeroAssure: line.numBeneficiaire,
                                                                                                        patientId: line.assureId,
                                                                                                        idPrestataire: detail.prestataireExecId,
                                                                                                        providerTypeId: detail.prestataireExecType
                                                                                                    }
                                                                                                });
                                                                                                dispatch(startLoader());
                                                                                                calculationNewRembBase(data)
                                                                                                    .then((response) => {
                                                                                                        let responseData = response.data;
                                                                                                        setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                            if (i === l) {
                                                                                                                let totExcl = 0;
                                                                                                                linA.benefList[j].remboursements[k].details[m].actes = detail.actes.map((act, i) => {
                                                                                                                    let resp = responseData[i];
                                                                                                                    if (resp && !act.isExclu && !act.isAccord) {
                                                                                                                        let baseRembours = 0;

                                                                                                                        baseRembours = Math.min(act.montantFacture, resp.montantPolice, resp.montantConvention);

                                                                                                                        act.tm = baseRembours * (100 - resp.tm) / 100;
                                                                                                                        act.baseRemb = baseRembours;
                                                                                                                        act.montantPaye = resp.montantPaye;
                                                                                                                        act.montantExclu = (act.montantFacture * act.quantite) - resp.montantPaye;
                                                                                                                    }
                                                                                                                    totExcl += act.montantExclu;
                                                                                                                    return act;

                                                                                                                });

                                                                                                                linA.benefList[j].remboursements[k].details[m].montantExclu = totExcl;
                                                                                                            }
                                                                                                            return linA;
                                                                                                        }));
                                                                                                        dispatch(stopLoader());
                                                                                                    })
                                                                                                    .catch((error) => {
                                                                                                        errorToastr("Erreur lors du calcul des montants", error);
                                                                                                        dispatch(stopLoader());
                                                                                                    });
                                                                                            }
                                                                                        }}
                                                                                    />}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        <strong>Base de remb.</strong>
                                                                                    </label>
                                                                                    <input type='number' className='form-control'
                                                                                        value={acte.baseRemb}
                                                                                        required
                                                                                        disabled={!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && !detail.prestataireExecId}
                                                                                        onChange={(e) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].baseRemb = e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }))
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        <strong>{t('common.tm')}</strong>
                                                                                    </label>
                                                                                    <input type='number' className='form-control'
                                                                                        value={acte.tm}
                                                                                        required
                                                                                        disabled={!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && !detail.prestataireExecId}
                                                                                        onChange={(e) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].tm = e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }))
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        <strong>Montant à payer</strong>
                                                                                    </label>
                                                                                    <input type='number' className='form-control'
                                                                                        value={acte.montantPaye}
                                                                                        required
                                                                                        disabled={!detail.categorieName.toLocaleLowerCase().includes("pharmacie") && !detail.prestataireExecId}
                                                                                        onChange={(e) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantPaye = e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }))
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        <strong>Montant exclu</strong>
                                                                                    </label>
                                                                                    <input type='number' className='form-control'
                                                                                        value={acte.montantExclu}
                                                                                        required
                                                                                        onChange={(e) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].montantExclu = e.target.value;
                                                                                                }
                                                                                                return linA;
                                                                                            }))
                                                                                            if (e.target.value > 0 && remboursement.actes.length === 0) {
                                                                                                addRembActeLine(e, i, j, k)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-2">
                                                                                <div className="form-group">
                                                                                    <label className='mb-1 '>
                                                                                        <strong>{t('appointment.reason')} Exclusion</strong>
                                                                                    </label>
                                                                                    <Select
                                                                                        className="select-search"
                                                                                        value={motifExclusion.filter(cat => { return cat.nameFr === acte.motifExclu })
                                                                                            .map(elt => { return { label: elt.nameFr, value: elt.id } })}
                                                                                        options={motifExclusion.map(opt => { return { label: opt.nameFr, value: opt.id } })}
                                                                                        placeholder={t('common.choose')}
                                                                                        search
                                                                                        isRequired
                                                                                        onChange={(val) => {
                                                                                            setLigneAssures(ligneAssures.map((linA, l) => {
                                                                                                if (i === l) {
                                                                                                    linA.benefList[j].remboursements[k].details[m].actes[n].motifExclu = val.label;
                                                                                                }
                                                                                                return linA;
                                                                                            }))
                                                                                        }}
                                                                                    />

                                                                                    {acte.montantExclu > 0 && !acte.motifExclu && <small className="text-warning">
                                                                                        veuillez préciser le motif d'exclusion
                                                                                    </small>}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-1">
                                                                                <button type="button" className="btn btn-danger shadow btn-xs sharp mx-1"
                                                                                    onClick={(e) => removeActeLine(e, i, j, k, m, n)}
                                                                                >
                                                                                    <i className="fa fa-close"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div> : <></>

                                                                    })}

                                                                    <div className="d-flex justify-content-end">
                                                                        <Button variant="danger" onClick={e => addActeLine(e, i, j, k, m)}>
                                                                            {detail.categorieName.toLocaleLowerCase().includes("pharmacie") ? "Ajouter un médicament" : "Ajouter un acte"}
                                                                        </Button>
                                                                    </div>
                                                                    <div className="border-top mt-2 mb-4"></div>

                                                                </>
                                                            })}
                                                            <div className="border-top mt-2 mb-4"></div>
                                                        </>
                                                    })
                                                    }
                                                    <div className="border-top mt-2 mb-4"></div>
                                                </>
                                            })}
                                            <div className="border-top border-primary mt-2 mb-4"></div>
                                        </>
                                    })}
                                </section>

                                <div className='row justify-content-center mt-4'>
                                    {showLoading &&
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                        </Button>}
                                    {!showLoading &&
                                        <div className='col-4'>
                                            <input type='submit' className='btn btn-primary btn-block' />
                                        </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        beneficiaires: state.contrat.beneficiaires,
        motifExclusion: state.facturation.motifExclusion,
        rembCalculDatas: state.facturation.rembCalculDatas,
        lettreCles: state.provider.lettreCles,
        remboursement: state.facturation.remboursement,
        providers: state.provider.providers,
        beneficiaires: state.contrat.beneficiaires,
        souscripteurs: state.contrat.souscripteurs,
        assureBenefs: state.contrat.assureBenefs,
        actes: state.provider.actesRes,
        actesConsultation: state.consultation.actesConsultation,
        showLoading: state.facturation.showLoading,
        garanties: state.contrat.garanties,
        drugs: state.consultation.drugs,
        diseases: state.consultation.diseases,
        // choosenContrat : state.police.dateEffet,
        ticketModDatas: state.facturation.ticketModDatas,
        providerTypes: state.provider.providerTypes,
        searchedContrats: state.contrat.searchedContrats,
        actesByType: state.consultation.actesByType,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(RemboursementForm));