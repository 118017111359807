import {
    getSinistres,
    getSinistresByPeriod,
    getSinistresDeclare,
    getRecours,
    getEvaluations,
    getReglements,
    getReglementsByPeriod
} from '../../services/SinistreService';
import { errorToastr } from '../../Utils/ToastrMessage';

export const GET_SINISTRES_FAILED = "[getSinistres action] failed getSinistres";
export const GET_SINISTRES_CONFIRMED = "[getSinistres action] confirmed getSinistres";
export const GET_SINISTRES_BY_PERIODE_CONFIRMED = "[getSinistresByPeriode action] confirmed getSinistresByPeriode";
export const GET_SINISTRES_DECLARE_CONFIRMED = "[getSinistresDeclare action] confirmed getSinistresDeclare";
export const GET_RECOURS_CONFIRMED = "[getRecours action] confirmed getRecours";
export const GET_EVALUATIONS_CONFIRMED = "[getEvaluations action] confirmed getEvaluations";
export const GET_REGLEMENTS_CONFIRMED = "[getReglements action] confirmed getReglements";
export const GET_REGLEMENTS_BY_PERIOD_CONFIRMED = "[getReglementsByPeriode action] confirmed getReglementsByPeriode";
export const START_LOADER_ACTION = "[sinistreLoader action] started sinistreLoader";

var I18n = require('react-redux-i18n').I18n;

export function getSinistresAction(PageNumber, PageSize) {
    return (dispatch) => {
        getSinistres(PageNumber, PageSize)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetSinistresAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('sinistre.error')+error.response?.data?.message);
            dispatch(getSinistresFailedAction(error.response?.data?.message));
        });
    };
}

export function getSinistresByPeriodAction(periodeDebut, periodeFin) {
    return (dispatch) => {
        getSinistresByPeriod(periodeDebut, periodeFin)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetSinistresByPeriodAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('sinistre.error')+error.response?.data?.message);
            dispatch(getSinistresFailedAction(error.response?.data?.message));
        });
    };
}

export function getSinistresDeclareAction(periodeDebut, periodeFin) {
    return (dispatch) => {
        getSinistresDeclare(periodeDebut, periodeFin)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetSinistresDeclareAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('sinistre.error')+error.response?.data?.message);
            dispatch(getSinistresFailedAction(error.response?.data?.message));
        });
    };
}

export function getRecoursAction(periodeDebut, periodeFin) {
    return (dispatch) => {
        getRecours(periodeDebut, periodeFin)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetRecoursAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('sinistre.error')+error.response?.data?.message);
            dispatch(getSinistresFailedAction(error.response?.data?.message));
        });
    };
}

export function getEvaluationsAction(periodeDebut, periodeFin) {
    return (dispatch) => {
        getEvaluations(periodeDebut, periodeFin)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetEvaluationsAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('sinistre.error')+error.response?.data?.message);
            dispatch(getSinistresFailedAction(error.response?.data?.message));
        });
    };
}

export function getReglementsAction(PageNumber, PageSize) {
    return (dispatch) => {
        getReglements(PageNumber, PageSize)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetReglementsAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('sinistre.error')+error.response?.data?.message);
            dispatch(getSinistresFailedAction(error.response?.data?.message));
        });
    };
}

export function getReglementsByPeriodeAction(periodeDebut, periodeFin) {
    return (dispatch) => {
        getReglementsByPeriod(periodeDebut, periodeFin)
        .then((response) => {
            let data = response.data;
            dispatch(confirmedGetReglementsByPeriodeAction(data));
        })
        .catch((error) => {
            errorToastr(I18n.t('sinistre.error')+error.response?.data?.message);
            dispatch(getSinistresFailedAction(error.response?.data?.message));
        });
    };
}

export function getSinistresFailedAction(data) {
    return {
        type: GET_SINISTRES_FAILED,
        payload: data,
    };
}

export function confirmedGetSinistresAction(data) {
    return {
        type: GET_SINISTRES_CONFIRMED,
        payload: data,
    };
}

export function confirmedGetSinistresByPeriodAction(data) {
    return {
        type: GET_SINISTRES_BY_PERIODE_CONFIRMED,
        payload: data,
    };
}

export function confirmedGetSinistresDeclareAction(data) {
    return {
        type: GET_SINISTRES_DECLARE_CONFIRMED,
        payload: data,
    };
}

export function confirmedGetRecoursAction(data) {
    return {
        type: GET_RECOURS_CONFIRMED,
        payload: data,
    };
}

export function confirmedGetEvaluationsAction(data) {
    return {
        type: GET_EVALUATIONS_CONFIRMED,
        payload: data,
    };
}

export function confirmedGetReglementsAction(data) {
    return {
        type: GET_REGLEMENTS_CONFIRMED,
        payload: data,
    };
}

export function confirmedGetReglementsByPeriodeAction(data) {
    return {
        type: GET_REGLEMENTS_BY_PERIOD_CONFIRMED,
        payload: data,
    };
}

export function startLoader() {
   return {
       type: START_LOADER_ACTION
   };
};

