import React from "react";
import { useDispatch } from 'react-redux';
import { updateClientData, checkClientRegistrationAction } from "../../../../store/actions/ClientAuthActions";
import { Link } from "react-router-dom";
import {format} from 'date-fns';

/* --- BEGIN ClientListItem
	@param(Object): props
    description: Listing des clients avec recherche
*/
const ClientListItem = (props) => {
    const { history, client } = props;
    const dispatch = useDispatch();

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");

    /* --- BEGIN chackboxFun
        @param(string): type
        description: Action sur les checkbox de la liste
    */
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };
    /* --- END chackboxFun */

    /* --- BEGIN registerClient
        @param(): aucun
        description: checking du statut d'enrollement du client en vue de l'enrollement
    */
    const registerClient = () => {
        dispatch(checkClientRegistrationAction(client.id, client.idClient, history));
    }
    /* --- END registerClient */

    return (
        <tr role="row" className="odd">
            <td className="patient_checkbox">
                <div className="checkbox text-right align-self-center">
                    <div className="custom-control custom-checkbox ">
                    <input
                        type="checkbox"
                        onClick={() => chackboxFun()}
                        className="custom-control-input"
                        id="customCheckBox1"
                        required
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheckBox1"
                    />
                    </div>
                </div>
            </td>
            <td>{client.idClient}</td>
            <td>{client.firstName+' '+client.lastName}</td>
            <td>{client.idCity}</td>
            <td>{client.phoneNumber}</td>
            {/*<td>{client.email}</td>
            <td>{client.sexe}</td>*/}
            <td>{format(new Date(client.birthdate), 'dd/MM/yyyy')}</td>
            <td className="d-flex">
                <button className="btn btn-primary shadow btn-xs sharp mr-2"
                        onClick={registerClient}
                    >
                    <i className="fa fa-pencil"></i>
                </button>
            </td>
        </tr>
    );
};
/* --- END ClientListItem */

export default ClientListItem;