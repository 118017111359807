import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import { PatientReducer } from './reducers/PatientReducer';
import { AdministrationReducer } from './reducers/AdministrationReducer';
import { ClientAuthReducer } from './reducers/ClientAuthReducer';
import { ProviderReducer } from './reducers/ProviderReducer';
import { ParameterReducer } from './reducers/ParameterReducer';
import { UserReducer } from './reducers/UserReducer';
import { AccordPreaReducer } from './reducers/AccordPreaReducer';
import { NotificationReducer } from './reducers/NotificationReducer';
import { ConsultationReducer } from './reducers/ConsultationReducer';
import { PharmacieReducer } from './reducers/PharmacieReducer';
import { FacturationReducer } from './reducers/FacturationReducer';
import { ContratReducer } from './reducers/ContratReducer';
import { HomeReducer } from './reducers/HomeReducer';
import { RapportReducer } from './reducers/RapportReducer';
import { SinistreReducer } from './reducers/SinistreReducer';
import {
    setLocale,
    loadTranslations,
    syncTranslationWithStore,
    i18nReducer
} from "react-redux-i18n";
import translations from '../i18n/translations';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    
    auth: AuthReducer,
    patient: PatientReducer,
    admin: AdministrationReducer,
    clientAuth: ClientAuthReducer,
    provider: ProviderReducer,
    parameters: ParameterReducer,
    user: UserReducer,
    accordPrea: AccordPreaReducer,
    notification: NotificationReducer,
    consultation: ConsultationReducer,
    pharmacie: PharmacieReducer,
    facturation: FacturationReducer,
    contrat: ContratReducer,
    dataDashboard: HomeReducer,
    rapport: RapportReducer,
    sinistre: SinistreReducer,
    i18n: i18nReducer
});

//const store = createStore(rootReducers);

const store = createStore(reducers,  composeEnhancers(middleware));

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("fr"));

export default store;
