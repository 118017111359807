
import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { getBeneficiairesAction } from '../../../store/actions/ContratActions';
import { registerClientAction, partialEnrollAction, getClientAction, startLoader } from '../../../store/actions/ClientAuthActions';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import swal from "sweetalert";
import {format} from 'date-fns';
import "./PreIdentify.css";
import "./PreIdentify.scss";

const PartialEnroll = (props) => {
    const dispatch = useDispatch();
    const { currentUser, history, clients, client, showLoading } = props;
    const {t}= useTranslation()

    const [assure, setAssure] = useState(null);
    const [telephone, setTelephone] = useState(null);

    const [phoneNumbers, setPhoneNumbers] = useState([]);

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    const enrollClient = (e) => {
        e.preventDefault();
        swal({
            title: t("client.enrol-confir"),
            text:t("client.confirm-phon")+ telephone +t("client.belongs-to")+
                assure.nom
                +" ? ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            }).then((confirm) => {
                if (confirm) {
                    let data = {
                        idClient: assure.id,
                        firstName: assure.nom,
                        lastName: null,
                        phoneNumber: telephone,
                        whatsappPhone: null,
                        lon: null,
                        lat: null,
                        postal: null,
                        idCity: 0,
                        idCountry: 0,
                        email: null,
                        sexe: assure.sexe,
                        birthdate: new Date(assure.dateNaissance),
                        idProfession: 0,
                        idWorkingField: 0,
                        //niu,
                        maritalSituation: null,
                        childNumber: "0",
                        numeroPolice: assure.numeroPolice,
                        numeroBeneficiaire: assure.numBeneficiaire,
                        numeroBeneficiairePrin: assure.numBeneficiairePrincipal,
                        nomBeneficiairePrin: assure.nomBeneficiairePrinc,
                        telBeneficiairePrin: assure.telBeneficiairePrinc,
                        numeroAssurer: assure.numBeneficiairePrincipal,
                        souscripteur: assure.souscripteur?.nom_Assu,
                        assureur: assure.police?.codeIntermediaire,
                        numeroAssureur: assure.police?.codeIntermediaire,
                        phoneNumbers: phoneNumbers.map(elt => {
                            return {phoneNumber: elt}
                        }),
                        clientIdDetails: null,
                        healhtParameters: null
                    }
                    dispatch(startLoader());
                    dispatch(partialEnrollAction(data, history));
                }
        })
    }

    useEffect(() => {
        if (hasMenu('MENENROLL')) {
        } else {
            props.history.push("/");
        }
    }, []);

    return (
        <>
            <div className="page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                        <Link to="#">Patient</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="#">Authentification</Link>
                    </li>
                </ol>
            </div>
            <div className="authincation h-100 p-meddle">
                <div className="container h-100" style={{ position: 'fixed' }}>


                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">

                            < div className="authincation-content" >
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form row">
                                            <h3 className="text-center mb-2">
                                                 {t("patient.enrollment-patient")}
                                            </h3>
                                            <form onSubmit={(e) => enrollClient(e)} style={{width: '100%'}}>
                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {assure && <div className='row mb-2' 
                                                            style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--highlight-text-color)', borderRadius: 'var(--border-radius)', padding: '5px'}}
                                                            >
                                                                <div className='col-6'>
                                                                    <label className='mb-1'>
                                                                        <strong>{t("common.name")}: </strong>
                                                                        <span>{assure.nom}</span>
                                                                    </label>
                                                                    <label className='mb-1 '>
                                                                        <strong>{t('common.insured-number')}: </strong>
                                                                        <span>{assure.id}</span>
                                                                    </label>
                                                                    {client.id && <label className='mb-1 '>
                                                                        <strong>{t("common.status")}: </strong>
                                                                        <span>{client ? t("common.enrole-on") +format(new Date(client.created), 'dd/MM/yyyy hh:mm') : "Non enrolé"}</span>
                                                                    </label>}
                                                                </div>
                                                                <div className='col-6'>
                                                                    <label className='mb-1 '>
                                                                        <strong>{t("common.gender")}: </strong>
                                                                        <span>{assure.sexe === 'F' ? 'Féminin' : 'Masculin'}</span>
                                                                    </label><br/>
                                                                    <label className='mb-1'>
                                                                        <strong>{t("common.born-on")}: </strong>
                                                                        <span>{assure.dateNaissance}</span>
                                                                    </label>
                                                                    {client.id && <label className='mb-1'>
                                                                        <strong>{t("common.phone")}: </strong>
                                                                        <span>{client.phoneNumber}</span>
                                                                    </label>}
                                                                </div>
                                                            </div>}
                                                            {!client.id && <div className='form-group'>
                                                                <label className='mb-1 '>
                                                                    <strong>{t("common.insured-search")}</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    options={clients.map(elt => {return {...elt, label: elt.id+' - '+elt.nom, value: elt.id}})}
                                                                    placeholder={t("common.enter-insured-name")}
                                                                    search
                                                                    isDisabled={showLoading}
                                                                    onInputChange={(val) => {
                                                                        if(val.length >= 1){
                                                                            dispatch(getBeneficiairesAction(val, null, null));
                                                                        }
                                                                    }}
                                                                    onChange={(val) => {
                                                                            dispatch(startLoader());
                                                                            dispatch(getClientAction(val.value));
                                                                            setAssure(val)
                                                                        }
                                                                    }
                                                                />
                                                            </div>}
                                                            {!client.id && <div className='form-group'>
                                                                <label className='mb-1 '>
                                                                    <strong>{t("common.phone")}</strong>
                                                                </label>
                                                                <PhoneInput
                                                                    country='cd'
                                                                    onlyCountries={['cm','ci','cd']}
                                                                    localization='fr'
                                                                    value={telephone}
                                                                    disabled={!assure || showLoading}
                                                                    onChange={phone => setTelephone(phone)}
                                                                />
                                                            </div>}
                                                            {!client.id && 
                                                                <div>
                                                                    {phoneNumbers.map((phone, i) => (<div className='form-group' style={{position: "relative"}} key={i}>
                                                                        <label className='mb-1 '>
                                                                            <strong>{t("common.additional-phone")}{' '+(i+1)}</strong>
                                                                        </label>
                                                                        <PhoneInput
                                                                            country='cd'
                                                                            onlyCountries={['cm','ci','cd']}
                                                                            localization='fr'
                                                                            value={phone}
                                                                            disabled={!assure || showLoading}
                                                                            onChange={val => {
                                                                                let temp = [...phoneNumbers];
                                                                                temp[i] = val;
                                                                                setPhoneNumbers(temp);
                                                                            }}
                                                                        />
                                                                        <a
                                                                            type="button"
                                                                            className='icons-sm text-danger'
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: '-35px',
                                                                                right: '-15px',
                                                                            }}
                                                                            onClick={() => {
                                                                                const temp = [...phoneNumbers];
                                                                                temp.splice(i, 1);
                                                                                setPhoneNumbers(temp);
                                                                            }}
                                                                        >
                                                                            <i class="fa fa-close"></i>
                                                                        </a>
                                                                    </div>))}
                                                                </div>
                                                            }
                                                            {!client.id && 
                                                                <Button 
                                                                    label={t("common.add-phone")}
                                                                    icon="pi pi-plus"
                                                                    severity="info"
                                                                    onClick={(e) =>{ 
                                                                        e.preventDefault();
                                                                        setPhoneNumbers(prevPhoneNumbers => [...prevPhoneNumbers, ""]);
                                                                    }}
                                                                />
                                                            }
                                                            { !client.id && showLoading && 
                                                                <div className='row justify-content-center mt-4'>
                                                                    <div className='col-4'>
                                                                        <Button variant="primary" disabled>
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />{' '}
                                                                            <span className="visually-hidden">{t("common.await-text")}</span>
                                                                        </Button> 
                                                                    </div>
                                                                </div>}
                                                            { !client.id && !showLoading &&
                                                                <div className='row justify-content-center mt-4'>
                                                                    <div className='col-4'>
                                                                        <input type='submit' disabled={!assure || !telephone || phoneNumbers.filter(elt => elt.trim().length === 0).length > 0 || showLoading} className='btn btn-primary btn-block'/>
                                                                    </div>
                                                                </div>}
                                                            { client.id &&
                                                                <div className='row justify-content-center mt-4'>
                                                                    <div className='col-4'>
                                                                        <input type='button' value={t("common.identify")} onClick={e => history.push('/identification-assure')} className='btn btn-primary btn-block'/>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >

        </>
    )
};
const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state),
        clients: state.contrat.beneficiaires,
        client: state.clientAuth.client,
        showLoading: state.clientAuth.showLoading,
    };
};
export default withRouter(connect(mapStateToProps)(PartialEnroll));